import type { Account, AccountCardDetail } from '../modules/auth/types';
import CreditCard from '../resources/icons/CreditCard.svg';
import { IsIntegerRewardUnit } from './constants';
import { formatNumberToLocaleString, parseNumber } from './util';

export const getAccountCardDetails = (params: {
  account: Account | undefined;
}): AccountCardDetail[] => {
  const { account } = params;

  if (!account) {
    return [];
  }

  const {
    loyalty_program: {
      /** Image URL of the account card */
      program_logo_url: accountCardImageUrl,
      /** Last 4 digits of the account card number as string, e.g. '1234' */
      last_four: accountCardLastFourDigits = '',
      /** Number of available reward units, e.g. 123456 */
      number_of_units: numberOfUnits,
      /** Number of dollars that is equivalent to 1 reward unit, e.g. 0.01 */
      redeem_per_dollar: redeemPerRewardUnit, // Note: API property name 'redeem_per_dollar' is misleading, it should be called 'redeem_per_unit'.
      /** Loyalty unit display name from API, e.g. 'miles', 'points' */
      unit_display_name: unitDisplayName = '',
      /** Loyalty unit name from API, e.g. 'MILES', 'POINTS'. To be used to call API to create order. */
      loyalty_unit_name: loyaltyUnitName = '',
    },
  } = account;

  /** Lower cased reward unit name, e.g. 'miles', 'points', etc. */
  const rewardUnitName: string = (unitDisplayName || loyaltyUnitName).toLowerCase() || '';

  /** True only for miles and points units */
  const isIntegerRewardUnit: boolean = IsIntegerRewardUnit[rewardUnitName] ?? false;

  /** Validated total number of available reward units, e.g. 123456. 0 if the value is not a number. */
  const rewardUnitsTotal: number = parseNumber(numberOfUnits, { minValue: 0, allowDecimals: !isIntegerRewardUnit }) ?? 0;

  /** Validated number of reward units that is equivalent to $1, e.g. 100. 1 if the value is not a number. */
  const rewardUnitsPerDollar: number = parseNumber(1 / redeemPerRewardUnit, { minValue: 0, allowDecimals: true }) ?? 1;

  /** Formatted number of available reward units, e.g. '123,456 miles', '123,456 points', '$123,456' */
  const formattedRewardUnitsTotal: string = rewardUnitName
    ? formatNumberToLocaleString({ num: rewardUnitsTotal, unitName: rewardUnitName, shouldIncludeUnitName: true })
    : '';

  return [
    {
      accountCardImageUrl: accountCardImageUrl || CreditCard,
      accountCardLastFourDigits,
      rewardUnitsTotal,
      rewardUnitsPerDollar,
      rewardUnitName,
      isIntegerRewardUnit,
      formattedRewardUnitsTotal,
    },
  ];
};
