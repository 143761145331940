import React from 'react';
import { Modal } from 'react-bootstrap';
import { PreCheckoutDetails } from '../../molecules/PreCheckoutDetails';
import styles from './GuestListPreCheckoutModal.module.scss';
import { usePresenter } from './GuestListPreCheckoutModal.presenter';
import type { GuestListPreCheckoutModalProps } from './GuestListPreCheckoutModal.types';

export const GuestListPreCheckoutModal: React.FC<GuestListPreCheckoutModalProps> = (props) => {
  const {
    listingDetailQueryParams,
    listingDetail,
    closePreCheckout,
  } = usePresenter(props);

  return (
    <Modal
      show={true}
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      onHide={closePreCheckout}
    >
      <PreCheckoutDetails
        listingDetailQueryParams={listingDetailQueryParams}
        listingDetail={listingDetail}
        shouldShowAipOverride={false}
        onCloseButtonClicked={closePreCheckout}
        theme='dark'
        isDesktopResponsive={true}
        classes={{
          topContent: styles.topContent,
          bottomContent: styles.bottomContent,
        }}
      />
    </Modal>
  );
};
