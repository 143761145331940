import type { DividerProps } from '../../atoms/Divider';
import type { SportsMenuTabListProps } from '../../molecules/SportsMenuTabList';
import type { SubmenuItemListProps } from '../../molecules/SubmenuItemList';

type SubmenuWithDoubleTabsInternalProps = {
  sportsMenuTabList?: SportsMenuTabListProps;
  dividerOne?: DividerProps;
  sportsMenuTabList1?: SportsMenuTabListProps;
  submenuItemListOne?: SubmenuItemListProps;
  submenuItemListTwo?: SubmenuItemListProps;
};

type SubmenuWithDoubleTabsPropsValues = {
  '': SubmenuWithDoubleTabsInternalProps;
};

const propValues: SubmenuWithDoubleTabsPropsValues = {
  '': {
    sportsMenuTabList: {
    },
    dividerOne: {
      type: 'Vertical',
      style: 'Thin',
      colour: 'Grey20',
    },
    sportsMenuTabList1: {
    },
    submenuItemListOne: {
    },
    submenuItemListTwo: {
    },
  },
};

const getProps = (type: string): SubmenuWithDoubleTabsInternalProps => {
  const values: SubmenuWithDoubleTabsInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
