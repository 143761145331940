import type { LogoTheme } from '../../atoms/Logo';
import type { TextColourEnum } from '../../atoms/Text';
import type { FooterTheme } from './Footer.types';

export const LogoThemesByFooterTheme = {
  light: 'dark',
  dark: 'light',
} as const satisfies Record<FooterTheme, LogoTheme>;

export const FooterButtonTextColoursByFooterTheme = {
  light: 'ActionBase',
  dark: 'BaseLight',
} as const satisfies Record<FooterTheme, TextColourEnum>;
