import { CA_PROVINCES, COUNTRIES, US_STATES } from '../../../lib/constants';
import i18n from '../../../modules/locale/i18n';
import type { TextDropdownOption } from '../../molecules/TextDropdown';
import type { BillingAddress, CountryCode } from './AddressInfo.types';

export const REQUIRED_ADDRESS_FIELDS = [
  'firstName',
  'lastName',
  'streetAddress',
  'city',
  'state',
  'postalCode',
  'country',
  'phoneNumber',
] as const satisfies ReadonlyArray<keyof BillingAddress>;

export const CITY_INPUT_FIELD_MAX_LENGTH = 35;
export const CITY_INPUT_FIELD_REMOVE_INVALID_CHAR_REGEX = /[^a-zA-Z- ]/g;

export const US_POSTAL_CODE_MAX_LENGTH = 5;
export const NON_US_POSTAL_CODE_MAX_LENGTH = 6;
export const POSTAL_CODE_INPUT_FIELD_REMOVE_INVALID_CHAR_REGEX = /[^a-zA-Z0-9]/g;

export const DefaultShippingAddressErrors = {
  firstName: false,
  lastName: false,
  streetAddress: false,
  streetAddressLine2: false,
  city: false,
  state: false,
  postalCode: false,
  country: false,
  phoneNumber: false,
} as const satisfies Record<keyof BillingAddress, boolean>;

export const DefaultShippingAddressChanges = {
  firstName: false,
  lastName: false,
  streetAddress: false,
  streetAddressLine2: false,
  city: false,
  state: false,
  postalCode: false,
  country: false,
  phoneNumber: false,
} as const satisfies Record<keyof BillingAddress, boolean>;

const SelectCountryOption: TextDropdownOption = { value: '', label: i18n.t('addressInfo.fields.country.placeholder') };
export const CountryOptions: TextDropdownOption[] = [SelectCountryOption].concat(COUNTRIES.map(({ code }) => ({ value: code, label: code })));

const SelectStateProvinceOption: TextDropdownOption = { value: '', label: i18n.t('addressInfo.fields.state.placeholder') };
const UsStateOptions: TextDropdownOption[] = US_STATES.map(({ code }) => ({ value: code, label: code }));
const CaProvinceOptions: TextDropdownOption[] = CA_PROVINCES.map(({ code }) => ({ value: code, label: code }));

export const StateProvinceOptionsByCountryCode = {
  US: [SelectStateProvinceOption].concat(UsStateOptions),
  CA: [SelectStateProvinceOption].concat(CaProvinceOptions),
  '': [SelectStateProvinceOption].concat(UsStateOptions).concat(CaProvinceOptions),
} as const satisfies Record<CountryCode, TextDropdownOption[]>;
