import cx from 'classnames';
import React from 'react';

import SubmenuItem from '../SubmenuItem';
import styles from './SubmenuItemList.module.scss';
import usePresenter from './SubmenuItemList.presenter';
import getProps from './SubmenuItemList.props';
import { SubmenuItemListCombinedProps } from './types';

const SubmenuItemList: React.FC<SubmenuItemListCombinedProps> = (props) => {
  const {
    className,
    classes,
    submenuItems,
    focusable, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.submenuItemList;

  const submenuItemViews = submenuItems?.map((submenuItem, index) => (
    <SubmenuItem
      focusable={focusable}
      key={index}
      className={cx(styles.submenuItem, classes?.submenuItem)}
      {...internalProps.submenuItem}
      {...submenuItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {submenuItemViews}
    </div>
  );
};

export default SubmenuItemList;
