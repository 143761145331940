import React, { type ReactNode } from 'react';
import { CATEGORY_PARAM, PERFORMER_PARAM } from '../../../lib/constants';
import { EventListProvider } from '../../../modules/eventList';
import { AllEventsPageMLBBannerProps, Banner } from '../../molecules/Banner';
import type { EmptyStateProps } from '../../molecules/EmptyState';
import { DateFilter } from '../../organisms/DateFilter';
import type { PaginatedListClasses } from '../../organisms/PaginatedList';
import { PresetFilter } from '../../organisms/PresetFilter';
import { RegionFilter } from '../../organisms/RegionFilter';
import { TabBar } from '../../organisms/TabBar';
import { BaseEventListPage } from '../BaseEventListPage';
import baseEventListPageStyles from '../BaseEventListPage/BaseEventListPage.module.scss';
import { TRACKING_PAGE_NAME } from './AllEventsPage.constants';
import styles from './AllEventsPage.module.scss';
import { usePresenter } from './AllEventsPage.presenter';
import type { AllEventsPageProps } from './AllEventsPage.types';

const InternalAllEventsPage: React.FC<AllEventsPageProps> = (props) => {
  const {
    tabs,
    regions,
    regionFilterState,
    dateFilterState,
    shouldShowCategoryFilter,
    categoryFilterPlaceholderKey,
    categoryFilterOptions,
    categoryFilterState,
    shouldShowPerformerFilter,
    performerFilterPlaceholderKey,
    performerFilterOptions,
    performerFilterState,
    isPerformerFilterDisabled,
    excludedSubCategoryName,
    className,
  } = usePresenter(props);

  const topContent: ReactNode = (<>
    <div className={styles.tabsContainer}>
      <div className={styles.leftSpacer} />
      <TabBar tabs={tabs} />
      <div className={styles.rightSpacer} />
    </div>
    <div className={baseEventListPageStyles.filtersContainer}>
      <div className={baseEventListPageStyles.filter}>
        <RegionFilter
          regions={regions}
          regionFilterState={regionFilterState}
          canSearchRegion={true}
          canUseCurrentRegion={true}
        />
      </div>
      <div className={baseEventListPageStyles.filter}>
        <DateFilter dateFilterState={dateFilterState} />
      </div>
      {shouldShowCategoryFilter && (
        <div className={baseEventListPageStyles.filter}>
          <PresetFilter
            queryParamName={CATEGORY_PARAM}
            presetFilterState={categoryFilterState}
            presetFilterOptions={categoryFilterOptions}
            placeholderKey={categoryFilterPlaceholderKey}
          />
        </div>
      )}
      {shouldShowPerformerFilter && (
        <div className={baseEventListPageStyles.filter}>
          <PresetFilter
            queryParamName={PERFORMER_PARAM}
            presetFilterState={performerFilterState}
            presetFilterOptions={performerFilterOptions}
            placeholderKey={performerFilterPlaceholderKey}
            isDisabled={isPerformerFilterDisabled}
          />
        </div>
      )}
    </div>
  </>);

  const mlbBanner: ReactNode = excludedSubCategoryName === 'MLB Baseball' && (
    <Banner {...AllEventsPageMLBBannerProps} />
  );

  const emptyStateProps: EmptyStateProps = {
    titleKey: 'allEventsPage.noResultsTitle',
    descriptionKey: 'allEventsPage.noResultsDescription',
  };

  const paginatedListClasses: PaginatedListClasses | undefined = excludedSubCategoryName === 'MLB Baseball'
    ? { itemList: styles.paginatedItemListWithBanner }
    : undefined;

  return (
    <BaseEventListPage
      topContent={topContent}
      topEventListContent={mlbBanner}
      // TODO: Check if we need to track analytics for fetch requests
      trackFetchAnalytics={undefined}
      emptyStateProps={emptyStateProps}
      pageName={TRACKING_PAGE_NAME.forEventCardClicks}
      className={className}
      classes={paginatedListClasses}
    />
  );
};

export const AllEventsPage: React.FC<AllEventsPageProps> = (props) => {
  return (
    <EventListProvider>
      <InternalAllEventsPage {...props} />
    </EventListProvider>
  );
};
