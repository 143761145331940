import { useCallback, useEffect, useMemo, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { processRawHtmlContent } from '../../../lib/util';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import type { EventInfoModalPresenterProps, EventInfoModalProps } from './EventInfoModal.types';

export const usePresenter = (props: EventInfoModalProps): EventInfoModalPresenterProps => {
  const {
    eventMetaData: {
      eventDescription: initialEventDescription = '',
    },
    closeInfoModal: initialCloseInfoModal,
  } = props;

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  // Track analytics for opening of the modal
  useEffect(() => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'Button',
      t('event.openEventInfoModal'),
    );
  }, [t, trackEvent]);

  /** Processed event description that may contain HTML tags */
  const processedEventDescriptionHtml: ReactNode = useMemo(
    () => processRawHtmlContent({ initialRawHtmlContent: initialEventDescription }),
    [initialEventDescription],
  );

  const closeInfoModal = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'EventDetailsModal',
      'Button',
      t('event.closeEventInfoModal'),
    );

    initialCloseInfoModal();
  }, [t, trackEvent, initialCloseInfoModal]);

  return {
    ...props,
    processedEventDescriptionHtml,
    closeInfoModal,
  };
};
