import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import { MLB_PRIVACY_POLICY_URL } from './MarketingOptinMlb.constants';
import styles from './MarketingOptinMlb.module.scss';
import { usePresenter } from './MarketingOptinMlb.presenter';
import type { MarketingOptinMlbProps } from './MarketingOptinMlb.types';

export const MarketingOptinMlb: React.FC<MarketingOptinMlbProps> = (props) => {
  const {
    email,
    teamName,
    isExpanded,
    toggleIsExpanded,
    hasSubscribeButtonBeenClicked,
    subscribeButtonType,
    subscribeButtonTextKey,
    onSubscribeButtonClick,
  } = usePresenter(props);

  const { t } = useTranslation();

  const toggleButton: ReactNode = (
    <Button
      type='TextIcon'
      style='Text'
      size='Small'
      icon={{
        asset: isExpanded ? 'ChevronUp' : 'ChevronDown',
        style: 'SubduedDark',
      }}
      text={{ value: t('orderConfirmationInfo.marketingOptinMlb.title') }}
      onClick={toggleIsExpanded}
      className={styles.toggleButton}
      classes={{
        text: styles.toggleButtonText,
        icon: styles.toggleButtonIcon,
      }}
    />
  );

  const expandedContent: ReactNode = !!isExpanded && (
    <div className={styles.expandedContent}>
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedDark'
        value={(
          <Trans
            i18nKey='orderConfirmationInfo.marketingOptinMlb.expandedContent'
            values={{ email, teamName }}
            components={{
              mlbPrivacyPolicyLink: <a href={MLB_PRIVACY_POLICY_URL} target='_blank' rel='noreferrer' className={styles.underlineText} />,
              strong: <strong className={styles.boldText} />,
            }}
          />
        )}
        classes={{ value: styles.expandedText }}
      />
      <Text
        size='Small'
        style='Regular'
        colour='SubduedLight'
        value={t('orderConfirmationInfo.marketingOptinMlb.expandedContentNote')}
      />
      <Button
        type={subscribeButtonType}
        style='Text'
        size='Small'
        icon={{
          asset: 'Checkmark',
          style: 'BaseDark',
        }}
        text={{
          value: t(subscribeButtonTextKey),
          className: styles.subscribeButtonText,
        }}
        onClick={onSubscribeButtonClick}
        className={cx(styles.subscribeButton, { [styles.isSubscribed]: hasSubscribeButtonBeenClicked })}
      />
    </div>
  );

  return (
    <div className={styles.marketingOptinMlbContainer}>
      {toggleButton}
      {expandedContent}
    </div>
  );
};
