import React, { type ReactNode } from 'react';
import { CATEGORY_PARAM } from '../../../lib/constants';
import { EventListProvider } from '../../../modules/eventList';
import { PageContentContainerLayout } from '../../layout/PageContentContainerLayout';
import { EmptyState, type EmptyStateProps } from '../../molecules/EmptyState';
import { ImageAndTitle } from '../../molecules/ImageAndTitle';
import { DateFilter } from '../../organisms/DateFilter';
import { PresetFilter } from '../../organisms/PresetFilter';
import { BaseEventListPage } from '../BaseEventListPage';
import baseEventListPageStyles from '../BaseEventListPage/BaseEventListPage.module.scss';
import { TRACKING_PAGE_NAME } from './VenuePage.constants';
import { usePresenter } from './VenuePage.presenter';
import type { VenuePageProps } from './VenuePage.types';

export const InternalVenuePage: React.FC<VenuePageProps> = (props) => {
  const {
    isVenueInvalid,
    venue,
    categoryFilterOptions,
    categoryFilterState,
    dateFilterState,
    className,
  } = usePresenter(props);

  const emptyStateProps: EmptyStateProps = {
    titleKey: 'venuePage.noResultsTitle',
    descriptionKey: 'venuePage.noResultsDescription',
  };

  const filters: ReactNode = (
    <div className={baseEventListPageStyles.filtersContainer}>
      <div className={baseEventListPageStyles.filter}>
        <PresetFilter
          queryParamName={CATEGORY_PARAM}
          presetFilterState={categoryFilterState}
          presetFilterOptions={categoryFilterOptions}
          placeholderKey='venuePage.category'
        />
      </div>
      <div className={baseEventListPageStyles.filter}>
        <DateFilter dateFilterState={dateFilterState} />
      </div>
    </div>
  );

  if (isVenueInvalid) {
    return (
      <PageContentContainerLayout className={className}>
        {filters}
        <EmptyState {...emptyStateProps} />
      </PageContentContainerLayout>
    );
  }

  const topContent: ReactNode = (<>
    {!!venue && (
      <div className={baseEventListPageStyles.imageAndTitleContainer}>
        <ImageAndTitle
          imageUrl={venue.image}
          title={venue.name}
        />
      </div>
    )}
    {filters}
  </>);

  return (
    <BaseEventListPage
      topContent={topContent}
      topEventListContent={null}
      // TODO: Check if we need to track analytics for fetch requests
      trackFetchAnalytics={undefined}
      emptyStateProps={emptyStateProps}
      pageName={TRACKING_PAGE_NAME.forEventCardClicks}
      className={className}
    />
  );
};

export const VenuePage: React.FC<VenuePageProps> = (props) => {
  return (
    <EventListProvider>
      <InternalVenuePage {...props} />
    </EventListProvider>
  );
};
