import { SubmenuItemListProps } from '../../molecules/SubmenuItemList';

type SubmenuWithoutTabsInternalProps = {
  submenuItemList?: SubmenuItemListProps;
};

type SubmenuWithoutTabsPropsValues = {
  '': SubmenuWithoutTabsInternalProps;
};

const propValues: SubmenuWithoutTabsPropsValues = {
  '': {
    submenuItemList: {
    },
  },
};

const getProps = (type: string): SubmenuWithoutTabsInternalProps => {
  const values: SubmenuWithoutTabsInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
