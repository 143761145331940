import type { Event, EventPageResponse, ListingDetails, Performer, SearchPageResponse, SearchSuggestionsResponse } from './types';

const NAME_REGEX = /taylor\s*swift/i;

export const matchesTaylorSwift = (value?: string): boolean => {
  if (!value) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  const matches = value.match(NAME_REGEX);
  if (matches) {
    return true;
  }
  return false;
};

export const matchesPerformer = ({ name }: Performer): boolean => {
  return matchesTaylorSwift(name);
};

export const matchesEvent = ({ name, performers }: Event): boolean => {
  if (matchesTaylorSwift(name)) {
    return true;
  }
  if (performers && performers.length) {
    for (let i = 0; i < performers.length; i++) {
      if (matchesPerformer(performers[i])) {
        return true;
      }
    }
  }
  return false;
};

export const matchesListingDetails = ({ event }: ListingDetails): boolean => {
  return matchesEvent(event);
};

export const filterEvents = (events: Event[]): Event[] => {
  return events.filter((event) => !matchesEvent(event));
};

export const filterPerformers = (performers: Performer[]): Performer[] => {
  return performers.filter((perfomer) => !matchesPerformer(perfomer));
};

export const filerSearchResults = (data: SearchPageResponse): SearchPageResponse => {
  return {
    ...data,
    items: filterEvents(data.items),
  };
};

export const filterSearchSuggestions = (data: SearchSuggestionsResponse): SearchSuggestionsResponse => {
  return {
    ...data,
    performers: filterPerformers(data.performers),
    events: filterEvents(data.events),
  };
};

export const filterEventPage = (data: EventPageResponse): EventPageResponse => {
  return {
    ...data,
    items: filterEvents(data.items),
  };
};

/**
 * Function to check if search suggestions actually have at least one suggestion.
 * @param searchSuggestions Search suggestions to be checked.
 * @returns {boolean} True if there is at least one event or performer or venue. Otherwise it's False.
 */
export const checkHasSearchSuggestions = (searchSuggestions: SearchSuggestionsResponse | undefined): boolean => {
  const { events = [], performers = [], venues = [] } = searchSuggestions ?? {};
  return !!events.length || !!performers.length || !!venues.length;
};
