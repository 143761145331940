import type { IconAssetEnum } from '../../atoms/Icon';
import type { LogoTheme } from '../../atoms/Logo';
import type { TextColourEnum } from '../../atoms/Text';
import type { ExclusiveBadgeTheme } from '../ExclusiveBadge';
import type { IconTextColor } from '../IconText';
import type { SubTotalTheme } from '../SubTotal';
import type { PreCheckoutDetailsTheme } from './PreCheckoutDetails.types';

export const CardholderExclusiveBadgeThemesByTheme = {
  light: 'dark',
  dark: 'light',
} as const satisfies Record<PreCheckoutDetailsTheme, ExclusiveBadgeTheme>;

export const PrimaryColoursByTheme = {
  light: 'SubduedDark',
  dark: 'BaseLight',
} as const satisfies Record<PreCheckoutDetailsTheme, TextColourEnum>;

export const SecondaryColoursByTheme = {
  light: 'SubduedLight',
  dark: 'BaseLight',
} as const satisfies Record<PreCheckoutDetailsTheme, TextColourEnum>;

export const IconTextColoursByTheme = {
  light: 'SubduedDark',
  dark: 'Charcoal10',
} as const satisfies Record<PreCheckoutDetailsTheme, IconTextColor>;

export const TicketsByLogoThemesByTheme = {
  light: 'dark',
  dark: 'light',
} as const satisfies Record<PreCheckoutDetailsTheme, LogoTheme>;

export const SellerNotesIconAssetsByTheme = {
  light: 'ProfileThin',
  dark: 'ProfileFilled',
} as const satisfies Record<PreCheckoutDetailsTheme, IconAssetEnum>;

export const TicketNotesIconAssetsByTheme = {
  light: 'TicketTilt',
  dark: 'PhoneMobile',
} as const satisfies Record<PreCheckoutDetailsTheme, IconAssetEnum>;

export const SubTotalThemesByTheme = {
  light: 'dark',
  dark: 'light',
} as const satisfies Record<PreCheckoutDetailsTheme, SubTotalTheme>;

export const TicketsGuaranteedIconAssetsByTheme = {
  light: 'CheckmarkInCircle',
  dark: 'CheckmarkInCircleFilled',
} as const satisfies Record<PreCheckoutDetailsTheme, IconAssetEnum>;
