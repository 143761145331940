import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../atoms/Icon';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';
import styles from './ExclusiveBadge.module.scss';
import { usePresenter } from './ExclusiveBadge.presenter';
import type { ExclusiveBadgeProps } from './ExclusiveBadge.types';

export const ExclusiveBadge: React.FC<ExclusiveBadgeProps> = (props) => {
  const {
    icon,
    textKey,
    theme,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  const exclusiveBadgeIcon: ReactNode = 'imageUrl' in icon
    ? <Image imageSrc={icon.imageUrl} className={styles.image} />
    : <Icon asset={icon.iconAsset} style={icon.iconStyle} />;

  return (
    <div className={cx(styles.exclusiveBadge, styles[`${theme}ExclusiveBadge`], className)}>
      {exclusiveBadgeIcon}
      <Text
        size='ExtraSmall'
        style='Regular'
        colour='InteractionBlue70'
        value={t(textKey)}
      />
    </div>
  );
};
