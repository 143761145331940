import type { HostedFieldsTokenizePayload } from 'braintree-web';
import { useCallback, useContext, useEffect, useRef, useState, type FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { BraintreeContext } from '../../../modules/braintree';
import { dummyLastFourDigits } from './CreditCardInfo.constants';
import type { CreditCardInfoPresenterProps, CreditCardInfoProps } from './CreditCardInfo.types';

export const usePresenter = (props: CreditCardInfoProps): CreditCardInfoPresenterProps => {
  const { accountCardLastFourDigits, setCreditCardData, isCreditCardInfoValidRef } = props;

  const { instance, isLoading } = useContext(BraintreeContext);

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  useEffect(() => {
    if (instance) {
      instance.clear('number');
      instance.clear('expirationDate');
      instance.clear('cvv');
    }
  }, [instance]);

  const ioBlackBoxRef = useRef<HTMLInputElement>(null);

  const [isCreditCardInfoValid, setIsCreditCardInfoValid] = useState<boolean>(true);

  const onSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();

      if (!instance) {
        return;
      }

      const tokenPayload: HostedFieldsTokenizePayload = await instance.tokenize({
        // TODO: Confirm if we need to pass these fields in:
        // cardholderName: '',
        // billingAddress: {},
      });

      if (tokenPayload) {
        trackSelectContentEvent(
          trackEvent,
          'CheckoutPage',
          'Card Details',
          t('cardInfo.button'),
        );

        // Dummy credit card numbers are acceptable even if the last 4 digits do not match the account.
        // VS Place Order API will automatically cancel the order on Production when a test credit card is used for ticket purchase.
        if (tokenPayload.details?.lastFour !== accountCardLastFourDigits && !dummyLastFourDigits.includes(tokenPayload.details?.lastFour)) {
          isCreditCardInfoValidRef.current = false;
          setIsCreditCardInfoValid(false);
        } else {
          isCreditCardInfoValidRef.current = true;
          setIsCreditCardInfoValid(true);

          setCreditCardData({
            payload: tokenPayload,
            ioBlackBox: ioBlackBoxRef.current?.value || null,
          });
        }
      }
    } catch (err) {
      isCreditCardInfoValidRef.current = false;
      setIsCreditCardInfoValid(false);
    }
  }, [t, instance, accountCardLastFourDigits, isCreditCardInfoValidRef, setCreditCardData, trackEvent]);

  return {
    ...props,
    isLoading,
    ioBlackBoxRef,
    isCreditCardInfoValid,
    onSubmit,
  };
};
