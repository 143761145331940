import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { TopNavContext } from '../../../modules/topNav';
import type { SearchButtonPresenterProps, SearchButtonProps } from './SearchButton.types';

export const usePresenter = (props: SearchButtonProps): SearchButtonPresenterProps => {
  const { setActiveTopNavMenuType } = useContext(TopNavContext);

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  const onSearchButtonClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'Button',
      t('topnav.searchButtonReaderText'),
    );

    setActiveTopNavMenuType('SearchMenu');
  }, [t, trackEvent, setActiveTopNavMenuType]);

  return {
    ...props,
    onSearchButtonClick,
  };
};
