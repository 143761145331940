import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import styles from './SoldOutCard.module.scss';
import { usePresenter } from './SoldOutCard.presenter';
import type { SoldOutCardProps } from './SoldOutCard.types';

export const SoldOutCard: React.FC<SoldOutCardProps> = (props) => {
  const {
    className,
    linkHref,
  } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <div className={cx(styles.soldOutCard, className)}>
      <div className={styles.topContent}>
        <Text
          size='Small'
          style='SemiBold'
          colour='Negative'
          align='Center'
          value={t('soldOutCard.soldOut')}
        />
        <Text
          size='Medium'
          style='Regular'
          colour='SubduedDark'
          align='Center'
          value={t('soldOutCard.soldOutMessage')}
        />
      </div>
      <Button
        type='TextIcon'
        style='Text'
        size='Medium'
        href={linkHref}
        text={{
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'ActionBase',
          align: 'Left',
          value: t('soldOutCard.exploreExclusives'),
          className: styles.linkText,
        }}
        icon={{
          asset: 'ArrowRight',
          style: 'ActionBase',
          className: styles.linkIcon,
        }}
        className={styles.link}
        classes={{
          content: styles.linkContent,
        }}
      />
    </div>
  );
};
