import { RefObject, useContext, useEffect, useRef } from 'react';
import { SnapListContext } from './SnapListContext';

type SnapItemResponse = {
  elementRef: RefObject<HTMLAnchorElement> | RefObject<HTMLDivElement>;
  currentIndex: number;
};

export const useSnapItem = (itemIndex?: number): SnapItemResponse => {
  const elementRef = useRef<HTMLAnchorElement>(null);
  const { listElementRef, currentIndex, scrollOffset } = useContext(SnapListContext);

  useEffect(() => {
    if (listElementRef?.current?.parentElement && elementRef.current && itemIndex === currentIndex) {
      listElementRef?.current?.parentElement?.scrollTo({
        left: elementRef.current.offsetLeft - scrollOffset,
        behavior: 'smooth',
      });
    }
  }, [itemIndex, currentIndex, listElementRef, elementRef]);

  return {
    elementRef,
    currentIndex,
  };
};
