import braintree, { type Client, type HostedFields } from 'braintree-web';
import React, { createContext, useCallback, useEffect, useState, type PropsWithChildren } from 'react';
import { useQuery } from 'react-query';
import type { PaymentTokenResponse } from '../partnership';
import { getPaymentToken } from '../partnership/api';
import { DEFAULT_BRAINTREE_CONTEXT_VALUE } from './Braintree.constants';
import type { BraintreeContextValue } from './Braintree.types';

export const BraintreeContext = createContext<BraintreeContextValue>(DEFAULT_BRAINTREE_CONTEXT_VALUE);

export const BraintreeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data: paymentTokenResponse, isLoading, error } = useQuery<PaymentTokenResponse, Error>('paymentToken', () => getPaymentToken());

  const [client, setClient] = useState<Client | undefined>(undefined);

  const [instance, updateInstance] = useState<HostedFields | undefined>(undefined);

  const initBraintree = useCallback(async (authorization: string) => {
    const newClient: Client = await braintree.client.create({ authorization });
    setClient(newClient);
  }, []);

  useEffect(() => {
    if (paymentTokenResponse?.token) {
      void initBraintree(paymentTokenResponse.token);
    }
  }, [paymentTokenResponse?.token, initBraintree]);

  const setInstance = useCallback((newInstance: HostedFields) => {
    updateInstance(newInstance);
  }, []);

  return (
    <BraintreeContext.Provider value={{
      isLoading,
      error,
      client,
      instance,
      setInstance,
    }}>
      {children}
    </BraintreeContext.Provider>
  );
};
