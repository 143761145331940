import cx from 'classnames';
import React from 'react';

import Text from '../../atoms/Text';
import EmptyStateBanner from '../../molecules/EmptyStateBanner';
import TabList from '../../molecules/TabList';
import EventsCardList from '../../organisms/EventsCardList';

import Spinner from '../../atoms/Spinner';
import styles from './MyTicketsBlock.module.scss';
import usePresenter from './MyTicketsBlock.presenter';
import getProps from './MyTicketsBlock.props';
import { MyTicketsBlockCombinedProps } from './types';

const MyTicketsBlock: React.FC<MyTicketsBlockCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    title,
    tabList,
    emptyStateBanner,
    eventsCardList,
    isLoading, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`myTicketsBlock${variantName}`];

  let componentView: JSX.Element;

  // MANUAL OVERRIDE START
  if (isLoading) {
    return (
      <div className={cx(currentStyle, className)}>
        <div className={cx(styles.topContent, classes?.topContent)}>
          <Text
            className={cx(styles.title, classes?.title)}
            {...internalProps.title}
            {...title} />
          <TabList
            className={cx(styles.tabList, classes?.tabList)}
            {...internalProps.tabList}
            {...tabList} />
        </div>
        <Spinner />
      </div>
    );
  }
  // MANUAL OVERRIDE END

  switch (variantName) {
    case 'Empty': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title} />
            <TabList
              className={cx(styles.tabList, classes?.tabList)}
              {...internalProps.tabList}
              {...tabList} />
          </div>
          <EmptyStateBanner
            className={cx(styles.emptyStateBanner, classes?.emptyStateBanner)}
            {...internalProps.emptyStateBanner}
            {...emptyStateBanner} />
        </div>
      );
      break;
    }
    case 'Filled': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title} />
            <TabList
              className={cx(styles.tabList, classes?.tabList)}
              {...internalProps.tabList}
              {...tabList} />
          </div>
          <EventsCardList
            className={cx(styles.eventsCardList, classes?.eventsCardList)}
            {...internalProps.eventsCardList}
            {...eventsCardList} />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default MyTicketsBlock;
