import cx from 'classnames';
import React from 'react';
import { Tab, Tabs, type TabsProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CONTENT_ID } from '../../../lib/constants';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import styles from './TabBar.module.scss';
import { usePresenter } from './TabBar.presenter';
import type { TabBarProps } from './TabBar.types';

// Fix warning about default props to be deprecated in React in the future.
// We have no control over react-bootstrap so we save their default props to a variable and delete them from react-bootstrap.
const tabsProps: TabsProps = Tabs.defaultProps;
Tabs.defaultProps = undefined;

export const TabBar: React.FC<TabBarProps> = (props) => {
  const {
    tabs,
    activeTabKey,
    onTabSelected,
  } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <Tabs
      {...tabsProps}
      activeKey={activeTabKey}
      onSelect={onTabSelected}
      className={styles.tabBar}
    >
      {Object.entries(tabs).map(([tabKey, tabProps], tabIndex: number) => {
        const isActiveTab: boolean = tabKey === activeTabKey;

        return (
          <Tab
            key={tabKey}
            eventKey={tabKey}
            id={tabIndex === 0 ? CONTENT_ID : undefined}
            tabClassName={cx(styles.tab, { [styles.activeTab]: isActiveTab })}
            title={<>
              <Icon
                asset={tabProps.iconAsset}
                style={isActiveTab ? 'ActionBase' : 'DigitalGrey60'}
                className={styles.tabIcon}
              />
              <Text
                size='Small'
                style='Regular'
                colour={isActiveTab ? 'ActionBase' : 'SubduedLight'}
                align='Center'
                value={t(tabProps.titleKey)}
                className={styles.tabTitle}
              />
            </>}
          />
        );
      })}
    </Tabs>
  );
};
