import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import type { FilterOption } from '../../atoms/BaseFilter';
import type { DateFilterOptionValue, DateFilterPresenterProps, DateFilterProps } from './DateFilter.types';
import { getDefaultFilterOptions, selectFilterOption } from './DateFilter.utils';

export const usePresenter = (props: DateFilterProps): DateFilterPresenterProps => {
  const { dateFilterState } = props;

  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  const [filterOptions, setFilterOptions] = useState<FilterOption<DateFilterOptionValue>[]>(() => getDefaultFilterOptions(dateFilterState));

  /** Indicates whether date picker should be shown instead of filter options when 'Choose Date' option is selected */
  const shouldShowDatePicker: boolean = useMemo(() => !filterOptions.length, [filterOptions.length]);

  useEffect(() => {
    setFilterOptions(getDefaultFilterOptions(dateFilterState));
  }, [dateFilterState]);

  const onFilterOptionSelected = useCallback((dateFilterOptionValue: DateFilterOptionValue | undefined) => {
    selectFilterOption({ dateFilterOptionValue, setFilterOptions, appendQueryParamsToUrl });
  }, [appendQueryParamsToUrl]);

  const onFilterDropdownToggled = useCallback((isOpen: boolean) => {
    // Revert to default filter options whenever the filter dropdown closes.
    // E.g. if user chooses a date picker and then closes the filter dropdown without selecting a date range,
    // the filter options should revert back to Today, Tomorrow, This Weekend, Choose Date.
    if (!isOpen) {
      setFilterOptions(getDefaultFilterOptions(dateFilterState));
    }
  }, [dateFilterState]);

  /** Function to update date filter state when date range is changed in date picker */
  const onDateRangeChanged = useCallback(([newStartDate, newEndDate]: Date[]) => {
    if (newStartDate && newEndDate) {
      selectFilterOption({
        dateFilterOptionValue: {
          // Date picker returns midnight date and time for local time zone
          // E.g. local 2024-01-31T00:00:00.000
          startDate: newStartDate,
          endDate: newEndDate,
        },
        setFilterOptions,
        appendQueryParamsToUrl,
      });
    }
  }, [appendQueryParamsToUrl]);

  return {
    ...props,
    filterOptions,
    onFilterOptionSelected,
    onFilterDropdownToggled,
    shouldShowDatePicker,
    onDateRangeChanged,
  };
};
