import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { CONTENT_ID } from '../../../lib/constants';
import { isValidUrl } from '../../../lib/util';
import Icon from '../Icon';
import Text from '../Text';
import styles from './Button.module.scss';
import usePresenter from './Button.presenter';
import getProps from './Button.props';
import customStyles from './Custom.module.scss';
import type { ButtonCombinedProps } from './types';

const Button: React.FC<ButtonCombinedProps> = (props) => {
  const {
    type,
    style,
    size,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    onClick,
    onFocus,
    onBlur,
    onMouseOver,
    onMouseOut,
    onKeyDown,
    icon,
    text,
    focusable = true,
    autoFocus,
    ariaLabel,
    ariaLabelledBy,
    leadingIcon,
    ...otherProps
  } = usePresenter(props);

  const variantName = `${type}${style}${size}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`button${variantName}`];

  let componentView: JSX.Element | undefined;

  let textView;
  let contentView;
  let iconView;

  switch (variantName) {
    case 'IconTextMedium':
    case 'IconTextSmall': {
      iconView = (
        <Icon
          className={cx(styles.icon, classes?.icon)}
          {...internalProps.icon}
          {...icon} />
      );
      break;
    }
    case 'IconTextTextOnGreyExtraSmall':
    case 'IconTextTextExtraSmall':
    case 'IconTextTextSmall': {
      contentView = (
        <div className={cx(styles.content, classes?.content)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon} />
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text} />
        </div>
      );
      break;
    }
    case 'TextIconTextMedium':
    case 'TextIconTextSmall':
    case 'TextIconTextUnderlineSmall':
    case 'TextIconTextWhiteSmall':
    case 'TextIconTextCharcoal10Small':
    case 'TextIconTextWhiteExtraSmall': {
      contentView = (
        <div className={cx(styles.content, classes?.content)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text} />
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon} />
        </div>
      );
      break;
    }
    case 'TextContainedGreenLarge':
    case 'TextContainedGreenMedium':
    case 'TextContainedLarge':
    case 'TextContainedMedium':
    case 'TextContainedWhiteLarge':
    case 'TextContainedWhiteMedium':
    case 'TextOutlinePillMedium':
    case 'TextOutlineMedium':
    case 'TextOutlineSmall':
    case 'TextTextDarkMedium':
    case 'TextTextGreySmall':
    case 'TextTextMedium':
    case 'TextTextSmall':
    case 'TextTextWhiteMedium':
    case 'TextTextWhiteSmall':
    case 'TextTextUnderlineSmall': {
      textView = (
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text} />
      );
      break;
    }
    case 'IconTextIconContainedMedium':
      contentView = (
        <div className={cx(styles.content, classes?.content)}>
          <Icon
            className={cx(styles.leadingIcon, classes?.leadingIcon)}
            {...internalProps.leadingIcon}
            {...leadingIcon} />
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text} />
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon} />
        </div>
      );
      break;
    default:
      componentView = <div>�</div>;
      break;
  }

  if (href) {
    if (isValidUrl(href) || href === `#${CONTENT_ID}`) {
      return (
        <a
          target={target}
          rel='noreferrer'
          tabIndex={focusable ? 0 : -1}
          autoFocus={autoFocus}
          href={href}
          aria-label={ariaLabel}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onKeyDown={onKeyDown}
          className={cx(currentStyle, customStyles.linkPath, className)}
          {...otherProps}
        >
          {textView}
          {contentView}
          {iconView}
        </a>
      );
    }

    return (
      <Link
        tabIndex={focusable ? 0 : -1}
        autoFocus={autoFocus}
        to={href}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onKeyDown={onKeyDown}
        aria-label={ariaLabel}
        data-item="true"
        target={target}
        className={cx(currentStyle, customStyles.linkPath, className)}
        {...otherProps}
      >
        {textView}
        {contentView}
        {iconView}
      </Link>
    );
  }

  if (onClick || buttonForm) {
    return (
      <button
        tabIndex={focusable ? 0 : -1}
        autoFocus={autoFocus}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        type={buttonType}
        form={buttonForm}
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onKeyDown={onKeyDown}
        className={cx(currentStyle, className)}
        {...otherProps}
      >
        {textView}
        {contentView}
        {iconView}
      </button>
    );
  }

  return componentView ? (
    <>
      {componentView}
    </>
  ) : (
    <div
      className={cx(currentStyle, className)}>
      {textView}
      {contentView}
      {iconView}
    </div>
  );
};

export default Button;
