import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { getGoBackHistoryOffset } from '../../../modules/navigation/Navigation.utils';
import { TopNavContext } from '../../../modules/topNav';
import type { MobileSearchHeaderPresenterProps, MobileSearchHeaderProps } from './MobileSearchHeader.types';

export const usePresenter = (props: MobileSearchHeaderProps): MobileSearchHeaderPresenterProps => {
  const { isInsideModal } = props;

  const { setActiveTopNavMenuType, setSearchQuery } = useContext(TopNavContext);

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const onBackButtonClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'Search',
      t('common.goBack'),
    );

    setSearchQuery('');
    setActiveTopNavMenuType(undefined);

    // When back button is inside a modal then we do not need to navigate anywhere.
    // We just need to close the modal which will be done by calling 'setActiveTopNavMenuType(undefined)'.
    if (!isInsideModal) {
      navigate(getGoBackHistoryOffset());
    }
  }, [t, isInsideModal, trackEvent, setSearchQuery, setActiveTopNavMenuType, navigate]);

  return {
    ...props,
    onBackButtonClick,
    isSearchBarFocusedOnMount: isInsideModal,
    isSearchBarBlurredOnFocus: !isInsideModal,
  };
};
