import PanZoom, { type PanzoomObject } from '@panzoom/panzoom';
import { useCallback, useContext, useEffect, useMemo, useRef, useState, type MouseEvent } from 'react';
import { MapContext, SVG_MAP_ID } from '../../../modules/map';
import type { SvgMapSection, SvgMapSectionName } from '../../../modules/partnership';
import { SVG_MAP_CLICK_MAX_SHIFT, SVG_MAP_MIN_SCALE, SVG_MAP_STEP, SVG_MAP_ZOOM_STEP } from './SvgMap.constants';
import type { SvgMapPresenterProps, SvgMapProps } from './SvgMap.types';
import { checkIsPanZoomEvent } from './SvgMap.utils';

export const usePresenter = (props: SvgMapProps): SvgMapPresenterProps => {
  const { isSvgMapPanZoomEnabled } = props;

  const {
    isSvgMapDataLoading,
    svgMapData,
    svgMapDataError,
    setHoveredSection,
    setSelectedSection,
  } = useContext(MapContext);

  const svgMapContainerRef = useRef<HTMLDivElement>(null);

  const svgMapRef = useRef<SVGSVGElement>(null);

  const panZoomStartXRef = useRef<number>(0);
  const panZoomStartYRef = useRef<number>(0);
  const isSvgMapDraggingRef = useRef<boolean>(false);

  const [panZoomObject, setPanZoomObject] = useState<PanzoomObject | undefined>();

  // Activate pan-zoom functionality for SVG map if it is enabled and SVG map data has loaded
  useEffect(() => {
    const svgMapContainer: HTMLDivElement | null = svgMapContainerRef.current;
    const svgMap: SVGSVGElement | null = svgMapRef.current;

    let newPanZoomObject: PanzoomObject | undefined;

    const wheelEventListener = (event: WheelEvent) => newPanZoomObject?.zoomWithWheel(event);

    /**
     * Checking if the map is clicked or it is being dragged.
     * This determines whether or not we need to process onSvgMapClick handler.
     * onSvgMapClick handler is not called during map dragging to keep the selected section on the map unchanged.
     *
     * Explanation:
     *
     * Sequence of events on dragging: 'panzoomstart' (on mouse down), then a series of 'panzoompan' events (repetitive), and at the end 'panzoomend' (on mouse up).
     *
     * Sequence of events on click is normally 'panzoomstart' (on mouse down) followed by 'panzoomend' (on mouse up).
     * But it is possible (especially on mobiles with very sensitive screens) that the map can shift slightly when user taps on the map.
     * In this case 'panzoompan' event will be emitted as well.
     * So from the events perspective it is possible that a mouse click / tap may look like a short dragging.
     *
     * We reset isSvgMapDragging to false on 'panzoomstart' event.
     * Only if 'panzoompan' event follows and the map shifts by more than 10px in any direction, only then we set isSvgMapDragging to true.
     */

    const panZoomStartEventListener = (event: Event) => {
      if (checkIsPanZoomEvent(event)) {
        panZoomStartXRef.current = event.detail.x;
        panZoomStartYRef.current = event.detail.y;
        isSvgMapDraggingRef.current = false;
      }
    };

    const panZoomPanEventListener = (event: Event) => {
      if (checkIsPanZoomEvent(event) && !isSvgMapDraggingRef.current) {
        const { detail: { x, y } } = event;

        /**
         * During user's mouse click or tap the SVG map may shift slightly.
         * If it is a small shift we consider it as a click, otherwise it is dragging.
         */
        if (Math.abs(x - panZoomStartXRef.current) > SVG_MAP_CLICK_MAX_SHIFT || Math.abs(y - panZoomStartYRef.current) > SVG_MAP_CLICK_MAX_SHIFT) {
          isSvgMapDraggingRef.current = true;

          // Reset hovered section to hide tooltip with min price
          setHoveredSection(undefined);
        }
      }
    };

    if (isSvgMapPanZoomEnabled && !isSvgMapDataLoading && svgMapData && svgMapContainer && svgMap) {
      newPanZoomObject = PanZoom(svgMap, {
        canvas: true,
        minScale: SVG_MAP_MIN_SCALE,
        step: SVG_MAP_STEP,
      });

      svgMapContainer.addEventListener('wheel', wheelEventListener);

      svgMap.addEventListener('panzoomstart', panZoomStartEventListener);
      svgMap.addEventListener('panzoompan', panZoomPanEventListener);
    }

    setPanZoomObject(newPanZoomObject);

    // Clean-up function
    return () => {
      if (svgMapContainer && svgMap && newPanZoomObject) {
        svgMapContainer.removeEventListener('wheel', wheelEventListener);

        svgMap.removeEventListener('panzoomstart', panZoomStartEventListener);
        svgMap.removeEventListener('panzoompan', panZoomPanEventListener);

        newPanZoomObject.reset();
        newPanZoomObject.resetStyle();
        newPanZoomObject.destroy();
      }
    };
  }, [isSvgMapPanZoomEnabled, isSvgMapDataLoading, svgMapData, setHoveredSection]);

  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    const timer: NodeJS.Timeout | undefined = timerRef.current;

    // Clear timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const onSvgMapClick = useCallback((event: MouseEvent<SVGSVGElement>) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Delay processing by 200ms in case user is making a double click
    timerRef.current = setTimeout(() => {
      if (!isSvgMapDraggingRef.current) {
        // Set hovered section to show tooltip with min price
        setHoveredSection({ element: event.target as SVGSVGElement });

        // Note: If this section is already selected directly from SVG map and the same section is selected again then selection will be reset
        setSelectedSection({
          element: event.target as SVGSVGElement,
          selectionMode: 'with-listing-filtering-by-section',
        });
      } else {
        isSvgMapDraggingRef.current = false;
      }
    }, 200);
  }, [setHoveredSection, setSelectedSection]);

  const onSvgMapDoubleClick = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Zoom SVG map if pan-zoom functionality is enabled
    if (isSvgMapPanZoomEnabled && panZoomObject) {
      panZoomObject.zoomIn({ step: SVG_MAP_ZOOM_STEP });
    }
  }, [isSvgMapPanZoomEnabled, panZoomObject]);

  const onSvgMapMouseOver = useCallback((event: MouseEvent<SVGSVGElement>) => {
    if (!isSvgMapDraggingRef.current) {
      // Set hovered section to show tooltip with min price
      setHoveredSection({ element: event.target as SVGSVGElement });
    }
  }, [setHoveredSection]);

  const onSvgMapMouseOut = useCallback(() => {
    // Reset hovered section to hide tooltip with min price
    setHoveredSection(undefined);
  }, [setHoveredSection]);

  // Hide tooltip with min price on click outside SVG map
  useEffect(() => {
    const documentOnClick: EventListener = (event: Event) => {
      // Process only clicks outside SVG map
      if (!(event.target as HTMLElement).closest(`#${SVG_MAP_ID}`)) {
        // Reset hovered section to hide tooltip with min price
        setHoveredSection(undefined);
      }
    };

    document.addEventListener('click', documentOnClick);
    return () => document.removeEventListener('click', documentOnClick);
  }, [setHoveredSection]);

  const svgMapSections: SvgMapSection[] = useMemo(
    () => (svgMapData?.elements.filter(({ type }) => type === 'path') as SvgMapSection[] | undefined) ?? [],
    [svgMapData?.elements],
  );

  const svgMapSectionNames: SvgMapSectionName[] = useMemo(
    () => (svgMapData?.elements.filter(({ type }) => type === 'text') as SvgMapSectionName[] | undefined) ?? [],
    [svgMapData?.elements],
  );

  return {
    ...props,
    isSvgMapDataLoading,
    svgMapContainerRef,
    svgMapRef,
    svgMapData,
    svgMapDataError,
    onSvgMapClick,
    onSvgMapDoubleClick,
    onSvgMapMouseOver,
    onSvgMapMouseOut,
    svgMapSections,
    svgMapSectionNames,
  };
};
