import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberToLocaleString } from '../../../lib/util';
import type { SliderPresenterProps, SliderProps } from './Slider.types';

export const usePresenter = (props: SliderProps): SliderPresenterProps => {
  const {
    range,
    minMaxRange,
    onRangeChanged,
    unitName,
    leftThumbAriaLabelKey,
    rightThumbAriaLabelKey,
    getThumbAriaValueText,
  } = props;

  const { t } = useTranslation();

  const rangeValues: [number, number] = useMemo(
    () => [range.minValue, range.maxValue],
    [range.minValue, range.maxValue],
  );

  // Formatted minimum value based on the unit name, e.g. $123,456
  const [formattedMinValue, setFormattedMinValue] = useState<string>('');

  // Formatted maximum value based on the unit name, e.g. $123,456
  const [formattedMaxValue, setFormattedMaxValue] = useState<string>('');

  useEffect(() => {
    setFormattedMinValue(formatNumberToLocaleString({ num: range.minValue, unitName, shouldIncludeUnitName: false }));
    setFormattedMaxValue(formatNumberToLocaleString({ num: range.maxValue, unitName, shouldIncludeUnitName: false }));
  }, [range.minValue, range.maxValue, unitName]);

  /** Callback called on every value change */
  const onChange = useCallback((newRangeValues: number[]) => {
    if (newRangeValues.length === 2) {
      setFormattedMinValue(formatNumberToLocaleString({ num: newRangeValues[0], unitName, shouldIncludeUnitName: false }));
      setFormattedMaxValue(formatNumberToLocaleString({ num: newRangeValues[1], unitName, shouldIncludeUnitName: false }));
    }
  }, [unitName]);

  /** Callback called only after moving a thumb has ended. The callback will only be called if the action resulted in a change. */
  const onAfterChange = useCallback((newRangeValues: number[]) => {
    if (newRangeValues.length === 2) {
      onRangeChanged({ minValue: newRangeValues[0], maxValue: newRangeValues[1] });
    }
  }, [onRangeChanged]);

  /** ARIA labels to apply to the left and right thumbs */
  const thumbAriaLabels: [string, string] = useMemo(
    () => [t(leftThumbAriaLabelKey), t(rightThumbAriaLabelKey)],
    [t, leftThumbAriaLabelKey, rightThumbAriaLabelKey],
  );

  return {
    ...props,
    value: rangeValues,
    min: minMaxRange.minValue,
    max: minMaxRange.maxValue,
    onChange,
    onAfterChange,
    formattedMinValue,
    formattedMaxValue,
    ariaLabel: thumbAriaLabels,
    ariaValuetext: getThumbAriaValueText,
  };
};
