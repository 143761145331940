import cx from 'classnames';
import React from 'react';
import { AriaRoles } from '../../../lib/types';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import { IconAssetsBySeverity, IconStylesBySeverity } from './HighlightedMessage.constants';
import styles from './HighlightedMessage.module.scss';
import { usePresenter } from './HighlightedMessage.presenter';
import type { HighlightedMessageProps } from './HighlightedMessage.types';

export const HighlightedMessage: React.FC<HighlightedMessageProps> = (props) => {
  const {
    severity,
    content,
    className,
  } = usePresenter(props);

  return (
    <div className={cx(styles.highlightedMessage, styles[`${severity}HighlightedMessage`], className)}>
      <Icon
        asset={IconAssetsBySeverity[severity]}
        style={IconStylesBySeverity[severity]}
        className={styles.icon}
      />
      <Text
        size='Medium'
        style='Regular'
        colour='BaseDark'
        value={content}
        ariaRole={AriaRoles.ALERT}
        ariaAtomic={true}
      />
    </div>
  );
};
