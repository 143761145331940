import { useCallback, useEffect, useMemo, useState } from 'react';
import type { BaseComponentProps } from '../../../lib/types';
import { getIntervalledFunc } from '../../../lib/util';
import { FETCH_NEXT_INTERVAL_MS } from './PaginatedList.constants';
import type { PaginatedListPresenterProps, PaginatedListProps } from './PaginatedList.types';

export const usePresenter = <TComponentProps extends BaseComponentProps>(
  props: PaginatedListProps<TComponentProps>,
): PaginatedListPresenterProps<TComponentProps> => {
  const { isFetchingItems, fetchNext } = props;

  const [isSeeMoreInitiated, setIsSeeMoreInitiated] = useState<boolean>(false);

  // When there is a short period between clicks on See More button (this may occur when user clicks on See More button, then quickly scrolls to be bottom of the page and then clicks on See More button again),
  // browsers tend to move the scroll position to account for the new items that are added to the list.
  // This is not desirable, i.e. we want the scroll position to remain unchanged when new items are added to the list.
  //
  // But it seems that browsers stop moving the scroll position after about 3 seconds since the last change to the list of items.
  // Hence we use "getIntervalledFunc" function to allow "fetchNext" function to be invoked at most once every 3 seconds.
  // This means that when user clicks on See More button, the new items are added to the list but the scroll stays where it was.
  //
  // That artificial delay of 3 seconds should not cause UX issues because this is the expected time that it takes to scroll through the newly added items in the list.
  const intervalledFetchNext = useMemo(() => getIntervalledFunc(fetchNext, FETCH_NEXT_INTERVAL_MS), [fetchNext]);

  const onSeeMoreClick = useCallback(() => {
    setIsSeeMoreInitiated(true);
    intervalledFetchNext();
  }, [intervalledFetchNext]);

  useEffect(() => {
    if (isFetchingItems) {
      setIsSeeMoreInitiated(false);
    }
  }, [isFetchingItems]);

  return {
    ...props,
    onSeeMoreClick,
    isSeeMoreDisabled: isSeeMoreInitiated || isFetchingItems,
  };
};
