import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from '../../atoms/Logo';
import { LabelledContent } from '../../molecules/LabelledContent';
import commonStyles from '../../pages/CheckoutPage/CheckoutPage.module.scss';
import styles from './ListingDetailInfo.module.scss';
import { usePresenter } from './ListingDetailInfo.presenter';
import type { ListingDetailInfoProps } from './ListingDetailInfo.types';

export const ListingDetailInfo: React.FC<ListingDetailInfoProps> = (props) => {
  const {
    listingDetailMetadata: {
      eventMetadata: {
        eventName,
        eventDateTimeAndVenueDetails,
      },
      listingMetadata: {
        sectionName,
        row,
        sellerNotes,
      },
      pricing: {
        quantity,
      },
      seats,
      deliveryMethod,
    },
    bottomLogo,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  const eventDetail: ReactNode = (
    <LabelledContent
      labelKey={eventName}
      items={[
        t('event.shortWeekDayShortDateTime', eventDateTimeAndVenueDetails),
        t('event.venueNameCityStateCode', eventDateTimeAndVenueDetails),
      ]}
    />
  );

  const seatingInfo: ReactNode = (
    <div className={styles.seatingInfo}>
      <LabelledContent
        labelKey='listingDetailInfo.section'
        item={sectionName}
        itemTextType='Body'
        itemTextSize='Large'
        className={styles.availableWidthItem}
      />
      <LabelledContent
        labelKey='listingDetailInfo.row'
        item={row}
        itemTextType='Body'
        itemTextSize='Large'
        className={styles.fixedWidthItem}
      />
      <LabelledContent
        labelKey='listingDetailInfo.quantity'
        item={quantity.toString()}
        itemTextType='Body'
        itemTextSize='Large'
        className={styles.fixedWidthItem}
      />
      {!!seats && (
        <LabelledContent
          labelKey='listingDetailInfo.seats'
          item={seats}
          itemTextType='Body'
          itemTextSize='Large'
          className={styles.fixedWidthItem}
        />
      )}
    </div>
  );

  const sellerNotesView: ReactNode = !!sellerNotes && (
    <LabelledContent
      labelKey='listingDetailInfo.sellerNotes'
      item={sellerNotes}
    />
  );

  const deliveryMethodView: ReactNode = (
    <LabelledContent
      labelKey='listingDetailInfo.deliveryMethod'
      item={deliveryMethod}
    />
  );

  const bottomLogoView: ReactNode = (
    <Logo
      asset={bottomLogo}
      theme='dark'
      className={styles[`${bottomLogo}BottomLogo`]}
    />
  );

  return (
    <div className={cx(commonStyles.column, styles.listingDetailInfo, className)}>
      {eventDetail}
      {seatingInfo}
      {sellerNotesView}
      {deliveryMethodView}
      {bottomLogoView}
    </div>
  );
};
