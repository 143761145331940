import cx from 'classnames';
import React, { type ReactNode } from 'react';
import Text from '../../atoms/Text';
import { LabelColoursByTheme } from './TextDropdown.constants';
import styles from './TextDropdown.module.scss';
import { usePresenter } from './TextDropdown.presenter';
import type { TextDropdownOptionProps, TextDropdownProps } from './TextDropdown.types';

export const TextDropdown: React.FC<TextDropdownProps> = (props) => {
  const {
    label,
    optionProps,
    selectedValue,
    onChange,
    onBlur,
    name,
    theme,
    ariaLabel,
    className,
    selectClassName,
  } = usePresenter(props);

  const dropdownOptions: ReactNode[] = optionProps.map((currentOptionProps: TextDropdownOptionProps) => (
    <option
      {...currentOptionProps}
      key={currentOptionProps.key}
    />
  ));

  return (
    <div className={cx(styles.textDropdown, styles[`${theme}TextDropdown`], className)}>
      <Text
        size='Medium'
        style='Regular'
        colour={LabelColoursByTheme[theme]}
        value={label}
      />
      <select
        name={name}
        value={selectedValue}
        onChange={onChange}
        onBlur={onBlur}
        aria-label={ariaLabel || label}
        className={cx(styles.textDropdownSelect, selectClassName)}
      >
        {dropdownOptions}
      </select>
    </div>
  );
};
