import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { marketingOptinMlb } from '../../../modules/partnership/api';
import type { ButtonTypeEnum } from '../../atoms/Button';
import type { MarketingOptinMlbPresenterProps, MarketingOptinMlbProps } from './MarketingOptinMlb.types';

export const usePresenter = (props: MarketingOptinMlbProps): MarketingOptinMlbPresenterProps => {
  const { orderId } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleIsExpanded = useCallback(() => setIsExpanded((prevIsExpanded: boolean) => !prevIsExpanded), []);

  const [hasSubscribeButtonBeenClicked, setHasSubscribeButtonBeenClicked] = useState<boolean>(false);

  const subscribeButtonType: ButtonTypeEnum = useMemo(
    () => hasSubscribeButtonBeenClicked ? 'IconText' : 'Text',
    [hasSubscribeButtonBeenClicked],
  );

  const subscribeButtonTextKey: string = useMemo(
    () => `orderConfirmationInfo.marketingOptinMlb.${hasSubscribeButtonBeenClicked ? 'subscribed' : 'subscribe'}`,
    [hasSubscribeButtonBeenClicked],
  );

  const { mutateAsync: subscribeToMlbMarketing } = useMutation(marketingOptinMlb);

  const onSubscribeButtonClick = useCallback(() => {
    if (!hasSubscribeButtonBeenClicked) {
      setHasSubscribeButtonBeenClicked(true);
      // Do not await for the API response
      void subscribeToMlbMarketing({ orderId });
    }
  }, [hasSubscribeButtonBeenClicked, orderId, subscribeToMlbMarketing]);

  return {
    ...props,
    isExpanded,
    toggleIsExpanded,
    hasSubscribeButtonBeenClicked,
    subscribeButtonType,
    subscribeButtonTextKey,
    onSubscribeButtonClick,
  };
};
