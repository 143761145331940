import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { BaseComponentProps } from '../../../lib/types';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import type { CarouselSectionPresenterProps, CarouselSectionProps } from './CarouselSection.types';

export const usePresenter = <TComponentProps extends BaseComponentProps>(
  props: CarouselSectionProps<TComponentProps>,
): CarouselSectionPresenterProps<TComponentProps> => {
  const { pageName, titleKey, linkLabelKey, onLinkClick: initialOnLinkClick } = props;

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  const onLinkClick = useCallback(() => {
    initialOnLinkClick?.();

    trackSelectContentEvent(
      trackEvent,
      pageName,
      t(titleKey),
      t(linkLabelKey),
    );
  }, [t, pageName, titleKey, linkLabelKey, initialOnLinkClick, trackEvent]);

  return {
    ...props,
    onLinkClick,
  };
};
