import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ErrorBlockCombinedProps } from '../../legacy/blocks/ErrorBlock/types';
import { getTranslation } from '../../lib/reactIUtils';
import CautionErrorAsset from '../../resources/icons/Caution.svg';
import GenericErrorAsset from '../../resources/icons/GenericError.svg';
import { CP1ContactUsLink } from './constants';
import styles from './ErrorHandler.module.scss';
import type { ErrorHandlerProps } from './types';

const usePresenter = (props: ErrorHandlerProps): ErrorHandlerProps => {
  const { t } = useTranslation();

  // When set to true it will log out user
  const [shouldLogout, setShouldLogout] = useState(false);

  const orderErrorBlockProps: ErrorBlockCombinedProps = {
    image: {
      imageSrc: GenericErrorAsset,
    },
    title: {
      value: t('notRedeemAbleAccount.title'),
    },
    message: {
      value: t('notRedeemAbleAccount.message'),
    },
    button: {
      className: styles.hideErrorButton,
    },
  };

  const notAccessibleErrorBlockProps: ErrorBlockCombinedProps = {
    classes: {
      image: styles.errorIcon,
      title: styles.notAccessibleErrorBlockTitle,
    },
    image: {
      imageSrc: CautionErrorAsset,
    },
    title: {
      value: getTranslation('notAccessibleAccount.title'),
    },
    showMessage: false,
    showSignOutButton: true,
    button: {
      text: {
        value: t('notAccessibleAccount.button'),
      },
      href: CP1ContactUsLink,
    },
    signOutButton: {
      text: {
        value: t('notAccessibleAccount.signOutButton'),
      },
      onClick: () => {
        setShouldLogout(true);
      },
    },
  };

  const notEligibleCardErrorBlockProps: ErrorBlockCombinedProps = {
    classes: {
      image: styles.errorIcon,
      title: styles.notAccessibleErrorBlockTitle,
    },
    image: {
      imageSrc: CautionErrorAsset,
    },
    title: {
      value: t('notEligibleCard.title'),
    },
    showMessage: false,
    showSignOutButton: false,
    button: {
      className: styles.hideErrorButton,
    },
  };

  const notAccessibleEmailErrorBlockProps: ErrorBlockCombinedProps = {
    classes: {
      image: styles.errorIcon,
      title: styles.notAccessibleErrorBlockTitle,
    },
    image: {
      imageSrc: CautionErrorAsset,
    },
    title: {
      value: t('notAccessibleEmailForAccount.title'),
    },
    showMessage: false,
    showSignOutButton: true,
    button: {
      className: styles.hideErrorButton,
    },
    signOutButton: {
      text: {
        value: t('notAccessibleAccount.signOutButton'),
      },
      onClick: () => {
        setShouldLogout(true);
      },
    },
  };

  const notFountErrorBlockProps: ErrorBlockCombinedProps = {
    ...orderErrorBlockProps,
    message: {
      value: t('notFountError.message'),
    },
  };

  return {
    ...props,
    shouldLogout,
    errorBlockProps: orderErrorBlockProps,
    notFountErrorBlockProps: notFountErrorBlockProps,
    notAccessibleEmailErrorBlockProps,
    notAccessibleErrorBlockProps,
    notEligibleCardErrorBlockProps,
  };
};

export default usePresenter;
