import type { Dispatch, SetStateAction } from 'react';
import { FULL_VISIBILITY_PERCENTAGE } from './Carousel.constants';
import type { CarouselItemsState } from './Carousel.types';

/**
 * Checks if scroll buttons should be visible based on device type and the number of items.
 * @returns {boolean} True if user is on desktops and there is at least one carousel item, otherwise false.
 */
export const checkAreScrollButtonsVisible = (params: {
  /** Indicates if the device is a desktop */
  isDesktop: boolean;
  /** The number of carousel items */
  itemsLength: number;
}): boolean => {
  const { isDesktop, itemsLength } = params;
  return isDesktop && itemsLength > 0;
};

/**
 * Determines the state of visible carousel items that contains the following:
 * - Index of the first fully visible carousel item
 * - Index of the last fully visible carousel item
 * @returns {CarouselItemsState | undefined} State of visible carousel items or undefined if no carousel items are visible.
 */
export const getCarouselItemsState = (params: {
  /** Boolean array where true means that the carousel item at that index (0-based) is fully visible width-wise */
  carouselItemVisibilities: boolean[];
}): CarouselItemsState | undefined => {
  const { carouselItemVisibilities } = params;

  const lowestCarouselVisibleItemIndex: number = carouselItemVisibilities.indexOf(true);
  if (lowestCarouselVisibleItemIndex < 0) {
    return undefined;
  }

  const highestCarouselVisibleItemIndex: number = carouselItemVisibilities.lastIndexOf(true);

  return {
    lowestCarouselVisibleItemIndex,
    highestCarouselVisibleItemIndex,
  };
};

/**
 * Creates an intersection observer callback to keep track of what carousel items are visible on the screen and update the visibility array of carousel items.
 * @returns {IntersectionObserverCallback} Intersection observer callback.
 */
export const getCarouselIntersectionObserverCallback = (params: {
  /** Array of carousel item HTML elements */
  carouselItems: Element[];
  /** Setter for carousel item visibilities */
  setCarouselItemVisibilities: Dispatch<SetStateAction<boolean[]>>;
}): IntersectionObserverCallback => {
  const { carouselItems, setCarouselItemVisibilities } = params;

  return (entries: IntersectionObserverEntry[]) => {
    for (const entry of entries) {
      const carouselItemIndex: number = carouselItems.indexOf(entry.target);

      if (carouselItemIndex >= 0) {
        const isCarouselItemVisible: boolean = entry.isIntersecting && entry.boundingClientRect.width * FULL_VISIBILITY_PERCENTAGE <= entry.intersectionRect.width;

        setCarouselItemVisibilities((prevCarouselVisibleItemIndexes: boolean[]) => {
          const newCarouselVisibleItemIndexes: boolean[] = [...prevCarouselVisibleItemIndexes];
          newCarouselVisibleItemIndexes[carouselItemIndex] = isCarouselItemVisible;
          return newCarouselVisibleItemIndexes;
        });
      }
    }
  };
};

/**
 * Generates a function to scroll carousel to the left.
 * @returns {(() => void) | undefined} Function to scroll carousel to the left or undefined if not applicable.
 */
export const getOnGoBackwardClick = (params: {
  /** True if user is on desktops and there is at least one carousel item */
  areScrollButtonsVisible: boolean;
  /** State of visible carousel items that contains the following:
   * - Index of the first fully visible carousel item
   * - Index of the last fully visible carousel item
   */
  carouselItemsState: CarouselItemsState | undefined;
  /** HTML element of carousel items container */
  carouselItemsContainer: HTMLDivElement | null;
}): (() => void) | undefined => {
  const { areScrollButtonsVisible, carouselItemsState, carouselItemsContainer } = params;

  if (areScrollButtonsVisible && carouselItemsState && carouselItemsContainer) {
    const carouselItems: HTMLCollection = carouselItemsContainer.children;

    // Proceed only if there are invisible carousel items on the left
    if (carouselItemsState.lowestCarouselVisibleItemIndex > 0) {
      // Get index of the last invisible carousel item on the left
      const prevCarouselInvisibleItemIndex: number = carouselItemsState.lowestCarouselVisibleItemIndex - 1;

      // Compute scroll distance
      const scrollByPx: number = carouselItems[carouselItemsState.highestCarouselVisibleItemIndex].getBoundingClientRect().right - carouselItems[prevCarouselInvisibleItemIndex].getBoundingClientRect().right;
      const scrollPx: number = carouselItemsContainer.scrollLeft - scrollByPx;

      return () => {
        // Using 'smooth' behavior to scroll with scroll transition
        carouselItemsContainer.scrollTo({ left: scrollPx, behavior: 'smooth' });

        // Fix for Chrome only:
        // In Chrome only, intersection observer callback is not always triggered on scroll with 'smooth' behavior (we use intersection observer to track visible items).
        // So we set a 400ms timeout to repeat the same scroll but this time we use 'auto' behavior (instant scroll without animation).
        // 400ms is enough time to complete scroll animation set by 'smooth' behavior.
        // So users should not see any difference but what's important is that 'auto' behavior triggers intersection observer callback in Chrome as expected.
        setTimeout(() => carouselItemsContainer.scrollTo({ left: scrollPx, behavior: 'auto' }), 400);
      };
    }
  }

  return undefined;
};

/**
 * Generates a function to scroll carousel to the right.
 * @returns {(() => void) | undefined} Function to scroll carousel to the right or undefined if not applicable.
 */
export const getOnGoForwardClick = (params: {
  /** True if user is on desktops and there is at least one carousel item */
  areScrollButtonsVisible: boolean;
  /** State of visible carousel items that contains the following:
   * - Index of the first fully visible carousel item
   * - Index of the last fully visible carousel item
   */
  carouselItemsState: CarouselItemsState | undefined;
  /** HTML element of carousel items container */
  carouselItemsContainer: HTMLDivElement | null;
}): (() => void) | undefined => {
  const { areScrollButtonsVisible, carouselItemsState, carouselItemsContainer } = params;

  if (areScrollButtonsVisible && carouselItemsState && carouselItemsContainer) {
    const carouselItems: HTMLCollection = carouselItemsContainer.children;

    // Proceed only if there are carousel items on the right to scroll to
    if (carouselItemsState.highestCarouselVisibleItemIndex < carouselItems.length - 1) {
      // Get index of the first invisible carousel item on the right
      const nextCarouselInvisibleItemIndex: number = carouselItemsState.highestCarouselVisibleItemIndex + 1;

      // Compute scroll distance
      const scrollByPx: number = carouselItems[nextCarouselInvisibleItemIndex].getBoundingClientRect().left - carouselItems[carouselItemsState.lowestCarouselVisibleItemIndex].getBoundingClientRect().left;
      const scrollPx: number = carouselItemsContainer.scrollLeft + scrollByPx;

      return () => {
        // Using 'smooth' behavior to scroll with scroll transition
        carouselItemsContainer.scrollTo({ left: scrollPx, behavior: 'smooth' });

        // Fix for Chrome only:
        // In Chrome only, intersection observer callback is not always triggered on scroll with 'smooth' behavior (we use intersection observer to track visible items).
        // So we set a 400ms timeout to repeat the same scroll but this time we use 'auto' behavior (instant scroll without animation).
        // 400ms is enough time to complete scroll animation set by 'smooth' behavior.
        // So users should not see any difference but what's important is that 'auto' behavior triggers intersection observer callback in Chrome as expected.
        setTimeout(() => carouselItemsContainer.scrollTo({ left: scrollPx, behavior: 'auto' }), 400);
      };
    }
  }

  return undefined;
};

/**
 * Checks if we need to render additional left box shadow before the carousel to achieve the box shadow effect
 * @returns {boolean} True if areBoxShadowsEnabled is true and there is at least one carousel item.
 */
export const checkShouldRenderLeftBoxShadow = (params: {
  /**
   * Since carousel items are rendered within a container with "overflow: hidden" on desktops, all box shadows on carousel items get cut off.
   * This flag indicates if we need to render additional box shadows before and after the carousel to achieve the box shadow effect.
   */
  areBoxShadowsEnabled: boolean;
  /** The number of carousel items */
  itemsLength: number;
}): boolean => {
  const { areBoxShadowsEnabled, itemsLength } = params;
  return areBoxShadowsEnabled && itemsLength > 0;
};

/**
 * Checks if we need to render additional right box shadow after the carousel to achieve the box shadow effect
 * @returns {boolean} True if areBoxShadowsEnabled is true and there are enough carousel items to fill the entire screen width.
 */
export const checkShouldRenderRightBoxShadow = (params: {
  /**
   * Since carousel items are rendered within a container with "overflow: hidden" on desktops, all box shadows on carousel items get cut off.
   * This flag indicates if we need to render additional box shadows before and after the carousel to achieve the box shadow effect.
   */
  areBoxShadowsEnabled: boolean;
  /** Maximum available screen width in pixels for the carousel container */
  maxAvailableScreenWidthPx: number;
  /** HTML collection of carousel item HTML elements */
  carouselItems: HTMLCollection | undefined;
}): boolean => {
  const { areBoxShadowsEnabled, maxAvailableScreenWidthPx, carouselItems } = params;

  if (!areBoxShadowsEnabled || maxAvailableScreenWidthPx <= 0 || !carouselItems) {
    return false;
  }

  const carouselItemsTotalWidthPx: number = Array.from(carouselItems).reduce((totalWidthPx, carouselItem: Element) => {
    return totalWidthPx + carouselItem.getBoundingClientRect().width;
  }, 0);

  // Return true if the total width of carousel items covers at least 90% of available screen width
  // This accounts for gaps between carousel items
  return carouselItemsTotalWidthPx >= maxAvailableScreenWidthPx * FULL_VISIBILITY_PERCENTAGE;
};
