import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import styles from './MlbCard.module.scss';
import { usePresenter } from './MlbCard.presenter';
import type { MlbCardProps } from './MlbCard.types';

export const MlbCard: React.FC<MlbCardProps> = (props) => {
  const {
    mlbCardTitle,
    mlbCardDescription,
    mlbCardButtonLabel,
    mlbCardHref,
    onMlbCardClick,
    className,
  } = usePresenter(props);

  return (
    <Link
      to={mlbCardHref}
      className={cx(styles.mlbCard, className)}
      onClick={onMlbCardClick}
    >
      <div className={styles.image} />
      <div className={styles.content}>
        <div className={styles.topSubContent}>
          <Text
            type='MLBCardTitle'
            size='Medium'
            style='SemiBold'
            colour='BaseLight'
            value={mlbCardTitle}
          />
          <Text
            size='Large'
            style='Regular'
            colour='BaseLight'
            value={mlbCardDescription}
          />
        </div>
        <div className={styles.bottomSubContent}>
          <Text
            size='Medium'
            style='Regular'
            colour='BaseLight'
            value={mlbCardButtonLabel}
          />
          <Icon
            asset='ArrowRightFilled'
            style='White'
            className={styles.buttonIcon}
          />
        </div>
      </div>
      <div className={styles.overlay} />
    </Link>
  );
};
