import cx from 'classnames';
import React from 'react';
import Text from '../../atoms/Text';
import { SearchSuggestionItem } from '../../molecules/SearchSuggestionItem';
import styles from './SearchSuggestionSection.module.scss';
import { usePresenter } from './SearchSuggestionSection.presenter';
import type { SearchSuggestionSectionProps } from './SearchSuggestionSection.types';

export const SearchSuggestionSection: React.FC<SearchSuggestionSectionProps> = (props) => {
  const {
    sectionTitle,
    searchSuggestionItems,
  } = usePresenter(props);

  return (
    <div className={styles.searchSuggestionSection}>
      <Text
        type='Subheading'
        size='Medium'
        style='SemiBold'
        colour='BaseDark'
        value={sectionTitle}
        className={cx(styles.sectionTitle)}
      />
      {searchSuggestionItems.map((searchSuggestionItemProps, index) => (
        <SearchSuggestionItem key={index} {...searchSuggestionItemProps} />
      ))}
    </div>
  );
};
