import type { ErrorBlockCombinedProps } from '../../legacy/blocks/ErrorBlock/types';

export type ServiceErrorResponse = {
  code: number;
  type: string;
  message: string;
};

export type ErrorHandlerProps = {
  error?: Error;
  shouldLogout?: boolean;
  isAuthError?: boolean;
  errorBlockProps?: ErrorBlockCombinedProps;
  notFountErrorBlockProps?: ErrorBlockCombinedProps;
  notAccessibleErrorBlockProps?: ErrorBlockCombinedProps;
  notEligibleCardErrorBlockProps?: ErrorBlockCombinedProps;
  notAccessibleEmailErrorBlockProps?: ErrorBlockCombinedProps;
  children?: React.ReactNode;
};

export class ApiError extends Error {
  code: number;

  type: string;

  constructor(response: ServiceErrorResponse) {
    super(response.message);
    this.code = response.code;
    this.type = response.type;
  }

  static isApiError(error: unknown): error is ApiError {
    const maybeApiError = error as Partial<ApiError> | undefined;
    return (
      !!maybeApiError?.code &&
      maybeApiError?.type != null &&
      maybeApiError instanceof Error
    );
  }
}
