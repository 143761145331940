import cx from 'classnames';
import React from 'react';

import SubmenuItemList from '../../molecules/SubmenuItemList';
import styles from './SubmenuWithoutTabs.module.scss';
import usePresenter from './SubmenuWithoutTabs.presenter';
import getProps from './SubmenuWithoutTabs.props';
import { SubmenuWithoutTabsCombinedProps } from './types';

const SubmenuWithoutTabs: React.FC<SubmenuWithoutTabsCombinedProps> = (props) => {
  const {
    className,
    classes,
    submenuItemList,
    isOpen, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.submenuWithoutTabs;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <SubmenuItemList
          focusable={isOpen}
          className={cx(styles.submenuItemList, classes?.submenuItemList)}
          {...internalProps.submenuItemList}
          {...submenuItemList} />
      </div>
    </div>
  );
};

export default SubmenuWithoutTabs;
