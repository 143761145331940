import type { DividerProps } from '../../atoms/Divider';
import type { SportsMenuTabListProps } from '../../molecules/SportsMenuTabList';
import type { SubmenuItemListProps } from '../../molecules/SubmenuItemList';

type SubmenuWithTabsInternalProps = {
  sportsMenuTabList?: SportsMenuTabListProps;
  divider1?: DividerProps;
  submenuItemList?: SubmenuItemListProps; // MANUAL OVERRIDE
};

type SubmenuWithTabsPropsValues = {
  '': SubmenuWithTabsInternalProps;
};

const propValues: SubmenuWithTabsPropsValues = {
  '': {
    sportsMenuTabList: {
    },
    divider1: {
      type: 'Vertical',
      style: 'Thin',
      colour: 'Grey20',
    },
    submenuItemList: { // MANUAL OVERRIDE
    },
  },
};

const getProps = (type: string): SubmenuWithTabsInternalProps => {
  const values: SubmenuWithTabsInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
