import { useContext, useMemo } from 'react';
import { AuthContext } from '../../../modules/auth';
import type { AccountCardProps } from '../AccountCard';
import type { RewardsBalancePresenterProps, RewardsBalanceProps } from './RewardsBalance.types';
import { getAccountCardProps } from './RewardsBalance.utils';

export const usePresenter = (props: RewardsBalanceProps): RewardsBalancePresenterProps => {
  const { theme } = props;

  const { selectedAccountCardIndex, selectAccountCardByIndex, accountCardDetails } = useContext(AuthContext);

  const accountCardProps: AccountCardProps[] = useMemo(() => getAccountCardProps({ accountCardDetails, theme }), [accountCardDetails, theme]);

  return {
    ...props,
    selectedAccountCardIndex,
    selectAccountCardByIndex,
    accountCardProps,
  };
};
