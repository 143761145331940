import type { EventListContextValue } from './EventListContext.types';

export const EventListContextInitialValue = {
  isFetchEnabled: false,
  setIsFetchEnabled: () => { },
  staticApiParams: {},
  setStaticApiParams: () => { },
  eventsTotal: 0,
  setEventsTotal: () => { },
} as const satisfies EventListContextValue;
