import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTabKeyFromUrl } from './TabBar.hooks';
import type { TabBarPresenterProps, TabBarProps } from './TabBar.types';
import { handleOnTabSelected } from './TabBar.utils';

export const usePresenter = (props: TabBarProps): TabBarPresenterProps => {
  const { tabs } = props;

  const navigate = useNavigate();

  const allowedTabKeys: string[] = useMemo(() => Object.keys(tabs), [tabs]);

  const activeTabKey: string = useTabKeyFromUrl({ allowedTabKeys });

  const onTabSelected = useCallback((newActiveTabKey: string | null) => {    
    return handleOnTabSelected({ activeTabKey, tabs, navigate, newActiveTabKey });
  }, [activeTabKey, tabs, navigate]);

  return {
    ...props,
    activeTabKey,
    onTabSelected,
  };
};
