import cx from 'classnames';
import React from 'react';
import Icon from '../../atoms/Icon';
import { RadioButtonIconPropsByTheme } from './RadioButton.constants';
import styles from './RadioButton.module.scss';
import { usePresenter } from './RadioButton.presenter';
import type { RadioButtonProps } from './RadioButton.types';

export const RadioButton = <TValue extends (number | string) = string>(props: RadioButtonProps<TValue>) => {
  const {
    children,
    isSelected,
    onClick,
    onKeyDown,
    theme,
    ariaLabel,
    className,
  } = usePresenter(props);

  const radioButtonIcon: React.ReactNode = (
    <Icon
      {...RadioButtonIconPropsByTheme[`${theme}${isSelected ? 'Selected' : 'Unselected'}`]}
      className={styles.radioButtonIcon}
    />
  );

  return (
    <div
      tabIndex={0} // To be able to access div by pressing Tab key
      role='radio'
      aria-label={ariaLabel}
      aria-checked={isSelected}
      onClick={onClick}
      onKeyDown={onKeyDown} // Trigger onClick on pressing Enter or Space keys
      className={cx(styles.radioButton, className)}
    >
      <div className={styles.radioButtonLabel}>
        {children}
      </div>
      {radioButtonIcon}
    </div>
  );
};
