import { useCallback, type KeyboardEvent } from 'react';
import type { RadioButtonPresenterProps, RadioButtonProps } from './RadioButton.types';

export const usePresenter = <TValue extends (number | string) = string>(props: RadioButtonProps<TValue>): RadioButtonPresenterProps<TValue> => {
  const { value, onClick: initialOnClick } = props;

  const onClick = useCallback(() => initialOnClick(value), [value, initialOnClick]);

  const onKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (['Enter', 'Space'].includes(event.code)) {
      event.preventDefault();
      onClick();
    }
  }, [onClick]);

  return {
    ...props,
    onClick,
    onKeyDown,
  };
};
