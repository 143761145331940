import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MODAL_PARAM } from '../../../lib/constants';
import { trackScreenViewEvent, useAnalyticsManager } from '../../../modules/analytics';
import { TERMS_OF_USE_CONTENT_HTML, TERMS_OF_USE_MODAL } from './TermsOfUseModal.constants';
import type { TermsOfUseModalPresenterProps, TermsOfUseModalProps } from './TermsOfUseModal.types';

export const usePresenter = (props: TermsOfUseModalProps): TermsOfUseModalPresenterProps => {
  const { search } = useLocation();
  const query: URLSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  const navigate = useNavigate();

  const { trackEvent } = useAnalyticsManager();

  const isTermsOfUseModalShown: boolean = useMemo(() => query.get(MODAL_PARAM) === TERMS_OF_USE_MODAL, [query]);

  useEffect(() => {
    if (isTermsOfUseModalShown) {
      trackScreenViewEvent(
        trackEvent,
        'Terms and Privacy',
      );
    }
  }, [isTermsOfUseModalShown, trackEvent]);

  const closeTermsOfUseModal = useCallback(() => navigate(-1), [navigate]);

  return {
    ...props,
    isTermsOfUseModalShown,
    termsOfUseContentHtml: TERMS_OF_USE_CONTENT_HTML,
    closeTermsOfUseModal,
  };
};
