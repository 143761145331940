import cx from 'classnames';
import React from 'react';

import Divider from '../Divider';
import Icon from '../Icon';
import Text from '../Text';

import styles from './DropdownMenuItem.module.scss';
import usePresenter from './DropdownMenuItem.presenter';
import getProps from './DropdownMenuItem.props';
import { DropdownMenuItemCombinedProps } from './types';

const DropdownMenuItem: React.FC<DropdownMenuItemCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    onDropdownMenuItemClicked,
    divider,
    divider1,
    title,
    description,
    text,
    icon,
    // MANUAL OVERRIDE STARTS
    ariaLabel,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`dropdownMenuItem${variantName}`];

  let componentView: JSX.Element;

  switch (variantName) {
    case 'IndicatorText': {
      componentView = (
        <button
          disabled={disabled}
          aria-label={ariaLabel} // MANUAL OVERRIDE
          type={buttonType}
          form={buttonForm}
          onClick={onDropdownMenuItemClicked}
          className={cx(currentStyle, className)}>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider} />
          <div className={cx(styles.content, classes?.content)}>
            <Divider
              className={cx(styles.divider1, classes?.divider1)}
              {...internalProps.divider1}
              {...divider1} />
            <div className={cx(styles.info, classes?.info)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title} />
              <Text
                className={cx(styles.description, classes?.description)}
                {...internalProps.description}
                {...description} />
            </div>
          </div>
        </button>
      );
      break;
    }
    case 'Text': {
      componentView = (
        <button
          disabled={disabled}
          aria-label={ariaLabel} // MANUAL OVERRIDE
          type={buttonType}
          form={buttonForm}
          onClick={onDropdownMenuItemClicked}
          className={cx(currentStyle, className)}>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider} />
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text} />
          </div>
        </button>
      );
      break;
    }
    case 'IconText': {
      componentView = (
        <button
          disabled={disabled}
          aria-label={ariaLabel} // MANUAL OVERRIDE
          type={buttonType}
          form={buttonForm}
          onClick={onDropdownMenuItemClicked}
          className={cx(currentStyle, className)}>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider} />
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon} />
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text} />
          </div>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default DropdownMenuItem;
