import type { CategoryId } from '../modules/categories';
import type { Category, SubCategory } from '../modules/partnership';

/**
 * Function to return an array of all sub-categories for the provided category
 * @returns {SubCategory[]} An array of all sub-categories for the provided category
 */
export const getSubCategoriesByCategory = (params: {
  /** All categories with sub-categories */
  categories: readonly Category[];
  /** Category Id to return sub-categories for */
  categoryId: CategoryId;
}): readonly SubCategory[] => {
  const { categories, categoryId } = params;

  if (!categories.length) {
    return [];
  }

  const matchingCategory: Category | undefined = categories.find((category: Category) => category.id === categoryId);

  return matchingCategory?.subCategories ?? [];
};
