import type { SpinnerTheme } from '../../atoms/Spinner';
import type { CarouselTheme } from './Carousel.types';

/** If 90% of the element width is visible then we consider that element as fully visible */
export const FULL_VISIBILITY_PERCENTAGE = 0.90;

export const SpinnerThemesByCarouselTheme = {
  light: 'dark',
  dark: 'light',
} as const satisfies Record<CarouselTheme, SpinnerTheme>;
