import { useMemo, useRef, type ReactNode, type RefObject } from 'react';
import { processRawHtmlContent, useHtmlElementSize, useWindowSize } from '../../../lib/util';
import { useModalState } from '../../../modules/modals';
import type { ExpandableContentPresenterProps, ExpandableContentProps } from './ExpandableContent.types';
import { checkIsSeeMoreButtonShown, getContentClassName } from './ExpandableContent.utils';

export const usePresenter = (props: ExpandableContentProps): ExpandableContentPresenterProps => {
  const { mobileMaxLines, desktopMaxLines, content: initialRawHtmlContent } = props;

  const { isMobile } = useWindowSize();

  const contentRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  /** Processed full content that may contain HTML tags */
  const processedContentHtml: ReactNode = useMemo(
    () => processRawHtmlContent({ initialRawHtmlContent }),
    [initialRawHtmlContent],
  );

  /** Class name to be applied to the content if the content needs to be truncated */
  const contentClassName: string | undefined = useMemo(
    () => getContentClassName({ maxLines: isMobile ? mobileMaxLines : desktopMaxLines }),
    [isMobile, mobileMaxLines, desktopMaxLines],
  );

  const { width: contentWidthPx } = useHtmlElementSize({ htmlElement: contentRef.current });

  const isSeeMoreButtonShown: boolean = useMemo(
    () => checkIsSeeMoreButtonShown({ contentHtmlElement: contentRef.current }),
    // Need to explicitly include dependency on content width so that the value is recomputed on window resize
    [contentWidthPx], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const {
    isModalOpen: isContentModalShown,
    /** Class name to be applied to the content modal to enable slide up/down animation on opening/closing of the content modal */
    animatedModalClassName: contentModalClassName,
    openModal: onSeeMoreButtonClick,
    closeModal: closeContentModal,
  } = useModalState({ isAnimationEnabled: isMobile });

  return {
    ...props,
    contentRef,
    processedContentHtml,
    contentClassName,
    isSeeMoreButtonShown,
    onSeeMoreButtonClick,
    isContentModalShown,
    contentModalClassName,
    closeContentModal,
  };
};
