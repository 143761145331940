import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import type { TicketAlertModalPresenterProps, TicketAlertModalProps } from './TicketAlertModal.types';

export const usePresenter = (props: TicketAlertModalProps): TicketAlertModalPresenterProps => {
  const {
    primaryButtonLabel,
    onPrimaryButtonClick: initialOnPrimaryButtonClick,
    onCloseButtonClick: initialOnCloseButtonClick,
  } = props;

  const { t } = useTranslation();

  const { trackEvent } = useAnalyticsManager();

  const onPrimaryButtonClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Production',
      'TicketAlertModal',
      primaryButtonLabel,
    );

    initialOnPrimaryButtonClick();
  }, [primaryButtonLabel, initialOnPrimaryButtonClick, trackEvent]);

  const onCloseButtonClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Production',
      'TicketAlertModal',
      t('common.close'),
    );

    initialOnCloseButtonClick();
  }, [t, initialOnCloseButtonClick, trackEvent]);

  return {
    ...props,
    onPrimaryButtonClick,
    onCloseButtonClick,
  };
};
