import cx from 'classnames';
import React from 'react';

import Text from '../../atoms/Text';
import Tag from '../Tag';

import styles from './PerformerInfo.module.scss';
import usePresenter from './PerformerInfo.presenter';
import getProps from './PerformerInfo.props';
import { PerformerInfoCombinedProps } from './types';

const PerformerInfo: React.FC<PerformerInfoCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    title,
    date,
    time,
    location,
    price,
    tag,
    soldOut,
    locationMobile,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`performerInfo${variantName}`];

  let componentView: JSX.Element;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.title, classes?.title)}
            {...internalProps.title}
            {...title} />
          <div className={cx(styles.eventLogistics, classes?.eventLogistics)}>
            <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
              <Text
                className={cx(styles.date, classes?.date)}
                {...internalProps.date}
                {...date} />
              <Text
                className={cx(styles.time, classes?.time)}
                {...internalProps.time}
                {...time} />
            </div>
            <Text
              className={cx(styles.location, classes?.location)}
              {...internalProps.location}
              {...location} />
            <Text // MANUAL OVERRIDE
              className={cx(styles.locationMobile, classes?.locationMobile)}
              {...internalProps.location}
              {...locationMobile} />
            <div className={cx(styles.locationAndPrice, classes?.locationAndPrice)}>
              <Text
                className={cx(styles.location, classes?.location)}
                {...internalProps.location}
                {...location} />
              <Text
                className={cx(styles.price, classes?.price)}
                {...internalProps.price}
                {...price} />
            </div>
            <Text
              className={cx(styles.price, classes?.price)}
              {...internalProps.price}
              {...price} />
          </div>
        </div>
      );
      break;
    }
    case 'SoldOut': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.title, classes?.title)}
            {...internalProps.title}
            {...title} />
          <div className={cx(styles.eventLogistics, classes?.eventLogistics)}>
            <div className={cx(styles.eventLogistics1, classes?.eventLogistics1)}>
              <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
                <Text
                  className={cx(styles.date, classes?.date)}
                  {...internalProps.date}
                  {...date} />
                <Text
                  className={cx(styles.time, classes?.time)}
                  {...internalProps.time}
                  {...time} />
              </div>
              <div className={cx(styles.locationAndStatus, classes?.locationAndStatus)}>
                <Text
                  className={cx(styles.location, classes?.location)}
                  {...internalProps.location}
                  {...location} />
                <Tag
                  className={cx(styles.tag, classes?.tag)}
                  {...internalProps.tag}
                  {...tag} />
              </div>
            </div>
            <Text
              className={cx(styles.location, classes?.location)}
              {...internalProps.location}
              {...location} />
            <Tag
              className={cx(styles.soldOut, classes?.soldOut)}
              {...internalProps.soldOut}
              {...soldOut} />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default PerformerInfo;
