import cx from 'classnames';
import React, { type ReactNode } from 'react';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';
import { AccountCardInfoColoursByTheme } from './AccountCard.constants';
import styles from './AccountCard.module.scss';
import { usePresenter } from './AccountCard.presenter';
import type { AccountCardProps } from './AccountCard.types';

export const AccountCard: React.FC<AccountCardProps> = (props) => {
  const {
    accountCardImageUrl,
    accountCardLastFourDigits,
    formattedRewardUnitsTotal,
    theme,
    areRewardUnitsBold,
    className,
  } = usePresenter(props);

  /** Card info that is formatted as '•••• {accountCardLastFourDigits} | {formattedRewardUnitsTotal}', e.g. '•••• 1234 | 123,456 miles' */
  let accountCardInfo: ReactNode = `•••• ${accountCardLastFourDigits}`;

  if (formattedRewardUnitsTotal) {
    accountCardInfo = areRewardUnitsBold
      ? <>{accountCardInfo} | <span className={styles.boldRewardUnits}>{formattedRewardUnitsTotal}</span></>
      : <>{accountCardInfo} | {formattedRewardUnitsTotal}</>;
  }

  return (
    <div className={cx(styles.accountCard, className)}>
      <Image
        imageSrc={accountCardImageUrl}
        className={cx(styles.accountCardImage, styles[`${theme}AccountCardImage`])}
      />
      <div className={styles.accountCardInfoContainer}>
        <Text
          size='Medium'
          style='Regular'
          colour={AccountCardInfoColoursByTheme[theme]}
          value={accountCardInfo}
        />
      </div>
    </div>
  );
};
