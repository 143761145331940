import React from 'react';
import { Link } from 'react-router-dom';
import { buildGoBackHistoryState } from '../../../modules/navigation/Navigation.utils';
import Text from '../../atoms/Text';
import { getClickAccessibilityProps } from '../../organisms/TopNav';
import styles from './SearchSuggestionItem.module.scss';
import { usePresenter } from './SearchSuggestionItem.presenter';
import type { SearchSuggestionItemProps } from './SearchSuggestionItem.types';

export const SearchSuggestionItem: React.FC<SearchSuggestionItemProps> = (props) => {
  const {
    title,
    description,
    href,
    onClick,
    onEsc,
  } = usePresenter(props);

  const accessibilityProps = getClickAccessibilityProps({ actions: { onClick, onEsc }, addIndex: true });

  return (
    <Link
      to={href}
      state={buildGoBackHistoryState()}
      className={styles.searchSuggestionItem}
      onClick={onClick}
      {...accessibilityProps}
    >
      <div className={styles.content}>
        <div className={styles.innerContent}>
          <Text
            size='Medium'
            style='SemiBold'
            colour='BaseDark'
            value={title}
            className={styles.truncatable}
          />
          {!!description && (
            <Text
              size='Small'
              style='Regular'
              colour='Charcoal30'
              value={description}
              className={styles.truncatable}
            />
          )}
        </div>
      </div>
    </Link>
  );
};
