import cx from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import { MLB_PRIVACY_POLICY_URL } from './MarketingOptinMlb.constants';
import styles from './MarketingOptinMlb.module.scss';
import { usePresenter } from './MarketingOptinMlb.presenter';
import type { MarketingOptinMlbProps } from './MarketingOptinMlb.types';

export const MarketingOptinMlb: React.FC<MarketingOptinMlbProps> = (props) => {
  const { isExpanded, teamName, userEmail, setIsExpanded, handleSubscribeClick, isSubscribeButtonClicked } = usePresenter(props);

  const { t } = useTranslation();

  const expandedTitle = (
    <Trans
      i18nKey='marketingOptinMlb.expandedTitle'
      components={{
        mlbPrivacyPolicyLink: <a className={cx(styles.mlbPrivacyUrl)} target='_blank' href={MLB_PRIVACY_POLICY_URL} rel='noreferrer'>{MLB_PRIVACY_POLICY_URL}</a>,
        strong: <strong className={styles.boldExpandedTitle}></strong>,
      }}
      values={{ teamName, userEmail }}
    />
  );

  const expandedContent = (
    <div className={styles.expandedContent}>
      <Text
        type='Body'
        size='Large'
        style='Regular'
        colour='SubduedLight'
        align='Left'
        value={expandedTitle}
        classes={{ value: styles.expandedTitle }}
      />
      <Text
        type='Body'
        size='Small'
        style='Regular'
        colour='SubduedLight'
        align='Left'
        value={t('marketingOptinMlb.expandedDescription')}
      />
      <Button
        type='IconText'
        style='Text'
        size='Small'
        icon={{
          asset: 'Checkmark',
          style: 'DigitalGrey80',
        }}
        text={{ value: isSubscribeButtonClicked ? t('marketingOptinMlb.subscribed') : t('marketingOptinMlb.subscribe') }}
        onClick={handleSubscribeClick}
        className={cx(styles.subscribe, { [styles.subscribed]: isSubscribeButtonClicked })}
        classes={{
          text: cx(styles.subscribeText, { [styles.subscribedText]: isSubscribeButtonClicked }),
          icon: cx(styles.subscribeIcon, { [styles.subscribedIcon]: isSubscribeButtonClicked }),
        }}
      />
    </div>
  );

  return (
    <div className={cx(styles.marketingOptinMlbContainer)}>
      <Divider
        type='Horizontal'
        style='Thin'
        colour='DigitalGrey15'
        className={cx(styles.divider)}
      />
      <Button
        type='TextIcon'
        style='Text'
        size='Medium'
        icon={{
          asset: isExpanded ? 'ChevronUp' : 'ChevronDown',
          style: 'DigitalGrey80',
        }}
        text={{ value: t('marketingOptinMlb.toggleButtonText') }}
        onClick={() => setIsExpanded(!isExpanded)}
        className={cx(styles.toggleButtonContainer)}
        classes={{
          content: styles.toggleButtonContent,
          text: styles.toggleButtonText,
          icon: styles.toggleButtonIcon,
        }}
      />
      {isExpanded && expandedContent}
    </div>
  );
};
