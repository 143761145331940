import { useMemo } from 'react';
import type { TextDropdownOptionProps, TextDropdownPresenterProps, TextDropdownProps } from './TextDropdown.types';
import { getOptionProps } from './TextDropdown.utils';

export const usePresenter = (props: TextDropdownProps): TextDropdownPresenterProps => {
  const { options, selectedValue } = props;

  const optionProps: TextDropdownOptionProps[] = useMemo(
    () => getOptionProps({ options, selectedValue }),
    [options, selectedValue],
  );

  return {
    ...props,
    optionProps,
  };
};
