import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsManager } from '../../../modules/analytics';
import { MapContext } from '../../../modules/map';
import type { ListingCardProps } from '../../molecules/ListingCard';
import { getListingCardProps } from '../../pages/EventPage';
import styles from './NonGuestListListings.module.scss';
import type { NonGuestListListingsPresenterProps, NonGuestListListingsProps } from './NonGuestListListings.types';
import { checkHasListings } from './NonGuestListListings.utils';

export const usePresenter = (props: NonGuestListListingsProps): NonGuestListListingsPresenterProps => {
  const {
    eventMetadata: {
      isPayWithRewardsOnly,
      isSoldOut,
    },
  } = props;

  const {
    /** Array of listings to display, e.g. when user selects a section on SVG map then they should see listings only for that section. */
    listingsToDisplay,
    setHoveredSection,
    setSelectedSection,
  } = useContext(MapContext);

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  /** Array of listing card props for rendering the listings */
  const listingCardProps: ListingCardProps[] = useMemo(
    () => getListingCardProps({
      listings: listingsToDisplay,
      isPayWithRewardsOnly,
      shouldShowAipOverride: false,
      setHoveredSection,
      setSelectedSection,
      className: styles.listing,
    }),
    [listingsToDisplay, isPayWithRewardsOnly, setHoveredSection, setSelectedSection],
  );

  /** True if event is not sold out and there is at least one listing */
  const hasListings: boolean = useMemo(
    () => checkHasListings({ isSoldOut, listingQty: listingCardProps.length }),
    [isSoldOut, listingCardProps.length],
  );

  /** Announcement of the total number of listings to screen readers */
  const listingsTotalAnnouncement: string = useMemo(
    () => t('eventPage.listingsTotalAnnouncement', { listingsTotal: hasListings ? listingCardProps.length : 0 }),
    [t, hasListings, listingCardProps.length],
  );

  // Track event listings
  useEffect(() => {
    trackEvent('view_item', {
      ecommerce: {
        content_type: 'view_production',
        items: listingsToDisplay,
      },
    });
  }, [listingsToDisplay, trackEvent]);

  return {
    ...props,
    hasListings,
    listingCardProps,
    listingsTotalAnnouncement,
  };
};
