import cx from 'classnames';
import 'flatpickr/dist/flatpickr.css';
import React, { type ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import TextInput from '../../atoms/TextInput';
import DropdownSelectList from '../../organisms/DropdownSelectList';
import styles from './Filter.module.scss';
import usePresenter from './Filter.presenter';
import getProps from './Filter.props';
import type { FilterCombinedProps } from './types';

const Filter: React.FC<FilterCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    icon,
    filterButton,
    text,
    dropdownButton,
    closeButton,
    dropdownSelectList,
    showDropdown,
    showCalendar,
    isSearchInputEnabled,
    searchInput,
    handleToggle,
    setRange,
  } = usePresenter(props);

  const internalProps = getProps(type ?? '');
  const currentStyle = styles[`filter${type}`];

  let textView: ReactNode;
  let iconView: ReactNode;

  switch (type) {
    case 'IconFilter':
    case 'IconFilterClose': {
      iconView = (
        <Icon
          className={cx(styles.icon, classes?.icon)}
          {...internalProps.icon}
          {...icon} />
      );
      break;
    }
    case 'IconTextFilter': {
      iconView = (
        <Icon
          className={cx(styles.icon, classes?.icon)}
          {...internalProps.icon}
          {...icon} />
      );
      textView = (
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text} />
      );
      break;
    }
    case 'TextIconFilter': {
      textView = (
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text} />
      );
      break;
    }
    default:
      textView = <div>�</div>;
      break;
  }

  const filterButtonView: ReactNode = (
    <Button
      className={cx(styles.filterButton, classes?.filterButton)}
      {...internalProps.filterButton}
      {...filterButton} />
  );

  // Do not show chevron icon when dropdown is expanded and search input is enabled
  const dropdownButtonView: ReactNode = !!dropdownButton && (!showDropdown || !isSearchInputEnabled) && (
    <Icon className={styles.dropdownButton} {...dropdownButton.icon} />
  );

  const closeButtonView: ReactNode = !!closeButton && (
    <Button className={styles.closeButton} {...closeButton} />
  );

  const contentView: ReactNode = <>
    {iconView}
    {textView}
    {showDropdown && isSearchInputEnabled
      ? (
        <TextInput
          className={styles.searchInput}
          {...searchInput} />
      )
      : <>
        {filterButtonView}
        {closeButtonView}
      </>
    }
  </>;

  return (
    <div className={cx(currentStyle, className)}>
      <Dropdown
        className={styles.dropdown}
        onToggle={handleToggle}
        show={showDropdown}
      >
        {contentView}
        <Dropdown.Toggle
          className={styles.toggle} onClick={dropdownButton?.onClick}>
          {dropdownButtonView}
        </Dropdown.Toggle>
        {showDropdown && (
          <Dropdown.Menu className={styles.dropdownMenu} align='right'>
            {showCalendar
              ? (
                <Flatpickr
                  className={styles.flatpickr}
                  options={{ mode: 'range', inline: true, minDate: 'today' }}
                  onChange={(value: Date[]) => {
                    if (setRange) {
                      setRange(value);
                    }
                  }} />
              )
              : <DropdownSelectList {...dropdownSelectList} />
            }
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  );
};

export default Filter;
