import React from 'react';
import { usePresenter } from './SvgMapStyle.presenter';
import type { SvgMapStyleProps } from './SvgMapStyle.types';

const InternalSvgMapStyle: React.FC<SvgMapStyleProps> = (props) => {
  const {
    styleId,
    cssSelector,
    cssContentString,
  } = usePresenter(props);

  return (
    <style key={styleId}>
      {`${cssSelector} { ${cssContentString} }`}
    </style>
  );
};

/** Memoize SVG map style since map uses a large number of styles, e.g. hovering/selection on SVG map */
export const SvgMapStyle = React.memo(InternalSvgMapStyle);
