import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { CssVariableNameEnum, type Props } from '../../lib/types';
import { useAnalyticsManager } from '../analytics';
import { AuthContext } from '../auth';
import type { CategoryMenuItemState } from '../navigation/Navigation.types';
import type { SearchSuggestionsQueryParams, SearchSuggestionsResponse } from '../partnership';
import { getSearchSuggestions, getTopNavPerformers } from '../partnership/api';
import { checkHasSearchSuggestions } from '../partnership/utils';
import { RegionContext } from '../region';
import { InitialTopNavContextValue } from './TopNavContext.constants';
import type { TopFixedHeights, TopNavContextValue, TopNavMenuTypeEnum } from './TopNavContext.types';
import { checkAreSearchSuggestionsEnabled, getCategoryMenuItemStates, getSearchSuggestionsQueryParams } from './TopNavContext.utils';

export const TopNavContext = createContext<TopNavContextValue>(InitialTopNavContextValue);

export const TopNavProvider: React.FC<Props> = ({ children }) => {
  const { account } = useContext(AuthContext);

  const { homePageRegionFilterState } = useContext(RegionContext);

  const { trackEvent } = useAnalyticsManager();

  // Fetch top nav performers data
  const { data: topNavPerformersResponse } = useQuery('topNavPerformers', getTopNavPerformers, { retry: 2 });

  // 'updateCategoryMenuItemStates' function to be called again only when topNavPerformersResponse changes, avoiding unnecessary recalculations.
  const categoryMenuItemStates: CategoryMenuItemState[] = useMemo(
    () => getCategoryMenuItemStates({ topNavPerformersResponse, homePageRegionFilterState }),
    [topNavPerformersResponse, homePageRegionFilterState],
  );

  const [activeTopNavMenuType, setActiveTopNavMenuType] = useState<TopNavMenuTypeEnum | undefined>();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const areSearchSuggestionsEnabled: boolean = useMemo(
    () => checkAreSearchSuggestionsEnabled({ activeTopNavMenuType, searchQuery }),
    [activeTopNavMenuType, searchQuery],
  );

  const searchSuggestionsQueryParams: SearchSuggestionsQueryParams | undefined = useMemo(
    () => getSearchSuggestionsQueryParams({ areSearchSuggestionsEnabled, searchQuery, account }),
    [areSearchSuggestionsEnabled, searchQuery, account],
  );

  const { data: searchSuggestionsApiResponse, isLoading: isSearchSuggestionsApiResponseLoading } = useQuery(
    ['searchSuggestions', ...Object.values(searchSuggestionsQueryParams ?? {})],
    () => getSearchSuggestions(searchSuggestionsQueryParams!),
    { enabled: areSearchSuggestionsEnabled && !!searchSuggestionsQueryParams },
  );

  const [searchSuggestions, setSearchSuggestions] = useState<SearchSuggestionsResponse | undefined>();

  useEffect(() => {
    if (!areSearchSuggestionsEnabled) {
      setSearchSuggestions(undefined);
    }

    if (!isSearchSuggestionsApiResponseLoading && searchSuggestionsApiResponse) {
      setSearchSuggestions(checkHasSearchSuggestions(searchSuggestionsApiResponse) ? searchSuggestionsApiResponse : undefined);
    }
  }, [areSearchSuggestionsEnabled, isSearchSuggestionsApiResponseLoading, searchSuggestionsApiResponse]);

  useEffect(() => {
    if (searchSuggestionsQueryParams?.q) {
      trackEvent('search', { search_term: searchSuggestionsQueryParams.q });
    }
  }, [searchSuggestionsQueryParams?.q, trackEvent]);

  /** Breakdown of top fixed heights, e.g. TopNav height, TopDisclaimer height, etc. */
  const [topFixedHeights, setTopFixedHeights] = useState<TopFixedHeights>({});

  const addTopFixedHeight = useCallback((cssVariableName: CssVariableNameEnum, newTopFixedHeight: number) => {
    setTopFixedHeights(prevTopFixedHeights => {
      // Store value in CSS variable so that it becomes available in SCSS files
      document.documentElement.style.setProperty(cssVariableName, `${newTopFixedHeight / 16}rem`);
      return { ...prevTopFixedHeights, [cssVariableName]: newTopFixedHeight };
    });
  }, []);

  /** Height of the top fixed content (TopNav and TopDisclaimer) */
  const topFixedHeight: number = useMemo(() => {
    const newTopFixedHeight: number = Object.values(topFixedHeights).reduce((acc, val) => acc + val, 0);

    // Store value in CSS variable so that it becomes available in SCSS files
    document.documentElement.style.setProperty(CssVariableNameEnum.topFixedHeight, `${newTopFixedHeight / 16}rem`);

    return newTopFixedHeight;
  }, [topFixedHeights]);

  const [shouldShowTopNav, setShouldShowTopNav] = useState<boolean>(true);

  const [shouldShowFooter, setShouldShowFooter] = useState<boolean>(true);

  return (
    <TopNavContext.Provider value={{
      categoryMenuItemStates,
      hasCategoryMenuItemStates: !!categoryMenuItemStates.length,
      activeTopNavMenuType,
      setActiveTopNavMenuType,
      searchQuery,
      setSearchQuery,
      searchSuggestions,
      topFixedHeight,
      addTopFixedHeight,
      shouldShowTopNav,
      setShouldShowTopNav,
      shouldShowFooter,
      setShouldShowFooter,
    }}>
      {children}
    </TopNavContext.Provider>
  );
};
