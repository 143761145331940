import { useContext, useMemo } from 'react';
import { AuthContext } from '../../../modules/auth';
import type { AccountCardProps } from '../AccountCard/AccountCard.types';
import type { AccountCardsPresenterProps, AccountCardsProps } from './AccountCards.types';
import { getAccountCardProps } from './AccountCards.utils';

export const usePresenter = (props: AccountCardsProps): AccountCardsPresenterProps => {
  const { accountCardDetails } = useContext(AuthContext);

  const accountCardProps: AccountCardProps[] = useMemo(() => getAccountCardProps({ accountCardDetails }), [accountCardDetails]);

  return {
    ...props,
    accountCardProps,
  };
};
