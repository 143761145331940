import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addQueryParam } from '../../lib/util';
import type { AppendQueryParamsToUrlFunc, AppendQueryParamsToUrlOptions, UseAppendQueryParamsToUrlResponse } from './Navigation.types';
import { getCurrentHistoryState } from './Navigation.utils';

/** Hook to append new query parameters to the URL (current URL by default) */
export const useAppendQueryParamsToUrl = (): UseAppendQueryParamsToUrlResponse => {
  const navigate = useNavigate();

  const response: UseAppendQueryParamsToUrlResponse = useMemo(() => {
    const appendQueryParamsToUrl: AppendQueryParamsToUrlFunc = (queryParams: Record<string, string>, options?: AppendQueryParamsToUrlOptions) => {
      const { url: inputUrl, ...navigateOptions } = options ?? {};

      // Fallback to the current URL if no URL is provided
      const url: string = inputUrl ?? window.location.search;

      // Amend query string and navigate
      navigate(addQueryParam(url, queryParams, { skipEmptyParams: true }), { state: getCurrentHistoryState(), replace: true, ...navigateOptions });
    };

    return { appendQueryParamsToUrl };
  }, [navigate]);

  return response;
};

/** Hook that preserves history state for the current path name even when query string parameters change */
export const usePreserveHistoryState = (params?: { isDisabled: boolean; }): void => {
  const { isDisabled = false } = params ?? {};

  const location = useLocation();
  const navigate = useNavigate();

  const prevHistoryStateRef = useRef<unknown>();

  useEffect(() => {
    if (!isDisabled) {
      // If state is provided then store it and return
      if (location.state) {
        prevHistoryStateRef.current = location.state;
        return;
      }

      // If no state is provided but we have a previous state then navigate to the same location but add the previous state
      if (prevHistoryStateRef.current) {
        navigate(location, { state: prevHistoryStateRef.current, replace: true });
      }
    }
  }, [isDisabled, location, navigate]);
};
