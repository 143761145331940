import { NavigateFunction } from 'react-router-dom';
import type { TabProps, TabsMap } from './TabBar.types';

/**
 * Handles the selection of a tab and navigates to the corresponding URL. 
 */
export const handleOnTabSelected = (
  params: {
    /** The key of the currently active tab. */
    activeTabKey: string,
    /** A map of all tabs where each key corresponds to a tab. */
    tabs: TabsMap<string>,
    /** A function to navigate to a different URL. */
    navigate: NavigateFunction,
    /** The key of the newly selected tab. */
    newActiveTabKey: string | null,
  },
) => {
  const { activeTabKey, tabs, navigate, newActiveTabKey } = params;
  // Do nothing if the user selects the currently active tab
  if (activeTabKey === newActiveTabKey) {
    return;
  }

  // Get the newly selected tab's properties
  const selectedTab: TabProps | undefined = newActiveTabKey ? tabs[newActiveTabKey] : undefined;

  // If the selected tab exists, navigate to its URL
  if (selectedTab) {
    navigate(selectedTab.href);
  }
};

/**
 * extract and validate a tab key from the URL query parameters. 
 * @returns The validated tab key, or the first allowed tab key if the parameter is invalid.
 */
export const getValidTabKeyFromUrl = <TTabKey extends string>(
  params: {
    /** The tab key parameter from the URL. */
    tabKeyParam: TTabKey | undefined,
    /** Array of allowed tab keys. */
    allowedTabKeys: readonly TTabKey[],
    /** Function to clear the tab key parameter from the URL. */
    clearTabKeyParamInUrl: () => void,
  },
): TTabKey => {
  const { tabKeyParam, allowedTabKeys, clearTabKeyParamInUrl } = params;
  if (!tabKeyParam) {
    return allowedTabKeys[0];
  }

  let isTabKeyParamValid: boolean = false;

  try {
    // Accept only valid values for tab key parameter
    if (allowedTabKeys.includes(tabKeyParam)) {
      isTabKeyParamValid = true;
      return tabKeyParam;
    }
  } finally {
    // Remove invalid tab key parameter from the URL
    if (!isTabKeyParamValid) {
      clearTabKeyParamInUrl();
    }
  }

  return allowedTabKeys[0];
};