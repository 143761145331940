import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../atoms/Text';
import { PrimaryTextColoursByTheme, SecondaryTextColoursByTheme } from './SubTotal.constants';
import styles from './SubTotal.module.scss';
import { usePresenter } from './SubTotal.presenter';
import type { SubTotalProps } from './SubTotal.types';

export const SubTotal: React.FC<SubTotalProps> = (props) => {
  const {
    formattedTotalCashPrice = '',
    formattedTotalLoyaltyPrice = '',
    loyaltyUnitName,
    serviceFeeText,
    isPayWithRewardsOnly,
    theme,
  } = usePresenter(props);

  const { t } = useTranslation();

  const rightContent: ReactNode = (<>
    {!isPayWithRewardsOnly && (<>
      <Text
        size='Large'
        style='SemiBold'
        colour={PrimaryTextColoursByTheme[theme]}
        align='Right'
        value={formattedTotalCashPrice}
      />
      <Text
        size='Small'
        style='Regular'
        colour={SecondaryTextColoursByTheme[theme]}
        align='Right'
        value={t('subTotal.OR')}
      />
    </>)}
    <div className={styles.loyaltyPrice}>
      <Text
        size='Large'
        style='SemiBold'
        colour={PrimaryTextColoursByTheme[theme]}
        align='Right'
        value={formattedTotalLoyaltyPrice}
      />
      {!!loyaltyUnitName && (
        <Text
          size='Small'
          style='Regular'
          colour={SecondaryTextColoursByTheme[theme]}
          align='Right'
          value={loyaltyUnitName}
        />
      )}
    </div>
  </>);

  return (
    <div className={styles.subTotalContainer}>
      <div className={styles.subTotal}>
        <Text
          type='Subheading'
          size='Medium'
          style='SemiBold'
          colour={PrimaryTextColoursByTheme[theme]}
          value={t('subTotal.title')}
        />
        <div className={styles.rightContent}>
          {rightContent}
        </div>
      </div>
      {!!serviceFeeText && (
        <Text
          size='Small'
          style='Regular'
          colour={SecondaryTextColoursByTheme[theme]}
          align='Right'
          value={serviceFeeText}
          className={styles.serviceFeeText}
        />
      )}
    </div>
  );
};
