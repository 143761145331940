import { BlobProvider } from '@react-pdf/renderer';
import * as React from 'react';
import { runInNextCycle } from '../../../../lib/util';
import { useAnalyticsManager } from '../../../../modules/analytics/useAnalyticsManager';
import { trackErrorEvent } from '../../../../modules/analytics/util';

export interface PdfDocumentProps {
  title?: string;
  document: JSX.Element;
  setUrl?: React.Dispatch<string>;
}

const PdfDocument: React.FC<PdfDocumentProps> = ({ document, setUrl }) => {
  const { useState, useEffect } = React;
  const [ready, setReady] = useState(false);

  const { trackEvent } = useAnalyticsManager();

  // This is hacky but helps set the render to the back of event queue https://github.com/diegomura/react-pdf/issues/420
  useEffect(() => {
    runInNextCycle(() => setReady(true));
  }, []);
  // End of hacky stuff

  if (!ready) {
    return null;
  } else {
    return (
      <BlobProvider document={document}>
        {({ url, loading, error }) => {
          if (loading) {
            //TODO: Add nicer loading widget
            return <span>generating document...</span>;
          }
          if (!loading && url && setUrl) {
            setUrl(url);
          }
          if (error) {
            console.error(error);
            trackErrorEvent(
              trackEvent,
              '',
              error.message,
              {
                error_type: error.name,
              },
            );
            //TODO: Error boundary Add nicer loading widget
            return <p>An error occurred</p>;
          }
          return null;
        }}
      </BlobProvider>
    );
  }
};

export default PdfDocument;
