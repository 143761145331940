import type { ReactNode } from 'react';
import { ReactComponent as LogoCapitalOneIcon } from '../../../resources/icons/LogoCapitalOne.svg';
import { ReactComponent as LogoCapitalOneEntertainmentIcon } from '../../../resources/icons/LogoCapitalOneEntertainment.svg';
import { ReactComponent as LogoPoweredByVividSeatsIcon } from '../../../resources/icons/LogoPoweredByVividSeats.svg';
import { ReactComponent as LogoVividSeatsIcon } from '../../../resources/icons/LogoVividSeats.svg';
import type { LogoAssetEnum } from './Logo.types';

export const LogoIconsByAsset = {
  capitalOne: LogoCapitalOneIcon,
  capitalOneEntertainment: LogoCapitalOneEntertainmentIcon,
  vividSeats: LogoVividSeatsIcon,
  poweredByVividSeats: LogoPoweredByVividSeatsIcon,
} as const satisfies Record<LogoAssetEnum, ReactNode>;
