import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './StaticImageMap.module.scss';
import { usePresenter } from './StaticImageMap.presenter';
import type { StaticImageMapProps } from './StaticImageMap.types';

export const StaticImageMap: React.FC<StaticImageMapProps> = (props) => {
  const { staticImageMapUrl } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <div className={styles.staticImageMapWrapper}>
      <img
        src={staticImageMapUrl}
        alt={t('map.mapTitle')}
        className={styles.staticImageMap}
      />
    </div>
  );
};
