import React from 'react';
import type { EventsQueryParams, PageQueryParams } from '../../../modules/partnership';
import { Spinner } from '../../atoms/Spinner';
import { PageContentContainerLayout } from '../../layout/PageContentContainerLayout';
import { EmptyState } from '../../molecules/EmptyState';
import { EventCard, type EventCardProps } from '../../molecules/EventCard';
import { PaginatedList } from '../../organisms/PaginatedList';
import { usePresenter } from './BaseEventListPage.presenter';
import type { BaseEventListPageProps } from './BaseEventListPage.types';

/**
 * Component that contains the logic of fetching events and rendering them in a list.
 * Component allows for optional top content to be rendered above the event list.
 */
export const BaseEventListPage = <TApiParams extends PageQueryParams = EventsQueryParams>(props: BaseEventListPageProps<TApiParams>) => {
  const {
    topContent,
    topEventListContent,
    isFetchEnabled,
    isFetchingEvents,
    eventCardProps,
    eventListState,
    emptyStateProps,
    className,
    classes,
  } = usePresenter(props);

  const getEventListContent = (): React.ReactNode => {
    // Loading
    if (!isFetchEnabled || (isFetchingEvents && !eventCardProps.length)) {
      return <Spinner />;
    }

    // Empty state
    if (!eventCardProps.length) {
      return <EmptyState {...emptyStateProps} />;
    }

    // With events
    return (<>
      {topEventListContent}
      <PaginatedList<EventCardProps>
        {...eventListState}
        component={EventCard}
        componentProps={eventCardProps}
        classes={classes}
      />
    </>);
  };

  return (
    <PageContentContainerLayout className={className}>
      {topContent}
      {getEventListContent()}
    </PageContentContainerLayout>
  );
};
