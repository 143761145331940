import type { IconStyleEnum } from '../../atoms/Icon';
import type { TextColourEnum } from '../../atoms/Text';
import type { ToolTipTheme } from '../../atoms/ToolTip';
import type { CarouselTheme } from '../../molecules/Carousel';

export const CarouselTitleColoursByTheme = {
  light: 'BaseDark',
  dark: 'BaseLight',
} as const satisfies Record<CarouselTheme, TextColourEnum>;

export const ToolTipThemesByTheme = {
  light: 'dark',
  dark: 'light',
} as const satisfies Record<CarouselTheme, ToolTipTheme>;

export const CarouselLinkColoursByTheme = {
  light: 'ActionBase',
  dark: 'BaseLight',
} as const satisfies Record<CarouselTheme, TextColourEnum>;

export const CarouselLinkIconStylesByTheme = {
  light: 'ActionBase',
  dark: 'White',
} as const satisfies Record<CarouselTheme, IconStyleEnum>;
