import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from '../../atoms/Logo';
import FooterMenuList from '../../molecules/FooterMenuList';
import { LogoThemesByFooterTheme } from './Footer.constants';
import styles from './Footer.module.scss';
import { usePresenter } from './Footer.presenter';
import type { FooterProps } from './Footer.types';

export const Footer: React.FC<FooterProps> = (props) => {
  const {
    theme,
    footerButtons,
  } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <div className={cx(styles.footer, styles[`${theme}Footer`])}>
      <Logo
        asset='capitalOne'
        theme={LogoThemesByFooterTheme[theme]}
        logoAlt={t('logo.capitalOneEntertainment')}
        className={styles.footerLogo}
      />
      {!!footerButtons.length && (
        <FooterMenuList buttons={footerButtons} />
      )}
    </div>
  );
};
