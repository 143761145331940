import { useMemo } from 'react';
import type { StepItemProps } from '../StepItem';
import type { CheckoutStepperPresenterProps, CheckoutStepperProps } from './CheckoutStepper.types';
import { getStepItemProps } from './CheckoutStepper.utils';

export const usePresenter = (props: CheckoutStepperProps): CheckoutStepperPresenterProps => {
  const { step, stepItemLabelKeys } = props;

  /** Array of step item props for rendering the checkout steps */
  const stepItemProps: StepItemProps[] = useMemo(
    () => getStepItemProps({ step, stepItemLabelKeys }),
    [step, stepItemLabelKeys],
  );

  return {
    ...props,
    stepItemProps,
  };
};
