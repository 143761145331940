import cx from 'classnames';
import React, { RefObject } from 'react';

import { useSnapItem } from '../../../modules/snap/SnapListContext/useSnapItem';
import customStyles from './Custom.module.scss';
import styles from './Image.module.scss';
import usePresenter from './Image.presenter';
import { ImageCombinedProps } from './types';

const Image: React.FC<ImageCombinedProps> = (props) => {
  const {
    className,
    imageSrc,
    imageFallback,
    // MANUAL OVERRIDE STARTS
    imageSize,
    index,
    overlay,
    position,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const { elementRef } = useSnapItem(index);

  const currentStyle = styles.image;

  return (
    // MANUAL OVERRIDE STARTS
    <div className={cx(currentStyle, className)} style={{
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${imageSrc}), url(${imageFallback})`,
      backgroundSize: imageSize || 'cover',
      backgroundPosition: position,
    }}
      ref={elementRef as RefObject<HTMLDivElement>}
    >
      {
        overlay &&
        <div className={cx(customStyles.overlay)} style={{
          backgroundImage: overlay,
        }} />
      }
    </div>
    // MANUAL OVERRIDE ENDS
  );
};

export default Image;
