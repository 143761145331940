import cx from 'classnames';
import React from 'react';
import { Quantity } from '../../molecules/Quantity';
import { Slider } from '../../molecules/Slider';
import styles from './ListingsFilters.module.scss';
import { usePresenter } from './ListingsFilters.presenter';
import type { ListingsFiltersProps } from './ListingsFilters.types';

export const ListingsFilters: React.FC<ListingsFiltersProps> = (props) => {
  const {
    quantity,
    onQuantityChanged,
    priceRange,
    priceMinMaxRange,
    onPriceRangeChanged,
    getPriceThumbAriaValueText,
    className,
  } = usePresenter(props);

  return (
    <div className={cx(styles.listingsFilters, className)}>
      <Quantity
        labelKey='listingsFilters.howManyTickets'
        quantity={quantity}
        minQuantity={0}
        onQuantityChanged={onQuantityChanged}
        decrementAriaLabelKey='listingsFilters.decrementQuantityButton'
        incrementAriaLabelKey='listingsFilters.incrementQuantityButton'
        className={styles.listingsFilter}
      />
      {!!priceRange && !!priceMinMaxRange && (
        <Slider
          range={priceRange}
          minMaxRange={priceMinMaxRange}
          onRangeChanged={onPriceRangeChanged}
          unitName='dollars'
          leftThumbAriaLabelKey='listingsFilters.minPriceThumb'
          rightThumbAriaLabelKey='listingsFilters.maxPriceThumb'
          getThumbAriaValueText={getPriceThumbAriaValueText}
          className={styles.listingsFilter}
        />
      )}
    </div>
  );
};
