import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './TermsOfUseModal.module.scss';
import { usePresenter } from './TermsOfUseModal.presenter';
import type { TermsOfUseModalProps } from './TermsOfUseModal.types';

export const TermsOfUseModal: React.FC<TermsOfUseModalProps> = (props) => {
  const {
    isTermsOfUseModalShown,
    termsOfUseContentHtml,
    closeTermsOfUseModal,
  } = usePresenter(props);

  return (
    <Modal
      show={isTermsOfUseModalShown}
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      onHide={closeTermsOfUseModal}
    >
      <Modal.Header
        closeButton
        closeLabel=''
        className={styles.modalHeader}
      />
      <div dangerouslySetInnerHTML={{ __html: termsOfUseContentHtml }} />
    </Modal>
  );
};
