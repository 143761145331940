import { useCallback } from 'react';
import { MODAL_PARAM } from '../../../lib/constants';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import { TERMS_OF_USE_MODAL } from './TermsOfUseModal.constants';
import type { UseTermsOfUseModalResponse } from './TermsOfUseModal.types';

export const useTermsOfUseModal = (): UseTermsOfUseModalResponse => {
  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  const openTermsOfUseModal = useCallback(() => {
    // Add 'modal' query parameter to the current query string
    appendQueryParamsToUrl({ [MODAL_PARAM]: TERMS_OF_USE_MODAL }, { replace: false });
  }, [appendQueryParamsToUrl]);

  return { openTermsOfUseModal };
};
