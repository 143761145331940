import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import { SearchBar } from '../../molecules/SearchBar';
import styles from './MobileSearchHeader.module.scss';
import { usePresenter } from './MobileSearchHeader.presenter';
import type { MobileSearchHeaderProps } from './MobileSearchHeader.types';

export const MobileSearchHeader: React.FC<MobileSearchHeaderProps> = (props) => {
  const {
    onBackButtonClick,
    isSearchBarFocusedOnMount,
    isSearchBarBlurredOnFocus,
  } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <div className={styles.mobileSearchHeader}>
      <Button
        type='Icon'
        style='Text'
        size='Small'
        icon={{
          asset: 'ArrowLeft',
          style: 'White',
          className: styles.backButtonIcon,
        }}
        ariaLabel={t('common.goBack')}
        onClick={onBackButtonClick}
        className={styles.backButton}
      />
      <SearchBar
        className={styles.searchBar}
        isTextInputFocusedOnMount={isSearchBarFocusedOnMount}
        isTextInputFocusedOnClear={true}
        isTextInputBlurredOnFocus={isSearchBarBlurredOnFocus}
      />
    </div>
  );
};
