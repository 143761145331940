import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackErrorEvent } from '../../../modules/analytics/util';
import { AuthContext } from '../../../modules/auth';
import { ApiError } from '../../../modules/error/types';
import Step1Image from '../../../resources/images/Step1.png';
import Step2Image from '../../../resources/images/Step2.png';
import Step2MobileImage from '../../../resources/images/Step2Mobile.png';
import Step3Image from '../../../resources/images/Step3.png';
import Step3MobileImage from '../../../resources/images/Step3Mobile.png';
import type { TextProps } from '../../atoms/Text';
import type { StepContentCombinedProps } from '../StepContent';
import { updateNewUserExperienceUseCase } from './OnBoardingContent.interactor';
import styles from './OnBoardingContent.module.scss';
import type { OnBoardingContentCombinedProps } from './types';
import { formatPhoneNumber } from './utils';

const getTranslation = (transKey: string): React.ReactNode => {
  return (
    <Trans
      i18nKey={transKey}
      components={{
        capOneLink: (
          <a target={'_blank'} href='https://capitalone.com' className={styles.capOneLink} rel="noreferrer" />
        ),
      }}
    ></Trans>
  );
};

const usePresenter = (props: OnBoardingContentCombinedProps): OnBoardingContentCombinedProps => {
  const { handleClose } = props;
  const { account } = useContext(AuthContext);
  const { t } = useTranslation();
  const { trackEvent } = useAnalyticsManager();
  const [showLoader, setShowLoader] = useState(true);
  const [isStepRendered, setIsStepRendered] = useState(false);
  const isMobile = window.outerWidth < 1024;

  const changeIsStepRendered = () => {
    setIsStepRendered(true);
  };

  useEffect(() => {
    if (showLoader) {
      setTimeout(() => {
        setShowLoader(false);
      }, 200);
    }
  }, [showLoader]);

  const { mutateAsync: updateNewUserExperience } = useMutation('updateNewUserExperience', async () => {
    try {
      await updateNewUserExperienceUseCase();
    } catch (error) {
      if (error && ApiError.isApiError(error)) {
        trackErrorEvent(
          trackEvent,
          error.code,
          error.message,
        );
      }
      // TODO: handle api request error
    }
  });

  useEffect(() => {
    const updateAccount = async () => {
      try {
        if (account && account.new_user) {
          await updateNewUserExperience();
        }
      } catch (error) {
        if (error && ApiError.isApiError(error)) {
          trackErrorEvent(
            trackEvent,
            error.code,
            error.message,
          );
        }
        // TODO
      }
    };
    void updateAccount();
  }, [account]);

  const [step, setStep] = useState<number>(1);

  const onBoardingContactInformationLabelProps: TextProps = {
    align: 'Left',
    style: 'SemiBold',
    size: 'Medium',
    colour: 'BaseLight',
    type: 'Body',
  };

  const onBoardingContactInformationDescriptionProps: TextProps = {
    align: 'Left',
    style: 'Regular',
    size: 'Large',
    colour: 'BaseLight',
    type: 'Decoration',
  };

  const stepContentProps: StepContentCombinedProps[] = [
    {
      type: 'Default',
      imgSource: Step1Image,
      header: {
        value: t('onBoardingScreen.step1.heading', {
          name: account?.first_name,
        }),
        shouldFocus: true,
      },
      subHeader: {
        size: 'Large',
        style: 'SemiBold',
        type: 'Subheading',
        colour: 'BaseLight',
        align: 'Left',
        value: t('onBoardingScreen.step1.subHeading'),
        shouldFocus: true,
      },
      description: [
        {
          description: {
            value: t('onBoardingScreen.step1.description1'),
            colour: 'BaseLight',
            shouldFocus: true,
          },
          icon: {
            asset: 'EntertainmentTickets',
            style: 'DigitalGrey80',
          },
          type: 'Large',
        },
        {
          description: {
            value: t('onBoardingScreen.step1.description2'),
            colour: 'BaseLight',
            shouldFocus: true,
          },
          icon: {
            asset: 'Vector',
            style: 'DigitalGrey80',
          },
          type: 'Large',
        },
        {
          description: {
            value: t('onBoardingScreen.step1.description3'),
            colour: 'BaseLight',
            shouldFocus: true,
          },
          icon: {
            asset: 'RedeemRewards',
            style: 'DigitalGrey80',
          },
          type: 'Large',
        },
      ],
    },
    {
      imgSource: isMobile ? Step2MobileImage : Step2Image,
      type: 'Default',
      header: {
        value: t('onBoardingScreen.step2.heading', {
          name: account?.first_name,
        }),
        shouldFocus: true,
      },
      description: [
        {
          description: {
            value: t('onBoardingScreen.step2.description1'),
            colour: 'BaseLight',
            shouldFocus: true,
          },
          icon: {
            asset: 'Step1',
            style: 'DigitalGrey80',
          },
          type: 'Large',
        },
        {
          description: {
            value: t('onBoardingScreen.step2.description2'),
            colour: 'BaseLight',
            shouldFocus: true,
          },
          icon: {
            asset: 'Step2',
            style: 'DigitalGrey80',
          },
          type: 'Large',
        },
        {
          description: {
            value: t('onBoardingScreen.step2.description3'),
            colour: 'BaseLight',
            shouldFocus: true,
          },
          icon: {
            asset: 'Step3',
            style: 'DigitalGrey80',
          },
          type: 'Large',
        },
      ],
    },
    {
      imgSource: isMobile ? Step3MobileImage : Step3Image,
      type: 'Contact',
      header: {
        value: t('onBoardingScreen.step3.heading'),
        shouldFocus: true,
      },
      message: {
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        colour: 'BaseLight',
        align: 'Left',
        value: getTranslation('onBoardingScreen.step3.headingMessage'),
        shouldFocus: true,
      },
      contactInformation: {
        name: {
          label: {
            ...onBoardingContactInformationLabelProps,
            value: t('onBoardingScreen.step3.nameLabel'),
            shouldFocus: true,
          },
          description: {
            align: 'Left',
            style: 'Light',
            size: 'Large',
            colour: 'BaseLight',
            type: 'BannerHeading',
            value: `${account?.first_name} ${account?.last_name}`,
            shouldFocus: true,
          },
        },
        email: {
          label: {
            ...onBoardingContactInformationLabelProps,
            value: t('onBoardingScreen.step3.emailLabel'),
            shouldFocus: true,
          },
          description: {
            ...onBoardingContactInformationDescriptionProps,
            value: account?.email,
            shouldFocus: true,
          },
        },
        phone: {
          label: {
            ...onBoardingContactInformationLabelProps,
            value: t('onBoardingScreen.step3.phoneLabel'),
            shouldFocus: true,
          },
          description: {
            ...onBoardingContactInformationDescriptionProps,
            value: formatPhoneNumber(account?.phone || ''),
            shouldFocus: true,
          },
        },
        address: {
          label: {
            ...onBoardingContactInformationLabelProps,
            value: t('onBoardingScreen.step3.addressLabel'),
            shouldFocus: true,
          },
          description: {
            ...onBoardingContactInformationDescriptionProps,
            value: [
              account?.address.address_line1,
              <br key='1' />,
              `${account?.address.city}, ${account?.address.state_code}`,
              <br key='2' />,
              account?.address.postal_code,
            ],
            shouldFocus: true,
          },
        },
      },
    },
  ];

  const handleButtonClick = () => {
    if (step === stepContentProps.length && handleClose) {
      void handleClose();
    }
    setShowLoader(true);
    setIsStepRendered(false);
    setStep((prevStep: number) => prevStep + 1);
  };

  return {
    ...props,
    step,
    stepContent: stepContentProps,
    button: {
      text: {
        // to override the text variant for button variant
        type: 'Subheading',
        style: 'Regular',
        size: 'Medium',
        colour: 'BaseLight',
        align: 'Left',
        value: step !== 3 ? t('onBoardingScreen.next') : t('onBoardingScreen.step3.buttonText'),
      },
      onClick: handleButtonClick,
    },
    footer: {
      value: t('onBoardingScreen.step', {
        stepNumber: step,
      }),
      shouldFocus: true,
    },
    isLoading: showLoader || !isStepRendered,
    changeIsStepRendered,
  };
};

export default usePresenter;
