import React from 'react';
import { AriaLiveRegions } from '../../../lib/types';
import { ListingCard, type ListingCardProps } from '../../molecules/ListingCard';
import { NoListings } from '../../molecules/NoListings';
import styles from './NonGuestListListings.module.scss';
import { usePresenter } from './NonGuestListListings.presenter';
import type { NonGuestListListingsProps } from './NonGuestListListings.types';

export const NonGuestListListings: React.FC<NonGuestListListingsProps> = (props) => {
  const {
    hasListings,
    listingCardProps,
    listingsTotalAnnouncement,
  } = usePresenter(props);

  return (
    <div className={styles.listings}>
      <div aria-live={AriaLiveRegions.ASSERTIVE} aria-label={listingsTotalAnnouncement} />
      {hasListings
        ? listingCardProps.map((currentListingCardProps: ListingCardProps) => (
          <ListingCard
            {...currentListingCardProps}
            key={currentListingCardProps.key}
          />
        ))
        : <NoListings />
      }
    </div>
  );
};
