import React, { type ReactNode } from 'react';
import { HOME_OR_AWAY_PARAM } from '../../../lib/constants';
import { EventListProvider } from '../../../modules/eventList';
import { PageContentContainerLayout } from '../../layout/PageContentContainerLayout';
import { EmptyState, type EmptyStateProps } from '../../molecules/EmptyState';
import { ImageAndTitle } from '../../molecules/ImageAndTitle';
import { MultiToggle } from '../../molecules/MultiToggle';
import { DateFilter } from '../../organisms/DateFilter';
import { RegionFilter } from '../../organisms/RegionFilter';
import { BaseEventListPage } from '../BaseEventListPage';
import baseEventListPageStyles from '../BaseEventListPage/BaseEventListPage.module.scss';
import { HomeAwayToggleOptions, TRACKING_PAGE_NAME } from './PerformerPage.constants';
import { usePresenter } from './PerformerPage.presenter';
import type { PerformerPageProps } from './PerformerPage.types';

export const InternalPerformerPage: React.FC<PerformerPageProps> = (props) => {
  const {
    isPerformerInvalid,
    performerDetail,
    performerType,
    homeAwayToggleState,
    regionsByPerformerId,
    regionFilterState,
    isRegionFilterEnabled,
    dateFilterState,
    className,
  } = usePresenter(props);

  const emptyStateProps: EmptyStateProps = {
    titleKey: 'performerPage.noResultsTitle',
    descriptionKey: 'performerPage.noResultsDescription',
  };

  const filters: ReactNode = (
    <div className={baseEventListPageStyles.filtersContainer}>
      {performerType === 'sports' && (
        <div className={baseEventListPageStyles.filterFullWidth}>
          <MultiToggle
            queryParamName={HOME_OR_AWAY_PARAM}
            multiToggleOptions={HomeAwayToggleOptions}
            multiToggleState={homeAwayToggleState}
          />
        </div>
      )}
      <div className={baseEventListPageStyles.filter}>
        <RegionFilter
          regions={regionsByPerformerId}
          regionFilterState={regionFilterState}
          canSearchRegion={false}
          canUseCurrentRegion={false}
          isDisabled={!isRegionFilterEnabled}
        />
      </div>
      <div className={baseEventListPageStyles.filter}>
        <DateFilter dateFilterState={dateFilterState} />
      </div>
    </div>
  );

  if (isPerformerInvalid) {
    return (
      <PageContentContainerLayout className={className}>
        {filters}
        <EmptyState {...emptyStateProps} />
      </PageContentContainerLayout>
    );
  }

  const topContent: ReactNode = (<>
    {!!performerDetail && (
      <div className={baseEventListPageStyles.imageAndTitleContainer}>
        <ImageAndTitle
          imageUrl={performerDetail.image}
          title={performerDetail.name}
        />
      </div>
    )}
    {filters}
  </>);

  return (
    <BaseEventListPage
      topContent={topContent}
      topEventListContent={null}
      // TODO: Check if we need to track analytics for fetch requests
      trackFetchAnalytics={undefined}
      shouldShowEventImages={false}
      emptyStateProps={emptyStateProps}
      pageName={performerType ? TRACKING_PAGE_NAME[performerType].forEventCardClicks : ''}
      className={className}
    />
  );
};

export const PerformerPage: React.FC<PerformerPageProps> = (props) => {
  return (
    <EventListProvider>
      <InternalPerformerPage {...props} />
    </EventListProvider>
  );
};
