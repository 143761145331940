export const generateGetBoundingClientRect = (x = 500, y = 500) => {
  return () => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
  });
};
