import cx from 'classnames';
import React from 'react';
import styles from './Divider.module.scss';
import usePresenter from './Divider.presenter';
import type { DividerCombinedProps } from './types';

const Divider: React.FC<DividerCombinedProps> = (props) => {
  const {
    type,
    style,
    colour,
    className,
  } = usePresenter(props);

  const variantName = `${type}${style}${colour}`;
  const currentStyle = styles[`divider${variantName}`];

  let componentView: JSX.Element;

  switch (variantName) {
    case 'HorizontalThickWhite':
    case 'HorizontalThin2pxCP1Red':
    case 'HorizontalThinCP1Red':
    case 'HorizontalThinCoreBlue60':
    case 'HorizontalThinDigitalGrey10':
    case 'HorizontalThinDigitalGrey15':
    case 'HorizontalThinDigitalGrey5':
    case 'HorizontalThinGrey20':
    case 'HorizontalThinPrimaryBlue60':
    case 'HorizontalThinWhite':
    case 'VerticalThickCP1Red':
    case 'VerticalThickClear':
    case 'VerticalThickCoreBlue60':
    case 'VerticalThickInteractionBlue50':
    case 'VerticalThickWhite':
    case 'VerticalThinDigitalGrey10':
    case 'VerticalThinDigitalGrey15':
    case 'VerticalThinDigitalGrey5':
    case 'VerticalThinGrey20':
    case 'VerticalThinPrimaryBlue60':
    case 'VerticalThinWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Divider;
