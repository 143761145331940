import type { AppendQueryParamsToUrlFunc } from '../../../modules/navigation/Navigation.types';
import type { FilterOption } from '../../atoms/BaseFilter';
import type { PresetFilterOption } from './PresetFilter.types';

/** Function to get the display value of the preset filter state */
export const getDisplayValue = ({ name }: PresetFilterOption): string => name;

/**
 * Returns filter options for preset filter.
 * @returns {FilterOption<PresetFilterOption>[]} Filter options for preset filter.
 */
export const getPresetFilterOptions = (params: {
  /** Current state of the preset filter component */
  presetFilterState: PresetFilterOption | undefined;
  /** List of all available preset filter options */
  presetFilterOptions: PresetFilterOption[];
}): FilterOption<PresetFilterOption>[] => {
  const { presetFilterState, presetFilterOptions } = params;

  return presetFilterOptions.map((presetFilterOption: PresetFilterOption) => ({
    value: presetFilterOption,
    textKey: presetFilterOption.name,
    isSelected: presetFilterOption.id.toString() === presetFilterState?.id.toString(),
  }));
};

/** Handles the selection of a filter option by updating preset query parameters in the current URL */
export const selectFilterOption = (params: {
  /** Query parameter in the URL that will be used for managing the state of the preset filter component */
  queryParamName: string;
  /** The selected filter option value */
  presetFilterOptionValue: PresetFilterOption | undefined;
  /** Function that appends query parameters to the current URL */
  appendQueryParamsToUrl: AppendQueryParamsToUrlFunc;
}): void => {
  const { queryParamName, presetFilterOptionValue, appendQueryParamsToUrl } = params;
  appendQueryParamsToUrl({ [queryParamName]: presetFilterOptionValue ? presetFilterOptionValue.id.toString() : '' });
};

export const convertPresetFilterStateToQueryParams = (queryParamName: string, presetFilterState: PresetFilterOption | undefined): Record<string, string> | undefined => {
  return presetFilterState
    ? { [queryParamName]: presetFilterState.id.toString() }
    : undefined;
};

export const getPresetFilterStateFromUrl = <TId extends (string | number) = (string | number)>(params: {
  /** Map of allowed preset filter options where key is preset filter option Id and value is preset filter option */
  presetFilterOptionsMap: Record<string, PresetFilterOption<TId>> | undefined;
  /** Value of preset filter parameter from the current URL */
  presetParam: string | undefined;
  /** Function to clear preset filter query parameter in the URL */
  clearPresetParamInUrl: () => void;
}): PresetFilterOption<TId> | undefined => {
  const { presetFilterOptionsMap, presetParam, clearPresetParamInUrl } = params;

  if (!presetFilterOptionsMap || !presetParam) {
    return undefined;
  }

  let isPresetParamValid: boolean = false;

  try {
    // Accept only valid values for preset filter parameter.
    // Try to find an existing preset filter option by Id.
    const presetFilterOption: PresetFilterOption<TId> | undefined = presetFilterOptionsMap[presetParam];
    if (presetFilterOption) {
      isPresetParamValid = true;
      return presetFilterOption;
    }
  } finally {
    // Remove invalid preset filter parameter from the URL
    if (!isPresetParamValid) {
      clearPresetParamInUrl();
    }
  }

  return undefined;
};
