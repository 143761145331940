import { Dispatch, RefObject, SetStateAction, type FocusEvent, type KeyboardEvent } from 'react';
import type { NavigateFunction } from 'react-router-dom';
import { getEventUrl } from '../../../lib/eventUtils';
import { getPerformerUrl } from '../../../lib/performerUtils';
import { blurFocusedElement } from '../../../lib/util';
import { getVenueUrl } from '../../../lib/venueUtils';
import { trackSelectContentEvent, type EventKeys } from '../../../modules/analytics';
import { buildGoBackHistoryState, getItemIfMatch } from '../../../modules/navigation/Navigation.utils';
import type { SearchSuggestionsResponse } from '../../../modules/partnership';
import type { TopNavMenuTypeEnum } from '../../../modules/topNav';
import { buildSearchResultsPageUrlWithParams } from '../../pages/SearchResultsPage';

/**
 * Handles the focus event on the text input field.
 */
export const handleTextInputFocus = (params: {
  /** Determines if the text input should be blurred on focus. */
  isTextInputBlurredOnFocus: boolean,
  /** The onFocus handler function. */
  onFocus: (event: FocusEvent) => void,
  /** The focus event object. */
  event: FocusEvent<HTMLInputElement>,
  /** SetState from topNav context to set the active top navigation menu type. */
  setActiveTopNavMenuType: Dispatch<SetStateAction<TopNavMenuTypeEnum | undefined>>,
}) => {
  const { isTextInputBlurredOnFocus, onFocus, event, setActiveTopNavMenuType } = params;

  onFocus(event);

  setActiveTopNavMenuType('SearchMenu');

  // Blur the search bar so that the search suggestions modal wouldn't automatically re-open when the user tries to close it.
  // See description of 'isTextInputBlurredOnFocus' for more details.
  if (isTextInputBlurredOnFocus) {
    blurFocusedElement();
  }
};

/**
 * Handles the keydown event on the text input field.
 */
export const handleTextInputKeyDown = (params: {
  /** The keyboard event object. */
  event: KeyboardEvent<HTMLInputElement>,
  /** The current search query string. */
  searchQuery: string,
  /** search suggestions response. */
  searchSuggestions: SearchSuggestionsResponse | undefined,
  /** SetState from topNav context to set the active top navigation menu type. */
  setActiveTopNavMenuType: Dispatch<SetStateAction<TopNavMenuTypeEnum | undefined>>,
  /** The navigate function from react-router-dom. */
  navigate: NavigateFunction,
  /** The current pathname. */
  pathname: string,
  /** The current search query in the URL. */
  search: string,
}) => {
  const { event, searchQuery, searchSuggestions, setActiveTopNavMenuType, navigate, pathname, search } = params;

  if (event.key === 'Enter') {
    const trimmedSearchQuery = searchQuery.trim();
    if (trimmedSearchQuery.length) {
      setActiveTopNavMenuType(undefined);

      let searchPath: string | undefined;

      // Check for matching performers, events, and venues in the search suggestions.
      const matchingPerformer = getItemIfMatch(searchQuery, searchSuggestions?.performers);
      if (matchingPerformer) {
        searchPath = getPerformerUrl(matchingPerformer.id);
      } else {
        const matchingEvent = getItemIfMatch(searchQuery, searchSuggestions?.events);
        if (matchingEvent) {
          searchPath = getEventUrl(matchingEvent.id);
        } else {
          const matchingVenue = getItemIfMatch(searchQuery, searchSuggestions?.venues);
          if (matchingVenue) {
            searchPath = getVenueUrl(matchingVenue.id);
          }
        }
      }

      // If no specific match is found, build a generic search results page URL.
      if (!searchPath) {
        searchPath = buildSearchResultsPageUrlWithParams({ searchQuery: trimmedSearchQuery });
      }

      // Prevent navigation to the same URL.
      if (`${pathname}${search}` !== searchPath) {
        navigate(searchPath, { state: buildGoBackHistoryState() });
      }
    }
  }
};

/**
 * Handles the click event on the text input field.
 */
export const handleTextInputClick = (params: {
  /** The onClick handler function. */
  onClick: () => void,
  /** Function to track analytics events. */
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  /** SetState from topNav context to set the active top navigation menu type. */
  setActiveTopNavMenuType: Dispatch<SetStateAction<TopNavMenuTypeEnum | undefined>>,
  /**  Determines if the text input should be blurred on focus. */
  isTextInputBlurredOnFocus: boolean,
}) => {
  const { onClick, trackEvent, setActiveTopNavMenuType, isTextInputBlurredOnFocus } = params;

  onClick();

  trackSelectContentEvent(
    trackEvent,
    'Header',
    'SearchBar',
  );

  setActiveTopNavMenuType('SearchMenu');

  // Blur the search bar so that the search suggestions modal wouldn't automatically re-open when the user tries to close it.
  // See description of 'isTextInputBlurredOnFocus' for more details.
  if (isTextInputBlurredOnFocus) {
    blurFocusedElement();
  }
};

/**
 * Handles the click event on the clear button of the text input field.
 */
export const handleClearButtonClick = (params: {
  /**  SetState to set the search query state. */
  setSearchQuery: Dispatch<SetStateAction<string>>,
  /** SetState from topNav context to set the active top navigation menu type. */
  setActiveTopNavMenuType: Dispatch<SetStateAction<TopNavMenuTypeEnum | undefined>>,
  /**  Determines if the text input should be focused on clear. */
  isTextInputFocusedOnClear: boolean,
  /**  Reference to the text input field. */
  textInputRef: RefObject<HTMLInputElement>,
}) => {
  const { setSearchQuery, setActiveTopNavMenuType, isTextInputFocusedOnClear, textInputRef } = params;

  setSearchQuery('');
  setActiveTopNavMenuType(undefined);

  if (isTextInputFocusedOnClear && textInputRef.current) {
    textInputRef.current.focus();
    textInputRef.current.click();
  }
};
