import { SubmenuItemCombinedProps } from '../../molecules/SubmenuItem/types';
import { SubmenuItemListCombinedProps } from '../../molecules/SubmenuItemList/types';
import styles from './SubmenuWithoutTabs.module.scss';
import { SubmenuWithoutTabsCombinedProps } from './types';

const usePresenter = (props: SubmenuWithoutTabsCombinedProps): SubmenuWithoutTabsCombinedProps => {
  const { data, id, onItemClicked } = props;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const eventObject = data?.find(category => category.id === id);

  const events: SubmenuItemListCombinedProps = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    submenuItems: eventObject?.performers?.map((performer): SubmenuItemCombinedProps => {
      return {
        text: {
          value: performer.name,
          colour: 'SubduedDark',
        },
        linkPath: performer.slug,
        externalLink: performer.external_link,
        onItemClicked: () => {
          onItemClicked?.(performer);
        },
        classes: {
          text: performer.isBoldText ? styles.boldText : '',
        },
      };
    }),
  };

  return {
    submenuItemList: events,
  };
};

export default usePresenter;
