import { useCallback } from 'react';
import type { SliderRange } from '../../molecules/Slider';
import type { ListingsFiltersPresenterProps, ListingsFiltersProps } from './ListingsFilters.types';
import { getPriceThumbAriaValueText } from './ListingsFilters.utils';

export const usePresenter = (props: ListingsFiltersProps): ListingsFiltersPresenterProps => {
  const {
    quantity,
    priceRange,
    onListingsFiltersChanged,
  } = props;

  /** Callback when quantity changes */
  const onQuantityChanged = useCallback((newQuantity: number) => {
    onListingsFiltersChanged({ newQuantity: newQuantity || undefined, newPriceRange: priceRange });
  }, [priceRange, onListingsFiltersChanged]);

  /** Callback when price slider range changes */
  const onPriceRangeChanged = useCallback((newPriceRange: SliderRange) => {
    onListingsFiltersChanged({ newQuantity: quantity, newPriceRange });
  }, [quantity, onListingsFiltersChanged]);

  return {
    ...props,
    onQuantityChanged,
    onPriceRangeChanged,
    getPriceThumbAriaValueText,
  };
};
