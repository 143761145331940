import cx from 'classnames';
import type { FC } from 'react';
import { EVENT_TAGS } from '../../../lib/constants';
import { formatDateToApiDate, handleTagFilter } from '../../../lib/util';
import type { Account } from '../../../modules/auth/types';
import { getTodaysDate } from '../../../modules/date/utils';
import type { Event, EventsQueryParams } from '../../../modules/partnership';
import type { EventCardProps } from '../../molecules/EventCard';
import type { EventOrMlbCardProps } from '../../molecules/EventOrMlbCard';
import type { CarouselSectionProps } from '../../organisms/CarouselSection';
import type { DateFilterState, DateRange } from '../../organisms/DateFilter';
import type { PresetFilterOption } from '../../organisms/PresetFilter';
import type { RegionFilterState } from '../../organisms/RegionFilter';
import { buildAllEventsPageUrlWithParams } from '../AllEventsPage';
import { CommonCarouselSectionProps, DEFAULT_PAGE, DEFAULT_PAGE_SIZE, StaticCarouselSectionPropsByType, TRACKING_PAGE_NAME } from './ExclusiveEventsPage.constants';
import styles from './ExclusiveEventsPage.module.scss';

/**
 * Builds API query parameters to fetch exclusive events.
 * @returns {EventsQueryParams | undefined} API query parameters to fetch exclusive events. Undefined if required data is still loading or account is not provided.
 */
export const buildEventsQueryParams = (params: {
  /** Indicates if required data is still loading */
  isRequiredDataLoading: boolean;
  /** Account object */
  account: Account | undefined;
  category: typeof EVENT_TAGS.Sports | typeof EVENT_TAGS.Music | typeof EVENT_TAGS.Dining | typeof EVENT_TAGS.Travel | typeof EVENT_TAGS.CultureArts | typeof EVENT_TAGS.PromotedProduction,
  /** Id of the currently selected region */
  regionId?: number;
  /** Object containing the start and end dates for filtering events */
  dateRange?: Record<keyof DateRange, string>;
  /** Additional API query parameters */
  additionalQueryParams?: EventsQueryParams;
}): EventsQueryParams | undefined => {
  const { isRequiredDataLoading, account, category, regionId, dateRange, additionalQueryParams } = params;

  if (isRequiredDataLoading || !account) {
    return undefined;
  }

  return handleTagFilter(
    {
      page: DEFAULT_PAGE,
      per_page: DEFAULT_PAGE_SIZE,
      category,
      ...(regionId ? { region_id: regionId } : {}),
      date_start: dateRange ? dateRange.startDate : formatDateToApiDate(getTodaysDate(), '2-digit'),
      ...(dateRange?.endDate ? { date_end: dateRange.endDate } : {}),
      ...additionalQueryParams,
    },
    account,
    undefined,
    undefined,
    category === EVENT_TAGS.Sports, // Exclude collapsed events, e.g. MLB Baseball
  );
};

/**
 * Returns a link to All Events page (Exclusives tab) if there are more than minItems items.
 * @returns Link to All Events page (Exclusives tab) if there are more than minItems items. Otherwise, undefined.
 */
export const getAllEventsLinkHref = (params: {
  /** Number of items */
  itemsTotal: number | undefined;
  /** Minimum number of items to render View All link */
  minItems: number;
  /** The state of the category filter. */
  categoryFilterState: PresetFilterOption;
  /** The state of the region filter. */
  regionFilterState?: RegionFilterState;
  /** The state of the date filter. */
  dateFilterState?: DateFilterState;
}): string | undefined => {
  const { itemsTotal, minItems, categoryFilterState, regionFilterState, dateFilterState } = params;

  return itemsTotal && itemsTotal > minItems
    ? buildAllEventsPageUrlWithParams({ tabKey: 'exclusives', categoryFilterState, regionFilterState, dateFilterState })
    : undefined;
};

/**
 * Scrolls to an anchor on the page, e.g. /exclusive-events#sports, /exclusive-events#music.
 * Takes care of the offset for TopNav, TopDisclaimer and filters container.
 */
export const scrollToAnchor = (params: {
  /** A URL fragment identifier, beginning with #, e.g. #sports, #music, etc. */
  hash: string;
  /** Height of the top fixed content (TopNav and TopDisclaimer) */
  topFixedHeight: number;
  /** HTML element of the filters container */
  filtersContainer: HTMLDivElement | null;
}): void => {
  const { hash, topFixedHeight, filtersContainer } = params;

  /** Element that needs to be scrolled into view */
  const element = document.getElementById(hash.substring(1));
  if (element) {
    // Add heights of TopNav and TopDisclaimer to the vertical offset
    let offsetY: number = topFixedHeight;

    // If filters container is sticky (currently on mobiles only) then add its height to the vertical offset as well
    if (filtersContainer) {
      const filtersContainerStyles: CSSStyleDeclaration = window.getComputedStyle(filtersContainer);
      if (filtersContainerStyles.getPropertyValue('position').includes('sticky')) {
        offsetY += filtersContainer.getBoundingClientRect().height;
      }
    }

    // Vertical position of the element
    const { y: elementY } = element.getBoundingClientRect();

    // Using 'auto' behaviour to scroll instantly without scroll transition
    window.scrollTo({ top: elementY + window.scrollY - offsetY, behavior: 'auto' });
  }
};

/**
 * Gets array of event card props to render events in a carousel.
 * @returns {EventCardProps[]} Array of event card props.
 */
export const getEventCardProps = (params: {
  /** Events to get event card props for */
  events: Event[] | undefined;
  /** Property key of StaticCarouselSectionPropsByType constant that contains carousel title key and carousel index */
  carouselSectionPropsKey: keyof typeof StaticCarouselSectionPropsByType;
}): EventCardProps[] => {
  const { events = [], carouselSectionPropsKey } = params;

  return events.map((event: Event, eventCardIndex: number) => ({
    key: event.id,
    event,
    variant: 'squareOnDark',
    pageName: TRACKING_PAGE_NAME.forEventCardClicks,
    sectionTitleKey: StaticCarouselSectionPropsByType[carouselSectionPropsKey].titleKey,
    sectionIndex: StaticCarouselSectionPropsByType[carouselSectionPropsKey].carouselIndex,
    eventCardIndex,
    className: styles.carouselItem,
  }));
};

/**
 * Gets array of event card props to render spotlight events in a carousel.
 * @returns {EventCardProps[]} Array of spotlight event card props.
 */
export const getSpotlightEventCardProps = (params: {
  /** Spotlight events to get event card props for */
  spotlightEvents: Event[];
}): EventCardProps[] => {
  const { spotlightEvents } = params;

  return spotlightEvents.map((spotlightEvent: Event, eventCardIndex: number) => ({
    key: spotlightEvent.id,
    event: spotlightEvent,
    variant: 'squareOnDark',
    shouldShowDescription: true,
    shouldSplitEventDateAndVenueDetails: true,
    pageName: TRACKING_PAGE_NAME.forEventCardClicks,
    sectionTitleKey: StaticCarouselSectionPropsByType.spotlight.titleKey,
    sectionIndex: StaticCarouselSectionPropsByType.spotlight.carouselIndex,
    eventCardIndex,
    className: cx(styles.carouselItem, styles.spotlightEventsCarouselItem),
    classes: { topContent: styles.spotlightEventTopContent },
  }));
};

/**
 * Gets array of component props to render MLB card and sports events in a carousel.
 * @returns {EventOrMlbCardProps[]} Array of MLB card props and sports event card props.
 */
export const getSportsEventCardProps = (params: {
  /** Sports events to get event card props for */
  sportsEvents: Event[] | undefined;
}): EventOrMlbCardProps[] => {
  // Always add MLB card props
  const eventOrMlbCardProps: EventOrMlbCardProps[] = [{
    renderer: 'mlbCard',
    key: 'mlb',
    pageName: TRACKING_PAGE_NAME.forMlbCardClicks,
    className: styles.carouselItem,
  }];

  const { sportsEvents } = params;

  // Add event card props for sports events
  const sportsEventCardProps: EventCardProps[] = getEventCardProps({ events: sportsEvents, carouselSectionPropsKey: 'sports' });
  sportsEventCardProps.forEach((eventCardProps: EventCardProps) => {
    eventOrMlbCardProps.push({
      renderer: 'eventCard',
      ...eventCardProps,
    });
  });

  return eventOrMlbCardProps;
};

/**
 * Gets carousel section props for a specific type of carousel, e.g. spotlight events, sports, music, etc.
 * @returns {CarouselSectionProps<TComponentProps>} Carousel section props.
 */
export const getCarouselSectionProps = <TComponentProps extends EventCardProps | EventOrMlbCardProps>(params: {
  /** Optional View All button link. If not provided then View All button will not be rendered. */
  linkHref?: string;
  /** Indicates if items are loading */
  isLoading: boolean;
  /** Indicates if there is an error with loading items */
  isError: boolean;
  /** React component that is used to render each item in the carousel */
  component: FC<TComponentProps>;
  /** Array of all component props for rendering the list of components */
  componentProps: TComponentProps[];
  /** Property key of StaticCarouselSectionPropsByType constant that contains static carousel section props, e.g. carousel title key, carousel index, etc. */
  carouselSectionPropsKey: keyof typeof StaticCarouselSectionPropsByType;
}): CarouselSectionProps<TComponentProps> => {
  const { linkHref, isLoading, isError, component, componentProps, carouselSectionPropsKey } = params;

  const { carouselIndex, ...staticCarouselSectionProps } = StaticCarouselSectionPropsByType[carouselSectionPropsKey];

  return {
    ...CommonCarouselSectionProps,
    ...staticCarouselSectionProps,
    linkHref,
    isLoading,
    isError,
    component,
    componentProps,
  };
};
