import * as Sentry from '@sentry/browser';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { SENTRY_DSN_VALUE } from '../lib/config';

export const initSentry = (): void => {
  Sentry.init({
    dsn: SENTRY_DSN_VALUE,
    integrations: [
      new CaptureConsoleIntegration(
        {
          levels: ['warn', 'error'],
        },
      ),
      new Sentry.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.005,
    beforeSend: (event: Sentry.Event) => {
      // To remove the URL with assertion
      if (event.request?.query_string) {
        const queryParams = new URLSearchParams(event.request.query_string);
        if (queryParams.has('assertion')) {
          queryParams.delete('assertion');
        }
        if (queryParams.has('code')) {
          queryParams.delete('code');
        }

        event.request.query_string = queryParams.toString();
      }
      // To remove code param
      if (event.request?.data && event.request.data.code) {
        delete event.request.data.code;
      }
      return event;
    },
    beforeBreadcrumb: (breadcrumb: Sentry.Breadcrumb) => {
      // to remove the breadcrumb when user is
      // redirected from "/?assertion={code}" to "/"
      // OR has request with code
      const hasAssertion = breadcrumb.category === 'navigation' && breadcrumb.data
        && typeof breadcrumb.data.from === 'string'
        && breadcrumb.data.from.includes('assertion');

      const hasCode = breadcrumb.category === 'xhr' && breadcrumb.data
        && typeof breadcrumb.data.url === 'string'
        && breadcrumb.data.url.toLowerCase().includes('code');
      return (hasAssertion || hasCode) ? null : breadcrumb;
    },
  });
};
