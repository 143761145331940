import type { AllEventsTabKey } from '../../components/pages/AllEventsPage';
import { EXCLUSIVE_EVENTS_PAGE_ANCHORS, URLs } from '../../lib/constants';
import { CATEGORIES, MUSIC_SUB_CATEGORIES, SPORTS_SUB_CATEGORIES, THEATER_SUB_CATEGORIES } from '../categories';
import type { CategoryMenuItemState } from './Navigation.types';

export const EXCLUSIVE_ID = 'exclusive';

export const CATEGORIES_WITH_VIEW_EVENTS_LINK = {
  [CATEGORIES.music]: 'music',
  [CATEGORIES.theater]: 'theater',
  [THEATER_SUB_CATEGORIES.comedy]: 'comedy',
} as const satisfies Record<string, AllEventsTabKey>;

/** Hardcoded categories which do not have any sub-categories */
export const CATEGORIES_WITH_NO_SUB_CATEGORIES = [String(THEATER_SUB_CATEGORIES.comedy), EXCLUSIVE_ID];

export const TOP_NAV_PERFORMERS_DATA: CategoryMenuItemState[] = [
  {
    id: CATEGORIES.sports.toString(),
    name: 'Sports',
    subCategories: [
      {
        subCategoryId: SPORTS_SUB_CATEGORIES.nbaBasketball,
        name: 'NBA',
        image: 'nba_sub_genre_header.jpg',
        slug: URLs.NbaPage,
        performers: [
          {
            name: 'Atlanta Hawks',
            slug: '/performers/52',
            teamCode: 'ATL',
            primaryColor: '#E03A3E',
            secondaryColor: '#C1D32F',
          },
          {
            name: 'Boston Celtics',
            slug: '/performers/105',
            teamCode: 'BOS',
            primaryColor: '#007A33',
            secondaryColor: '#FFFFFF',
          },
          {
            name: 'Brooklyn Nets',
            slug: '/performers/29773',
            teamCode: 'BKN',
            primaryColor: '#000000',
            secondaryColor: '#FFFFFF',
          },
          {
            name: 'Charlotte Hornets',
            slug: '/performers/4547',
            teamCode: 'CHA',
            primaryColor: '#00788C',
            secondaryColor: '#1D1160',
          },
          {
            name: 'Chicago Bulls',
            slug: '/performers/161',
            teamCode: 'CHI',
            primaryColor: '#CE1141',
            secondaryColor: '#000000',
          },
          {
            name: 'Cleveland Cavaliers',
            slug: '/performers/182',
            teamCode: 'CLE',
            primaryColor: '#860038',
            secondaryColor: '#FDBB30',
          },
          {
            name: 'Dallas Mavericks',
            slug: '/performers/215',
            teamCode: 'DAL',
            primaryColor: '#00538C',
            secondaryColor: '#B8C4CA',
          },
          {
            name: 'Denver Nuggets',
            slug: '/performers/235',
            teamCode: 'DEN',
            primaryColor: '#0E2240',
            secondaryColor: '#FEC524',
          },
          {
            name: 'Detroit Pistons',
            slug: '/performers/239',
            teamCode: 'DET',
            primaryColor: '#1D42BA',
            secondaryColor: '#C8102E',
          },
          {
            name: 'Golden State Warriors',
            slug: '/performers/328',
            teamCode: 'GSW',
            primaryColor: '#1D428A',
            secondaryColor: '#FFC72C',
          },
          {
            name: 'Houston Rockets',
            slug: '/performers/360',
            teamCode: 'HOU',
            primaryColor: '#CE1141',
            secondaryColor: '#C4CED4',
          },
          {
            name: 'Indiana Pacers',
            slug: '/performers/369',
            teamCode: 'IND',
            primaryColor: '#FDBB30',
            secondaryColor: '#002D62',
          },
          {
            name: 'Los Angeles Clippers',
            slug: '/performers/480',
            teamCode: 'LAC',
            primaryColor: '#006BB6',
            secondaryColor: '#BEC0C2',
          },
          {
            name: 'Los Angeles Lakers',
            slug: '/performers/483',
            teamCode: 'LAL',
            primaryColor: '#FDB927',
            secondaryColor: '#552583',
          },
          {
            name: 'Memphis Grizzlies',
            slug: '/performers/910',
            teamCode: 'MEM',
            primaryColor: '#5D76A9',
            secondaryColor: '#12173F',
          },
          {
            name: 'Miami Heat',
            slug: '/performers/533',
            teamCode: 'MIA',
            primaryColor: '#98002E',
            secondaryColor: '#F9A01B',
          },
          {
            name: 'Milwaukee Bucks',
            slug: '/performers/550',
            teamCode: 'MIL',
            primaryColor: '#00471B',
            secondaryColor: '#EEE1C6',
          },
          {
            name: 'Minnesota Timberwolves',
            slug: '/performers/553',
            teamCode: 'MIN',
            primaryColor: '#0C2340',
            secondaryColor: '#9EA2A2',
          },
          {
            name: 'New Orleans Pelicans',
            slug: '/performers/153',
            teamCode: 'NOP',
            primaryColor: '#C8102E',
            secondaryColor: '#FFFFFF',
          },
          {
            name: 'New York Knicks',
            slug: '/performers/602',
            teamCode: 'NYK',
            primaryColor: '#006BB6',
            secondaryColor: '#F58426',
          },
          {
            name: 'Oklahoma City Thunder',
            slug: '/performers/16164',
            teamCode: 'OKC',
            primaryColor: '#007AC1',
            secondaryColor: '#EF3B24',
          },
          {
            name: 'Orlando Magic',
            slug: '/performers/636',
            teamCode: 'ORL',
            primaryColor: '#0077C0',
            secondaryColor: '#C4CED4',
          },
          {
            name: 'Philadelphia 76ers',
            slug: '/performers/668',
            teamCode: 'PHI',
            primaryColor: '#006BB6',
            secondaryColor: '#ED174C',
          },
          {
            name: 'Phoenix Suns',
            slug: '/performers/679',
            teamCode: 'PHX',
            primaryColor: '#1D1160',
            secondaryColor: '#E56020',
          },
          {
            name: 'Portland Trail Blazers',
            slug: '/performers/690',
            teamCode: 'POR',
            primaryColor: '#E03A3E',
            secondaryColor: '#000000',
          },
          {
            name: 'Sacramento Kings',
            slug: '/performers/748',
            teamCode: 'SAC',
            primaryColor: '#5A2D81',
            secondaryColor: '#63727A',
          },
          {
            name: 'San Antonio Spurs',
            slug: '/performers/754',
            teamCode: 'SAS',
            primaryColor: '#000000',
            secondaryColor: '#C4CED4',
          },
          {
            name: 'Toronto Raptors',
            slug: '/performers/879',
            teamCode: 'TOR',
            primaryColor: '#CE1141',
            secondaryColor: '#000000',
          },
          {
            name: 'Utah Jazz',
            slug: '/performers/902',
            teamCode: 'UTA',
            primaryColor: '#002B5C',
            secondaryColor: '#F9A01B',
          },
          {
            name: 'Washington Wizards',
            slug: '/performers/926',
            teamCode: 'WAS',
            primaryColor: '#002B5C',
            secondaryColor: '#E31837',
          },
        ],
      },
      {
        subCategoryId: SPORTS_SUB_CATEGORIES.nhlHockey,
        name: 'NHL',
        image: 'nhl_sub_genre_header.jpg',
        slug: URLs.NhlPage,
        performers: [
          {
            name: 'Anaheim Ducks',
            slug: '/performers/30',
            teamCode: 'ANA',
            primaryColor: '#000000',
            secondaryColor: '#F47A38',
          },
          {
            name: 'Arizona Coyotes',
            slug: '/performers/677',
            teamCode: 'ARI',
            primaryColor: '#8C2633',
            secondaryColor: '#E2D6B5',
          },
          {
            name: 'Boston Bruins',
            slug: '/performers/104',
            teamCode: 'BOS',
            primaryColor: '#000000',
            secondaryColor: '#FFB81C',
          },
          {
            name: 'Buffalo Sabres',
            slug: '/performers/129',
            teamCode: 'BUF',
            primaryColor: '#002654',
            secondaryColor: '#FCB514',
          },
          {
            name: 'Calgary Flames',
            slug: '/performers/136',
            teamCode: 'CGY',
            primaryColor: '#C8102E',
            secondaryColor: '#000000',
          },
          {
            name: 'Carolina Hurricanes',
            slug: '/performers/143',
            teamCode: 'CAR',
            primaryColor: '#CC0000',
            secondaryColor: '#000000',
          },
          {
            name: 'Chicago Blackhawks',
            slug: '/performers/160',
            teamCode: 'CHI',
            primaryColor: '#CF0A2C',
            secondaryColor: '#000000',
          },
          {
            name: 'Colorado Avalanche',
            slug: '/performers/191',
            teamCode: 'COL',
            primaryColor: '#6F263D',
            secondaryColor: '#236192',
          },
          {
            name: 'Columbus Blue Jackets',
            slug: '/performers/979',
            teamCode: 'CBJ',
            primaryColor: '#002654',
            secondaryColor: '#CE1126',
          },
          {
            name: 'Dallas Stars',
            slug: '/performers/216',
            teamCode: 'DAL',
            primaryColor: '#006847',
            secondaryColor: '#8F8F8C',
          },
          {
            name: 'Detroit Red Wings',
            slug: '/performers/240',
            teamCode: 'DET',
            primaryColor: '#CE1126',
            secondaryColor: '#FFFFFF',
          },
          {
            name: 'Edmonton Oilers',
            slug: '/performers/263',
            teamCode: 'EDM',
            primaryColor: '#FF4C00',
            secondaryColor: '#041E42',
          },
          {
            name: 'Florida Panthers',
            slug: '/performers/1132',
            teamCode: 'FLA',
            primaryColor: '#C8102E',
            secondaryColor: '#041E42',
          },
          {
            name: 'Los Angeles Kings',
            slug: '/performers/482',
            teamCode: 'LAK',
            primaryColor: '#111111',
            secondaryColor: '#A2AAAD',
          },
          {
            name: 'Minnesota Wild',
            slug: '/performers/1137',
            teamCode: 'MIN',
            primaryColor: '#154734',
            secondaryColor: '#DDCBA4',
          },
          {
            name: 'Montreal Canadiens',
            slug: '/performers/559',
            teamCode: 'MTL',
            primaryColor: '#AF1E2D',
            secondaryColor: '#192168',
          },
          {
            name: 'Nashville Predators',
            slug: '/performers/573',
            teamCode: 'NSH',
            primaryColor: '#FFB81C',
            secondaryColor: '#041E42',
          },
          {
            name: 'New Jersey Devils',
            slug: '/performers/593',
            teamCode: 'NJD',
            primaryColor: '#CE1126',
            secondaryColor: '#000000',
          },
          {
            name: 'New York Islanders',
            slug: '/performers/600',
            teamCode: 'NYI',
            primaryColor: '#00539B',
            secondaryColor: '#F47D30',
          },
          {
            name: 'New York Rangers',
            slug: '/performers/606',
            teamCode: 'NYR',
            primaryColor: '#0038A8',
            secondaryColor: '#CE1126',
          },
          {
            name: 'Ottawa Senators',
            slug: '/performers/639',
            teamCode: 'OTT',
            primaryColor: '#C52032',
            secondaryColor: '#C2912C',
          },
          {
            name: 'Philadelphia Flyers',
            slug: '/performers/670',
            teamCode: 'PHI',
            primaryColor: '#F74902',
            secondaryColor: '#000000',
          },
          {
            name: 'Pittsburgh Penguins',
            slug: '/performers/684',
            teamCode: 'PIT',
            primaryColor: '#000000',
            secondaryColor: '#CFC493',
          },
          {
            name: 'San Jose Sharks',
            slug: '/performers/761',
            teamCode: 'SJS',
            primaryColor: '#006D75',
            secondaryColor: '#000000',
          },
          {
            name: 'Seattle Kraken',
            slug: '/performers/25558090',
            teamCode: 'SEA',
            primaryColor: '#001628',
            secondaryColor: '#99d9d9',
          },
          {
            name: 'St. Louis Blues',
            slug: '/performers/806',
            teamCode: 'STL',
            primaryColor: '#002F87',
            secondaryColor: '#FCB514',
          },
          {
            name: 'Tampa Bay Lightning',
            slug: '/performers/841',
            teamCode: 'TBL',
            primaryColor: '#002868',
            secondaryColor: '#FFFFFF',
          },
          {
            name: 'Toronto Maple Leafs',
            slug: '/performers/878',
            teamCode: 'TOR',
            primaryColor: '#003E7E',
            secondaryColor: '#FFFFFF',
          },
          {
            name: 'Vancouver Canucks',
            slug: '/performers/909',
            teamCode: 'VAN',
            primaryColor: '#00205B',
            secondaryColor: '#00843D',
          },
          {
            name: 'Vegas Golden Knights',
            slug: '/performers/54228',
            teamCode: 'VGK',
            primaryColor: '#333F42',
            secondaryColor: '#B4975A',
          },
          {
            name: 'Washington Capitals',
            slug: '/performers/923',
            teamCode: 'WAS',
            primaryColor: '#C8102E',
            secondaryColor: '#041E42',
          },
          {
            name: 'Winnipeg Jets',
            slug: '/performers/26544',
            teamCode: 'WIN',
            primaryColor: '#041E42',
            secondaryColor: '#004C97',
          },
        ],
      },
      {
        subCategoryId: SPORTS_SUB_CATEGORIES.nflFootball,
        name: 'NFL',
        image: 'nfl_sub_genre_header.jpg',
        slug: URLs.NflPage,
        performers: [
          {
            name: 'Arizona Cardinals',
            slug: '/performers/40',
            teamCode: 'ARI',
            primaryColor: '#97233F',
            secondaryColor: '#FFB612',
          },
          {
            name: 'Atlanta Falcons',
            slug: '/performers/51',
            teamCode: 'ATL',
            primaryColor: '#000000',
            secondaryColor: '#A71930',
          },
          {
            name: 'Baltimore Ravens',
            slug: '/performers/60',
            teamCode: 'BAL',
            primaryColor: '#241773',
            secondaryColor: '#9E7C0C',
          },
          {
            name: 'Buffalo Bills',
            slug: '/performers/128',
            teamCode: 'BUF',
            primaryColor: '#C60C30',
            secondaryColor: '#00338D',
          },
          {
            name: 'Carolina Panthers',
            slug: '/performers/144',
            teamCode: 'CAR',
            primaryColor: '#101820',
            secondaryColor: '#0085CA',
          },
          {
            name: 'Chicago Bears',
            slug: '/performers/159',
            teamCode: 'CHI',
            primaryColor: '#0B162A',
            secondaryColor: '#E64100',
          },
          {
            name: 'Cincinnati Bengals',
            slug: '/performers/172',
            teamCode: 'CIN',
            primaryColor: '#FB4F14',
            secondaryColor: '#000000',
          },
          {
            name: 'Cleveland Browns',
            slug: '/performers/181',
            teamCode: 'CLE',
            primaryColor: '#FF3C00',
            secondaryColor: '#311D00',
          },
          {
            name: 'Dallas Cowboys',
            slug: '/performers/214',
            teamCode: 'DAL',
            primaryColor: '#869397',
            secondaryColor: '#041E42',
          },
          {
            name: 'Denver Broncos',
            slug: '/performers/234',
            teamCode: 'DEN',
            primaryColor: '#002244',
            secondaryColor: '#FB4F14',
          },
          {
            name: 'Detroit Lions',
            slug: '/performers/238',
            teamCode: 'DET',
            primaryColor: '#B0B7BC',
            secondaryColor: '#0076B6',
          },
          {
            name: 'Green Bay Packers',
            slug: '/performers/339',
            teamCode: 'GB',
            primaryColor: '#FFB612',
            secondaryColor: '#203731',
          },
          {
            name: 'Houston Texans',
            slug: '/performers/2559',
            teamCode: 'HOU',
            primaryColor: '#03202F',
            secondaryColor: '#A71930',
          },
          {
            name: 'Indianapolis Colts',
            slug: '/performers/370',
            teamCode: 'IND',
            primaryColor: '#003A70',
            secondaryColor: '#A5ACB0',
          },
          {
            name: 'Jacksonville Jaguars',
            slug: '/performers/381',
            teamCode: 'JAX',
            primaryColor: '#101820',
            secondaryColor: '#D7A22A',
          },
          {
            name: 'Kansas City Chiefs',
            slug: '/performers/427',
            teamCode: 'KC',
            primaryColor: '#E31837',
            secondaryColor: '#FFB81C',
          },
          {
            name: 'Las Vegas Raiders',
            slug: '/performers/626',
            teamCode: 'LV',
            primaryColor: '#A5ACAF',
            secondaryColor: '#000000',
          },
          {
            name: 'Los Angeles Chargers',
            slug: '/performers/755',
            teamCode: 'LAC',
            primaryColor: '#002A5E',
            secondaryColor: '#FFC20E',
          },
          {
            name: 'Los Angeles Rams',
            slug: '/performers/808',
            teamCode: 'LAR',
            primaryColor: '#003594',
            secondaryColor: '#FFA300',
          },
          {
            name: 'Miami Dolphins',
            slug: '/performers/532',
            teamCode: 'MIA',
            primaryColor: '#008E97',
            secondaryColor: '#FC4C02',
          },
          {
            name: 'Minnesota Vikings',
            slug: '/performers/555',
            teamCode: 'MIN',
            primaryColor: '#4F2683',
            secondaryColor: '#FFC62F',
          },
          {
            name: 'New England Patriots',
            slug: '/performers/592',
            teamCode: 'NE',
            primaryColor: '#B0B7BC',
            secondaryColor: '#002244',
          },
          {
            name: 'New Orleans Saints',
            slug: '/performers/597',
            teamCode: 'NO',
            primaryColor: '#D3BC8D',
            secondaryColor: '#101820',
          },
          {
            name: 'New York Giants',
            slug: '/performers/599',
            teamCode: 'NYG',
            primaryColor: '#0B2265',
            secondaryColor: '#A71930',
          },
          {
            name: 'New York Jets',
            slug: '/performers/601',
            teamCode: 'NYJ',
            primaryColor: '#125740',
            secondaryColor: '#FFFFFF',
          },
          {
            name: 'Philadelphia Eagles',
            slug: '/performers/669',
            teamCode: 'PHI',
            primaryColor: '#004C54',
            secondaryColor: '#A5ACAF',
          },
          {
            name: 'Pittsburgh Steelers',
            slug: '/performers/686',
            teamCode: 'PIT',
            primaryColor: '#101820',
            secondaryColor: '#FFB612',
          },
          {
            name: 'San Francisco 49ers',
            slug: '/performers/758',
            teamCode: 'SF',
            primaryColor: '#B3995D',
            secondaryColor: '#AA0000',
          },
          {
            name: 'Seattle Seahawks',
            slug: '/performers/772',
            teamCode: 'SEA',
            primaryColor: '#002244',
            secondaryColor: '#69BE28',
          },
          {
            name: 'Tampa Bay Buccaneers',
            slug: '/performers/839',
            teamCode: 'TB',
            primaryColor: '#D50A0A',
            secondaryColor: '#34302B',
          },
          {
            name: 'Tennessee Titans',
            slug: '/performers/848',
            teamCode: 'TEN',
            primaryColor: '#0C2340',
            secondaryColor: '#4B92DB',
          },
          {
            name: 'Washington Football Team',
            slug: '/performers/925',
            teamCode: 'WAS',
            primaryColor: '#773141',
            secondaryColor: '#FFB612',
          },
        ],
      },
      {
        subCategoryId: SPORTS_SUB_CATEGORIES.mls,
        name: 'MLS',
        image: 'mls_sub_genre_header.jpg',
        slug: URLs.MlsPage,
        performers: [
          {
            name: 'Atlanta United',
            slug: '/performers/54757',
            teamCode: 'ATL',
            primaryColor: '#80000A',
            secondaryColor: '#221F1F',
          },
          {
            name: 'Chicago Fire',
            slug: '/performers/4233',
            teamCode: 'CHI',
            primaryColor: '#121F48',
            secondaryColor: '#B3272D',
          },
          {
            name: 'Colorado Rapids',
            slug: '/performers/192',
            teamCode: 'COL',
            primaryColor: '#862633',
            secondaryColor: '#8BB8E8',
          },
          {
            name: 'Columbus Crew',
            slug: '/performers/4530',
            teamCode: 'CLB',
            primaryColor: '#FEF200',
            secondaryColor: '#231F20',
          },
          {
            name: 'DC United',
            slug: '/performers/924',
            teamCode: 'DC',
            primaryColor: '#231F20',
            secondaryColor: '#EE1A39',
          },
          {
            name: 'FC Cincinnati',
            slug: '/performers/48506',
            teamCode: 'CIN',
            primaryColor: '#003087',
            secondaryColor: '#FE5000',
          },
          {
            name: 'FC Dallas',
            slug: '/performers/7116',
            teamCode: 'DAL',
            primaryColor: '#D11241',
            secondaryColor: '#003E7E',
          },
          {
            name: 'Houston Dynamo',
            slug: '/performers/8825',
            teamCode: 'HOU',
            primaryColor: '#F4911E',
            secondaryColor: '#231F20',
          },
          {
            name: 'Inter Miami CF',
            slug: '/performers/121844',
            teamCode: 'MIA',
            primaryColor: '#F7B5CD',
            secondaryColor: '#231F20',
          },
          {
            name: 'Los Angeles FC',
            slug: '/performers/67331',
            teamCode: 'LFC',
            primaryColor: '#000000',
            secondaryColor: '#C39E6D',
          },
          {
            name: 'Los Angeles Galaxy',
            slug: '/performers/2678',
            teamCode: 'LA',
            primaryColor: '#00245D',
            secondaryColor: '#EEB111',
          },
          {
            name: 'Minnesota United FC',
            slug: '/performers/34704',
            teamCode: 'MIN',
            primaryColor: '#585958',
            secondaryColor: '#9BCDE4',
          },
          {
            name: 'Montreal Impact',
            slug: '/performers/15893',
            teamCode: 'MTL',
            primaryColor: '#373536',
            secondaryColor: '#2B63AD',
          },
          {
            name: 'Nashville SC',
            slug: '/performers/71618',
            teamCode: 'NSC',
            primaryColor: '#E8E857',
            secondaryColor: '#130235',
          },
          {
            name: 'New England Revolution',
            slug: '/performers/1018',
            teamCode: 'NE',
            primaryColor: '#002B5C',
            secondaryColor: '#E51938',
          },
          {
            name: 'New York City FC',
            slug: '/performers/40297',
            teamCode: 'NYC',
            primaryColor: '#6CACE4',
            secondaryColor: '#041E42',
          },
          {
            name: 'New York Red Bulls',
            slug: '/performers/5551',
            teamCode: 'NY',
            primaryColor: '#E31351',
            secondaryColor: '#002F65',
          },
          {
            name: 'Orlando City Lions',
            slug: '/performers/32652',
            teamCode: 'ORL',
            primaryColor: '#61259E',
            secondaryColor: '#FFE293',
          },
          {
            name: 'Philadelphia Union',
            slug: '/performers/23154',
            teamCode: 'PHI',
            primaryColor: '#002D55',
            secondaryColor: '#B49759',
          },
          {
            name: 'Portland Timbers',
            slug: '/performers/19629',
            teamCode: 'POR',
            primaryColor: '#00482B',
            secondaryColor: '#D69A00',
          },
          {
            name: 'Real Salt Lake',
            slug: '/performers/7240',
            teamCode: 'RSL',
            primaryColor: '#B30838',
            secondaryColor: '#013A81',
          },
          {
            name: 'San Jose Earthquakes',
            slug: '/performers/4532',
            teamCode: 'SJ',
            primaryColor: '#1F1F1F',
            secondaryColor: '#30457A',
          },
          {
            name: 'Seattle Sounders FC',
            slug: '/performers/16516',
            teamCode: 'SEA',
            primaryColor: '#658D1B',
            secondaryColor: '#236192',
          },
          {
            name: 'Sporting Kansas City',
            slug: '/performers/25709',
            teamCode: 'SKC',
            primaryColor: '#93B1D7',
            secondaryColor: '#002A5C',
          },
          {
            name: 'Toronto FC',
            slug: '/performers/12613',
            teamCode: 'TOR',
            primaryColor: '#AB1E2D',
            secondaryColor: '#A3AAAD',
          },
          {
            name: 'Vancouver Whitecaps',
            slug: '/performers/13639',
            teamCode: 'VAN',
            primaryColor: '#04265C',
            secondaryColor: '#94C2E4',
          },
        ],
      },
      {
        subCategoryId: SPORTS_SUB_CATEGORIES.mlbBaseball,
        name: 'MLB',
        image: 'mlb_sub_genre_header.jpg',
        slug: URLs.MlbPage,
        performers: [
          {
            name: 'Arizona Diamondbacks',
            slug: '/performers/41',
            teamCode: 'ARI',
            primaryColor: '#A71930',
            secondaryColor: '#000000',
          },
          {
            name: 'Atlanta Braves',
            slug: '/performers/50',
            teamCode: 'ATL',
            primaryColor: '#13274F',
            secondaryColor: '#CE1141',
          },
          {
            name: 'Baltimore Orioles',
            slug: '/performers/59',
            teamCode: 'BAL',
            primaryColor: '#DF4601',
            secondaryColor: '#000000',
          },
          {
            name: 'Boston Red Sox',
            slug: '/performers/107',
            teamCode: 'BOS',
            primaryColor: '#192C55',
            secondaryColor: '#BD3039',
          },
          {
            name: 'Chicago Cubs',
            slug: '/performers/162',
            teamCode: 'CHC',
            primaryColor: '#0E3386',
            secondaryColor: '#CC3433',
          },
          {
            name: 'Chicago White Sox',
            slug: '/performers/164',
            teamCode: 'CHW',
            primaryColor: '#000000',
            secondaryColor: '#C4CED4',
          },
          {
            name: 'Cincinnati Reds',
            slug: '/performers/173',
            teamCode: 'CIN',
            primaryColor: '#C6011F',
            secondaryColor: '#000000',
          },
          {
            name: 'Cleveland Guardians',
            slug: '/performers/183',
            teamCode: 'CLE',
            primaryColor: '#0C2340',
            secondaryColor: '#E31937',
          },
          {
            name: 'Colorado Rockies',
            slug: '/performers/193',
            teamCode: 'COL',
            primaryColor: '#33006F',
            secondaryColor: '#C4CED4',
          },
          {
            name: 'Detroit Tigers',
            slug: '/performers/241',
            teamCode: 'DET',
            primaryColor: '#0C2340',
            secondaryColor: '#FA4616',
          },
          {
            name: 'Houston Astros',
            slug: '/performers/358',
            teamCode: 'HOU',
            primaryColor: '#EB6E1F',
            secondaryColor: '#002D62',
          },
          {
            name: 'Kansas City Royals',
            slug: '/performers/428',
            teamCode: 'KC',
            primaryColor: '#174885',
            secondaryColor: '#7BB2DD',
          },
          {
            name: 'Los Angeles Angels',
            slug: '/performers/29',
            teamCode: 'LAA',
            primaryColor: '#BA0021',
            secondaryColor: '#003263',
          },
          {
            name: 'Los Angeles Dodgers',
            slug: '/performers/481',
            teamCode: 'LAD',
            primaryColor: '#005A9C',
            secondaryColor: '#FFFFFF',
          },
          {
            name: 'Miami Marlins',
            slug: '/performers/293',
            teamCode: 'MIA',
            primaryColor: '#00A3E0',
            secondaryColor: '#000000',
          },
          {
            name: 'Milwaukee Brewers',
            slug: '/performers/549',
            teamCode: 'MIL',
            primaryColor: '#0A2351',
            secondaryColor: '#B6922E',
          },
          {
            name: 'Minnesota Twins',
            slug: '/performers/554',
            teamCode: 'MIN',
            primaryColor: '#002B5C',
            secondaryColor: '#D31145',
          },
          {
            name: 'New York Mets',
            slug: '/performers/605',
            teamCode: 'NYM',
            primaryColor: '#002D72',
            secondaryColor: '#FF5910',
          },
          {
            name: 'New York Yankees',
            slug: '/performers/607',
            teamCode: 'NYY',
            primaryColor: '#132448',
            secondaryColor: '#C4CED3',
          },
          {
            name: 'Oakland Athletics',
            slug: '/performers/625',
            teamCode: 'OAK',
            primaryColor: '#003831',
            secondaryColor: '#EFB21E',
          },
          {
            name: 'Philadelphia Phillies',
            slug: '/performers/672',
            teamCode: 'PHI',
            primaryColor: '#E81828',
            secondaryColor: '#FFFFFF',
          },
          {
            name: 'Pittsburgh Pirates',
            slug: '/performers/685',
            teamCode: 'PIT',
            primaryColor: '#000000',
            secondaryColor: '#FDB827',
          },
          {
            name: 'San Diego Padres',
            slug: '/performers/757',
            teamCode: 'SD',
            primaryColor: '#2F241D',
            secondaryColor: '#FFC425',
          },
          {
            name: 'San Francisco Giants',
            slug: '/performers/759',
            teamCode: 'SF',
            primaryColor: '#000000',
            secondaryColor: '#FD5A1E',
          },
          {
            name: 'Seattle Mariners',
            slug: '/performers/771',
            teamCode: 'SEA',
            primaryColor: '#0C2C56',
            secondaryColor: '#005C5C',
          },
          {
            name: 'St. Louis Cardinals',
            slug: '/performers/807',
            teamCode: 'STL',
            primaryColor: '#C41E3A',
            secondaryColor: '#FFFFFF',
          },
          {
            name: 'Tampa Bay Rays',
            slug: '/performers/840',
            teamCode: 'TB',
            primaryColor: '#092C5C',
            secondaryColor: '#8FBCE6',
          },
          {
            name: 'Texas Rangers',
            slug: '/performers/853',
            teamCode: 'TEX',
            primaryColor: '#003278',
            secondaryColor: '#C0111F',
          },
          {
            name: 'Toronto Blue Jays',
            slug: '/performers/877',
            teamCode: 'TOR',
            primaryColor: '#134A8E',
            secondaryColor: '#FFFFFF',
          },
          {
            name: 'Washington Nationals',
            slug: '/performers/560',
            teamCode: 'WAS',
            primaryColor: '#14225A',
            secondaryColor: '#AB0003',
          },
        ],
      },
      {
        subCategoryId: SPORTS_SUB_CATEGORIES.ncaaFootball,
        name: 'NCAA football',
        slug: '',
        performers: [
          {
            name: 'Alabama Crimson Tide',
            slug: '/performers/988',
          },
          {
            name: 'Auburn Tigers',
            slug: '/performers/54',
          },
          {
            name: 'California Golden Bears',
            slug: '/performers/896',
          },
          {
            name: 'Clemson Tigers',
            slug: '/performers/180',
          },
          {
            name: 'Florida Gators',
            slug: '/performers/292',
          },
          {
            name: 'Florida State Seminoles',
            slug: '/performers/294',
          },
          {
            name: 'Georgia Bulldogs',
            slug: '/performers/320',
          },
          {
            name: 'Georgia Tech Yellow Jackets',
            slug: '/performers/321',
          },
          {
            name: 'Illinois Fighting Illini',
            slug: '/performers/367',
          },
          {
            name: 'Iowa Hawkeyes',
            slug: '/performers/1140',
          },
          {
            name: 'Kansas State Wildcats',
            slug: '/performers/1066',
          },
          {
            name: 'Louisville Cardinals',
            slug: '/performers/489',
          },
          {
            name: 'LSU Tigers',
            slug: '/performers/1003',
          },
          {
            name: 'Maryland Terrapins',
            slug: '/performers/517',
          },
          {
            name: 'Miami Hurricanes',
            slug: '/performers/534',
          },
          {
            name: 'Michigan State Spartans',
            slug: '/performers/540',
          },
          {
            name: 'Michigan Wolverines',
            slug: '/performers/541',
          },
          {
            name: 'Mississippi Rebels',
            slug: '/performers/1100',
          },
          {
            name: 'Notre Dame Fighting Irish',
            slug: '/performers/618',
          },
          {
            name: 'Ohio State Buckeyes',
            slug: '/performers/630',
          },
          {
            name: 'Oklahoma Sooners',
            slug: '/performers/1012',
          },
          {
            name: 'Oklahoma State Cowboys',
            slug: '/performers/1301',
          },
          {
            name: 'Penn State Nittany Lions',
            slug: '/performers/1013',
          },
          {
            name: 'Pittsburgh Panthers',
            slug: '/performers/1174',
          },
          {
            name: 'South Florida Bulls',
            slug: '/performers/6990',
          },
          {
            name: 'Stanford Cardinal',
            slug: '/performers/809',
          },
          {
            name: 'Tennessee Volunteers',
            slug: '/performers/849',
          },
          {
            name: 'Texas Longhorns',
            slug: '/performers/852',
          },
          {
            name: 'UCLA Bruins',
            slug: '/performers/3350',
          },
          {
            name: 'USC Trojans',
            slug: '/performers/1031',
          },
          {
            name: 'Washington Huskies',
            slug: '/performers/1133',
          },
          {
            name: 'West Virginia Mountaineers',
            slug: '/performers/1362',
          },
          {
            name: 'Wisconsin Badgers',
            slug: '/performers/939',
          },
        ],
      },
      {
        subCategoryId: SPORTS_SUB_CATEGORIES.ncaaBasketball,
        name: "NCAA men's basketball",
        slug: '',
        performers: [
          {
            name: 'Arizona State Sun Devils',
            slug: '/performers/3095',
          },
          {
            name: 'Arizona Wildcats',
            slug: '/performers/3098',
          },
          {
            name: 'California Golden Bears',
            slug: '/performers/3360',
          },
          {
            name: 'Cincinnati Bearcats',
            slug: '/performers/3131',
          },
          {
            name: 'Duke Blue Devils',
            slug: '/performers/255',
          },
          {
            name: 'Florida Gators',
            slug: '/performers/3165',
          },
          {
            name: 'Florida State Seminoles',
            slug: '/performers/3168',
          },
          {
            name: 'Georgetown Hoyas',
            slug: '/performers/319',
          },
          {
            name: 'Georgia Tech Yellow Jackets',
            slug: '/performers/3184',
          },
          {
            name: 'Gonzaga Bulldogs',
            slug: '/performers/4755',
          },
          {
            name: 'Indiana Hoosiers',
            slug: '/performers/3204',
          },
          {
            name: 'Iowa Hawkeyes',
            slug: '/performers/3206',
          },
          {
            name: 'Kansas Jayhawks',
            slug: '/performers/3214',
          },
          {
            name: 'Kansas State Wildcats',
            slug: '/performers/3217',
          },
          {
            name: 'Kentucky Wildcats',
            slug: '/performers/3224',
          },
          {
            name: 'Marquette Golden Eagles',
            slug: '/performers/511',
          },
          {
            name: 'Memphis Tigers',
            slug: '/performers/3241',
          },
          {
            name: 'Miami Hurricanes',
            slug: '/performers/3244',
          },
          {
            name: 'Michigan State Spartans',
            slug: '/performers/3248',
          },
          {
            name: 'Michigan Wolverines',
            slug: '/performers/3252',
          },
          {
            name: 'North Carolina State Wolfpack',
            slug: '/performers/3271',
          },
          {
            name: 'North Carolina Tar Heels',
            slug: '/performers/3274',
          },
          {
            name: 'Notre Dame Fighting Irish',
            slug: '/performers/3281',
          },
          {
            name: 'Ohio State Buckeyes',
            slug: '/performers/3285',
          },
          {
            name: 'Oregon Ducks',
            slug: '/performers/3290',
          },
          {
            name: 'Stanford Cardinal',
            slug: '/performers/3331',
          },
          {
            name: 'Texas Longhorns',
            slug: '/performers/3345',
          },
          {
            name: 'UCLA Bruins',
            slug: '/performers/893',
          },
          {
            name: 'Villanova Wildcats',
            slug: '/performers/3384',
          },
          {
            name: 'Virginia Cavaliers',
            slug: '/performers/3372',
          },
          {
            name: 'Virginia Tech Hokies',
            slug: '/performers/3387',
          },
          {
            name: 'Wake Forest Demon Deacons',
            slug: '/performers/3390',
          },
          {
            name: 'West Virginia Mountaineers',
            slug: '/performers/3393',
          },
        ],
      },
      {
        subCategoryId: SPORTS_SUB_CATEGORIES.collegeBaseball,
        name: 'NCAA baseball',
        slug: '',
        performers: [
          {
            name: 'Arkansas Razorbacks',
            slug: '/performers/3100',
          },
          {
            name: 'Auburn Tigers',
            slug: '/performers/3107',
          },
          {
            name: 'Cal St. Fullerton Titans',
            slug: '/performers/23088',
          },
          {
            name: 'Clemson Tigers',
            slug: '/performers/3138',
          },
          {
            name: 'Florida Gators',
            slug: '/performers/3164',
          },
          {
            name: 'Florida State Seminoles',
            slug: '/performers/3167',
          },
          {
            name: 'Houston Cougars',
            slug: '/performers/36283',
          },
          {
            name: 'Kentucky Wildcats',
            slug: '/performers/3223',
          },
          {
            name: 'Long Beach State Forty Niners',
            slug: '/performers/23087',
          },
          {
            name: 'Louisville Cardinals',
            slug: '/performers/3232',
          },
          {
            name: 'LSU Tigers',
            slug: '/performers/3226',
          },
          {
            name: 'Maryland Terrapins',
            slug: '/performers/3237',
          },
          {
            name: 'Miami Hurricanes',
            slug: '/performers/3243',
          },
          {
            name: 'Mississippi State Bulldogs',
            slug: '/performers/3258',
          },
          {
            name: 'North Carolina Tar Heels',
            slug: '/performers/3273',
          },
          {
            name: 'Oregon State Beavers',
            slug: '/performers/9020',
          },
          {
            name: 'TCU Horned Frogs',
            slug: '/performers/19462',
          },
          {
            name: 'Texas A&M Aggies',
            slug: '/performers/3341',
          },
          {
            name: 'Texas Longhorns',
            slug: '/performers/3344',
          },
          {
            name: 'Vanderbilt Commodores',
            slug: '/performers/3380',
          },
          {
            name: 'Virginia Cavaliers',
            slug: '/performers/3371',
          },
          {
            name: 'Wake Forest Demon Deacons',
            slug: '/performers/3389',
          },
        ],
      },
      {
        subCategoryId: SPORTS_SUB_CATEGORIES.f1Racing,
        name: 'F1 racing',
        slug: '',
        performers: [],
      },
      {
        subCategoryId: SPORTS_SUB_CATEGORIES.pgaGolf,
        name: 'PGA golf',
        slug: '',
        performers: [],
      },
    ],
    performers: [],
  },
  {
    id: CATEGORIES.music.toString(),
    name: 'Music',
    subCategories: [
      {
        subCategoryId: MUSIC_SUB_CATEGORIES.alternative,
        name: 'Alternative',
        slug: '',
        performers: [],
      },
      {
        subCategoryId: MUSIC_SUB_CATEGORIES.countryFolk,
        name: 'Country & folk',
        slug: '',
        performers: [],
      },
      {
        subCategoryId: MUSIC_SUB_CATEGORIES.hardRock,
        name: 'Hard rock',
        slug: '',
        performers: [],
      },
      {
        subCategoryId: MUSIC_SUB_CATEGORIES.pop,
        name: 'Pop',
        slug: '',
        performers: [],
      },
      {
        subCategoryId: MUSIC_SUB_CATEGORIES.rapHipHop,
        name: 'Rap/hip hop',
        slug: '',
        performers: [],
      },
    ],
    performers: [],
  },
  {
    id: THEATER_SUB_CATEGORIES.comedy.toString(),
    name: 'Comedy',
    subCategories: [],
    performers: [],
  },
  {
    id: CATEGORIES.theater.toString(),
    name: 'Theater',
    subCategories: [
      {
        subCategoryId: THEATER_SUB_CATEGORIES.musical,
        name: 'Musical',
        slug: '',
        performers: [],
      },
      {
        subCategoryId: THEATER_SUB_CATEGORIES.family,
        name: 'Family',
        slug: '',
        performers: [],
      },
      {
        subCategoryId: THEATER_SUB_CATEGORIES.cirqueDuSoleil,
        name: 'Cirque du Soleil',
        slug: '',
        performers: [],
      },
      {
        subCategoryId: THEATER_SUB_CATEGORIES.artsTheater,
        name: 'Arts',
        slug: '',
        performers: [],
      },
      {
        subCategoryId: THEATER_SUB_CATEGORIES.opera,
        name: 'Opera',
        slug: '',
        performers: [],
      },
      {
        subCategoryId: THEATER_SUB_CATEGORIES.balletDance,
        name: 'Ballet & dance',
        slug: '',
        performers: [],
      },
      {
        subCategoryId: THEATER_SUB_CATEGORIES.broadway,
        name: 'Broadway',
        slug: '',
        performers: [],
      },
      {
        subCategoryId: THEATER_SUB_CATEGORIES.publicSpeaking,
        name: 'Public speaking',
        slug: '',
        performers: [],
      },
      {
        subCategoryId: THEATER_SUB_CATEGORIES.magic,
        name: 'Magic',
        slug: '',
        performers: [],
      },
    ],
    performers: [],
  },
  {
    id: EXCLUSIVE_ID,
    name: 'Cardholder Exclusives',
    subCategories: [],
    performers: [
      {
        name: 'All Cardholder Exclusives',
        slug: URLs.ExclusiveEventsPage,
        isBoldText: true,
      },
      {
        name: 'Sports',
        slug: `${URLs.ExclusiveEventsPage}#${EXCLUSIVE_EVENTS_PAGE_ANCHORS.sports}`,
      },
      {
        name: 'Music',
        slug: `${URLs.ExclusiveEventsPage}#${EXCLUSIVE_EVENTS_PAGE_ANCHORS.music}`,
      },
      {
        name: 'Dining',
        slug: `${URLs.ExclusiveEventsPage}#${EXCLUSIVE_EVENTS_PAGE_ANCHORS.dining}`,
      },
      {
        name: 'Getaways',
        slug: `${URLs.ExclusiveEventsPage}#${EXCLUSIVE_EVENTS_PAGE_ANCHORS.getaways}`,
      },
      {
        name: 'Art + theater',
        slug: `${URLs.ExclusiveEventsPage}#${EXCLUSIVE_EVENTS_PAGE_ANCHORS.artTheater}`,
      },
      {
        name: 'Presales',
        slug: 'https://capitalone.com/pre-sales',
        external_link: true,
      },
    ],
    hasTopBorder: true,
  },
];
