import { useMemo } from 'react';
import { useQueryParamFromUrl } from '../../../lib/util';
import { SEARCH_QUERY_PARAM } from './SearchResultsPage.constants';
import { getSearchQueryFromUrl } from './SearchResultsPage.utils';

/** Hook to extract search query from query parameters in the current URL and decode its value */
export const useSearchQueryFromUrl = (): string => {
  const rawSearchQuery: string | undefined = useQueryParamFromUrl(SEARCH_QUERY_PARAM);

  const searchQueryFromUrl: string = useMemo(() => getSearchQueryFromUrl(rawSearchQuery), [rawSearchQuery]);

  return searchQueryFromUrl;
};
