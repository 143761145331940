import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { LogoPresenterProps, LogoProps } from './Logo.types';

export const usePresenter = (props: LogoProps): LogoPresenterProps => {
  const { asset, logoAlt } = props;

  const { t } = useTranslation();

  const ariaLabel: string = useMemo(
    () => logoAlt || t(`logo.${asset}`),
    [t, asset, logoAlt],
  );

  return {
    ...props,
    ariaLabel,
  };
};
