import type { Dispatch, SetStateAction } from 'react';
import { DATE_RANGE_PARAM, END_DATE_PARAM, START_DATE_PARAM } from '../../../lib/constants';
import { formatDateToApiDate } from '../../../lib/util';
import { formatSelectedDate, getTodaysDate, getTomorrowsDate, getWeekendDateRange, treatAsLocalDate } from '../../../modules/date/utils';
import i18n from '../../../modules/locale/i18n';
import type { AppendQueryParamsToUrlFunc } from '../../../modules/navigation/Navigation.types';
import type { FilterOption } from '../../atoms/BaseFilter';
import type { DateFilterOptionValue, DateFilterState, DateRange } from './DateFilter.types';

/**
 * Gets display value for the provided date range.
 * @param {DateRange} dateRange - The date range to get display value for.
 * @returns {string} Display value for the provided date range.
 */
const getDisplayValueForDateRange = (dateRange: DateRange): string => {
  return i18n.t('dateFilters.selectedDateRange', {
    startDate: formatSelectedDate(dateRange.startDate),
    endDate: formatSelectedDate(dateRange.endDate),
  });
};

/**
 * Checks if the provided date filter state is a date range.
 * @param {DateFilterState | undefined} dateFilterState - Current state of the date filter component.
 * @returns {boolean} True if the date filter state is a date range.
 */
const isDateRangeSelected = (dateFilterState: DateFilterState | undefined): boolean => {
  return typeof dateFilterState !== 'string' && !!dateFilterState?.startDate && !!dateFilterState.endDate;
};

/**
 * Returns a display value based on the current filter state.
 * @param {DateFilterState} displayDateFilterState - Current state of the date filter component.
 * @returns {string} The display value for the current selected dates.
 */
export const getDisplayValue = (displayDateFilterState: DateFilterState): string => {
  if (typeof displayDateFilterState === 'string') {
    const stringValues: DateFilterState[] = ['today', 'tomorrow'];
    if (stringValues.includes(displayDateFilterState)) {
      return i18n.t(`dateFilters.${displayDateFilterState}`);
    } else if (displayDateFilterState === 'thisWeekend') {
      const thisWeekendDateRange: DateRange = getWeekendDateRange();
      return getDisplayValueForDateRange(thisWeekendDateRange);
    }
  } else {
    // Default: format and return the selected date range
    return getDisplayValueForDateRange(displayDateFilterState);
  }

  // Default return value when no valid state is provided
  return i18n.t('dateFilters.date');
};

/**
 * Returns the default filter options based on the provided date range.
 * @param {DateFilterState | undefined} dateFilterState - Current state of the date filter component.
 * @returns {FilterOption<DateFilterOptionValue>[]} The default filter options.
 */
export const getDefaultFilterOptions = (dateFilterState: DateFilterState | undefined): FilterOption<DateFilterOptionValue>[] => [
  {
    value: 'today',
    textKey: 'dateFilters.today',
    isSelected: dateFilterState === 'today',
  },
  {
    value: 'tomorrow',
    textKey: 'dateFilters.tomorrow',
    isSelected: dateFilterState === 'tomorrow',
  },
  {
    value: 'thisWeekend',
    textKey: 'dateFilters.thisWeekend',
    isSelected: dateFilterState === 'thisWeekend',
  },
  {
    value: 'chooseDate',
    textKey: 'dateFilters.chooseDate',
    isSelected: isDateRangeSelected(dateFilterState),
  },
];

/**
 * Handles the selection of a filter option by updating filter options state or date range query parameters in the current URL.
 */
export const selectFilterOption = (params: {
  /** The selected filter option value */
  dateFilterOptionValue: DateFilterOptionValue | DateRange | undefined;
  /** The state setter for the filter options */
  setFilterOptions: Dispatch<SetStateAction<FilterOption<DateFilterOptionValue>[]>>;
  /** Function that appends query parameters to the current URL */
  appendQueryParamsToUrl: AppendQueryParamsToUrlFunc;
}): void => {
  const {
    dateFilterOptionValue,
    setFilterOptions,
    appendQueryParamsToUrl,
  } = params;

  if (dateFilterOptionValue === 'chooseDate') {
    // Clear filter options which will switch to showing date picker
    setFilterOptions([]);
    return;
  }

  // Initialize new query parameters
  const newQueryParams = {
    [DATE_RANGE_PARAM]: '',
    [START_DATE_PARAM]: '',
    [END_DATE_PARAM]: '',
  };

  if (typeof dateFilterOptionValue === 'string' && ['today', 'tomorrow', 'thisWeekend'].includes(dateFilterOptionValue)) {
    newQueryParams[DATE_RANGE_PARAM] = dateFilterOptionValue;
  } else if (typeof dateFilterOptionValue === 'object') {
    newQueryParams[START_DATE_PARAM] = formatDateToApiDate(dateFilterOptionValue.startDate, '2-digit');
    newQueryParams[END_DATE_PARAM] = formatDateToApiDate(dateFilterOptionValue.endDate, '2-digit');
  }

  // Append new query parameters to the URL
  appendQueryParamsToUrl(newQueryParams);
};

/**
 * Converts the date filter state to a date range.
 * @param {DateFilterState | undefined} dateFilterState - The current state of the date filter component.
 * @returns {Record<keyof DateRange, string>} The converted date range.
 */
export const convertDateFilterStateToDateRange = (dateFilterState: DateFilterState | undefined): Record<keyof DateRange, string> => {
  let startDate: string = formatDateToApiDate(getTodaysDate(), '2-digit');
  let endDate: string = '';

  if (typeof dateFilterState === 'string') {
    switch (dateFilterState) {
      case 'today': {
        const today = formatDateToApiDate(getTodaysDate(), '2-digit');
        startDate = endDate = today;
        break;
      }
      case 'tomorrow': {
        const tomorrow = formatDateToApiDate(getTomorrowsDate(), '2-digit');
        startDate = endDate = tomorrow;
        break;
      }
      case 'thisWeekend': {
        const thisWeekend = getWeekendDateRange();
        startDate = formatDateToApiDate(thisWeekend.startDate, '2-digit');
        endDate = formatDateToApiDate(thisWeekend.endDate, '2-digit');
        break;
      }
    }
  }

  if (typeof dateFilterState === 'object') {
    startDate = formatDateToApiDate(dateFilterState.startDate, '2-digit');
    endDate = formatDateToApiDate(dateFilterState.endDate, '2-digit');
  }

  return { startDate, endDate };
};

/**
 * Converts the date filter state to query parameters.
 * @param {DateFilterState | undefined} dateFilterState - The current state of the date filter component.
 * @returns {Record<string, string> | undefined} The converted query parameters.
 */
export const convertDateFilterStateToQueryParams = (dateFilterState: DateFilterState | undefined): Record<string, string> | undefined => {
  if (!dateFilterState) {
    return undefined;
  }

  if (typeof dateFilterState === 'string') {
    return {
      [DATE_RANGE_PARAM]: dateFilterState,
    };
  }

  return {
    [START_DATE_PARAM]: formatDateToApiDate(dateFilterState.startDate, '2-digit'),
    [END_DATE_PARAM]: formatDateToApiDate(dateFilterState.endDate, '2-digit'),
  };
};

/**
 * Extracts and validates the date filter state from URL parameters.
 * @returns {DateFilterState | undefined} - Returns a DateFilterState if valid parameters are provided, otherwise undefined.
 */
export const extractDateFilterStateFromParams = (
  params: {
    /** The date range parameter from the URL, can be 'today', 'tomorrow', or 'thisWeekend'. */
    dateRangeParam: string | undefined,
    /** The start date parameter from the URL, in ISO format. */
    startDateParam: string | undefined,
    /** The end date parameter from the URL, in ISO format. */
    endDateParam: string | undefined,
    /** A function to clear date parameters from the URL. */
    clearDateParamsInUrl: () => void,
  },
): DateFilterState | undefined => {

  const { dateRangeParam, startDateParam, endDateParam, clearDateParamsInUrl } = params;

  // If no date parameters are provided, return undefined.
  if (!dateRangeParam && !startDateParam && !endDateParam) {
    return undefined;
  }

  // Handle date range parameter.
  if (dateRangeParam) {
    let isDateRangeParamValid: boolean = false;

    try {
      if (startDateParam || endDateParam) {
        return undefined;
      }

      // Accept only valid values for date range parameter.
      const validValues: DateFilterState[] = ['today', 'tomorrow', 'thisWeekend'];
      if (validValues.includes(dateRangeParam as DateFilterState)) {
        isDateRangeParamValid = true;
        return dateRangeParam as DateFilterState;
      }
    } finally {
      // Remove invalid date range and/or start/end date parameters if they are also present in the URL.
      if (!isDateRangeParamValid) {
        clearDateParamsInUrl();
      }
    }
  } else if (startDateParam || endDateParam) {
    let areStartAndEndDateParamsValid: boolean = false;

    try {
      // Accept only valid values for start and end date parameters.
      if (startDateParam && endDateParam) {
        const startDate = treatAsLocalDate(new Date(startDateParam));
        const endDate = treatAsLocalDate(new Date(endDateParam));

        // Check if the start date is before or equal to the end date.
        if (startDate <= endDate) {
          areStartAndEndDateParamsValid = true;
          return { startDate, endDate };
        }
      }
    } finally {
      // Remove invalid start and end date parameters.
      if (!areStartAndEndDateParamsValid) {
        clearDateParamsInUrl();
      }
    }
  }

  return undefined;
};
