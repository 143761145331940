import cx from 'classnames';
import React, { type LegacyRef, type ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CHECKOUT_BRAINTREE_FORM } from '../../../lib/constants';
import { AriaRoles } from '../../../lib/types';
import { BraintreeElements, BraintreeProvider } from '../../../modules/braintree';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import commonStyles from '../../pages/CheckoutPage/CheckoutPage.module.scss';
import { BlackBoxInput } from '../BlackBoxInput';
import styles from './CreditCardInfo.module.scss';
import { usePresenter } from './CreditCardInfo.presenter';
import type { CreditCardInfoProps } from './CreditCardInfo.types';

const InternalCreditCardInfo: React.FC<CreditCardInfoProps> = (props) => {
  const {
    accountCardLastFourDigits,
    ioBlackBoxRef,
    isCreditCardInfoValid,
    onSubmit,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  const creditCardInfoTitle: ReactNode = (
    <Text
      type='Subheading'
      size='Medium'
      style='SemiBold'
      colour='SubduedDark'
      value={t('paymentInfo.creditCardInfo.title')}
    />
  );

  // TODO: Use creditCardAlert for multicard experience in the future
  // const creditCardAlert: ReactNode = (
  //   <HighlightedMessage
  //     severity='info'
  //     content={(
  //       <Trans
  //         i18nKey='paymentInfo.creditCardInfo.alert'
  //         values={{ accountCardLastFourDigits }}
  //         components={{ bold: (<span className={commonStyles.boldText} />) }}
  //       />
  //     )}
  //   />
  // );

  const creditCardDescription: ReactNode = (
    <Text
      size='Medium'
      style='Regular'
      colour='SubduedLight'
      value={(
        <Trans
          i18nKey='paymentInfo.creditCardInfo.description'
          values={{ accountCardLastFourDigits }}
          components={{ bold: (<span className={commonStyles.boldText} />) }}
        />
      )}
    />
  );

  const creditCardIcons: ReactNode = (
    <div className={cx(commonStyles.row, styles.creditCardIcons)}>
      <Icon
        asset='MasterCard'
        style='DigitalGrey80'
      />
      <Icon
        asset='Visa'
        style='DigitalGrey80'
      />
    </div>
  );

  const renderCreditCardNumberInput = (cardNumberRef: LegacyRef<HTMLDivElement> | undefined): ReactNode => {
    return (
      <div className={cx(commonStyles.column, styles.creditCardDetail, styles.creditCardNumber)}>
        <Text
          size='Medium'
          style='Regular'
          colour='BaseDark'
          value={t('paymentInfo.creditCardInfo.cardNumber')}
        />
        <div
          ref={cardNumberRef}
          className={cx(styles.textInput, { [styles.textInputError]: !isCreditCardInfoValid })}
        />
        {!isCreditCardInfoValid && (
          <Text
            size='Small'
            style='Regular'
            colour='Negative'
            value={t('paymentInfo.creditCardInfo.error')}
            ariaRole={AriaRoles.ALERT}
            ariaAtomic={true}
          />
        )}
      </div>
    );
  };

  const renderCreditCardExpiryInput = (expiryRef: LegacyRef<HTMLDivElement> | undefined): ReactNode => {
    return (
      <div className={cx(commonStyles.column, styles.creditCardDetail)}>
        <Text
          size='Medium'
          style='Regular'
          colour='BaseDark'
          value={t('paymentInfo.creditCardInfo.expiry')}
        />
        <div
          ref={expiryRef}
          className={cx(styles.textInput, styles.creditCardExpiry)}
        />
      </div>
    );
  };

  const renderCreditCardCvvInput = (cvvRef: LegacyRef<HTMLDivElement> | undefined): ReactNode => {
    return (
      <div className={cx(commonStyles.column, styles.creditCardDetail)}>
        <Text
          size='Medium'
          style='Regular'
          colour='BaseDark'
          value={t('paymentInfo.creditCardInfo.cvv')}
        />
        <div
          ref={cvvRef}
          className={cx(styles.textInput, styles.creditCardCvv)}
        />
      </div>
    );
  };

  const braintreeStyles = {
    input: {
      'font-size': '0.875rem',
      'line-height': '1.3125rem',
      color: '#141414',
      padding: '0.25rem 0.5rem 0.3125rem 0.5rem',
    },
  };

  const creditCardFields = (
    <BraintreeElements styles={braintreeStyles}>
      {(cardNumberRef, expiryRef, cvvRef) => (
        <>
          {renderCreditCardNumberInput(cardNumberRef)}
          <div className={cx(commonStyles.row, styles.creditCardDetails)}>
            {renderCreditCardExpiryInput(expiryRef)}
            {renderCreditCardCvvInput(cvvRef)}
          </div>
        </>
      )}
    </BraintreeElements>
  );

  return (
    <form
      id={CHECKOUT_BRAINTREE_FORM}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={onSubmit}
      className={cx(commonStyles.column, styles.creditCardInfo, className)}
    >
      {creditCardInfoTitle}
      {creditCardDescription}
      {creditCardIcons}
      {creditCardFields}
      <BlackBoxInput ioBlackBoxRef={ioBlackBoxRef} />
    </form>
  );
};

export const CreditCardInfo: React.FC<CreditCardInfoProps> = (props) => {
  return (
    <BraintreeProvider>
      <InternalCreditCardInfo {...props} />
    </BraintreeProvider>
  );
};
