import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../lib/util';
import Button from '../../atoms/Button';
import { Logo } from '../../atoms/Logo';
import AccountMenu from '../../molecules/AccountMenu';
import { ExploreButton } from '../../molecules/ExploreButton';
import MenuButton from '../../molecules/MenuButton';
import { SearchBar } from '../../molecules/SearchBar';
import { SearchButton } from '../../molecules/SearchButton';
import { MobileSearchHeader } from '../MobileSearchHeader';
import { SearchBarWithSuggestionsDropdown } from '../SearchBarWithSuggestionsDropdown';
import { SearchSuggestionsModal } from '../SearchSuggestionsModal';
import styles from './TopNav.module.scss';
import { usePresenter } from './TopNav.presenter';
import type { TopNavProps } from './TopNav.types';

export const TopNav: React.FC<TopNavProps> = (props) => {
  const {
    topNavType,
    topNavRef,
    className,
    onLogoClick,
    onMenuButtonClick,
    onCloseButtonClick,
    accountMenu,
  } = usePresenter(props);

  const { isMobile } = useWindowSize();

  const { t } = useTranslation();

  const currentStyle = styles[`topNav${topNavType}`];

  const logo: ReactNode = (
    <div className={styles.logoContainer}>
      <Logo
        asset='capitalOneEntertainment'
        theme='light'
        href='/'
        onClick={onLogoClick}
        className={styles.logo}
      />
    </div>
  );

  const menuButton: ReactNode = isMobile && (
    <MenuButton
      type='IconOnly'
      ariaLabel={t('topnav.menuButtonReaderText')}
      className={styles.menuButton}
      classes={{ icon: styles.menuButtonIcon }}
      onClick={onMenuButtonClick}
    />
  );

  const closeButton: ReactNode = (
    <Button
      type='Icon'
      style='Text'
      size='Medium'
      icon={{
        asset: 'Close',
        style: 'White',
      }}
      ariaLabel={t('common.close')}
      onClick={onCloseButtonClick}
      className={styles.closeButton}
    />
  );

  // Use NonNullable<ReactNode> to ensure we return a value for each variant
  const getTopNav = (): NonNullable<ReactNode> => {
    switch (topNavType) {
      case 'Mobile': return (
        <div ref={topNavRef} className={cx(styles.topNav, currentStyle, className)}>
          {logo}
          <SearchButton />
          {menuButton}
          <AccountMenu {...accountMenu} />
        </div>
      );
      case 'Mobile2Rows': return (
        <div ref={topNavRef} className={cx(styles.topNav, currentStyle, className)}>
          <div className={styles.topContent}>
            {logo}
            {menuButton}
            <AccountMenu {...accountMenu} />
          </div>
          <div className={styles.bottomContent}>
            <SearchBar
              className={styles.searchBar}
              isTextInputFocusedOnMount={false}
              isTextInputFocusedOnClear={false}
              isTextInputBlurredOnFocus={true}
            />
          </div>
        </div>
      );
      case 'MobileWithSearchAndBack': return (
        <div ref={topNavRef} className={cx(styles.topNav, currentStyle, className)}>
          <MobileSearchHeader isInsideModal={false} />
        </div>
      );
      case 'Desktop': return (
        <div ref={topNavRef} className={cx(styles.topNav, currentStyle, className)}>
          <div className={styles.leftContent}>
            {logo}
            <SearchBarWithSuggestionsDropdown />
            <ExploreButton />
          </div>
          <div className={styles.rightContent}>
            <AccountMenu
              className={styles.accountMenu}
              {...accountMenu}
            />
          </div>
        </div>
      );
      case 'LogoWithClose': return (
        <div ref={topNavRef} className={cx(styles.topNav, currentStyle, className)}>
          {logo}
          {closeButton}
        </div>
      );
      case 'LogoOnly': return (
        <div ref={topNavRef} className={cx(styles.topNav, currentStyle, className)}>
          {logo}
        </div>
      );
    }
  };

  return (
    <>
      {getTopNav()}
      {/* Modal with search suggestions on mobiles */}
      <SearchSuggestionsModal />
    </>
  );
};
