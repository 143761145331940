import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styles from './App.module.scss';
import { MainLayout } from './components/layout/MainLayout';
import ProtectedRoute from './modules/auth/ProtectedRoute';

const App: React.FC<{}> = () => {
  const props = {
    className: styles.layout,
  };

  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path='*' element={<MainLayout {...props} />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
