import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { URLs } from '../../../lib/constants';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackSelectContentEvent } from '../../../modules/analytics/util';
import { AuthContext } from '../../../modules/auth';
import { TopNavContext } from '../../../modules/topNav';
import { AccountCards } from '../../molecules/AccountCards';
import styles from './AccountMenuBlock.module.scss';
import type { AccountMenuBlockCombinedProps } from './types';

const usePresenter = (props: AccountMenuBlockCombinedProps): AccountMenuBlockCombinedProps => {
  const { isModalOpen } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { account } = useContext(AuthContext);

  const { hasCategoryMenuItemStates } = useContext(TopNavContext);

  const { trackEvent } = useAnalyticsManager();

  const [isSelectedNavMenuItem, setIsSelectedNavMenuItem] = useState<boolean>(false);

  return {
    ...props,
    hasCategoryMenuItemStates,
    isModalOpen,
    accountMenu: {
      ...props.accountMenu,
      state: props.accountMenu?.state,
      greetingFirstName: {
        value: `${t('topnav.welcome', { accountFirstName: account?.first_name })}`,
      },
      closeButton: {
        ...props.accountMenu?.closeButton,
        icon: {
          asset: 'Close',
        },
        classes: {
          icon: styles.closeButtonIcon,
        },
      },
    },
    navMenuList: {
      setIsSelectedNavMenuItem,
    },
    myAccount: {
      title: {
        value: t('topnav.accountMenu.myAccount'),
        colour: 'SubduedDark',
      },
      type: 'Collapsed',
      dividerPosition: 'Top',
      expansionProps: {
        direction: 'down',
        // eslint-disable-next-line react/react-in-jsx-scope
        expandedContent: <AccountCards />,
      },
    },
    myTickets: {
      title: {
        value: t('topnav.accountMenu.myTickets'),
        colour: 'SubduedDark',
      },
      type: 'Default',
      dividerPosition: 'Top',
      onClick: () => {
        trackSelectContentEvent(
          trackEvent,
          'AccountMenu',
          'MenuItem',
          t('topnav.accountMenu.myTickets'),
        );
        navigate(URLs.MyTicketsPage);
      },
    },
    logOut: {
      title: {
        value: t('topnav.accountMenu.logOut'),
      },
      type: 'Critical',
      onClick: () => {
        trackSelectContentEvent(
          trackEvent,
          'AccountMenu',
          'MenuItem',
          t('topnav.accountMenu.logOut'),
        );
        navigate(URLs.Logout);
      },
    },
    isSelectedNavMenuItem,
  };
};

export default usePresenter;
