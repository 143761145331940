import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import { CarouselButtonIconAssetsByType } from './CarouselButton.constants';
import styles from './CarouselButton.module.scss';
import { usePresenter } from './CarouselButton.presenter';
import type { CarouselButtonProps } from './CarouselButton.types';

export const CarouselButton: React.FC<CarouselButtonProps> = (props) => {
  const {
    type,
    onClick,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <button
      onClick={onClick}
      className={cx(styles.carouselButton, styles[`${type}CarouselButton`], className)}
      aria-label={t(`carouselButton.${type}`)}
    >
      <Icon
        asset={CarouselButtonIconAssetsByType[type]}
        style='ActionBase'
        className={styles.carouselButtonIcon}
      />
    </button>
  );
};
