import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Spinner.module.scss';
import { usePresenter } from './Spinner.presenter';
import type { SpinnerProps } from './Spinner.types';

export const Spinner: React.FC<SpinnerProps> = (props) => {
  const {
    theme = 'dark',
    isOverlay = false,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <div className={cx(styles.spinnerContainer, className, { [styles.overlayingSpinner]: isOverlay })} >
      <div
        role='alert'
        aria-atomic='true'
        className={cx(styles.spinner, styles[`${theme}Spinner`])}
      >
        <span className={styles.screenReaderOnly}>
          {t('loading.loadingMessageForScreenReader')}
        </span>
      </div>
    </div>
  );
};
