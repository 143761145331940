import { useCallback, useContext, useEffect, useMemo, useRef, useState, type ChangeEvent } from 'react';
import { AuthContext } from '../../../../modules/auth';
import type { IconAssetEnum, IconStyleEnum } from '../../../atoms/Icon';
import { INVALID_INTEGER_CHARS_REGEX, PHONE_NUMBER_KEY, PHONE_NUMBER_LENGTH } from '../CheckoutPage.constants';
import type { CheckoutContactInfoProps } from '../CheckoutPage.types';
import { checkIsPhoneNumberValid, getFormattedPhoneNumber } from '../CheckoutPage.utils';

export const useCheckoutContactInfo = (): CheckoutContactInfoProps => {
  const { account } = useContext(AuthContext);

  const email: string = useMemo(() => account?.email || '', [account?.email]);

  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const [editedPhoneNumber, setEditedPhoneNumber] = useState<string>('');

  const [isPhoneNumberEditing, setIsPhoneNumberEditing] = useState<boolean>(false);

  /** In phone number edit mode return edited phone number */
  const formattedPhoneNumber: string = useMemo(
    () => isPhoneNumberEditing ? editedPhoneNumber : getFormattedPhoneNumber(phoneNumber),
    [isPhoneNumberEditing, editedPhoneNumber, phoneNumber],
  );

  const [isPhoneNumberError, setIsPhoneNumberError] = useState<boolean>(false);

  /** Callback to call in phone number edit mode to update phone number */
  const updatePhoneNumber = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (isPhoneNumberEditing) {
      // Note: All non-digit characters have already been removed from newPhoneNumber (See input.removeChar)
      const newPhoneNumber: string = event.target.value;
      setEditedPhoneNumber(newPhoneNumber);
    }
  }, [isPhoneNumberEditing]);

  /** Starts phone number edit mode */
  const startEditingPhoneNumber = useCallback((params: { initialPhoneNumber: string; }) => {
    if (!isPhoneNumberEditing) {
      setEditedPhoneNumber(params.initialPhoneNumber);
      setIsPhoneNumberEditing(true);
      setIsPhoneNumberError(false);
    }
  }, [isPhoneNumberEditing]);

  /**
   * Validates edited phone number and if it is valid then saves it.
   * Otherwise sets phone number error flag.
   */
  const saveEditedPhoneNumber = useCallback((): boolean => {
    if (isPhoneNumberEditing) {
      const isEditedPhoneNumberValid: boolean = checkIsPhoneNumberValid(editedPhoneNumber);

      if (isEditedPhoneNumberValid) {
        sessionStorage.setItem(PHONE_NUMBER_KEY, editedPhoneNumber);
        setPhoneNumber(editedPhoneNumber);
        setIsPhoneNumberEditing(false);
      }

      setIsPhoneNumberError(!isEditedPhoneNumberValid);

      return isEditedPhoneNumberValid;
    }

    return false;
  }, [isPhoneNumberEditing, editedPhoneNumber]);

  /** Toggles between non-edit and edit modes for the phone number */
  const toggleIsPhoneNumberEditing = useCallback(() => {
    if (!isPhoneNumberEditing) {
      // Enter phone number edit mode
      startEditingPhoneNumber({ initialPhoneNumber: phoneNumber });
    } else {
      // Exit phone number edit mode
      saveEditedPhoneNumber();
    }
  }, [isPhoneNumberEditing, phoneNumber, startEditingPhoneNumber, saveEditedPhoneNumber]);

  const isPhoneNumberInitialisedRef = useRef<boolean>(false);

  // Initialize phone number with session storage value (if exists) or account phone number
  // Also check that phone number is valid and if it is not then auto switch to edit mode
  useEffect(() => {
    if (account && !isPhoneNumberInitialisedRef.current) {
      isPhoneNumberInitialisedRef.current = true;

      /** Phone number that is initialized with session storage value (if exists) or account phone number */
      const newPhoneNumber: string = sessionStorage.getItem(PHONE_NUMBER_KEY) || account.phone;

      setPhoneNumber(newPhoneNumber);

      // Auto switch to edit mode if phone number is invalid.
      // E.g. it is possible that account phone numbers that we get from APIs may be blank.
      if (!checkIsPhoneNumberValid(newPhoneNumber)) {
        startEditingPhoneNumber({ initialPhoneNumber: newPhoneNumber });
      }
    }
  }, [account, startEditingPhoneNumber]);

  const [isAcknowledged, setIsAcknowledged] = useState<boolean>(false);

  const toggleAcknowledgement = useCallback(() => {
    setIsAcknowledged((prevIsAcknowledged: boolean) => !prevIsAcknowledged);
  }, []);

  const [isAcknowledgementError, setIsAcknowledgementError] = useState<boolean>(false);

  // Reset isAcknowledgementError when isAcknowledged is true
  useEffect(() => {
    if (isAcknowledged && isAcknowledgementError) {
      setIsAcknowledgementError(false);
    }
  }, [isAcknowledged, isAcknowledgementError]);

  /** Icon asset for acknowledgement checkbox */
  const acknowledgementCheckboxIconAsset: IconAssetEnum = useMemo(
    () => isAcknowledged ? 'CheckboxFilled' : 'CheckboxEmpty',
    [isAcknowledged],
  );

  /** Icon style for acknowledgement checkbox */
  const acknowledgementCheckboxStyle: IconStyleEnum = useMemo(
    () => isAcknowledgementError ? 'Error' : 'ActionBase',
    [isAcknowledgementError],
  );

  /** Function to check if the contact information is valid */
  const checkIsContactInfoValid = useCallback(() => {
    let isContactInfoValid: boolean = true;

    // In phone number edit mode we need to auto-save phone number if it is valid
    // If edited phone number is invalid then do not proceed
    if (isPhoneNumberEditing && !saveEditedPhoneNumber()) {
      isContactInfoValid = false;
    }

    // Check that isAcknowledged is true
    // Otherwise do not proceed
    if (!isAcknowledged) {
      setIsAcknowledgementError(true);
      isContactInfoValid = false;
    }

    return isContactInfoValid;
  }, [isPhoneNumberEditing, saveEditedPhoneNumber, isAcknowledged]);

  return {
    email,
    phoneNumber: formattedPhoneNumber,
    isPhoneNumberEditing,
    updatePhoneNumber,
    phoneNumberMaxLength: PHONE_NUMBER_LENGTH,
    phoneNumberInvalidCharsRegex: INVALID_INTEGER_CHARS_REGEX,
    isPhoneNumberError,
    toggleIsPhoneNumberEditing,
    isAcknowledged,
    acknowledgementCheckboxIconAsset,
    acknowledgementCheckboxStyle,
    toggleAcknowledgement,
    isAcknowledgementError,
    checkIsContactInfoValid,
  };
};
