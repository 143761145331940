import cx from 'classnames';
import React, { type ReactNode } from 'react';
import C1XLandingPageHero1Asset from '../../../resources/images/C1XLandingPageHero1.png';
import { Banner, ExclusiveEventsPageDiningBannerProps } from '../../molecules/Banner';
import type { EventCardProps } from '../../molecules/EventCard';
import type { EventOrMlbCardProps } from '../../molecules/EventOrMlbCard';
import { HeroBanner } from '../../molecules/HeroBanner';
import { CarouselSection } from '../../organisms/CarouselSection';
import { DateFilter } from '../../organisms/DateFilter';
import { RegionFilter } from '../../organisms/RegionFilter';
import styles from './ExclusiveEventsPage.module.scss';
import { usePresenter } from './ExclusiveEventsPage.presenter';
import type { ExclusiveEventsPageProps } from './ExclusiveEventsPage.types';

export const ExclusiveEventsPage: React.FC<ExclusiveEventsPageProps> = (props) => {
  const {
    spotlightEventsCarouselSectionProps,
    sportsEventsCarouselSectionProps,
    musicEventsCarouselSectionProps,
    diningEventsCarouselSectionProps,
    getawaysEventsCarouselSectionProps,
    artTheaterEventsCarouselSectionProps,
    filtersContainerRef,
    regions,
    regionFilterState,
    dateFilterState,
    onDiningBannerButtonClick,
    className,
  } = usePresenter(props);

  const heroBanner: ReactNode = (
    <HeroBanner
      titleKey='exclusiveEventsPage.heroTitle'
      descriptionKey='exclusiveEventsPage.heroDescription'
      backgroundImageUrl={C1XLandingPageHero1Asset}
    />
  );

  const diningBanner: ReactNode = (
    <Banner
      {...ExclusiveEventsPageDiningBannerProps}
      onButtonClick={onDiningBannerButtonClick}
      className={cx(styles.bannerContainer, styles.diningBannerContainer)}
      classes={{
        banner: cx(styles.banner, styles.diningBanner),
        content: styles.content,
        title: styles.title,
        description: styles.description,
        buttonContainer: styles.buttonContainer,
        buttonText: styles.buttonText,
      }}
    />
  );

  const regionFilter: ReactNode = (
    <RegionFilter
      regions={regions}
      regionFilterState={regionFilterState}
      canSearchRegion={true}
      canUseCurrentRegion={true}
      theme='dark'
      className={styles.filter}
    />
  );

  const dateFilter: ReactNode = (
    <DateFilter
      dateFilterState={dateFilterState}
      theme='dark'
      className={styles.filter}
    />
  );

  const spotlightEventsCarouselSection: ReactNode = <CarouselSection<EventCardProps> {...spotlightEventsCarouselSectionProps} />;
  const sportsEventsCarouselSection: ReactNode = <CarouselSection<EventOrMlbCardProps> {...sportsEventsCarouselSectionProps} />;
  const musicEventsCarouselSection: ReactNode = <CarouselSection<EventCardProps> {...musicEventsCarouselSectionProps} />;
  const diningEventsCarouselSection: ReactNode = <CarouselSection<EventCardProps> {...diningEventsCarouselSectionProps} />;
  const getawaysEventsCarouselSection: ReactNode = <CarouselSection<EventCardProps> {...getawaysEventsCarouselSectionProps} />;
  const artTheaterEventsCarouselSection: ReactNode = <CarouselSection<EventCardProps> {...artTheaterEventsCarouselSectionProps} />;

  return (
    <div className={cx(styles.exclusiveEventsPage, className)}>
      <div className={cx(styles.container, styles.darkContainer, styles.withBottomBorder)}>
        {heroBanner}
      </div>
      <div className={cx(styles.container, styles.darkContainer)}>
        <div className={cx(styles.carouselContainer, styles.withVerticalBorders)}>
          {spotlightEventsCarouselSection}
        </div>
        <div ref={filtersContainerRef} className={cx(styles.subContainer, styles.filtersContainer, styles.withVerticalBorders)}>
          {regionFilter}
          {dateFilter}
        </div>
        <div className={cx(styles.carouselContainer, styles.withVerticalBorders)}>
          {sportsEventsCarouselSection}
          {musicEventsCarouselSection}
          {diningEventsCarouselSection}
          {getawaysEventsCarouselSection}
          {artTheaterEventsCarouselSection}
        </div>
      </div>
      <div className={cx(styles.container, styles.darkContainer)}>
        <div className={cx(styles.subContainer, styles.withVerticalBorders)}>
          {diningBanner}
        </div>
      </div>
    </div>
  );
};
