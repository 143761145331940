import { useCallback, useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { AuthContext } from '../../../modules/auth';
import { marketingOptinMlb } from '../../../modules/partnership/api';
import type { MarketingOptinMlbPresenterProps, MarketingOptinMlbProps } from './MarketingOptinMlb.types';

export const usePresenter = (props: MarketingOptinMlbProps): MarketingOptinMlbPresenterProps => {
  const { orderId } = props;

  const { account } = useContext(AuthContext);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const [isSubscribeButtonClicked, setSubscribeButtonClicked] = useState<boolean>(false);

  const { mutateAsync: subscribeToMlbMarketing } = useMutation(marketingOptinMlb);

  const handleSubscribeClick = useCallback(() => {
    if (orderId && !isSubscribeButtonClicked) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      subscribeToMlbMarketing({ orderId });
      setSubscribeButtonClicked(true);
    }
  }, [orderId, isSubscribeButtonClicked, subscribeToMlbMarketing]);

  return {
    ...props,
    userEmail: account?.email || '',
    isExpanded,
    setIsExpanded,
    isSubscribeButtonClicked,
    handleSubscribeClick,
  };
};
