import type { Dispatch, SetStateAction } from 'react';
import { SPORTS_ROUTES, URLs } from '../../../lib/constants';
import type { EventKeys } from '../../../modules/analytics/types';
import i18n from '../../../modules/locale/i18n';
import { CATEGORIES_WITH_NO_SUB_CATEGORIES } from '../../../modules/navigation/Navigation.constants';
import type { CategoryMenuItemState, MobileMenuItem, MobileMenuItemTypeType, MobileMenuState, PerformerMenuItemState, SubCategoryMenuItemState } from '../../../modules/navigation/Navigation.types';
import type { NavMenuItemCombinedProps } from '../../molecules/NavMenuItem/types';
import styles from './NavMenuList.module.scss';

const buildNavMenuBackButtonProps = (title: string, handleBackClick: () => void): NavMenuItemCombinedProps => (
  {
    type: 'Back',
    title: {
      value: title,
      colour: 'SubduedDark',
    },
    backButton: {
      icon: {
        asset: 'ChevronLeft',
        style: 'SubduedDark',
      },
    },
    onClick: () => {
      // Handle back button click
      handleBackClick();
    },
  }
);

const buildNavMenuSearchAllProps = (params: { title: string; slug: string; }): NavMenuItemCombinedProps => ({
  type: 'Default',
  title: {
    value: i18n.t('search.allTitle', { title: params.title }),
    colour: 'SubduedDark',
  },
  linkPath: params.slug,
});

/**
 * Generates menu list items based on the provided menu state.
 * @param {MobileMenuState} menuState - The current state of the mobile menu.
 * @param {() => void} handleBackClick - Function to handle back button click.
 * @param {(event: EventKeys, extraData?: Record<string, unknown>) => void} trackEvent - Function to track analytics events.
 * @param {TFunction<'translation', undefined>} t - Translation function.
 * @param {Dispatch<SetStateAction<MobileMenuState>>} setMenuState - Function to set the menu state.
 * @param {MobileMenuState} initialMenuState - The initial state of the menu.
 * @param {(id: string) => void} onItemClick - Function to handle menu item click.
 * @returns {NavMenuItemCombinedProps[]} Array of menu list items.
 */
export const buildNavMenuListItems = (
  menuState: MobileMenuState,
  handleBackClick: () => void,
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  setMenuState: Dispatch<SetStateAction<MobileMenuState>>,
  initialMenuState: MobileMenuState,
  onItemClick: (id: string) => void,
): NavMenuItemCombinedProps[] => {
  const menuListItems: NavMenuItemCombinedProps[] = [];

  // If there is a selected menu item, add a back button
  if (menuState.selected) {
    const { type, title, slug } = menuState.selected;

    menuListItems.push(buildNavMenuBackButtonProps(title, handleBackClick));

    // Add extra menu list item for certain leagues with 'All' prefix, e.g. 'All NBA'
    if (type === 'subCategory' && SPORTS_ROUTES.includes(slug)) {
      menuListItems.push(buildNavMenuSearchAllProps({ title, slug }));
    }
  }

  // Loop through menuState items and generate list items
  menuState.items.forEach(item => {

    const { id, type, title, hasTopBorder, isBoldText } = item;
    const isItem = type === 'item';

    menuListItems.push({
      type: isItem ? 'Default' : 'Next',
      title: { value: title, colour: 'SubduedDark' },
      dividerPosition: hasTopBorder ? 'Top' : 'None',
      onClick: () => {
        if (isItem) {
          if (id.startsWith(URLs.ExclusiveEventsPage) && title !== 'Presales') {
            trackEvent('select_content_c1x_navigation-menu');
          }
          setMenuState(initialMenuState);
          return;
        }
        onItemClick(id);
      },
      nextButton: {
        icon: {
          asset: 'ChevronRight',
          style: 'SubduedDark',
        },
      },
      newTab: title === 'Presales',
      linkPath: isItem ? id : undefined,
      classes: {
        title: isBoldText ? styles.boldText : '',
      },
    });
  });

  return menuListItems;
};

/**
 * Generates the list of sub-categories based on the selected parent category for back button.
 * @param {CategoryMenuItemState[]} categoryMenuItemStates - The array of top navigation tabs containing category information.
 * @param {MobileMenuState} menuState - The current state of the mobile menu.
 * @returns {MobileMenuItem[]} - The array of mobile menu items representing sub-categories.
 */
export const getSubCategoriesOnBackButton = (categoryMenuItemStates: CategoryMenuItemState[], menuState: MobileMenuState): MobileMenuItem[] => {
  // Find the parent category object based on the selected parent ID
  const categoryObject = categoryMenuItemStates.find((category) => String(category.id) === String(menuState.selected?.parentId));

  // Initialize an empty array to store the list of sub-categories
  const subCategories: MobileMenuItem[] = [];

  categoryObject?.subCategories.forEach((child: SubCategoryMenuItemState) =>
    subCategories.push({
      type: 'subCategory',
      title: child.name,
      slug: child.slug || child.url || child.name,
      id: String(child.subCategoryId),
      parentId: menuState?.selected?.parentId,
    } as MobileMenuItem),
  );

  return subCategories;
};

/**
 * Generates a category menu item based on the current menu state category for back button.
 * @param {MobileMenuState} menuState - The current state of the mobile menu.
 * @param {CategoryMenuItemState[]} categoryMenuItemStates - The array of top navigation tabs containing category information.
 * @returns {MobileMenuItem} - The category menu item.
 */
export const getCategoryMenuItemOnBackButton = (menuState: MobileMenuState, categoryMenuItemStates: readonly CategoryMenuItemState[]): MobileMenuItem => {
  const categoryObject = categoryMenuItemStates.find((category) => String(category.id) === String(menuState.selected?.parentId));
  return (
    {
      type: 'category',
      id: String(categoryObject?.id),
      title: categoryObject?.name || '',
      slug: categoryObject?.name || '',
    });
};

/**
 * Function to transform an array of CategoryMenuItemState objects into an array of MobileMenuItem objects.
 * @param {CategoryMenuItemState[]} data - An array of CategoryMenuItemState objects representing navigation categories.
 * @returns {MobileMenuItem[]} An array of MobileMenuItem objects.
 */
export const getInitialCategoryItems = (data: readonly CategoryMenuItemState[]): MobileMenuItem[] => {
  return data.map((category) => {
    return {
      type: 'category',
      title: category.name,
      id: category.id,
      slug: category.id,
      hasTopBorder: category.hasTopBorder,
    };
  });
};

/**
 * Retrieves a list of category options based on the provided data and ID.
 * @param {CategoryMenuItemState[]} data - An array of CategoryMenuItemState objects representing navigation categories.
 * @param {string} id - The ID of the category.
 * @returns {MobileMenuItem[]} - An array of mobile menu items.
 */
const getListOfCategoryOptions = (
  data: readonly CategoryMenuItemState[],
  id: string,
): MobileMenuItem[] => {
  const cat = data.find((category) => String(category.id) === String(id));
  if (!cat) return [];

  const isCategoryWithoutSubcategories = CATEGORIES_WITH_NO_SUB_CATEGORIES.includes(String(id));
  if (isCategoryWithoutSubcategories) {
    const list: MobileMenuItem[] = cat.performers.map((child: PerformerMenuItemState) => {
      return {
        type: 'item',
        title: child.name,
        slug: child?.slug || child?.url || '',
        id: child?.slug || child?.url || '',
        parentId: id,
        isBoldText: child.isBoldText,
      };
    });
    return list;
  } else {
    const list: MobileMenuItem[] = cat.subCategories.map((child: SubCategoryMenuItemState) => {
      return {
        type: 'subCategory',
        title: child.name,
        slug: child?.slug || child?.url || '',
        id: String(child.subCategoryId),
        parentId: id,
      };
    });

    return list;
  }
};

/**
 * Retrieves a list of performers for a given subCategory within a selected category.
 *
 * @param {CategoryMenuItemState[]} categoryMenuItemStates - An array of CategoryMenuItemState objects representing navigation categories.
 * @param {string} subGenreId - The ID of the subCategory to retrieve performers for.
 * @param {MobileMenuState} menuState - The current state of the mobile menu.
 * @returns {MobileMenuItem[] | undefined} - An array of mobile menu items representing the performers, or undefined if no performers are found.
 */
const getListOfPerformers = (
  categoryMenuItemStates: readonly CategoryMenuItemState[],
  subGenreId: string,
  menuState: MobileMenuState,
): MobileMenuItem[] | undefined => {
  const categoryID = menuState.selected?.id;
  const categoryObject = categoryMenuItemStates.find((category) => String(category.id) === String(categoryID));
  const subCategoryObject = categoryObject?.subCategories.find(
    (subCat) => String(subCat.subCategoryId) === String(subGenreId),
  );

  // If a subCategory object is found, map its children (performers) to an array of mobile menu items
  return (
    subCategoryObject &&
    subCategoryObject?.performers &&
    subCategoryObject.performers.map((performer) => ({
      type: 'item',
      title: performer.name,
      slug: performer.slug,
      id: performer.slug,
      isBoldText: performer.isBoldText,
    }))
  );
};

/**
 * Populates the mobile menu with children based on the type.
 * @param data The array of top navigation tabs.
 * @param type The type of the menu item.
 * @param id The ID of the menu item.
 * @param menuState The state of the mobile menu.
 * @returns An array of MobileMenuItem representing the children of the menu item.
 */
export const populateWithChildren = (
  data: readonly CategoryMenuItemState[],
  type: MobileMenuItemTypeType,
  id: string,
  menuState: MobileMenuState,
): MobileMenuItem[] | undefined => {
  switch (type) {
    case 'category': {
      return getListOfCategoryOptions(data, id);
    }
    case 'subCategory': {
      return getListOfPerformers(data, id, menuState);
    }
    default:
      break;
  }
  return undefined;
};
