import type { TextColourEnum } from '../../atoms/Text';
import type { EmptyStateTheme } from './EmptyState.types';

export const TitleColoursByTheme = {
  light: 'SubduedDark',
  dark: 'BaseLight',
} as const satisfies Record<EmptyStateTheme, TextColourEnum>;

export const DescriptionColoursByTheme = {
  light: 'SubduedLight',
  dark: 'BaseLight',
} as const satisfies Record<EmptyStateTheme, TextColourEnum>;
