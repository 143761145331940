import i18n from '../../../modules/locale/i18n';
import styles from './TextDropdown.module.scss';
import type { TextDropdownOption, TextDropdownOptionProps } from './TextDropdown.types';

export const getOptionProps = (params: {
  options: TextDropdownOption[];
  selectedValue: string;
}): TextDropdownOptionProps[] => {
  const { options, selectedValue } = params;

  const lowerCaseSelectedValue: string = selectedValue.toLowerCase();

  return options.map(({ value, label, ariaLabel: initialAriaLabel }: TextDropdownOption) => {
    const isSelected: boolean = value.toLowerCase() === lowerCaseSelectedValue;

    const ariaLabel: string = i18n.t(
      isSelected ? 'textDropdown.selectedOption' : 'textDropdown.unselectedOption',
      { option: initialAriaLabel || label },
    );

    return {
      key: value,
      value,
      'aria-label': ariaLabel,
      children: label,
      className: styles.option,
    };
  });
};
