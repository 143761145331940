import { getEventDateTimeAndVenueDetails } from '../../../lib/eventUtils';
import { trackSelectContentEvent, type EventKeys } from '../../../modules/analytics';
import i18n from '../../../modules/locale/i18n';
import type { Event, Performer, Venue } from '../../../modules/partnership';
import type { SearchSuggestionSectionProps } from '../../organisms/SearchSuggestionSection';
import { AnalyticsSources, SearchSuggestionSectionMaxItems, UrlGenerators } from './SearchSuggestionBlock.constants';
import type { SearchSuggestionSectionKey } from './SearchSuggestionBlock.types';

/**
 * Builds the props for a search suggestion section based on the provided section key and items.
 * @returns {SearchSuggestionSectionProps | undefined} Props for the search suggestion section or undefined if no items are provided.
 */
export const buildSearchSuggestionSectionProps = <TItem extends (Event | Performer | Venue)>(params: {
  /** Key of the section for which props are being built */
  sectionKey: SearchSuggestionSectionKey;
  /** Items to be displayed in the section */
  items: TItem[];
  /** Function to track analytics events */
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown> | undefined) => void;
}): SearchSuggestionSectionProps | undefined => {
  const { sectionKey, items, trackEvent } = params;

  if (!items.length) {
    return undefined;
  }

  // Limit the number of items to the max number per section type
  const maxItems: number = SearchSuggestionSectionMaxItems[sectionKey];
  const slicedItems = items.length > maxItems
    ? items.slice(0, maxItems)
    : items;

  return {
    sectionTitle: i18n.t(`search.${sectionKey}`),
    searchSuggestionItems: slicedItems.map(item => ({
      title: item.name,
      description: sectionKey === 'events'
        ? i18n.t('event.shortDateTimeVenueNameCityStateCode', getEventDateTimeAndVenueDetails(item as Event))
        : '',
      href: UrlGenerators[sectionKey](item.id),
      onClick: () => {
        trackSelectContentEvent(
          trackEvent,
          'Search Suggestions',
          AnalyticsSources[sectionKey],
          item.name,
        );
      },
    })),
  };
};
