import cx from 'classnames';
import React from 'react';

import Text from '../../atoms/Text';
import Tag from '../Tag';

import styles from './EventDateStatus.module.scss';
import usePresenter from './EventDateStatus.presenter';
import getProps from './EventDateStatus.props';
import { EventDateStatusCombinedProps } from './types';

const EventDateStatus: React.FC<EventDateStatusCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    date,
    time,
    tag,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`eventDateStatus${variantName}`];

  let componentView: JSX.Element;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
            <Text
              className={cx(styles.date, classes?.date)}
              {...internalProps.date}
              {...date} />
            <Text
              className={cx(styles.time, classes?.time)}
              {...internalProps.time}
              {...time} />
          </div>
        </div>
      );
      break;
    }
    case 'WithTag': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Tag
            className={cx(styles.tag, classes?.tag)}
            {...internalProps.tag}
            {...tag} />
          <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
            <Text
              className={cx(styles.date, classes?.date)}
              {...internalProps.date}
              {...date} />
            <Text
              className={cx(styles.time, classes?.time)}
              {...internalProps.time}
              {...time} />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default EventDateStatus;
