import { trackErrorEvent, type EventKeys } from '../analytics';
import { ApiError } from '../error/types';
import type { CategoriesPageResponse } from '../partnership';

/**
 * Checks if the provided error is an API error and tracks it.
 * @param {unknown} error - The .
 * @param {(event: EventKeys, extraData?: Record<string, unknown> | undefined) => void} trackEvent - The function to track analytics events.
 */
export const checkCategoriesApiError = (params: {
  /** Error object to check */
  error: unknown;
  /** Function to track analytics events */
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown> | undefined) => void;
}): void => {
  const { error, trackEvent } = params;
  if (error && ApiError.isApiError(error)) {
    trackErrorEvent(
      trackEvent,
      error.code,
      error.message,
    );
  }
};

/**
 * Creates a context value for categories based on the provided response, loading state, and error.
 * @param {CategoriesPageResponse | undefined} categoriesResponse - The response containing categories data.
 * @param {boolean} areCategoriesLoading - A boolean indicating whether the categories are currently loading.
 * @param {unknown} error - An error object if there was an error loading the categories.
 * @returns {{categories: Array, areCategoriesLoading: boolean, error: Error | undefined}} An object containing the categories, loading state, and error.
 */
export const getCategoriesContextValue = (params: {
  categoriesResponse: CategoriesPageResponse | undefined,
  areCategoriesLoading: boolean,
  error: unknown,
}) => {
  const { categoriesResponse, areCategoriesLoading, error } = params;
  return {
    categories: categoriesResponse?.items ?? [],
    areCategoriesLoading: areCategoriesLoading || !categoriesResponse?.items.length,
    error: error as Error | undefined,
  };
};
