import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MapTooltip.module.scss';
import { usePresenter } from './MapTooltip.presenter';
import type { MapTooltipProps } from './MapTooltip.types';

export const MapTooltip: React.FC<MapTooltipProps> = (props) => {
  const {
    shouldShowMapTooltip,
    formattedMinPrice,
    updatePopperElement,
    isArrowElementShown = false,
    updateArrowElement,
    popperStyles,
    attributes,
  } = usePresenter(props);

  const { t } = useTranslation();

  return <>
    {shouldShowMapTooltip && (
      <div
        ref={updatePopperElement}
        style={popperStyles.popper}
        {...attributes.popper}
        className={styles.mapTooltip}
      >
        <span>{t('common.from')}{' '}<strong>{formattedMinPrice}</strong></span>
        {isArrowElementShown && (
          <div
            ref={updateArrowElement}
            style={popperStyles.arrow}
            className={styles.mapTooltipArrow}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8"><path fill="#141414" d="M15.96.309a.51.51 0 0 0-.198-.225.56.56 0 0 0-.297-.084H.533a.559.559 0 0 0-.297.084.508.508 0 0 0-.196.225.478.478 0 0 0 .116.545l7.466 7A.536.536 0 0 0 8 8c.14 0 .275-.053.378-.147l7.466-7a.471.471 0 0 0 .116-.544Z" /></svg>
          </div>
        )}
      </div>
    )}
  </>;
};
