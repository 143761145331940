import type { SvgMapSectionNameProps, SvgMapSectionNamesPresenterProps, SvgMapSectionNamesProps } from './SvgMapSectionNames.types';
import { getSvgMapSectionNameProps } from './SvgMapSectionNames.utils';

export const usePresenter = (props: SvgMapSectionNamesProps): SvgMapSectionNamesPresenterProps => {
  const { svgMapSectionNames } = props;

  // Important: This component is memoized so there is no need to use useMemo here in the presenter.

  /** Array of SVG map section name props for rendering on the map */
  const svgMapSectionNameProps: SvgMapSectionNameProps[] = getSvgMapSectionNameProps({ svgMapSectionNames });

  return {
    ...props,
    svgMapSectionNameProps,
  };
};
