import cx from 'classnames';
import React from 'react';

import Text from '../../atoms/Text';

import styles from './Tag.module.scss';
import usePresenter from './Tag.presenter';
import getProps from './Tag.props';
import { TagCombinedProps } from './types';

const Tag: React.FC<TagCombinedProps> = (props) => {
  const {
    type,
    size,
    className,
    classes,
    label,
  } = usePresenter(props);

  const variantName = `${type}${size}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`tag${variantName}`];

  let componentView: JSX.Element;

  switch (variantName) {
    case 'InformationRegular':
    case 'InformationSmall':
    case 'NegativeRegular':
    case 'NegativeSmall':
    case 'NeutralSmall':
    case 'PositiveRegular':
    case 'PositiveSmall':
    case 'WarningRegular':
    case 'WarningSmall': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.label, classes?.label)}
            {...internalProps.label}
            {...label} />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Tag;
