import { useMemo } from 'react';
import { useAnalyticsManager } from '../../../modules/analytics';
import type { SearchSuggestionSectionProps } from '../../organisms/SearchSuggestionSection';
import type { SearchSuggestionBlockPresenterProps, SearchSuggestionBlockProps } from './SearchSuggestionBlock.types';
import { buildSearchSuggestionSectionProps } from './SearchSuggestionBlock.utils';

export const usePresenter = (props: SearchSuggestionBlockProps): SearchSuggestionBlockPresenterProps => {
  const { searchSuggestions } = props;

  const { trackEvent } = useAnalyticsManager();

  const events: SearchSuggestionSectionProps | undefined = useMemo(
    () => buildSearchSuggestionSectionProps({ sectionKey: 'events', items: searchSuggestions.events, trackEvent }),
    [searchSuggestions.events, trackEvent],
  );

  const performers: SearchSuggestionSectionProps | undefined = useMemo(
    () => buildSearchSuggestionSectionProps({ sectionKey: 'performers', items: searchSuggestions.performers, trackEvent }),
    [searchSuggestions.performers, trackEvent],
  );

  const venues: SearchSuggestionSectionProps | undefined = useMemo(
    () => buildSearchSuggestionSectionProps({ sectionKey: 'venues', items: searchSuggestions.venues, trackEvent }),
    [searchSuggestions.venues, trackEvent],
  );

  // Return sections in the specific order with their respective max items
  const searchSuggestionSections: SearchSuggestionSectionProps[] = useMemo(() => {
    // Filter out any undefined sections
    return [events, performers, venues].filter(Boolean) as SearchSuggestionSectionProps[];
  }, [events, performers, venues]);

  return {
    ...props,
    searchSuggestionSections,
  };
};
