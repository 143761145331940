import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../lib/util';
import Button from '../../atoms/Button';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';
import styles from './EventInfoModal.module.scss';
import { usePresenter } from './EventInfoModal.presenter';
import type { EventInfoModalProps } from './EventInfoModal.types';

export const EventInfoModal: React.FC<EventInfoModalProps> = (props) => {
  const {
    eventMetaData: {
      eventName,
      eventModalMobileImageUrl,
      eventModalDesktopImageUrl,
      eventDateTimeAndVenueDetails,
    },
    processedEventDescriptionHtml,
    closeInfoModal,
  } = usePresenter(props);

  const { t } = useTranslation();

  const { isMobile } = useWindowSize();

  const eventInfoHeader: ReactNode = (
    <div className={cx(styles.row, styles.eventInfoHeader)}>
      <Text
        type='NewEventDetailsTitle'
        size='Large'
        style='SemiBold'
        colour='SubduedDark'
        value={eventDateTimeAndVenueDetails.venueName}
      />
      <Button
        type='Icon'
        style='Text'
        size='Medium'
        icon={{
          asset: 'Close',
          style: 'SubduedDark',
        }}
        onClick={closeInfoModal}
        autoFocus={true}
        ariaLabel={t('event.closeEventInfoModal')}
        className={styles.closeButton}
      />
    </div>
  );

  const eventImage: ReactNode = (
    <Image
      imageSrc={isMobile ? eventModalMobileImageUrl : eventModalDesktopImageUrl}
      className={styles.eventImage}
    />
  );

  const eventDateTimeAndVenue: ReactNode = (
    <div className={cx(styles.column, styles.fullWidth)}>
      <Text
        size='Large'
        style='Regular'
        colour='SubduedLight'
        value={t('event.shortWeekDayShortDateTime', eventDateTimeAndVenueDetails)}
      />
      <Text
        size='Large'
        style='Regular'
        colour='SubduedLight'
        value={eventDateTimeAndVenueDetails.venueName}
      />
      <Text
        size='Large'
        style='Regular'
        colour='SubduedLight'
        value={t('event.venueCityStateCode', eventDateTimeAndVenueDetails)}
      />
    </div>
  );

  const eventNameView: ReactNode = (
    <Text
      type='NewEventDetailsTitle'
      size='Large'
      style='SemiBold'
      colour='SubduedLight'
      value={eventName}
      className={styles.lineHeight31px}
    />
  );

  /** Event description with HTML being handled */
  const processedEventDescription: ReactNode = typeof processedEventDescriptionHtml === 'string'
    ? <span dangerouslySetInnerHTML={{ __html: processedEventDescriptionHtml }} />
    : processedEventDescriptionHtml;

  const eventDescriptionView: ReactNode = (
    <Text
      size='Large'
      style='Regular'
      colour='SubduedLight'
      value={processedEventDescription}
      className={cx(styles.eventDescription, styles.lineHeight21px)}
    />
  );

  return (
    <Modal
      show={true}
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      onHide={closeInfoModal}
    >
      <div className={cx(styles.row, styles.gap24px)}>
        <div className={styles.desktopOnly}>
          {eventImage}
        </div>
        <div className={cx(styles.column, styles.eventInfoModalContent)}>
          {eventInfoHeader}
          <div className={cx(styles.column, styles.gap24px)}>
            <div className={cx(styles.row, styles.gap32px)}>
              <div className={styles.mobileOnly}>
                {eventImage}
              </div>
              {eventDateTimeAndVenue}
            </div>
            {eventNameView}
            {eventDescriptionView}
          </div>
        </div>
      </div>
    </Modal>
  );
};
