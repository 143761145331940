import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../atoms/Text';
import styles from './MultiToggle.module.scss';
import { usePresenter } from './MultiToggle.presenter';
import type { MultiToggleOption, MultiToggleProps } from './MultiToggle.types';

export const MultiToggle = <TId extends string | number>(props: MultiToggleProps<TId>) => {
  const {
    multiToggleOptions,
    multiToggleState,
    onMultiToggleOptionSelected,
    itemRefs,
    selectionChipStyles,
    selectionChipClassName,
  } = usePresenter(props);

  const { t } = useTranslation();

  const multiToggleItems: React.ReactNode = multiToggleOptions.map((multiToggleOption: MultiToggleOption<TId>, index: number) => {
    const isSelected: boolean = multiToggleOption.id === multiToggleState;

    return (
      <button
        key={multiToggleOption.id}
        ref={el => itemRefs.current[index] = el}
        role='tab'
        aria-selected={isSelected}
        onClick={() => onMultiToggleOptionSelected(multiToggleOption)}
        className={cx(styles.multiToggleItem, { [styles.selectedMultiToggleItem]: isSelected })}
      >
        <Text
          size='Large'
          style='Regular'
          colour={isSelected ? 'BaseLight' : 'ActionBase'}
          value={t(multiToggleOption.labelKey)}
        />
      </button>
    );
  });

  return (
    <div role='tablist' className={styles.multiToggle}>
      <div className={cx(styles.selectionChip, selectionChipClassName)} style={selectionChipStyles} />
      {multiToggleItems}
    </div>
  );
};
