import React, { type ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Image from '../../atoms/Image';
import { PreCheckoutDetails } from '../../molecules/PreCheckoutDetails';
import styles from './NonGuestListPreCheckoutModal.module.scss';
import { usePresenter } from './NonGuestListPreCheckoutModal.presenter';
import type { NonGuestListPreCheckoutModalProps } from './NonGuestListPreCheckoutModal.types';

export const NonGuestListPreCheckoutModal: React.FC<NonGuestListPreCheckoutModalProps> = (props) => {
  const {
    eventImageUrl,
    listingDetailQueryParams,
    listingDetail,
    closeNonGuestListPreCheckoutModal,
  } = usePresenter(props);

  const { t } = useTranslation();

  const eventImage: ReactNode = (
    <Image
      imageSrc={eventImageUrl}
      className={styles.eventImage}
    />
  );

  const backToAllTicketsButton: ReactNode = (
    <Button
      type='IconText'
      style='Text'
      size='Small'
      icon={{
        asset: 'ArrowLeft',
        style: 'ActionBase',
      }}
      text={{
        size: 'Large',
        style: 'Regular',
        colour: 'ActionBase',
        value: t('nonGuestListEventPage.backToAllTickets'),
        className: styles.backToAllTicketsButtonText,
      }}
      onClick={closeNonGuestListPreCheckoutModal}
      className={styles.backToAllTicketsButton}
    />
  );

  return (
    <Modal
      show={true}
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      backdrop={false}
      autoFocus={true}
      enforceFocus={false}
      onHide={closeNonGuestListPreCheckoutModal}
    >
      {eventImage}
      {backToAllTicketsButton}
      <PreCheckoutDetails
        listingDetailQueryParams={listingDetailQueryParams}
        listingDetail={listingDetail}
        shouldShowAipOverride={false}
        theme='light'
        isDesktopResponsive={false}
        classes={{
          topContent: styles.topContent,
          bottomContent: styles.bottomContent,
        }}
      />
    </Modal>
  );
};
