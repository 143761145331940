import type { IconAssetEnum, IconStyleEnum } from '../../atoms/Icon';
import type { HighlightedMessageSeverity } from './HighlightedMessage.types';

export const IconAssetsBySeverity = {
  info: 'InfoFilled',
  error: 'Warning',
} as const satisfies Record<HighlightedMessageSeverity, IconAssetEnum>;

export const IconStylesBySeverity = {
  info: 'DigitalGrey80',
  error: 'SubduedDark',
} as const satisfies Record<HighlightedMessageSeverity, IconStyleEnum>;
