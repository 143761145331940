import { getEventUrl } from '../../../lib/eventUtils';
import { getPerformerUrl } from '../../../lib/performerUtils';
import { getVenueUrl } from '../../../lib/venueUtils';
import type { SearchSuggestionSectionKey } from './SearchSuggestionBlock.types';

export const SearchSuggestionSectionKeys = ['events', 'performers', 'venues'] as const;

export const SearchSuggestionSectionMaxItems = {
  events: 5,
  performers: 3,
  venues: 2,
} as const satisfies Record<SearchSuggestionSectionKey, number>;

/** A map object that for events/performers/venues contains a function to generate a link URL */
export const UrlGenerators = {
  events: getEventUrl,
  performers: getPerformerUrl,
  venues: getVenueUrl,
} as const satisfies Record<SearchSuggestionSectionKey, (id: number) => string>;

/** A map object that for events/performers/venues contains an analytics source */
export const AnalyticsSources = {
  events: 'Production',
  performers: 'Performer',
  venues: 'Venue',
} as const satisfies Record<SearchSuggestionSectionKey, string>;
