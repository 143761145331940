import cx from 'classnames';
import React from 'react';

import styles from './Icon.module.scss';
import usePresenter from './Icon.presenter';
import type { IconCombinedProps } from './types';

import { ReactComponent as ArenaIcon } from '../../../resources/legacyIcons/Arena.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../resources/legacyIcons/ArrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from '../../../resources/legacyIcons/ArrowRight.svg';
import { ReactComponent as ArrowRightFilledIcon } from '../../../resources/legacyIcons/ArrowRightFilled.svg';
import { ReactComponent as CalendarIcon } from '../../../resources/legacyIcons/Calendar.svg';
import { ReactComponent as CalendarIllustrationIcon } from '../../../resources/legacyIcons/CalendarIllustration.svg';
import { ReactComponent as CalendarLargeIcon } from '../../../resources/legacyIcons/CalendarLarge.svg';
import { ReactComponent as CalenderNewIcon } from '../../../resources/legacyIcons/CalenderNew.svg';
import { ReactComponent as CheckboxEmptyIcon } from '../../../resources/legacyIcons/CheckboxEmpty.svg';
import { ReactComponent as CheckboxFilledIcon } from '../../../resources/legacyIcons/CheckboxFilled.svg';
import { ReactComponent as CheckmarkIcon } from '../../../resources/legacyIcons/Checkmark.svg';
import { ReactComponent as CheckmarkInCircleIcon } from '../../../resources/legacyIcons/CheckmarkInCircle.svg';
import { ReactComponent as CheckmarkInCircleFilledIcon } from '../../../resources/legacyIcons/CheckmarkInCircleFilled.svg';
import { ReactComponent as ChevronDownIcon } from '../../../resources/legacyIcons/ChevronDown.svg';
import { ReactComponent as ChevronDownFilledIcon } from '../../../resources/legacyIcons/ChevronDownFilled.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../resources/legacyIcons/ChevronLeft.svg';
import { ReactComponent as ChevronLeftFilledIcon } from '../../../resources/legacyIcons/ChevronLeftFilled.svg';
import { ReactComponent as ChevronRightIcon } from '../../../resources/legacyIcons/ChevronRight.svg';
import { ReactComponent as ChevronRightFilledIcon } from '../../../resources/legacyIcons/ChevronRightFilled.svg';
import { ReactComponent as ChevronUpIcon } from '../../../resources/legacyIcons/ChevronUp.svg';
import { ReactComponent as ChevronUpFilledIcon } from '../../../resources/legacyIcons/ChevronUpFilled.svg';
import { ReactComponent as ClockIcon } from '../../../resources/legacyIcons/Clock.svg';
import { ReactComponent as ClockLargeIcon } from '../../../resources/legacyIcons/ClockLarge.svg';
import { ReactComponent as CloseIcon } from '../../../resources/legacyIcons/Close.svg';
import { ReactComponent as CloseCircleIcon } from '../../../resources/legacyIcons/CloseCircle.svg';
import { ReactComponent as CloseCircleFillIcon } from '../../../resources/legacyIcons/CloseCircleFill.svg';
import { ReactComponent as DeliveryIcon } from '../../../resources/legacyIcons/Delivery.svg';
import { ReactComponent as EntertainmentTicketsAsset } from '../../../resources/legacyIcons/EntertainmentTickets.svg';
import { ReactComponent as FootballIcon } from '../../../resources/legacyIcons/Football.svg';
import { ReactComponent as InfoIcon } from '../../../resources/legacyIcons/Info.svg';
import { ReactComponent as InfoFilledIcon } from '../../../resources/legacyIcons/InfoFilled.svg';
import { ReactComponent as LocationMarkerIcon } from '../../../resources/legacyIcons/LocationMarker.svg';
import { ReactComponent as LocationMarkerSmallIcon } from '../../../resources/legacyIcons/LocationMarkerSmall.svg';
import { ReactComponent as LocationPinIcon } from '../../../resources/legacyIcons/LocationPin.svg';
import { ReactComponent as LocationPinFilledIcon } from '../../../resources/legacyIcons/LocationPinFilled.svg';
import { ReactComponent as MailIcon } from '../../../resources/legacyIcons/Mail.svg';
import { ReactComponent as MasterCardIcon } from '../../../resources/legacyIcons/MasterCard.svg';
import { ReactComponent as MenuIcon } from '../../../resources/legacyIcons/Menu.svg';
import { ReactComponent as MinusIcon } from '../../../resources/legacyIcons/Minus.svg';
import { ReactComponent as MobileIcon } from '../../../resources/legacyIcons/Mobile.svg';
import { ReactComponent as MobileScanIcon } from '../../../resources/legacyIcons/MobileScan.svg';
import { ReactComponent as MyLocationIcon } from '../../../resources/legacyIcons/MyLocation.svg';
import { ReactComponent as PlusIcon } from '../../../resources/legacyIcons/Plus.svg';
import { ReactComponent as PrinterIcon } from '../../../resources/legacyIcons/Printer.svg';
import { ReactComponent as ProfileIcon } from '../../../resources/legacyIcons/Profile.svg';
import { ReactComponent as RadioEmptyIcon } from '../../../resources/legacyIcons/RadioEmpty.svg';
import { ReactComponent as RadioFilledIcon } from '../../../resources/legacyIcons/RadioFilled.svg';
import { ReactComponent as RedeemRewardsAsset } from '../../../resources/legacyIcons/RedeemRewards.svg';
import { ReactComponent as SearchIcon } from '../../../resources/legacyIcons/Search.svg';
import { ReactComponent as SearchSmallIcon } from '../../../resources/legacyIcons/SearchSmall.svg';
import { ReactComponent as SettingsIcon } from '../../../resources/legacyIcons/Settings.svg';
import { ReactComponent as ShirtIcon } from '../../../resources/legacyIcons/Shirt.svg';
import { ReactComponent as Step1Asset } from '../../../resources/legacyIcons/Step1.svg';
import { ReactComponent as Step2Asset } from '../../../resources/legacyIcons/Step2.svg';
import { ReactComponent as Step3Asset } from '../../../resources/legacyIcons/Step3.svg';
import { ReactComponent as ThumbtackIcon } from '../../../resources/legacyIcons/Thumbtack.svg';
import { ReactComponent as TicketIcon } from '../../../resources/legacyIcons/Ticket.svg';
import { ReactComponent as TicketBigIcon } from '../../../resources/legacyIcons/TicketBig.svg';
import { ReactComponent as TicketCutIcon } from '../../../resources/legacyIcons/TicketCut.svg';
import { ReactComponent as TicketWithLinesIcon } from '../../../resources/legacyIcons/TicketWithLines.svg';
import { ReactComponent as VectorAsset } from '../../../resources/legacyIcons/Vector.svg';
import { ReactComponent as VisaIcon } from '../../../resources/legacyIcons/Visa.svg';
import { ReactComponent as WarningIcon } from '../../../resources/legacyIcons/Warning.svg';
import { ReactComponent as WarningOutlineIcon } from '../../../resources/legacyIcons/WarningOutline.svg';

const Icon: React.FC<IconCombinedProps> = (props) => {
  const {
    asset,
    style,
    className,
  } = usePresenter(props);

  const variantName = `${asset}${style}`;
  const currentStyle = styles[`icon${variantName}`];

  let componentView: JSX.Element;

  switch (variantName) {
    case 'ArenaBaseDark':
    case 'ArenaWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArenaIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ArrowLeftActionBase':
    case 'ArrowLeftBaseDark':
    case 'ArrowLeftWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowLeftIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ArrowRightFilledActionBase':
    case 'ArrowRightFilledActionHover':
    case 'ArrowRightFilledWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowRightFilledIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ArrowRightActionBase':
    case 'ArrowRightWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowRightIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CalendarLargeDigitalGrey30': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CalendarLargeIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CalendarActionActive': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CalendarIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CalenderNewSubduedDark': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CalenderNewIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CheckboxFilledDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckboxFilledIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CheckmarkInCircleFilledGreen60': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckmarkInCircleFilledIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CheckmarkInCircleDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckmarkInCircleIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CheckmarkActionActive':
    case 'CheckmarkActionBase':
    case 'CheckmarkDigitalGrey80':
    case 'CheckmarkWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckmarkIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ChevronDownFilledDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronDownFilledIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ChevronDownActionActive':
    case 'ChevronDownActionHover':
    case 'ChevronDownDigitalGrey80':
    case 'ChevronDownWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronDownIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ChevronLeftFilledDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronLeftFilledIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ChevronLeftActionActive':
    case 'ChevronLeftActionBase':
    case 'ChevronLeftActionHover':
    case 'ChevronLeftDigitalGrey100':
    case 'ChevronLeftWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronLeftIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ChevronRightFilledDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronRightFilledIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ChevronRightActionActive':
    case 'ChevronRightActionHover': // MANUAL OVERRIDE
    case 'ChevronRightActionBase':
    case 'ChevronRightDigitalGrey100':
    case 'ChevronRightWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronRightIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ChevronUpFilledDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronUpFilledIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ChevronUpActionActive':
    case 'ChevronUpActionHover':
    case 'ChevronUpDigitalGrey80':
    case 'ChevronUpWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronUpIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ClockLargeDigitalGrey60': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ClockLargeIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ClockWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ClockIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ClockBaseDark': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ClockIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CloseCircleFillDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CloseCircleFillIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CloseCircleWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CloseCircleIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CloseActionBase':
    case 'CloseDigitalGrey80':
    case 'CloseWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CloseIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'DeliveryDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <DeliveryIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'FootballDigitalGrey30': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <FootballIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'InfoDigitalGrey80':
    case 'InfoWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <InfoIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'LocationMarkerSmallWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LocationMarkerSmallIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'LocationMarkerActionActive': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LocationMarkerIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'LocationPinFilledSubduedDark': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LocationPinFilledIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'LocationPinActionBase':
    case 'LocationPinDigitalGrey100':
    case 'LocationPinWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LocationPinIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'MailDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MailIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'MenuWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MenuIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'MobileScanDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MobileScanIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'MobileDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MobileIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'MyLocationActionBase':
    case 'MyLocationDigitalGrey100':
    case 'MyLocationWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MyLocationIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'PlusActionActive':
    case 'PlusActionBase':
    case 'PlusDigitalGrey60': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PlusIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'PrinterActionActive':
    case 'PrinterActionBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PrinterIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ProfileDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ProfileIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'RadioFilledDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <RadioFilledIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'SearchSmallCharcoal20':
    case 'SearchSmallWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SearchSmallIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'SearchWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SearchIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'SearchBaseDark': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SearchIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'SettingsDigitalGrey60': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SettingsIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ShirtBaseDark':
    case 'ShirtWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ShirtIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'TicketBigDigitalGrey30': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TicketBigIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'TicketCutDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TicketCutIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'TicketDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TicketIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'TicketWithLinesDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TicketWithLinesIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'WarningOutlineDigitalGrey60': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <WarningOutlineIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'WarningDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <WarningIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CalendarIllustrationDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CalendarIllustrationIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CheckboxEmptyDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckboxEmptyIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'RadioEmptyDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <RadioEmptyIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CloseCircleActionHover':
    case 'CloseCircleDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CloseCircleIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'InfoFilledDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <InfoFilledIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'MasterCardDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MasterCardIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'VisaDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <VisaIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'MinusActionBase': // MANUAL OVERRIDE
    case 'MinusDigitalGrey60': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MinusIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ThumbtackDigitalGrey30': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ThumbtackIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    // MANUAL OVERRIDE STARTS
    case 'EntertainmentTicketsDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <EntertainmentTicketsAsset style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'RedeemRewardsDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <RedeemRewardsAsset style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'VectorDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <VectorAsset style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'Step1DigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Step1Asset style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'Step2DigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Step2Asset style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'Step3DigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Step3Asset style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    // MANUAL OVERRIDE ENDS
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Icon;
