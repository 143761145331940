import type { IconStyleEnum } from '../Icon/types';
import type { TextColourEnum } from '../Text/types';
import type { FilterTheme } from './BaseFilter.types';

/**
 * Calculates display value of the filter state.
 * @template TFilterState Type of the filter state.
 * @returns {string} Display value of the filter state.
 */
// eslint-disable-next-line @typescript-eslint/comma-dangle
export const calculateDisplayValue = <TFilterState,>(params: {
  /** Current state of the filter component. */
  filterState: TFilterState | undefined;
  /** Placeholder text for the filter component which will be displayed when filter state is undefined. */
  placeholder: string;
  /** Function to get the display value of the filter state. */
  getDisplayValue: (filterState: TFilterState) => string;
}): string => {
  const { filterState, placeholder, getDisplayValue } = params;

  return filterState
    ? getDisplayValue(filterState)
    : placeholder; // If filterState is undefined, return the placeholder
};

/**
 * Calculates text colour of the display value based on theme, state, and other parameters.
 * @template TFilterState Type of the filter state.
 * @returns {TextColourEnum} Text colour of the display value.
 */
// eslint-disable-next-line @typescript-eslint/comma-dangle
export const calculateDisplayValueColour = <TFilterState,>(params: {
  /** The theme of the filter, either 'light' or 'dark'. */
  theme: FilterTheme;
  /** A flag indicating if the filter is disabled. */
  isDisabled: boolean;
  /** Current state of the filter component. */
  filterState: TFilterState | undefined;
  /** A flag indicating if the filter is expanded. */
  isExpanded: boolean;
}): TextColourEnum => {
  const { theme, isDisabled, isExpanded, filterState } = params;

  // Display value is always white for dark theme
  if (theme === 'dark') {
    return 'BaseLight';
  }

  // For light theme display value is blue when filter has a selected value or it is expanded
  return !isDisabled && (filterState || isExpanded) ? 'ActionBase' : 'SubduedDark';
};

/**
 * Calculates filter icon style based on theme, state, and other parameters.
 * @template TFilterState Type of the filter state.
 * @returns {IconStyleEnum} Filter icon style.
 */
// eslint-disable-next-line @typescript-eslint/comma-dangle
export const calculateFilterIconStyle = <TFilterState,>(params: {
  /** The theme of the filter, either 'light' or 'dark'. */
  theme: FilterTheme;
  /** A flag indicating if the filter is disabled. */
  isDisabled: boolean;
  /** Current state of the filter component. */
  filterState: TFilterState | undefined;
  /** A flag indicating if the filter is expanded. */
  isExpanded: boolean;
}): IconStyleEnum => {
  const { theme, isDisabled, filterState, isExpanded } = params;

  // Icon is always white for dark theme
  if (theme === 'dark') {
    return 'White';
  }

  // For light theme the icon is blue when filter has a selected value or it is expanded
  return !isDisabled && (filterState || isExpanded) ? 'ActionBase' : 'SubduedDark';
};
