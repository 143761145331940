import type { IconAssetEnum, IconStyleEnum } from '../../atoms/Icon';
import type { TextColourEnum } from '../../atoms/Text';
import type { ExclusiveBadgeTheme } from '../../molecules/ExclusiveBadge';
import type { EventInfoHeaderTheme } from './EventInfoHeader.types';

export const PrimaryColoursByTheme = {
  light: 'SubduedDark',
  dark: 'BaseLight',
} as const satisfies Record<EventInfoHeaderTheme, TextColourEnum>;

export const SecondaryColoursByTheme = {
  light: 'SubduedLight',
  dark: 'BaseLight',
} as const satisfies Record<EventInfoHeaderTheme, TextColourEnum>;

export const BackButtonIconStylesByTheme = {
  light: 'SubduedDark',
  dark: 'White',
} as const satisfies Record<EventInfoHeaderTheme, IconStyleEnum>;

export const CardholderExclusiveBadgeThemesByTheme = {
  light: 'dark',
  dark: 'light',
} as const satisfies Record<EventInfoHeaderTheme, ExclusiveBadgeTheme>;

export const InfoButtonAssetsByTheme = {
  light: 'InfoFilled',
  dark: 'Info',
} as const satisfies Record<EventInfoHeaderTheme, IconAssetEnum>;

export const InfoButtonStylesByTheme = {
  light: 'ActionBase',
  dark: 'White',
} as const satisfies Record<EventInfoHeaderTheme, IconStyleEnum>;
