import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { END_DATE_PARAM, START_DATE_PARAM } from '../../../lib/constants';
import { addQueryParam, formatDateToApiDate } from '../../../lib/util';
import type { ButtonCombinedProps } from '../../atoms/Button/types';
import type { DropdownMenuItemCombinedProps } from '../../atoms/DropdownMenuItem/types';
import type { TextCombinedProps } from '../../atoms/Text/types';
import type { DropdownOption, FilterCombinedProps, SelectedOption } from './types';
import { formatStartEndDateOption } from './utils';

const usePresenter = (props: FilterCombinedProps): FilterCombinedProps => {
  const {
    filterType,
    filterTitle,
    filterQueryParamName,
    options,
    defaultFilterOption,
    selectedFilterOption,
    onFilterTextChanged: propsOnFilterTextChanged,
    filterIcon,
    type,
  } = props;

  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  /** Filter value from query parameters */
  const filterQueryParam: string | null = filterQueryParamName ? query.get(filterQueryParamName) : null;

  /** Start date filter value from query parameters */
  const startDateParam: string | null = query.get(START_DATE_PARAM);

  /** End date filter value from query parameters */
  const endDateParam: string | null = query.get(END_DATE_PARAM);

  const { t } = useTranslation();

  const [showDropdown, setShowDropdown] = useState(false);

  const [selectedOption, setSelectedOption] = useState<SelectedOption | undefined>(selectedFilterOption || defaultFilterOption);

  const [selectedDateOption, setSelectedDateOption] = useState<SelectedOption | undefined>();

  const [filterText, setFilterText] = useState<string>('');
  const isSearchInputEnabled: boolean = useMemo(() => filterType === 'SearchLocationFilter', [filterType]);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const setDateFilterOptionFromUrl = useCallback(() => {
    if (options) {
      let currentOption: DropdownOption | undefined;

      if (startDateParam && endDateParam) {
        currentOption = options.find((option) => {
          if (option.value?.startDate && option.value.endDate) {
            return (
              formatDateToApiDate(option.value.startDate) === startDateParam &&
              formatDateToApiDate(option.value.endDate) === endDateParam
            );
          }
          return false;
        });

        if (!currentOption) {
          const startDate = new Date(startDateParam.replace(/-/g, '/')); //https://stackoverflow.com/questions/4310953/invalid-date-in-safari
          const endDate = new Date(endDateParam.replace(/-/g, '/')); //https://stackoverflow.com/questions/4310953/invalid-date-in-safari
          currentOption = {
            type: 'SelectValue',
            title: formatStartEndDateOption(startDate, endDate),
            value: {
              startDate,
              endDate,
            },
          };

          setSelectedDateOption(currentOption);
        }
      }

      setSelectedOption(currentOption || defaultFilterOption);
    }
  }, [options, startDateParam, endDateParam, defaultFilterOption]);

  useEffect(() => {
    if (options) {
      if (filterType === 'DateFilter') {
        setDateFilterOptionFromUrl();
      } else {
        let currentOption: DropdownOption | undefined;

        if (selectedFilterOption) {
          currentOption = selectedFilterOption;
        } else {
          currentOption = options.find((option) => option.value?.value === filterQueryParam);
        }

        setSelectedOption(currentOption || defaultFilterOption);
      }
    }
  }, [options, filterType, filterQueryParam, selectedFilterOption, defaultFilterOption, setDateFilterOptionFromUrl]);

  const updateSelectedOption = (option: SelectedOption) => {
    setSelectedOption(option);
    setShowDropdown(false);

    if (option.value) {
      const { value, startDate, endDate } = option.value;

      let params: string | undefined;

      if (filterType === 'DateFilter') {
        params = addQueryParam(search, {
          [START_DATE_PARAM]: startDate ? formatDateToApiDate(startDate) : '',
          [END_DATE_PARAM]: endDate ? formatDateToApiDate(endDate) : '',
        });
      } else if (filterQueryParamName) {
        params = addQueryParam(search, {
          [filterQueryParamName]: value || '',
        });
      }

      if (params) {
        navigate({ search: params });
      }
    }
  };

  const handleUpdateDateRange = (dates: Date[]) => {
    const [startDate, endDate] = dates;

    if (startDate && endDate) {
      const newOption: SelectedOption = {
        title: formatStartEndDateOption(startDate, endDate),
        value: {
          startDate,
          endDate,
        },
      };

      setSelectedDateOption(newOption);
      updateSelectedOption(newOption);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      // Auto focus search input
      if (isSearchInputEnabled && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    } else {
      // Always clear search query when filter search field gets hidden
      setFilterText('');
      propsOnFilterTextChanged?.('');
    }
  }, [showDropdown, isSearchInputEnabled, propsOnFilterTextChanged]);

  const handleToggle = (isOpen: boolean) => {
    setShowDropdown(isOpen);

    // For date filter if currently selected option is either Choose Date or a date picker
    // Then revert to previously selected option
    if (!isOpen && filterType === 'DateFilter' && (selectedOption?.title === t('dateFilters.chooseDate') || selectedOption?.showCalendar)) {
      setDateFilterOptionFromUrl();
    }
  };

  const dropdownMenuItems: DropdownMenuItemCombinedProps[] = [];
  if (options) {
    const combinedOptions = [...options];

    if (selectedDateOption) {
      combinedOptions.push({
        type: 'SelectValue',
        title: selectedDateOption.title,
        value: selectedDateOption.value,
      });
    }

    combinedOptions.forEach((option) => {
      // Don't render the SearchInput
      if (option.type === 'SearchInput') {
        return;
      }

      const isOptionSelected: boolean = option.title === selectedOption?.title;

      dropdownMenuItems.push({
        type: isOptionSelected ? 'IconText' : 'Text',
        text: {
          value: option.dropdownTitle || option.title,
          colour: isOptionSelected ? 'ActionHover' : 'BaseDark',
        },
        ariaLabel: isOptionSelected
          ? t('shopByWidget.selectedOptionAnnouncement', { option: option.title })
          : '',
        onDropdownMenuItemClicked: () => {
          if (option.type === 'PickDate') {
            setSelectedOption({
              title: option.title,
              showCalendar: true,
            });
          } else if (option.type === 'SearchResult') {
            updateSelectedOption({
              title: option.title,
              value: option.value,
            });
          } else {
            if (
              startDateParam &&
              endDateParam &&
              filterType === 'DateFilter' &&
              option.value?.startDate &&
              option.value.endDate
            ) {
              if (
                startDateParam !== formatDateToApiDate(option.value.startDate) &&
                endDateParam !== formatDateToApiDate(option.value.endDate)
              ) {
                setSelectedDateOption(undefined);
              }
            } else {
              setSelectedDateOption(undefined);
            }

            updateSelectedOption({
              title: option.title,
              value: option.value,
            });
          }
        },
      });
    });
  }

  /** Text change handler to update filter text for filter search field */
  const onFilterTextChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const newFilterText: string = event.target.value;
    setFilterText(newFilterText);
    propsOnFilterTextChanged?.(newFilterText);
  };

  const isNonDefaultOption: boolean = selectedOption?.title !== defaultFilterOption?.title;

  const dropdownButton: ButtonCombinedProps | undefined = !isNonDefaultOption
    ? {
      type: 'Icon',
      style: 'Contained',
      size: 'Small',
      icon: {
        asset: 'ChevronDown',
        style: type !== 'TextIconFilter' ? 'ActionActive' : 'DigitalGrey80',
      },
      onClick: () => setShowDropdown(true),
    }
    : undefined;

  const closeButton: ButtonCombinedProps | undefined = isNonDefaultOption
    ? {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
      icon: {
        asset: 'CloseCircle',
        style: type !== 'TextIconFilter' ? 'ActionHover' : 'DigitalGrey100',
      },
      onClick: () => {
        updateSelectedOption({
          title: defaultFilterOption?.title || '',
          value: defaultFilterOption?.value,
          showCalendar: false,
        });
      },
    }
    : undefined;

  // ?? TextIconFilter are using different variants which is not what figma shows
  const textProps: TextCombinedProps =
    type !== 'TextIconFilter'
      ? {
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        colour: 'ActionHover',
        align: 'Left',
      }
      : {
        type: 'Subheading',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedDark',
        align: 'Left',
      };

  const buttonTextProps: TextCombinedProps =
    type !== 'TextIconFilter'
      ? {
        type: 'Decoration',
        size: 'Medium',
        style: 'Regular',
        colour: 'ActionHover',
        align: 'Left',
      }
      : {
        type: 'Decoration',
        size: 'Large',
        style: 'Regular',
        colour: 'SubduedDark',
        align: 'Left',
      };

  return {
    ...props,
    icon: {
      asset: filterIcon,
    },
    showDropdown,
    showCalendar: selectedOption?.showCalendar,
    isSearchInputEnabled,
    text: {
      ...textProps,
      value: filterTitle,
    },
    searchInput: {
      state: 'Filled',
      style: 'Default',
      size: 'Small',
      textPlaceholder: t('search.searchLocations'),
      textValue: filterText,
      onTextChanged: onFilterTextChanged,
      onClick: (event?: React.MouseEvent<HTMLElement>) => {
        // Stop event propagation so that dropdown menu remains open
        event?.stopPropagation();
      },
      inputRef: searchInputRef,
    },
    filterButton: {
      text: {
        ...buttonTextProps,
        value: selectedOption?.title,
      },
      onClick: () => setShowDropdown(true),
    },
    dropdownButton,
    closeButton,
    dropdownSelectList: {
      dropdownMenuItems,
    },
    handleToggle,
    setRange: handleUpdateDateRange,
  };
};

export default usePresenter;
