import type { Dispatch, SetStateAction } from 'react';
import type { UseMutateAsyncFunction } from 'react-query/types/react/types';
import { areObjectsEqual } from '../../../lib/objectUtils';
import { trackErrorEvent, type EventKeys } from '../../../modules/analytics';
import { ApiError } from '../../../modules/error/types';
import type { PageQueryParams, PageResponse } from '../../../modules/partnership';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from './PaginatedList.constants';
import type { PageState } from './PaginatedList.types';

/**
 * If static API parameters have changed then this function will:
 * - Update static API parameters
 * - Reset page index to 1
 * - Clear all items that have been fetched so far
 */
export const updateStaticApiParams = <TApiParams extends PageQueryParams, TItem extends { id: number; }>(params: {
  /** New static API parameters */
  newStaticApiParams: TApiParams;
  /** Current static API parameters */
  staticApiParams: TApiParams | undefined;
  /** Function to update current static API parameters */
  setStaticApiParams: Dispatch<SetStateAction<TApiParams | undefined>>;
  /** Function to update current page state */
  setPageState: Dispatch<SetStateAction<PageState>>;
  /** Function to update items that have been fetched so far */
  setItems: Dispatch<SetStateAction<TItem[]>>;
}): void => {
  const { newStaticApiParams, staticApiParams, setStaticApiParams, setPageState, setItems } = params;

  // Check if static API parameters have changed, ignoring the page property
  const areStaticApiParamsChanged: boolean = !areObjectsEqual(newStaticApiParams, staticApiParams, { properties: { page: 'ignore' } });

  // Proceed only if there is an actual change
  if (areStaticApiParamsChanged) {
    // Update static API parameters
    setStaticApiParams(newStaticApiParams);

    // Reset page index and trigger fetch
    setPageState({
      page: DEFAULT_PAGE,
      shouldFetchPage: true,
    });

    // Clear all items
    setItems([]);
  }
};

/** Fetches items from the backend and stores API response in the local state */
export const fetchItemsFromApi = async <TApiParams extends PageQueryParams, TItem extends { id: number; }>(params: {
  /** Current static API parameters */
  staticApiParams: TApiParams;
  /** Current page state */
  pageState: PageState;
  /** All items that have been fetched so far */
  items: TItem[];
  /** Optional function to track analytics whenever API fetch request is made */
  trackFetchAnalytics?: (apiParams: TApiParams) => void;
  /** API fetch function to be used to fetch paginated data from API */
  fetchItems: UseMutateAsyncFunction<PageResponse<TItem>, unknown, TApiParams, unknown>;
  /** Optional function to filter data whenever API fetch request is made */
  filterItemsFunc?: (items: TItem[]) => TItem[];
  /** Function to set the last fetch response */
  setLastFetchResponse: Dispatch<SetStateAction<PageResponse<TItem> | undefined>>;
  /** Function to update current page state */
  setPageState: Dispatch<SetStateAction<PageState>>;
  /** Function to track analytics events */
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void;
}): Promise<void> => {
  const { staticApiParams, pageState, items, trackFetchAnalytics, fetchItems, filterItemsFunc, setLastFetchResponse, setPageState, trackEvent } = params;

  try {
    // Construct API parameters
    const apiParams: TApiParams = {
      ...staticApiParams,
      page: pageState.page,
      per_page: staticApiParams.per_page || DEFAULT_PAGE_SIZE,
      ...(items.length ? { last_fetched_item_id: items[items.length - 1].id } : {}),
    };

    // Optionally track fetch analytics
    trackFetchAnalytics?.(apiParams);

    const fetchResponse: PageResponse<TItem> = await fetchItems(apiParams);

    // Filter items if a filter function is provided
    if (filterItemsFunc) {
      fetchResponse.items = filterItemsFunc(fetchResponse.items);
    }

    // Update last fetch response
    setLastFetchResponse(fetchResponse);

    // Update page state to indicate fetch completion
    setPageState((prevPageState: PageState) => ({
      page: prevPageState.page,
      shouldFetchPage: false,
    }));
  } catch (error) {
    // Track error event if an API error occurs
    if (error && ApiError.isApiError(error)) {
      trackErrorEvent(
        trackEvent,
        error.code,
        error.message,
      );
    }
  }
};

/** Updates page index (if changed), items and itemsTotal whenever we receive an API response */
export const updatePageStateFromResponse = <TItem>(params: {
  /** Response from the last API fetch request */
  lastFetchResponse: PageResponse<TItem>;
  /** Function to update current page state */
  setPageState: Dispatch<SetStateAction<PageState>>;
  /** Function to update items that have been fetched so far */
  setItems: Dispatch<SetStateAction<TItem[]>>;
  /** Function to update the total number of items */
  setItemsTotal: Dispatch<SetStateAction<number>>;
}): void => {
  const { lastFetchResponse, setPageState, setItems, setItemsTotal } = params;

  // Update page index if API response returns a different page index.
  // This may happen when we apply filtering on the BFF and BFF needs to fetch more pages from API to get the required number of items.
  setPageState((prevPageState: PageState) => {
    if (prevPageState.page !== lastFetchResponse.page) {
      return {
        page: lastFetchResponse.page,
        // Do not trigger a refetch by setting shouldFetchPage to false
        shouldFetchPage: false,
      };
    }
    return prevPageState;
  });

  // Merge new items with the existing items
  if (lastFetchResponse.items.length) {
    setItems((prevItems: TItem[]) => [...prevItems, ...lastFetchResponse.items]);
  }

  // Set the total number of items on the backend
  setItemsTotal(lastFetchResponse.total);
};
