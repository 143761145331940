import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { MapTooltip } from '../../components/molecules/MapTooltip';
import { SvgMapStyle } from '../../components/molecules/SvgMapStyle';
import { filterListingsBySectionHtmlId, getListingMinPricesBySection, getListingSectionHtmlIdMap } from '../../lib/listingUtils';
import type { ListingMinPricesBySection } from '../../lib/types';
import { isNumber } from '../../lib/util';
import { useAnalyticsManager } from '../analytics';
import type { Listing, SvgMapData } from '../partnership';
import { getSvgMapData } from '../partnership/api';
import { DEFAULT_MAP_CONTEXT_VALUE, SVG_MAP_ID, SVG_MAP_SECTION_STYLES } from './MapContext.constants';
import type { MapContextValue, MapProviderProps, MinPriceTooltipData, SelectedSection, SelectionMode, TooltipPosition } from './MapContext.types';
import { getCssSelectorForSections, getTooltipPositionFromDimensions, handleSetSelectedSection } from './mapUtils';

export const MapContext = createContext<MapContextValue>(DEFAULT_MAP_CONTEXT_VALUE);

export const MapProvider: React.FC<MapProviderProps> = (props) => {
  const {
    svgMapJsonUrl,
    staticImageMapUrl,
    filteredListings = [],
    unfilteredListings = [],
    children,
  } = props;

  const { onError } = useAnalyticsManager();

  /** True only if SVG map JSON URL is defined in listings response */
  const isSvgMapSupported: boolean = !!svgMapJsonUrl;

  const {
    data: svgMapData,
    isLoading: isSvgMapDataLoading,
    isFetching: isSvgMapDataFetching,
    error: svgMapDataError,
  } = useQuery<SvgMapData>(
    ['getSvgMapData', svgMapJsonUrl],
    () => getSvgMapData({ svgMapJsonUrl: svgMapJsonUrl! }),
    { enabled: isSvgMapSupported, onError, retry: false, refetchOnMount: false },
  );

  /** Object with HTML Ids of all available sections that have at least one listing with a price. This is used for hovering/selection functionality on SVG map. */
  const availableSectionHtmlIdMap: Record<string, true> = useMemo(
    () => getListingSectionHtmlIdMap({ listings: filteredListings }),
    [filteredListings],
  );

  /**
   * All listing min prices per section as an object where key is section HTML Id and value is min price for the section.
   * This is used to display tooltips with min prices when user hovers over SVG map.
   */
  const listingMinPricesBySection: ListingMinPricesBySection = useMemo(
    () => getListingMinPricesBySection({ listings: unfilteredListings }),
    [unfilteredListings],
  );

  // HTML Id of the hovered section, e.g. id-123
  const [hoveredSectionHtmlId, setHoveredSectionHtmlId] = useState<string | undefined>(undefined);

  // Tooltip data with min price and position
  const [minPriceTooltipData, setMinPriceTooltipData] = useState<MinPriceTooltipData | undefined>(undefined);

  /**
   * Function that does the following:
   * - Updates hovered section HTML Id
   * - Updates tooltip data with min price and position
   *
   * params.element could be one of the following:
   * - SVG element that user is hovering over
   * - Or section HTML Id, e.g. id-123
   */
  const setHoveredSection = useCallback((params: { element: SVGSVGElement | string; } | undefined) => {
    // Do not do anything if SVG map is not supported
    if (!isSvgMapSupported) {
      return;
    }

    if (!params) {
      setHoveredSectionHtmlId(undefined);
      setMinPriceTooltipData(undefined);
      return;
    }

    const newHoveredSectionHtmlId: string | undefined = typeof params.element === 'string'
      ? params.element
      : params.element.id;

    // Update hovered section HTML Id
    // Ensure we update hovered section HTML Id only if it is one of available sections that have at least one listing with a price
    if (!newHoveredSectionHtmlId || !availableSectionHtmlIdMap[newHoveredSectionHtmlId]) {
      setHoveredSectionHtmlId(undefined);
    } else {
      setHoveredSectionHtmlId(newHoveredSectionHtmlId);
    }

    // Update tooltip data with min price and position
    // Ensure we update tooltip data only if we have a min price for the hovered section
    if (!newHoveredSectionHtmlId || !isNumber(listingMinPricesBySection[newHoveredSectionHtmlId])) {
      setMinPriceTooltipData(undefined);
    } else {
      const svgElement: SVGSVGElement | null = typeof params.element === 'string'
        ? document.querySelector(`#${SVG_MAP_ID} path#${newHoveredSectionHtmlId}`)
        : params.element;

      if (svgElement) {
        const { x, y, width, height }: DOMRect = svgElement.getBoundingClientRect();
        const tooltipPosition: TooltipPosition = getTooltipPositionFromDimensions({ x, y, width, height });

        setMinPriceTooltipData({
          minPrice: listingMinPricesBySection[newHoveredSectionHtmlId],
          tooltipPosition,
        });
      }
    }
  }, [isSvgMapSupported, availableSectionHtmlIdMap, listingMinPricesBySection]);

  // HTML Id of the selected section, e.g. id-123, and selection mode, e.g. 'with-listing-filtering-by-section' or 'without-listing-filtering-by-section'
  const [selectedSection, internalSetSelectedSection] = useState<SelectedSection | undefined>(undefined);

  /**
   * Function that does the following:
   * - Updates selected section HTML Id
   * - Optionally filters listings by selected section
   *
   * params.element could be one of the following:
   * - SVG element that user is hovering over
   * - Or section HTML Id, e.g. id-123
   *
   * params.selectionMode could be one of the following:
   * - 'with-listing-filtering-by-section' - Listings will be filtered by selected section. Used for selections directly from SVG map.
   * - 'without-listing-filtering-by-section' - Listings will not be filtered by selected section. Used for hovering over listing cards.
   *
   * If 'reset-temporary-selection' value is provided then selected section will be reset only if it was selected by hovering over a listing card.
  */
  const setSelectedSection = useCallback((params: { element: SVGSVGElement | string; selectionMode: SelectionMode; } | 'reset-temporary-selection' | undefined) => handleSetSelectedSection(isSvgMapSupported, params, internalSetSelectedSection, availableSectionHtmlIdMap), [isSvgMapSupported, availableSectionHtmlIdMap]);

  // Reset selected section when it is not available.
  // This may happen when section is selected and then user applies filters and the selected section has no listings after that.
  useEffect(() => {
    if (selectedSection?.sectionHtmlId && !availableSectionHtmlIdMap[selectedSection.sectionHtmlId]) {
      internalSetSelectedSection(undefined);
    }
  }, [selectedSection?.sectionHtmlId, availableSectionHtmlIdMap]);

  /** Array of listings to display, e.g. when user selects a section on SVG map then they should see listings only for that section. */
  const listingsToDisplay: readonly Listing[] = useMemo(
    () => selectedSection?.selectionMode === 'with-listing-filtering-by-section'
      ? filterListingsBySectionHtmlId({ listings: filteredListings, sectionHtmlId: selectedSection.sectionHtmlId })
      : filteredListings,
    [filteredListings, selectedSection],
  );

  /** CSS selector for all available sections, e.g. #svg-map path#id-123, #svg-map path#id-234 */
  const cssSelectorForAvailableSections: string | undefined = useMemo(
    () => getCssSelectorForSections({ sectionHtmlIds: Object.keys(availableSectionHtmlIdMap) }),
    [availableSectionHtmlIdMap],
  );

  /** CSS selector for hovered section, e.g. #svg-map path#id-123 */
  const cssSelectorForHoveredSection: string | undefined = useMemo(
    () => getCssSelectorForSections({ sectionHtmlIds: [hoveredSectionHtmlId] }),
    [hoveredSectionHtmlId],
  );

  /** CSS selector for selected section, e.g. #svg-map path#id-123 */
  const cssSelectorForSelectedSection: string | undefined = useMemo(
    () => getCssSelectorForSections({ sectionHtmlIds: [selectedSection?.sectionHtmlId] }),
    [selectedSection?.sectionHtmlId],
  );

  const value: MapContextValue = useMemo(
    () => ({
      isSvgMapSupported,
      svgMapData,
      isSvgMapDataLoading: isSvgMapDataLoading || isSvgMapDataFetching,
      svgMapDataError: svgMapDataError as Error | undefined,
      setHoveredSection,
      setSelectedSection,
      listingsToDisplay,
      staticImageMapUrl,
    }),
    [
      isSvgMapSupported,
      svgMapData,
      isSvgMapDataLoading,
      isSvgMapDataFetching,
      svgMapDataError,
      setHoveredSection,
      setSelectedSection,
      listingsToDisplay,
      staticImageMapUrl,
    ],
  );

  return (
    <MapContext.Provider value={value}>
      {children}
      {isSvgMapSupported && !!svgMapData && (<>
        {!!cssSelectorForAvailableSections && (
          <SvgMapStyle
            styleId='availableSectionsCss'
            cssSelector={cssSelectorForAvailableSections}
            cssContentString={SVG_MAP_SECTION_STYLES.availableSection} // Teal fill colour
          />
        )}
        {!!cssSelectorForHoveredSection && (
          <SvgMapStyle
            styleId='availableSectionsCss'
            cssSelector={cssSelectorForHoveredSection}
            cssContentString={SVG_MAP_SECTION_STYLES.hoveredSection} // Pink fill colour
          />
        )}
        {!!cssSelectorForSelectedSection && (
          <SvgMapStyle
            styleId='availableSectionsCss'
            cssSelector={cssSelectorForSelectedSection}
            cssContentString={SVG_MAP_SECTION_STYLES.selectedSection} // Pink fill colour with 2px border
          />
        )}
        {/*
          * Render tooltip with min price here for performance reasons.
          *
          * Explanation:
          * Tooltip with min price should be displayed on hovering over SVG map and over listing cards. This may occur very frequently.
          * To avoid unnecessary re-rendering of MapProvider's children, 'minPriceTooltipData' is not exposed from MapContext, it stays local.
          * Please note that if a component uses React context (i.e. useContext(MapContext)), it will be re-rendered every time the context value changes, even if the component doesn't really use the updated data.
          */}
        <MapTooltip minPriceTooltipData={minPriceTooltipData} />
      </>)}
    </MapContext.Provider>
  );
};
