import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import styles from './CheckoutTotal.module.scss';
import { usePresenter } from './CheckoutTotal.presenter';
import type { CheckoutTotalProps } from './CheckoutTotal.types';

export const CheckoutTotal: React.FC<CheckoutTotalProps> = (props) => {
  const {
    listingDetailMetadata: { eventMetadata },
    formattedTotalCashAndLoyaltyPrices,
    nextStepButtonLabelKey,
    nextStepButtonColour,
    onNextStepButtonClick,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  const eventName: ReactNode = (
    <Text
      size='Medium'
      style='SemiBold'
      colour='SubduedDark'
      value={eventMetadata.eventName}
      classes={{ value: styles.eventName }}
    />
  );

  const formattedTotalCashAndLoyaltyPricesView: ReactNode = (
    <Text
      size='Medium'
      style='Regular'
      colour='SubduedLight'
      value={formattedTotalCashAndLoyaltyPrices}
    />
  );

  const nextStepButton: ReactNode = (
    <Button
      type='Text'
      style={nextStepButtonColour}
      size='Large'
      text={{ value: t(nextStepButtonLabelKey) }}
      onClick={onNextStepButtonClick}
      className={styles.nextStepButton}
    />
  );

  return (
    <div className={cx(styles.checkoutTotal, className)}>
      <div className={styles.leftContent}>
        {eventName}
        {formattedTotalCashAndLoyaltyPricesView}
      </div>
      {nextStepButton}
    </div>
  );
};
