import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { useModalState } from '../../../modules/modals';
import { getGoBackHistoryOffset } from '../../../modules/navigation/Navigation.utils';
import type { EventInfoHeaderPresenterProps, EventInfoHeaderProps } from './EventInfoHeader.types';

export const usePresenter = (props: EventInfoHeaderProps): EventInfoHeaderPresenterProps => {
  const navigate = useNavigate();

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  const {
    isModalOpen: isInfoModalShown,
    openModal: onInfoButtonClick,
    closeModal: closeInfoModal,
  } = useModalState();

  const onBackButtonClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'Button',
      t('common.goBack'),
    );

    navigate(getGoBackHistoryOffset());
  }, [t, trackEvent, navigate]);

  return {
    ...props,
    isInfoModalShown,
    onInfoButtonClick,
    closeInfoModal,
    onBackButtonClick,
  };
};
