import React from 'react';
import { EventCard, type EventCardProps } from '../EventCard';
import { MlbCard, type MlbCardProps } from '../MlbCard';
import { usePresenter } from './EventOrMlbCard.presenter';
import type { EventOrMlbCardProps } from './EventOrMlbCard.types';

export const EventOrMlbCard: React.FC<EventOrMlbCardProps> = (props) => {
  const presenterProps = usePresenter(props);

  const { renderer } = presenterProps;

  switch (renderer) {
    case 'eventCard': {
      // If renderer is 'eventCard' then props will be EventCard props
      const eventCardProps: EventCardProps = presenterProps;
      return <EventCard {...eventCardProps} />;
    }
    case 'mlbCard': {
      // If renderer is 'mlbCard' then props will be MlbCard props
      const mlbCardProps: MlbCardProps = presenterProps;
      return <MlbCard {...mlbCardProps} />;
    }
  }
};
