import React from 'react';
import * as ReactDOM from 'react-dom/client';

import App from './App';
import AppProvider from './AppProvider';
import './index.css';
import { initRiskifiedBeacon } from './lib/initRiskifiedBeacon';
import { initSentry } from './lib/initSentry';
import { initializeTracking } from './lib/trackingUtils';
import * as serviceWorker from './serviceWorker';

initializeTracking();
initSentry();
initRiskifiedBeacon();

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const root = ReactDOM.createRoot(document.getElementById('root')!);
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
