import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import type { CarouselButtonPresenterProps, CarouselButtonProps } from './CarouselButton.types';

export const usePresenter = (props: CarouselButtonProps): CarouselButtonPresenterProps => {
  const { type, onClick: initialOnClick } = props;

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  const onClick = useCallback(() => {
    initialOnClick();

    trackSelectContentEvent(
      trackEvent,
      'Slider',
      'Button',
      t(`carouselButton.${type}`),
    );
  }, [t, type, initialOnClick, trackEvent]);

  return {
    ...props,
    onClick,
  };
};
