import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import styles from './Quantity.module.scss';
import { usePresenter } from './Quantity.presenter';
import type { QuantityProps } from './Quantity.types';

export const Quantity: React.FC<QuantityProps> = (props) => {
  const {
    labelKey,
    quantity,
    formattedQuantity,
    isDecrementEnabled,
    onDecrementClick,
    decrementAriaLabelKey,
    onIncrementClick,
    incrementAriaLabelKey,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  const label: ReactNode = (
    <Text
      size='Medium'
      style='Regular'
      colour='SubduedDark'
      value={t(labelKey)}
      className={styles.text}
    />
  );

  const decrementButton: ReactNode = (
    <Button
      type='Icon'
      style='Text'
      size='Small'
      icon={{
        asset: 'Minus',
        style: 'DigitalGrey60',
        className: styles.buttonIcon,
      }}
      disabled={!isDecrementEnabled}
      ariaLabel={t(decrementAriaLabelKey)}
      onClick={onDecrementClick}
      className={styles.button}
    />
  );

  const quantityView: ReactNode = (
    <Text
      size='Medium'
      style='Regular'
      colour={quantity ? 'SubduedDark' : 'SubduedLight'}
      align='Center'
      value={formattedQuantity}
      className={cx(styles.text, styles.quantity)}
    />
  );

  const incrementButton: ReactNode = (
    <Button
      type='Icon'
      style='Text'
      size='Small'
      icon={{
        asset: 'Plus',
        style: 'DigitalGrey60',
        className: styles.buttonIcon,
      }}
      ariaLabel={t(incrementAriaLabelKey)}
      onClick={onIncrementClick}
      className={styles.button}
    />
  );

  return (
    <div className={cx(styles.quantityContainer, className)}>
      {label}
      <div className={styles.quantityWrapper}>
        {decrementButton}
        {quantityView}
        {incrementButton}
      </div>
    </div>
  );
};
