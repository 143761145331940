import type { TopNavMenuTypeEnum } from '../../../modules/topNav';
import type { IconAssetEnum } from '../../atoms/Icon';

/**
 * Determines if the Explore menu is expanded based on the active top navigation menu type.
 *
 * @param activeTopNavMenuType - The current active top navigation menu type.
 * @returns True if the Explore menu is expanded, otherwise false.
 */
export const getIsExploreMenuExpanded = (params: { activeTopNavMenuType: TopNavMenuTypeEnum | undefined; }): boolean => {
  const { activeTopNavMenuType } = params;
  return activeTopNavMenuType === 'ExploreMenu';
};

/**
 * Gets the icon asset for the Explore button based on whether the Explore menu is expanded.
 *
 * @param isExploreMenuExpanded - Whether the Explore menu is expanded.
 * @returns The icon asset for the Explore button.
 */
export const getExploreButtonIconAsset = (params: { isExploreMenuExpanded: boolean; }): IconAssetEnum => {
  const { isExploreMenuExpanded } = params;
  return isExploreMenuExpanded ? 'ChevronUp' : 'ChevronDown';
};

/**
 * Determines the active top navigation menu type based on the previous active type and whether the menu is open.
 *
 * @param prevActiveTopNavMenuType - The previous active top navigation menu type.
 * @param isOpen - Whether the menu is open.
 * @returns The active top navigation menu type.
 */
export const getActiveTopNavMenuType = (params: {
  prevActiveTopNavMenuType: TopNavMenuTypeEnum | undefined;
  isOpen: boolean;
}) => {
  const { prevActiveTopNavMenuType, isOpen = false } = params;
  if (isOpen) {
    return 'ExploreMenu';
  }

  // When we close dropdown menu do not overwrite menu type if it has been changed to something else, e.g. SearchMenu
  return prevActiveTopNavMenuType && prevActiveTopNavMenuType !== 'ExploreMenu'
    ? prevActiveTopNavMenuType
    : undefined;
};
