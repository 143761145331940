import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberToLocaleString } from '../../../lib/util';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import type { QuantityPresenterProps, QuantityProps } from './Quantity.types';

export const usePresenter = (props: QuantityProps): QuantityPresenterProps => {
  const {
    quantity,
    minQuantity = 0,
    onQuantityChanged,
    decrementAriaLabelKey,
    incrementAriaLabelKey,
  } = props;

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  /** Formatted quantity as string. 'Any' if quantity is undefined. */
  const formattedQuantity: string = useMemo(
    () => quantity !== undefined ? formatNumberToLocaleString({ num: quantity, unitName: 'noUnits', shouldIncludeUnitName: false }) : t('common.any'),
    [t, quantity],
  );

  /** Indicates whether decrement button is enabled. True if quantity is greater than min quantity */
  const isDecrementEnabled: boolean = useMemo(
    () => quantity !== undefined && quantity > minQuantity,
    [quantity, minQuantity],
  );

  /** Callback when quantity decrements */
  const onDecrementClick = useCallback(() => {
    if (isDecrementEnabled) {
      trackSelectContentEvent(
        trackEvent,
        'Production',
        'Quantity Filter',
        t(decrementAriaLabelKey),
      );

      onQuantityChanged((quantity || 0) - 1);
    }
  }, [t, isDecrementEnabled, quantity, decrementAriaLabelKey, trackEvent, onQuantityChanged]);

  /** Callback when quantity increments */
  const onIncrementClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Production',
      'Quantity Filter',
      t(incrementAriaLabelKey),
    );

    onQuantityChanged((quantity || 0) + 1);
  }, [t, quantity, incrementAriaLabelKey, trackEvent, onQuantityChanged]);

  return {
    ...props,
    formattedQuantity,
    isDecrementEnabled,
    onDecrementClick,
    onIncrementClick,
  };
};
