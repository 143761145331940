import i18n from '../../../modules/locale/i18n';

/**
 * Returns the localized face value text based on the `isFaceValueShown` flag.
 */
export const getFaceValueText = (params: {
  isFaceValueShown: boolean;
}): string => {
  return params.isFaceValueShown
    ? i18n.t('orderInfo.confirmAndPlaceOrder.faceValueText')
    : '';
};

/**
 * Generates payment information text based on the provided payment details.
 * The text will indicate whether the payment is split between cash and rewards,
 * rewards only, or cash only.
 */
export const getPaymentInfoText = (params: {
  /** The remaining cash price to be paid. */
  remainingCashPrice: number;
  /** The formatted cash price with cents. */
  formattedRemainingCashPriceWithCents: string;
  /** The currency of the payment. */
  currency: string;
  /** The number of reward units applied. */
  appliedRewardUnits: number;
  /** Formatted reward units with unit name. */
  formattedAppliedRewardUnitsWithUnitName: string;
  /** The last four digits of the account card.  */
  accountCardLastFourDigits: string;
}): string => {
  const {
    remainingCashPrice,
    formattedRemainingCashPriceWithCents,
    currency,
    appliedRewardUnits,
    formattedAppliedRewardUnitsWithUnitName,
    accountCardLastFourDigits,
  } = params;

  // Split payment by default
  let paymentInfoTextKey: string = 'orderInfo.confirmAndPlaceOrder.paymentInfoText.splitPayment';

  if (!remainingCashPrice) {
    // Rewards only
    paymentInfoTextKey = 'orderInfo.confirmAndPlaceOrder.paymentInfoText.rewardsOnly';
  } else if (!appliedRewardUnits) {
    // Cash only
    paymentInfoTextKey = 'orderInfo.confirmAndPlaceOrder.paymentInfoText.cashOnly';
  }

  return i18n.t(paymentInfoTextKey, {
    formattedRemainingCashPriceWithCents,
    currency,
    formattedAppliedRewardUnitsWithUnitName,
    accountCardLastFourDigits,
  });
};