import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import type { BaseComponentProps } from '../../../lib/types';
import { useWindowSize } from '../../../lib/util';
import Image, { type ImageCombinedProps } from '../../atoms/Image';
import { Spinner } from '../../atoms/Spinner';
import Text from '../../atoms/Text';
import { Carousel } from '../../molecules/Carousel';
import { ExclusiveBadge } from '../../molecules/ExclusiveBadge';
import { IconText } from '../../molecules/IconText';
import { ListingCard, type ListingCardProps } from '../../molecules/ListingCard';
import { SoldOutCard } from '../../molecules/SoldOutCard';
import { ExpandableContent } from '../../organisms/ExpandableContent';
import { GuestListPreCheckoutModal } from '../../organisms/GuestListPreCheckoutModal';
import styles from './GuestListEventPage.module.scss';
import { usePresenter } from './GuestListEventPage.presenter';
import type { GuestListEventPagePresenterProps, GuestListEventPageProps } from './GuestListEventPage.types';

export const GuestListEventPage: React.FC<GuestListEventPageProps> = (props) => {
  const presenterProps: GuestListEventPagePresenterProps = usePresenter(props);

  const {
    eventMetadata: {
      eventName,
      eventDescription,
      eventDateTimeAndVenueDetails,
      isExclusiveEvent,
      isPayWithRewardsOnly,
      isSoldOut,
    },
    areListingsLoading,
    carouselImageProps,
    listingCardProps,
    shouldOpenPreCheckout,
    isListingDetailLoading,
    listingDetailQueryParams,
    listingDetail,
    className,
  } = presenterProps;

  const { t } = useTranslation();

  const { isDesktop } = useWindowSize();

  const topContent: ReactNode = (
    <Carousel<BaseComponentProps & ImageCombinedProps>
      isLoading={false}
      isError={false}
      isEmptyStateSupported={false}
      emptyStateTitleKey=''
      emptyStateDescriptionKey=''
      component={Image}
      componentProps={carouselImageProps}
      theme='dark'
      areBoxShadowsEnabled={false}
      classes={{
        carouselItemsContainer: styles.carouselImagesContainer,
        carouselBackwardButton: styles.carouselBackwardButton,
        carouselForwardButton: styles.carouselForwardButton,
      }}
    />
  );

  const cardholderExclusiveBadge: ReactNode = isExclusiveEvent && (
    <ExclusiveBadge
      icon={{
        iconAsset: 'Sparkles',
        iconStyle: 'Yellow',
      }}
      textKey='exclusiveBadge.cardholderExclusive'
      theme='light'
    />
  );

  const eventNameView: ReactNode = (
    <Text
      type='Subheading'
      size={isDesktop ? 'Large' : 'Medium'}
      style='SemiBold'
      colour='BaseLight'
      value={eventName}
    />
  );

  const eventDateTime: ReactNode = (
    <IconText
      iconAsset='CalenderNew'
      text={t('event.shortWeekDayLongDateTime', eventDateTimeAndVenueDetails)}
      color='Charcoal10'
      classes={{ icon: styles.eventInfoDetailIcon, text: styles.eventInfoDetailText }}
    />
  );

  const eventVenue: ReactNode = (
    <IconText
      iconAsset='LocationPinFilled'
      text={t('event.venueNameCityStateCode', eventDateTimeAndVenueDetails)}
      color='Charcoal10'
      classes={{ icon: styles.eventInfoDetailIcon, text: styles.eventInfoDetailText }}
    />
  );

  const payWithRewardsOnly: ReactNode = isPayWithRewardsOnly && (
    <IconText
      iconAsset='Trophy'
      text={t('event.payWithRewardsOnly')}
      color='Charcoal10'
      classes={{ icon: styles.eventInfoDetailIcon, text: styles.eventInfoDetailText }}
    />
  );

  const eventDescriptionView: ReactNode = (
    <ExpandableContent
      mobileMaxLines={3}
      desktopMaxLines={undefined}
      content={eventDescription}
      modalTitleKey='event.aboutThisEvent'
      className={styles.eventDescription}
    />
  );

  const listingsHeader: ReactNode = (
    <Text
      type='Subheading'
      size='Small'
      style='Regular'
      colour='BaseLight'
      value={t('guestListEventPage.selectYourTickets')}
      className={styles.listingsHeader}
    />
  );

  const listings: ReactNode = !isSoldOut && listingCardProps.length
    ? listingCardProps.map((currentListingCardProps: ListingCardProps) => (
      <ListingCard
        {...currentListingCardProps}
        key={currentListingCardProps.key}
      />
    ))
    : <SoldOutCard />;

  return (<>
    {(areListingsLoading || isListingDetailLoading) && <Spinner isOverlay={true} />}
    <div className={cx(styles.guestListEventPage, className)}>
      <div className={styles.topContent}>
        {topContent}
      </div>
      <div className={styles.bottomContent}>
        <div className={styles.eventDetails}>
          <div className={styles.eventDetailsHeader}>
            {cardholderExclusiveBadge}
            {eventNameView}
            {eventDateTime}
            {eventVenue}
            {payWithRewardsOnly}
          </div>
          {eventDescriptionView}
        </div>
        <div className={styles.listings}>
          {listingsHeader}
          <div className={styles.listingsContainer}>
            {listings}
          </div>
        </div>
      </div>
    </div>
    {shouldOpenPreCheckout && !!listingDetailQueryParams && !!listingDetail && (
      <GuestListPreCheckoutModal
        {...presenterProps}
        listingDetailQueryParams={listingDetailQueryParams}
        listingDetail={listingDetail}
      />
    )}
  </>);
};
