import React from 'react';
import { Trans } from 'react-i18next';
import { URLs } from '../../../lib/constants';
import i18n from '../../../modules/locale/i18n';
import CX1Banner1Asset from '../../../resources/images/C1XBanner1.png';
import DiningBanner2Asset from '../../../resources/images/DiningBanner2.png';
import MLBBannerAsset from '../../../resources/images/MLBBanner.png';
import styles from './Banner.module.scss';
import type { BannerProps } from './Banner.types';

export const AllEventsPageMLBBannerProps: BannerProps = {
  title: (
    <Trans
      i18nKey='allEventsPage.mlbBanner.title'
      values={{ currentYear: new Date().getFullYear() }}
      components={{
        wrap: <span className={styles.textLine} />,
        super: <span className={styles.textSuperscript} />,
      }}
    />
  ),
  backgroundImageUrl: MLBBannerAsset,
  href: URLs.MlbPage,
  buttonLabel: i18n.t('allEventsPage.mlbBanner.buttonLabel'),
  buttonType: 'TextIcon',
  buttonStyle: 'TextWhite',
  buttonSize: 'ExtraSmall',
  iconAsset: 'ArrowRightFilled',
  iconStyle: 'White',
  isBannerClickable: true,
};

export const HomePageMLBBannerProps: BannerProps = {
  title: i18n.t('homePage.mlbBanner.title'),
  description: i18n.t('homePage.mlbBanner.description', { currentYear: new Date().getFullYear() }),
  backgroundImageUrl: MLBBannerAsset,
  href: URLs.MlbPage,
  buttonLabel: i18n.t('homePage.mlbBanner.buttonLabel'),
  buttonType: 'Text',
  buttonStyle: 'ContainedWhite',
  buttonSize: 'Large',
};

export const HomePageCardholderExclusivesBannerProps: BannerProps = {
  title: i18n.t('homePage.cardholderExclusivesBanner.title'),
  description: i18n.t('homePage.cardholderExclusivesBanner.description'),
  backgroundImageUrl: CX1Banner1Asset,
  href: URLs.ExclusiveEventsPage,
  buttonLabel: i18n.t('homePage.cardholderExclusivesBanner.buttonLabel'),
  buttonType: 'Text',
  buttonStyle: 'ContainedWhite',
  buttonSize: 'Large',
};

export const ExclusiveEventsPageDiningBannerProps: BannerProps = {
  title: i18n.t('exclusiveEventsPage.diningBanner.title'),
  description: i18n.t('exclusiveEventsPage.diningBanner.description'),
  backgroundImageUrl: DiningBanner2Asset,
  href: URLs.ExclusiveDiningPage,
  buttonLabel: i18n.t('exclusiveEventsPage.diningBanner.buttonLabel'),
  buttonType: 'Text',
  buttonStyle: 'ContainedWhite',
  buttonSize: 'Large',
};
