import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CONTENT_ID } from '../../../lib/constants';
import { AriaRoles } from '../../../lib/types';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import TextInput from '../../atoms/TextInput';
import { ToolTip } from '../../atoms/ToolTip';
import { LabelledContent } from '../../molecules/LabelledContent';
import commonStyles from '../../pages/CheckoutPage/CheckoutPage.module.scss';
import styles from './ContactInfo.module.scss';
import { usePresenter } from './ContactInfo.presenter';
import type { ContactInfoProps } from './ContactInfo.types';

export const ContactInfo: React.FC<ContactInfoProps> = (props) => {
  const {
    email,
    phoneNumber,
    isPhoneNumberEditing,
    updatePhoneNumber,
    phoneNumberMaxLength,
    phoneNumberInvalidCharsRegex,
    isPhoneNumberError,
    toggleIsPhoneNumberEditing,
    isAcknowledged,
    acknowledgementCheckboxIconAsset,
    acknowledgementCheckboxStyle,
    toggleAcknowledgement,
    isAcknowledgementError,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  const stepTitle: ReactNode = (
    <Text
      type='Subheading'
      size='Medium'
      style='SemiBold'
      colour='SubduedDark'
      value={t('contactInfo.title')}
      id={CONTENT_ID}
    />
  );

  const emailContent: ReactNode = (
    <div className={commonStyles.column}>
      <LabelledContent labelKey='contactInfo.email.label'>
        <div className={commonStyles.row}>
          <Text
            size='Medium'
            style='Regular'
            colour='SubduedLight'
            value={email}
          />
          <ToolTip
            textKey='contactInfo.email.tooltip'
            ariaLabelKey='contactInfo.email.tooltip'
            placement='auto-end'
          >
            <Icon
              asset='Info'
              style='DigitalGrey80'
              className={commonStyles.infoIcon}
            />
          </ToolTip>
        </div>
      </LabelledContent>
    </div>
  );

  const renderPhoneNumberText = (): ReactNode => (
    <Text
      size='Medium'
      style='Regular'
      colour='SubduedLight'
      value={phoneNumber}
    />
  );

  const renderPhoneNumberInput = (): ReactNode => (
    <TextInput
      state='Filled'
      style='Default'
      size='Regular'
      textValue={phoneNumber}
      onTextChanged={updatePhoneNumber}
      maxLength={phoneNumberMaxLength}
      removeChar={phoneNumberInvalidCharsRegex}
      isAutoFocused={true}
      className={cx(commonStyles.textInput, styles.phoneNumberInput, { [commonStyles.textInputError]: isPhoneNumberError })}
    />
  );

  const phoneNumberEditSaveButton: ReactNode = (
    <Button
      type='Text'
      style='Text'
      size='Medium'
      focusable={true}
      text={{ value: isPhoneNumberEditing ? t('contactInfo.phoneNumber.save') : t('contactInfo.phoneNumber.edit') }}
      onClick={toggleIsPhoneNumberEditing}
      className={commonStyles.actionButton}
      classes={{ text: commonStyles.actionButtonText }}
    />
  );

  const phoneNumberContent: ReactNode = (
    <div className={commonStyles.column}>
      <LabelledContent
        labelKey='contactInfo.phoneNumber.label'
        labelInfoTooltipKey='contactInfo.phoneNumber.tooltip'
      >
        <div className={cx(commonStyles.row, styles.phoneNumberRow)}>
          {isPhoneNumberEditing ? renderPhoneNumberInput() : renderPhoneNumberText()}
          {phoneNumberEditSaveButton}
        </div>
      </LabelledContent>
      {isPhoneNumberError && (
        <Text
          size='Medium'
          style='Regular'
          colour='Negative'
          value={t('contactInfo.phoneNumber.error')}
          ariaRole={AriaRoles.ALERT}
          ariaAtomic={true}
          className={commonStyles.errorText}
        />
      )}
    </div>
  );

  const topContent: ReactNode = (
    <div className={cx(commonStyles.column, styles.topContent)}>
      {stepTitle}
      {emailContent}
      {phoneNumberContent}
    </div>
  );

  const bottomContent: ReactNode = (
    <div className={cx(commonStyles.column, styles.bottomContent)}>
      <button
        type='button'
        onClick={toggleAcknowledgement}
        aria-label={t('contactInfo.acknowledgement.ariaLabel')}
        aria-pressed={isAcknowledged}
        className={cx(commonStyles.checkbox, { [commonStyles.boxError]: isAcknowledgementError })}
      >
        <Icon
          asset={acknowledgementCheckboxIconAsset}
          style={acknowledgementCheckboxStyle}
          className={commonStyles.checkboxIcon}
        />
        <div className={cx(commonStyles.column, styles.acknowledgementText)}>
          <p>{t('contactInfo.acknowledgement.title')}</p>
          <ul className={styles.acknowledgementTextList}>
            <li>{t('contactInfo.acknowledgement.agreePhoneNumber')}</li>
            <li>{t('contactInfo.acknowledgement.agreeEmails')}</li>
          </ul>
        </div>
      </button>
      {isAcknowledgementError && (
        <Text
          size='Medium'
          style='Regular'
          colour='Negative'
          value={t('contactInfo.acknowledgement.error')}
          ariaRole={AriaRoles.ALERT}
          ariaAtomic={true}
        />
      )}
    </div>
  );

  return (
    <div className={cx(commonStyles.column, className)}>
      {topContent}
      {bottomContent}
    </div>
  );
};
