/**
 * Constructs a performer URL for a given performer Id.
 * @param {number} performerId Id of the performer.
 * @returns {string} Performer URL for a given performer Id.
 */
export const getPerformerUrl = (performerId: number): string => `/performers/${performerId}`;

/**
 * Extracts performer Id from a given path.
 * @param {string} slug - The path from which to extract performer Id.
 * @returns {string | null} The extracted performer Id as a string, or null if performer Id is not found.
 */
export const getPerformerIdFromSlug = (slug: string): string | null => {
  // Slug must end with performer Id or / or ?
  const match = slug.match(/\/performers\/(\d+)($|\/|\?)/);
  return match ? match[1] : null;
};
