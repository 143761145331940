import { URLs } from '../../../lib/constants';
import type { SearchQueryParams } from '../../../modules/partnership';
import type { DateRange } from '../../organisms/DateFilter';
import { SEARCH_QUERY_PARAM } from './SearchResultsPage.constants';

/**
 * Builds the URL for the search results page with the provided search query.
 * @param {Object} params - The parameters object.
 * @param {string} params.searchQuery - The search query string.
 * @returns {string} The URL for the search results page.
 */
export const buildSearchResultsPageUrlWithParams = (params: { searchQuery: string; }): string => {
  const { searchQuery } = params;

  return `${URLs.SearchResultsPage}?${SEARCH_QUERY_PARAM}=${encodeURIComponent(searchQuery)}`;
};

/**
 * Builds the query parameters for fetching events based on the provided filters.
 * @param {Object} params - The parameters object.
 * @param {string} params.searchQuery - The search query string.
 * @param {Record<keyof DateRange, string>} params.dateRange - Object containing the start and end dates for filtering events.
 * @param {string | undefined} params.apiTagFilter - Optional tag filter for API request.
 * @returns {SearchQueryParams} Query parameters for fetching events.
 */
export const buildSearchResultsPageStaticApiParams = (params: {
  searchQuery: string;
  /** Object containing the start and end dates for filtering events */
  dateRange: Record<keyof DateRange, string>;
  /** Optional tag filter for API request */
  apiTagFilter: string | undefined;
}): SearchQueryParams => {
  const {
    searchQuery,
    dateRange,
    apiTagFilter,
  } = params;

  const newStaticApiParams: SearchQueryParams = {
    q: searchQuery,
    ...(dateRange.startDate ? { date_start: dateRange.startDate } : {}),
    ...(dateRange.endDate ? { date_end: dateRange.endDate } : {}),
    tag_filter: apiTagFilter,
  };

  return newStaticApiParams;
};

/**
 * Retrieves and decodes the search query from the URL.
 * @param {string | undefined} rawSearchQuery - The raw search query string from the URL.
 * @returns {string} The decoded search query string.
 */
export const getSearchQueryFromUrl = (rawSearchQuery: string | undefined) => {
  return rawSearchQuery ? decodeURIComponent(rawSearchQuery) : '';
};
