import cx from 'classnames';
import React, { type ReactNode } from 'react';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import styles from './IconText.module.scss';
import { usePresenter } from './IconText.presenter';
import type { IconTextProps } from './IconText.types';

export const IconText: React.FC<IconTextProps> = (props) => {
  const {
    iconAsset,
    text,
    color,
    className,
    classes,
  } = usePresenter(props);

  // Determine the content to render based on the type of text
  const content: ReactNode = typeof text === 'string'
    ? (
      <Text
        size='Medium'
        style='Regular'
        colour={color}
        value={text}
        className={classes?.text}
      />
    )
    : text;

  return (
    <div className={cx(styles.iconTextContainer, className)}>
      <Icon
        asset={iconAsset}
        style={color}
        className={cx(styles.icon, classes?.icon)}
      />
      {content}
    </div>
  );
};
