import type { IconProps } from '../../atoms/Icon';
import type { RadioButtonTheme } from './RadioButton.types';

export const RadioButtonIconPropsByTheme = {
  lightSelected: {
    asset: 'RadioFilled',
    style: 'ActionBase',
  },
  lightUnselected: {
    asset: 'RadioEmpty',
    style: 'White',
  },
  darkSelected: {
    asset: 'RadioFilled',
    style: 'ActionBase',
  },
  darkUnselected: {
    asset: 'RadioEmpty',
    style: 'SubduedDark',
  },
} as const satisfies Record<`${RadioButtonTheme}${'Selected' | 'Unselected'}`, IconProps>;
