import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import styles from './RewardsOnlyPaymentInfo.module.scss';
import { usePresenter } from './RewardsOnlyPaymentInfo.presenter';
import type { RewardsOnlyPaymentInfoProps } from './RewardsOnlyPaymentInfo.types';

export const RewardsOnlyPaymentInfo: React.FC<RewardsOnlyPaymentInfoProps> = (props) => {
  const {
    formattedTotalPriceInRewardUnitsWithUnitName,
    formattedTotalPriceInCash,
  } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <div className={styles.rewardsOnlyPaymentInfo}>
      <Icon
        className={cx(styles.icon)}
        asset='TicketBig'
        style='DigitalGrey30'
      />
      <Text
        type='Subheading'
        size='Small'
        style='Regular'
        colour='SubduedDark'
        align='Center'
        value={t('paymentInfo.cardInfoNotRequired.title')}
        classes={{ value: styles.title }}
      />
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedDark'
        align='Center'
        value={t('paymentInfo.cardInfoNotRequired.subTitle')}
        classes={{ value: styles.description }}
      />
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedDark'
        align='Center'
        value={t('paymentInfo.cardInfoNotRequired.description', { formattedTotalPriceInRewardUnitsWithUnitName, formattedTotalPriceInCash })}
        classes={{ value: styles.description }}
      />
    </div>
  );
};
