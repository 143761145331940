import { sortRegionsByName } from '../../lib/regionUtils';
import type { Region } from '../partnership';

/**
 * Sorts regions by name if regions are not loading.
 * @returns {Region[]} An array of sorted regions if regions are not loading, otherwise an empty array.
 */
export const getSortedRegions = (params: {
  /** Indicates whether regions are currently loading */
  areRegionsLoading: boolean;
  /** Array of unsorted regions */
  unsortedRegions: Region[];
}): Region[] => {
  const { areRegionsLoading, unsortedRegions } = params;
  return !areRegionsLoading && unsortedRegions.length ? sortRegionsByName(unsortedRegions) : [];
};

/**
 * Gets the current region if regions by IP are not loading.
 * @returns {Region | undefined} The first region from the regions by IP if regions by IP are not loading, otherwise undefined.
 */
export const getCurrentRegion = (params: {
  /** Indicates whether regions by IP are currently loading */
  areRegionsByIpLoading: boolean;
  /** Array of regions by IP */
  regionsByIp: Region[];
}): Region | undefined => {
  const { areRegionsByIpLoading, regionsByIp } = params;
  return !areRegionsByIpLoading && regionsByIp.length ? regionsByIp[0] : undefined;
};

/**
 * Checks if the region context is loading based on various loading states.
 * @returns {boolean} True if any of the loading states is true, otherwise false.
 */
export const checkAreRegionsLoading = (params: {
  /** Indicates whether all regions are currently loading */
  areAllRegionsLoading: boolean;
  /** Total number of all regions */
  allRegionsLength: number;
  /** Indicates whether regions by IP are currently loading */
  areRegionsByIpLoading: boolean;
}): boolean => {
  const { areAllRegionsLoading, allRegionsLength, areRegionsByIpLoading } = params;
  return areAllRegionsLoading || !allRegionsLength || areRegionsByIpLoading;
};

/**
 * Retrieves the first error from the provided error parameters.
 * @returns {Error | undefined} The first encounted error or undefined if there are no errors.
 */
export const getRegionContextError = (params: {
  /** Error while fetching all regions */
  allRegionsError: unknown;
  /** Error while fetching regions by IP */
  regionsByIpError: unknown;
  /** Error while fetching performer regions */
  regionsByPerformerIdError: unknown;
}): Error | undefined => {
  const { allRegionsError, regionsByIpError, regionsByPerformerIdError } = params;
  return (allRegionsError || regionsByIpError || regionsByPerformerIdError || undefined) as Error | undefined;
};
