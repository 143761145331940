import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import styles from './ExpandableContent.module.scss';
import { usePresenter } from './ExpandableContent.presenter';
import type { ExpandableContentProps } from './ExpandableContent.types';

/**
 * Component that renders content with optional truncation after X number of lines.
 * If content gets truncated then See More button is rendered which opens a modal with full content.
 */
export const ExpandableContent: React.FC<ExpandableContentProps> = (props) => {
  const {
    contentRef,
    processedContentHtml,
    contentClassName,
    isSeeMoreButtonShown,
    onSeeMoreButtonClick,
    isContentModalShown,
    contentModalClassName,
    closeContentModal,
    modalTitleKey,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  if (!processedContentHtml) {
    return null;
  }

  /** Content with HTML being handled */
  const processedContent: ReactNode = typeof processedContentHtml === 'string'
    ? <span dangerouslySetInnerHTML={{ __html: processedContentHtml }} />
    : processedContentHtml;

  return (<>
    <div className={cx(styles.expandableContent, className)}>
      <Text
        textRef={contentRef}
        size='Medium'
        style='Regular'
        colour='Charcoal10'
        value={processedContent}
        className={cx(styles.content, contentClassName)}
      />
      {isSeeMoreButtonShown && (
        <Button
          type='TextIcon'
          style='TextCharcoal10'
          size='Small'
          text={{ value: t('expandableContent.seeMore') }}
          onClick={onSeeMoreButtonClick}
        />
      )}
    </div>
    {isSeeMoreButtonShown && isContentModalShown && (
      <Modal
        show={true}
        onEscapeKeyDown={closeContentModal}
        className={cx(styles.modal, contentModalClassName)}
        dialogClassName={styles.modalDialog}
        contentClassName={styles.modalContent}
      >
        <div className={styles.modalHeader}>
          <Text
            type='Subheading'
            size='Medium'
            style='SemiBold'
            colour='BaseLight'
            value={t(modalTitleKey)}
          />
          <Button
            type='Icon'
            style='Text'
            size='Medium'
            icon={{
              asset: 'Close',
              style: 'White',
            }}
            onClick={closeContentModal}
            autoFocus={true}
            ariaLabel={t('expandableContent.close')}
            className={styles.closeButton}
            classes={{ icon: styles.closeIcon }}
          />
        </div>
        <Text
          size='Medium'
          style='Regular'
          colour='Charcoal10'
          value={processedContent}
          className={cx(styles.content, styles.expandedContent)}
        />
      </Modal>
    )}
  </>);
};
