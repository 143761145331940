import type { TextColourEnum } from '../../atoms/Text';
import type { AccountCardTheme } from '../AccountCard';
import type { RadioButtonTheme } from '../RadioButton';
import type { RewardsBalanceTheme } from './RewardsBalance.types';

export const TitleColoursByRewardsBalanceTheme = {
  light: 'SubduedDark',
  dark: 'BaseLight',
} as const satisfies Record<RewardsBalanceTheme, TextColourEnum>;

export const AccountCardRadioButtonThemesByRewardsBalanceTheme = {
  light: 'dark',
  dark: 'light',
} as const satisfies Record<RewardsBalanceTheme, RadioButtonTheme>;

export const AccountCardThemesByRewardsBalanceTheme = {
  light: 'subduedDark',
  dark: 'light',
} as const satisfies Record<RewardsBalanceTheme, AccountCardTheme>;
