import cx from 'classnames';
import React from 'react';
import type { BaseComponentProps } from '../../../lib/types';
import { CarouselButton } from '../../atoms/CarouselButton';
import { Spinner } from '../../atoms/Spinner';
import { EmptyState } from '../EmptyState';
import { SpinnerThemesByCarouselTheme } from './Carousel.constants';
import styles from './Carousel.module.scss';
import { usePresenter } from './Carousel.presenter';
import type { CarouselProps } from './Carousel.types';

export const Carousel = <TComponentProps extends BaseComponentProps>(
  props: CarouselProps<TComponentProps>,
) => {
  const {
    carouselItemsContainerRef,
    isLoading,
    isError,
    isEmptyStateSupported,
    emptyStateTitleKey,
    emptyStateDescriptionKey,
    component: Component,
    componentProps,
    onGoBackwardClick,
    onGoForwardClick,
    shouldRenderLeftBoxShadow,
    shouldRenderRightBoxShadow,
    theme,
    classes,
  } = usePresenter(props);

  if (isLoading) {
    return (
      <div className={cx(styles.carousel, styles.carouselLoadingOrEmptyStateContainer, classes?.carouselLoadingOrEmptyStateContainer)}>
        <Spinner theme={SpinnerThemesByCarouselTheme[theme]} />
      </div>
    );
  }

  if (!componentProps.length || isError) {
    // If empty state is not supported then do not render anything
    return isEmptyStateSupported
      ? (
        <div className={cx(styles.carousel, styles.carouselLoadingOrEmptyStateContainer, classes?.carouselLoadingOrEmptyStateContainer)}>
          <EmptyState
            titleKey={emptyStateTitleKey}
            descriptionKey={emptyStateDescriptionKey}
            theme={theme}
            className={styles.carouselEmptyState}
          />
        </div>
      )
      : null;
  }

  const carouselItems: React.ReactNode = componentProps.map((currentComponentProps: TComponentProps) => (
    <Component
      {...currentComponentProps}
      key={currentComponentProps.key}
      className={cx(styles.carouselItem, currentComponentProps.className)}
    />
  ));

  return (
    <div className={cx(
      styles.carousel,
      {
        [styles.carouselWithLeftBoxShadow]: shouldRenderLeftBoxShadow,
        [styles.carouselWithRightBoxShadow]: shouldRenderRightBoxShadow,
      },
    )}
    >
      <div
        ref={carouselItemsContainerRef}
        className={cx(styles.carouselItemsContainer, classes?.carouselItemsContainer)}
      >
        {carouselItems}
      </div>
      {!!onGoBackwardClick && (
        <CarouselButton
          type='backward'
          onClick={onGoBackwardClick}
          className={classes?.carouselBackwardButton}
        />
      )}
      {!!onGoForwardClick && (
        <CarouselButton
          type='forward'
          onClick={onGoForwardClick}
          className={classes?.carouselForwardButton}
        />
      )}
    </div>
  );
};
