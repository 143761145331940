import type { MapContextValue } from './MapContext.types';

export const DEFAULT_MAP_CONTEXT_VALUE = {
  isSvgMapSupported: false,
  svgMapData: undefined,
  isSvgMapDataLoading: false,
  svgMapDataError: undefined,
  setHoveredSection: () => { },
  setSelectedSection: () => { },
  listingsToDisplay: [],
  staticImageMapUrl: '',
} as const satisfies MapContextValue;

/** Important: Value 'svg-map' is used by automation tests. Do not change accidentally. */
export const SVG_MAP_ID = 'svg-map';

export const SVG_MAP_SECTION_STYLES = {
  /** Teal fill colour */
  availableSection: 'fill: #78E4E8;',
  /** Pink fill colour */
  hoveredSection: 'fill: #F7B4DF;',
  /** Pink fill colour with 2px border */
  selectedSection: 'fill: #F7B4DF; stroke: #CE3197; stroke-width: 0.125rem;',
} as const satisfies Record<string, string>;
