import type { ListingsMetadata } from '../../../lib/types';
import { isNumber } from '../../../lib/util';

/** Returns true if event is not exclusive, not a Pay With Rewards Only event and min/max prices are set for listings */
export const checkHasMinMaxPriceFilter = (params: {
  /** Indicates if the event is exclusive, i.e. it contains C1_EXCLUSIVE tag */
  isExclusiveEvent: boolean;
  /** Indicates if the event only allows payment with rewards */
  isPayWithRewardsOnly: boolean;
  /** Detailed information about event listings */
  listingsMetadata: ListingsMetadata | undefined;
}): boolean => {
  const {
    isExclusiveEvent,
    isPayWithRewardsOnly,
    listingsMetadata: { minPrice, maxPrice } = {},
  } = params;

  return !isExclusiveEvent
    && !isPayWithRewardsOnly
    && isNumber(minPrice)
    && isNumber(maxPrice)
    && maxPrice > 0;
};

/**
 * Checks if pan-zoom functionality is enabled for SVG map.
 * On desktops it is always true.
 * On mobiles it is true only when map container remains fixed to the top, i.e. there is enough screen height so that page does not scroll.
 * This is to prevent page scroll from interfering with pan-zoom functionality.
 */
export const checkIsSvgMapPanZoomEnabled = (params: {
  isDesktop: boolean;
  mapContainerDiv: HTMLDivElement | null;
}): boolean => {
  const { isDesktop, mapContainerDiv } = params;

  if (isDesktop) {
    return true;
  }

  return mapContainerDiv
    ? mapContainerDiv.offsetHeight >= mapContainerDiv.scrollHeight
    : false;
};
