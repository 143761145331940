import cx from 'classnames';
import React from 'react';
import styles from './PageContentContainerLayout.module.scss';
import { usePresenter } from './PageContentContainerLayout.presenter';
import type { PageContentContainerLayoutProps } from './PageContentContainerLayout.types';

/**
 * Component that sets the width of page content:
 * - On mobiles page content takes full width of the screen.
 * - On desktops page content has a max width of 55.5 rems and is horizontally centered on the screen.
 */
export const PageContentContainerLayout: React.FC<PageContentContainerLayoutProps> = (props) => {
  const { children, className } = usePresenter(props);

  return (
    <div className={cx(styles.pageContentContainerLayout, className)}>
      {children}
    </div>
  );
};
