/** See https://developer.paypal.com/braintree/docs/reference/general/testing/node#valid-card-numbers */
export const dummyLastFourDigits: string[] = [
  '0004', // Visa: 4005 5192 0000 0004
  '0011', // Mastercard: 2223 0000 4840 0011
  '0026', // Visa: 4012 0000 3333 0026
  '0061', // Visa: 4500 6000 0000 0061
  '1111', // Visa: 4111 1111 1111 1111
  '1119', // Visa: 4217 6511 1111 1119
  '1234', // Visa: 4030 0000 1000 1234
  '1881', // Visa: 4009 3488 8888 1881
  '4444', // Mastercard: 5555 5555 5555 4444
  '7777', // Visa: 4012 0000 7777 7777
];
