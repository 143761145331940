import type { Region } from '../modules/partnership';

/**
 * Function that sorts regions by display name (or city if display name is not defined) in alphabetical order.
 * @param regions List of all regions.
 * @returns List of all sorted regions.
 */
export const sortRegionsByName = (regions: Region[]): Region[] => {
  return regions
    .slice()
    .sort((a, b) => (a.displayName ?? a.city).localeCompare(b.displayName ?? b.city));
};

/**
 * Function that filters regions by display name (or city if display name is not defined).
 * Filtering is case insensitive.
 * @param regions List of all regions.
 * @param filterText Text to filter by.
 * @returns List of matching regions.
 */
export const filterRegionsByText = (regions: Region[], filterText: string): Region[] => {
  if (!filterText.trim()) {
    return [];
  }

  const normalizedFilterText: string = filterText.trim().toLowerCase();

  return regions.filter((region: Region) => {
    const { displayName, city } = region;
    return (displayName ?? city).toLowerCase().includes(normalizedFilterText);
  });
};
