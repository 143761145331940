import type { NextStepButtonColour } from '../../molecules/CheckoutTotal';
import type { Step } from './CheckoutPage.types';
import { buildPOBoxZipCodesMap } from './CheckoutPage.utils';

export const Steps = {
  ContactInfo: 1,
  PaymentInfo: 2,
  Address: 3,
  ConfirmOrder: 4,
} as const satisfies Record<string, number>;

export const MAX_STEP: Step = Math.max(...Object.values(Steps)) as Step;

export const StepConfigurations = {
  [Steps.ContactInfo]: {
    labelKey: 'checkoutPage.steps.contactInfo.label',
    nextStepButtonLabelKey: 'checkoutPage.steps.contactInfo.buttonLabel',
    nextStepButtonColour: 'Contained',
  },
  [Steps.PaymentInfo]: {
    labelKey: 'checkoutPage.steps.paymentInfo.label',
    nextStepButtonLabelKey: 'checkoutPage.steps.paymentInfo.buttonLabel',
    nextStepButtonColour: 'Contained',
  },
  [Steps.Address]: {
    labelKey: 'checkoutPage.steps.address.label',
    nextStepButtonLabelKey: 'checkoutPage.steps.address.buttonLabel',
    nextStepButtonColour: 'Contained',
  },
  [Steps.ConfirmOrder]: {
    labelKey: 'checkoutPage.steps.confirmOrder.label',
    nextStepButtonLabelKey: 'checkoutPage.steps.confirmOrder.buttonLabel',
    nextStepButtonColour: 'ContainedGreen',
  },
} as const satisfies Record<Step, { labelKey: string; nextStepButtonLabelKey: string; nextStepButtonColour: NextStepButtonColour; }>;

export const CheckoutStepperLabelKeys: string[] = Object.values(StepConfigurations).map(({ labelKey }) => labelKey);

export const PHONE_NUMBER_KEY = 'PhoneNumber';

export const PHONE_NUMBER_LENGTH = 10;

/**
 * This regular expression will prevent any non-numeric characters
 * from being typed into the text input by matching and removing
 * anything that is not a digit (0-9).
 */
export const INVALID_INTEGER_CHARS_REGEX = /[^0-9]/g;

/**
 * This regular expression will prevent any non-numeric characters
 * from being typed into the text input by matching and removing
 * anything that is not a digit (0-9) or a dot.
 */
export const INVALID_DECIMAL_CHARS_REGEX = /[^0-9.]/g;

/** Map of PO box zip codes where key is a PO box zip code and value is always true */
export const PO_BOX_ZIP_CODES_MAP: Record<string, true> = buildPOBoxZipCodesMap();
