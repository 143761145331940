import { useCallback, useContext, useRef } from 'react';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { TopNavContext } from '../../../modules/topNav';
import type { SearchBarWithSuggestionsDropdownPresenterProps, SearchBarWithSuggestionsDropdownProps } from './SearchBarWithSuggestionsDropdown.types';

export const usePresenter = (props: SearchBarWithSuggestionsDropdownProps): SearchBarWithSuggestionsDropdownPresenterProps => {
  const { setActiveTopNavMenuType } = useContext(TopNavContext);

  const { trackEvent } = useAnalyticsManager();

  const searchSuggestionsDropdownRef = useRef<HTMLDivElement>(null);

  const onSearchSuggestionsDropdownToggled = useCallback(() => {
    if (!searchSuggestionsDropdownRef.current?.contains(document.activeElement)) {
      trackSelectContentEvent(
        trackEvent,
        'Header',
        'SearchBar',
      );

      setActiveTopNavMenuType(undefined);
    }
  }, [trackEvent, setActiveTopNavMenuType]);

  return {
    ...props,
    searchSuggestionsDropdownRef,
    onSearchSuggestionsDropdownToggled,
  };
};
