import type { PageState } from './PaginatedList.types';

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 25;
export const FETCH_NEXT_INTERVAL_MS = 3650;

export const INITIAL_PAGE_STATE = {
  page: DEFAULT_PAGE,
  shouldFetchPage: false,
} as const satisfies PageState;
