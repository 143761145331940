import cx from 'classnames';
import type { BaseComponentProps } from '../../../lib/types';
import type { ImageCombinedProps } from '../../atoms/Image';
import styles from './GuestListEventPage.module.scss';

/**
 * Gets an array of image props for rendering the carousel of event images.
 * @returns {Array<BaseComponentProps & ImageCombinedProps>} Array of image props for rendering the carousel of event images.
 */
export const getCarouselImageProps = (params: {
  carouselImageUrls: string[];
}): Array<BaseComponentProps & ImageCombinedProps> => {
  const { carouselImageUrls } = params;

  const carouselImageClassName: string = carouselImageUrls.length <= 1
    ? styles.singleCarouselImage
    : carouselImageUrls.length === 2 ? styles.doubleCarouselImages : styles.multipleCarouselImages;

  return carouselImageUrls.map((carouselImageUrl: string, key: number) => ({
    key,
    imageSrc: carouselImageUrl,
    className: cx(styles.carouselImage, carouselImageClassName),
  }));
};
