import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { MobileMenuItem, MobileMenuState } from '../../../modules/navigation/Navigation.types';
import { TopNavContext } from '../../../modules/topNav';
import { NavMenuItemCombinedProps } from '../../molecules/NavMenuItem/types';
import { buildNavMenuListItems, getCategoryMenuItemOnBackButton, getInitialCategoryItems, getSubCategoriesOnBackButton, populateWithChildren } from './NavMenuList.utils';
import { NavMenuListCombinedProps } from './types';

const useNavMenuListPresenter = (props: NavMenuListCombinedProps): NavMenuListCombinedProps => {
  const { setIsSelectedNavMenuItem, pageTopRef } = props;

  const { categoryMenuItemStates } = useContext(TopNavContext);

  const { trackEvent } = useAnalyticsManager();

  const location = useLocation();

  // Initial mobile menu state
  const initialMenuState: MobileMenuState = useMemo(() => ({ items: getInitialCategoryItems(categoryMenuItemStates) }), [categoryMenuItemStates]);
  const [menuState, setMenuState] = useState(initialMenuState);

  useEffect(() => {
    // Reset menu state and clear selection when location changes
    setMenuState(initialMenuState);
    setIsSelectedNavMenuItem?.(false);
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  /** Handle click event for the back button. */
  const handleBackClick = useCallback(() => {
    // Back button click handling for SubCategory
    if (menuState.selected?.type === 'subCategory') {
      setIsSelectedNavMenuItem?.(true);
      return setMenuState({
        selected: getCategoryMenuItemOnBackButton(menuState, categoryMenuItemStates),
        items: getSubCategoriesOnBackButton(categoryMenuItemStates, menuState),
      });
    }

    // Back button click handling for Category
    if (menuState.selected?.type === 'category') {
      setIsSelectedNavMenuItem?.(false);
      return setMenuState(initialMenuState);
    }
  }, [menuState, categoryMenuItemStates, initialMenuState, setIsSelectedNavMenuItem]);

  /**
   * Handle click event for menu item.
   * @param {string} id - ID of the selected item(Category or SubCategory).
   */
  const onItemClick = useCallback((id: string) => {
    const selectedItem = menuState.items.find((item) => item.id === id);
    let items: MobileMenuItem[] | undefined;
    if (selectedItem) {
      items = populateWithChildren(categoryMenuItemStates, selectedItem.type, id, menuState);
    }
    setMenuState({ selected: selectedItem, items: items ? items : [] });
    setIsSelectedNavMenuItem?.(!!selectedItem);
  }, [menuState, categoryMenuItemStates, setIsSelectedNavMenuItem]);

  const navMenuItems: NavMenuItemCombinedProps[] = useMemo(() => buildNavMenuListItems(menuState, handleBackClick, trackEvent, setMenuState, initialMenuState, onItemClick),
    [menuState, handleBackClick, trackEvent, initialMenuState, onItemClick]);

  useEffect(() => {
    // When menu list items change we need to scroll to the top of the page so that user can see the first menu item
    if (pageTopRef?.current) {
      // Using 'auto' behaviour to scroll instantly without scroll transition
      pageTopRef.current.scrollIntoView({ block: 'start', inline: 'start', behavior: 'auto' });
    }
  }, [pageTopRef, navMenuItems]);

  return {
    ...props,
    navMenuItems,
  };
};

export default useNavMenuListPresenter;
