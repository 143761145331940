import { useCallback, useEffect, useMemo, useRef, useState, type CSSProperties } from 'react';
import { useAnalyticsManager } from '../../../modules/analytics';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import styles from './MultiToggle.module.scss';
import type { MultiToggleOption, MultiTogglePresenterProps, MultiToggleProps } from './MultiToggle.types';
import { getSelectionChipStyles, selectMultiToggleOption } from './MultiToggle.utils';

export const usePresenter = <TId extends string | number>(props: MultiToggleProps<TId>): MultiTogglePresenterProps<TId> => {
  const {
    queryParamName,
    multiToggleOptions,
    multiToggleState: initialMultiToggleState,
  } = props;

  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  const { trackEvent } = useAnalyticsManager();

  const multiToggleState: TId | undefined = useMemo(() => initialMultiToggleState || multiToggleOptions[0]?.id, [initialMultiToggleState, multiToggleOptions]);

  const onMultiToggleOptionSelected = useCallback((newMultiToggleOption: MultiToggleOption<TId>) => {
    selectMultiToggleOption({
      queryParamName,
      newMultiToggleOption,
      appendQueryParamsToUrl,
      trackEvent,
    });
  }, [queryParamName, appendQueryParamsToUrl, trackEvent]);

  const itemRefs = useRef<Array<HTMLButtonElement | null>>([]);

  // Class name that activates animation of the selection chip
  const [selectionChipClassName, setSelectionChipClassName] = useState<string | undefined>();
  useEffect(() => {
    setTimeout(() => setSelectionChipClassName(styles.animatedSelectionChip), 300);
  }, []);

  // Styles that set X position and width of the selection chip
  const [selectionChipStyles, setSelectionChipStyles] = useState<CSSProperties | undefined>();
  useEffect(() => {
    setSelectionChipStyles(getSelectionChipStyles({ multiToggleOptions, multiToggleState, itemHtmlElements: itemRefs.current }));
  }, [multiToggleOptions, multiToggleState]);

  return {
    ...props,
    multiToggleState,
    onMultiToggleOptionSelected,
    itemRefs,
    selectionChipStyles,
    selectionChipClassName,
  };
};
