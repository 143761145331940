import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsManager } from '../../../modules/analytics';
import { trackSelectContentEvent } from '../../../modules/analytics/util';
import type { NoListingsPresenterProps, NoListingsProps } from './NoListings.types';

export const usePresenter = (props: NoListingsProps): NoListingsPresenterProps => {
  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  const onGoToHomeButtonClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Production',
      'Empty State',
      t('noListings.buttonText'),
    );
  }, [t, trackEvent]);

  return {
    ...props,
    onGoToHomeButtonClick,
  };
};
