import Cookie from 'js-cookie';
import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '.';
import { URLs } from '../../lib/constants';
import { displayMaintenanceWindow } from '../../lib/util';
import { useAnalyticsManager } from '../analytics/useAnalyticsManager';
import ErrorHandler from '../error';
import { TopNavProvider } from '../topNav';
import { UnauthorizedRedirect } from './AuthContext';

const CheckUserAccess: React.FC = () => {
  const { account, loading, error, verifyAccessToken } = useContext(AuthContext);
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  const assertion = query.get('assertion');
  const code = query.get('code');
  const lastFour = query.get('lastFour');
  const navigate = useNavigate();
  const redirectUrl = Cookie.get('redirect_url');

  // tracking marketing visit
  const { trackEvent } = useAnalyticsManager();
  const utmSource = query.get('utm_source');
  const utmTerm = query.get('utm_term');
  const utmMedium = query.get('utm_medium');
  const utmCampaign = query.get('utm_campaign');
  const utmTarget = query.get('utm_target');
  const utmAdgroupId = query.get('utm_adgroup_id');
  const utmKeywordId = query.get('utm_keyword_id');
  const utmContent = query.get('utm_content');

  useEffect(() => {
    if (utmSource) {
      trackEvent('marketing_visit', {
        utm: {
          medium: utmMedium,
          source: utmSource,
          campaign: utmCampaign,
          term: utmTerm,
          adgroupId: utmAdgroupId,
          target: utmTarget,
          keywordId: utmKeywordId,
          content: utmContent,
        },
      });
    }
  }, [utmSource]);

  useEffect(() => {
    if (code && !loading) {
      verifyAccessToken({ access_token: code, token_type: 'code' });
      trackEvent('login');
      navigate({ search: '' }, { replace: true });
    }
    if (assertion && !loading) {
      verifyAccessToken({ access_token: assertion, last_four: lastFour });
      trackEvent('login');
      query.delete('assertion');
      navigate({
        search: query.toString(),
      }, { replace: true });
    }
  }, [assertion, lastFour, loading, verifyAccessToken, history, code]);

  const inMaintenance = displayMaintenanceWindow();

  if (loading || assertion || code) {
    return <Outlet />;
  }

  if (pathname === URLs.HelpPage) {
    return <Outlet />;
  }

  if (error) {
    return <ErrorHandler isAuthError={true} error={error} />;
  }

  if (!account && !inMaintenance) {
    return <UnauthorizedRedirect redirectUrl={window.location.href} />;
  }

  if (redirectUrl) {
    Cookie.remove('redirect_url');
    window.location.assign(redirectUrl);
    return null;
  }

  return <Outlet />;
};

const ProtectedRoute: React.FC = () => {
  return (
    <TopNavProvider>
      <CheckUserAccess />
    </TopNavProvider>
  );
};

export default ProtectedRoute;
