import { useContext, useMemo } from 'react';
import { MapContext } from '../../../modules/map';
import type { MapWrapperPresenterProps, MapWrapperProps } from './MapWrapper.types';

export const usePresenter = (props: MapWrapperProps): MapWrapperPresenterProps => {
  const { isExclusiveEvent } = props;

  const {
    isSvgMapSupported,
    staticImageMapUrl,
  } = useContext(MapContext);

  /** True only for exclusive events with static image map URL */
  const isStaticImageMapSupported: boolean = useMemo(() => isExclusiveEvent && !!staticImageMapUrl, [isExclusiveEvent, staticImageMapUrl]);

  return {
    ...props,
    isSvgMapSupported,
    isStaticImageMapSupported,
    staticImageMapUrl,
  };
};
