import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../modules/auth';
import type { PriceBreakdownInfoPresenterProps, PriceBreakdownInfoProps } from './PriceBreakdownInfo.types';
import { formatPrice, getMainCharge, getRewardUnitCharge } from './PriceBreakdownInfo.utils';

export const usePresenter = (props: PriceBreakdownInfoProps): PriceBreakdownInfoPresenterProps => {
  const {
    listingDetailMetadata: {
      eventMetadata: {
        isPayWithRewardsOnly,
      },
      listingMetadata: {
        loyaltyUnitName = '',
      },
      pricing: {
        currency,
        quantity,
        price_per: pricePerTicket = 0,
        subtotal: totalTicketsPrice = 0,
        fulfillment_fee: serviceFee = 0,
        delivery_fee: deliveryFee = 0,
        taxes: tax = 0,
        total: orderTotal = 0,
      },
      faceValue,
    },
    formattedRemainingCashPrice,
    formattedAppliedRewardUnitsWithUnitName,
    formattedTotalPriceInRewardUnits,
  } = props;

  const {
    selectedAccountCardDetail: {
      rewardUnitsPerDollar = 1,
      rewardUnitName = '',
    } = {},
  } = useContext(AuthContext);

  const { t } = useTranslation();

  const mainCharge: string = useMemo(
    () => getMainCharge({ isPayWithRewardsOnly, formattedRemainingCashPrice, formattedTotalPriceInRewardUnits, loyaltyUnitName, currency }),
    [isPayWithRewardsOnly, formattedRemainingCashPrice, formattedTotalPriceInRewardUnits, loyaltyUnitName, currency],
  );

  const rewardUnitCharge: string | undefined = useMemo(
    () => getRewardUnitCharge({ isPayWithRewardsOnly, formattedAppliedRewardUnitsWithUnitName }),
    [isPayWithRewardsOnly, formattedAppliedRewardUnitsWithUnitName],
  );

  const formattedPricePerTicket: string = useMemo(
    () => formatPrice({ amount: pricePerTicket, isPayWithRewardsOnly, rewardUnitsPerDollar, rewardUnitName }),
    [pricePerTicket, isPayWithRewardsOnly, rewardUnitsPerDollar, rewardUnitName],
  );

  const ticketsLabel: string = useMemo(
    () => t('priceBreakdownInfo.tickets', { quantity, pricePerTicket: formattedPricePerTicket }),
    [t, quantity, formattedPricePerTicket],
  );

  const formattedTotalTicketsPrice: string = useMemo(
    () => formatPrice({ amount: totalTicketsPrice, isPayWithRewardsOnly, rewardUnitsPerDollar, rewardUnitName }),
    [totalTicketsPrice, isPayWithRewardsOnly, rewardUnitsPerDollar, rewardUnitName],
  );

  const formattedServiceFee: string = useMemo(
    () => formatPrice({ amount: serviceFee, isPayWithRewardsOnly, rewardUnitsPerDollar, rewardUnitName }),
    [serviceFee, isPayWithRewardsOnly, rewardUnitsPerDollar, rewardUnitName],
  );

  const formattedDeliveryFee: string = useMemo(
    () => formatPrice({ amount: deliveryFee, isPayWithRewardsOnly, rewardUnitsPerDollar, rewardUnitName }),
    [deliveryFee, isPayWithRewardsOnly, rewardUnitsPerDollar, rewardUnitName],
  );

  const formattedTax: string = useMemo(
    () => formatPrice({ amount: tax, isPayWithRewardsOnly, rewardUnitsPerDollar, rewardUnitName }),
    [tax, isPayWithRewardsOnly, rewardUnitsPerDollar, rewardUnitName],
  );

  const formattedOrderTotal: string = useMemo(
    () => formatPrice({ amount: orderTotal, isPayWithRewardsOnly, rewardUnitsPerDollar, rewardUnitName }),
    [orderTotal, isPayWithRewardsOnly, rewardUnitsPerDollar, rewardUnitName],
  );

  return {
    ...props,
    mainCharge,
    rewardUnitCharge,
    ticketsLabel,
    formattedTotalTicketsPrice,
    formattedServiceFee,
    formattedDeliveryFee,
    formattedTax,
    formattedOrderTotal,
    faceValue,
  };
};
