import cx from 'classnames';
import React from 'react';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Logo from '../../atoms/Logo';
import Spinner from '../../atoms/Spinner';
import Text from '../../atoms/Text';
import AdditionalTicketInformationList from '../../molecules/AdditionalTicketInformationList';
import HighlightMessage from '../../molecules/HighlightMessage';
import InfoDetails from '../../molecules/InfoDetails';
import InfoHeader from '../../molecules/InfoHeader';
import InfoOnMultipleLine from '../../molecules/InfoOnMultipleLine';
import { MarketingOptinMlb } from '../../molecules/MarketingOptinMlb';
import PaymentMethod from '../../molecules/PaymentMethod';
import SeatInfo from '../../molecules/SeatInfo';
import TextItem from '../../molecules/TextItem';
import styles from './OrderDetails.module.scss';
import usePresenter from './OrderDetails.presenter';
import getProps from './OrderDetails.props';
import type { OrderDetailsCombinedProps } from './types';

const OrderDetails: React.FC<OrderDetailsCombinedProps> = (props) => {
  const {
    className,
    classes,
    closeButton,
    infoHeader,
    printButton,
    divider,
    ticketInfoTitle,
    highlightMessage,
    infoDetails,
    seatInfo,
    sellerNotes,
    label,
    logo,
    divider1,
    deliveryInfoTitle,
    additionalTicketInformationList,
    deliveryDateInfo,
    downloadButton,
    divider2,
    billingInfoTitle,
    infoOnMultipleLine,
    phoneNumber,
    paymentMethod,
    totalCardPayment,
    rightContent,
    rewardsMilesRedeemed,
    // MANUAL OVERRIDE STARTS
    showHighlight,
    showDownloadButton,
    showCloseButton,
    showLogo,
    isLoading,
    marketingOptinMlbProps,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);
  // MANUAL OVERRIDE STARTS
  let hightLight;
  let download;
  let close;
  let viewLogo;

  const internalProps = getProps('');
  const currentStyle = styles.orderDetails;
  if (isLoading) {
    return <Spinner></Spinner>;
  }
  if (showHighlight) {
    hightLight = (
      <HighlightMessage
        className={cx(styles.highlightMessage, classes?.highlightMessage)}
        {...internalProps.highlightMessage}
        {...highlightMessage}
      />
    );
  }
  if (showDownloadButton) {
    download = <Button
      className={cx(styles.downloadButton, classes?.downloadButton)}
      {...internalProps.downloadButton}
      {...downloadButton} />;
  }
  if (showCloseButton) {
    close = (
      <>
        <div className={styles.buttonContainer}>
          <Button
            className={cx(styles.closeButton, classes?.closeButton)}
            {...internalProps.closeButton}
            {...closeButton} />
        </div>
        <div className={styles.buttonContainerMobile}>
          <Button
            className={cx(styles.closeButton, classes?.closeButton)}
            {...internalProps.closeButton}
            {...closeButton} />
        </div>
      </>
    );
  }
  if (showLogo) {
    viewLogo = <Logo
      className={cx(styles.logo, classes?.logo)}
      {...internalProps.logo}
      {...logo} />;
  }
  // MANUAL OVERRIDE ENDS
  return (
    // MANUAL OVERRIDE STARTS
    <div className={cx(currentStyle, className)}>
      {close}
      <div className={cx(styles.topContent, classes?.topContent)}>
        <InfoHeader
          className={cx(styles.infoHeader, classes?.infoHeader)}
          {...internalProps.infoHeader}
          {...infoHeader} />
        <Button
          className={cx(styles.printButton, classes?.printButton)}
          {...internalProps.printButton}
          {...printButton} />
      </div>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider} />
      <div className={cx(styles.content, classes?.content)}>
        <div className={cx(styles.ticketContent, classes?.ticketContent)}>
          <Text
            className={cx(styles.ticketInfoTitle, classes?.ticketInfoTitle)}
            {...internalProps.ticketInfoTitle}
            {...ticketInfoTitle} />
          {hightLight}
          <InfoDetails
            className={cx(styles.infoDetails, classes?.infoDetails)}
            {...internalProps.infoDetails}
            {...infoDetails} />
          <SeatInfo
            className={cx(styles.seatInfo, classes?.seatInfo)}
            {...internalProps.seatInfo}
            {...seatInfo} />
          <TextItem
            className={cx(styles.sellerNotes, classes?.sellerNotes)}
            {...internalProps.sellerNotes}
            {...sellerNotes} />
          <div className={cx(styles.logoContent, classes?.logoContent)}>
            <Text
              className={cx(styles.label, classes?.label)}
              {...internalProps.label}
              {...label} />
            {viewLogo}
          </div>
        </div>
        <Divider
          className={cx(styles.dividerVertical)}
          {...internalProps.divider1}
          {...divider1}
          type="Vertical"
        />
        <div className={cx(styles.deliveryContent, classes?.deliveryContent)}>
          <Text
            className={cx(styles.deliveryInfoTitle, classes?.deliveryInfoTitle)}
            {...internalProps.deliveryInfoTitle}
            {...deliveryInfoTitle} />
          <AdditionalTicketInformationList
            className={cx(styles.additionalTicketInformationList, classes?.additionalTicketInformationList)}
            {...internalProps.additionalTicketInformationList}
            {...additionalTicketInformationList}
          />
          <TextItem
            className={cx(styles.deliveryDateInfo, classes?.deliveryDateInfo)}
            {...internalProps.deliveryDateInfo}
            {...deliveryDateInfo} />
          {download}
        </div>
      </div>
      <div className={cx(styles.deliveryContentMobile)}>
        <Text
          className={cx(styles.deliveryInfoTitle, classes?.deliveryInfoTitle)}
          {...internalProps.deliveryInfoTitle}
          {...deliveryInfoTitle} />
        <AdditionalTicketInformationList
          className={cx(styles.additionalTicketInformationList, classes?.additionalTicketInformationList)}
          {...internalProps.additionalTicketInformationList}
          {...additionalTicketInformationList}
        />
        <TextItem
          className={cx(styles.deliveryDateInfo, classes?.deliveryDateInfo)}
          {...internalProps.deliveryDateInfo}
          {...deliveryDateInfo} />
        {download}
      </div>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider1} />
      <div className={cx(styles.billingContent, classes?.billingContent)}>
        <Text
          className={cx(styles.billingInfoTitle, classes?.billingInfoTitle)}
          {...internalProps.billingInfoTitle}
          {...billingInfoTitle} />
        <div className={cx(styles.content, classes?.content)}>
          <div className={cx(styles.leftContent, classes?.leftContent)}>
            <InfoOnMultipleLine
              className={cx(styles.infoOnMultipleLine, classes?.infoOnMultipleLine)}
              {...internalProps.infoOnMultipleLine}
              {...infoOnMultipleLine}
            />
            <TextItem
              className={cx(styles.phoneNumber, classes?.phoneNumber)}
              {...internalProps.phoneNumber}
              {...phoneNumber} />
          </div>
          <div className={cx(styles.middleContent, classes?.middleContent)}>
            <PaymentMethod
              className={cx(styles.paymentMethod, classes?.paymentMethod)}
              {...internalProps.paymentMethod}
              {...paymentMethod}
            />
            <TextItem
              className={cx(styles.totalCardPayment, classes?.totalCardPayment)}
              {...internalProps.totalCardPayment}
              {...totalCardPayment}
            />
          </div>
          <TextItem
            className={cx(styles.rightContent, classes?.rightContent)}
            {...internalProps.rightContent}
            {...rightContent} />
        </div>
      </div>
      <div className={styles.ticketContentMobile}>
        <Text
          className={cx(styles.ticketInfoTitle, classes?.ticketInfoTitle)}
          {...internalProps.ticketInfoTitle}
          {...ticketInfoTitle} />
        {hightLight}
        <InfoDetails
          className={cx(styles.infoDetails, classes?.infoDetails)}
          {...internalProps.infoDetails}
          {...infoDetails} />
        <SeatInfo
          className={cx(styles.seatInfo, classes?.seatInfo)}
          {...internalProps.seatInfo}
          {...seatInfo} />
        <TextItem
          className={cx(styles.sellerNotes, classes?.sellerNotes)}
          {...internalProps.sellerNotes}
          {...sellerNotes} />
        <div className={cx(styles.logoContent, classes?.logoContent)}>
          <Text
            className={cx(styles.label, classes?.label)}
            {...internalProps.label}
            {...label} />
          {viewLogo}
        </div>
      </div>
      <div className={styles.billingContentMobile}>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider2}
          {...divider2} />
        <Text
          className={cx(styles.billingInfoTitle, classes?.billingInfoTitle)}
          {...internalProps.billingInfoTitle}
          {...billingInfoTitle} />
        <InfoOnMultipleLine
          className={cx(styles.infoOnMultipleLine, classes?.infoOnMultipleLine)}
          {...internalProps.infoOnMultipleLine}
          {...infoOnMultipleLine}
        />
        <TextItem
          className={cx(styles.phoneNumber, classes?.phoneNumber)}
          {...internalProps.phoneNumber}
          {...phoneNumber} />
        <PaymentMethod
          className={cx(styles.paymentMethod, classes?.paymentMethod)}
          {...internalProps.paymentMethod}
          {...paymentMethod} />
        <TextItem
          className={cx(styles.totalCardPayment, classes?.totalCardPayment)}
          {...internalProps.totalCardPayment}
          {...totalCardPayment} />
        <TextItem
          className={cx(styles.rewardsMilesRedeemed, classes?.rewardsMilesRedeemed)}
          {...internalProps.rewardsMilesRedeemed}
          {...rewardsMilesRedeemed}
        />
      </div>
      {!!marketingOptinMlbProps && <MarketingOptinMlb {...marketingOptinMlbProps} />}
    </div>
    // MANUAL OVERRIDE ENDS
  );
};

export default OrderDetails;
