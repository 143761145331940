import { useCallback, useMemo, type KeyboardEvent } from 'react';
import type { OrderInfoPresenterProps, OrderInfoProps } from './OrderInfo.types';
import { getFaceValueText, getPaymentInfoText } from './OrderInfo.utils';

export const usePresenter = (props: OrderInfoProps): OrderInfoPresenterProps => {
  const {
    openTermsOfUseModal,
    isFaceValueShown,
    remainingCashPrice,
    formattedAppliedRewardUnitsWithUnitName,
    currency,
    appliedRewardUnits,
    formattedRemainingCashPriceWithCents,
    accountCardLastFourDigits,
  } = props;

  const openTermsOfUseModalOnKeyDown = useCallback((event: KeyboardEvent<HTMLAnchorElement>) => {
    if (['Enter', 'Space'].includes(event.code)) {
      event.preventDefault();
      openTermsOfUseModal();
    }
  }, [openTermsOfUseModal]);

  /** Text to include in the bottom disclaimer section if face value is provided. May be an empty string. */
  const faceValueText: string = useMemo(() => getFaceValueText({ isFaceValueShown }), [isFaceValueShown]);

  /**
   * Text to include in the bottom disclaimer section for the payment information.
   * 3 variations are possible:
   * - For cash only payments.
   * - For rewards only payments.
   * - For split payments with cash and rewards.
   */
  const paymentInfoText: string = useMemo(
    () => getPaymentInfoText({
      remainingCashPrice,
      formattedRemainingCashPriceWithCents,
      currency,
      appliedRewardUnits,
      formattedAppliedRewardUnitsWithUnitName,
      accountCardLastFourDigits,
    }),
    [
      remainingCashPrice,
      formattedRemainingCashPriceWithCents,
      currency,
      appliedRewardUnits,
      formattedAppliedRewardUnitsWithUnitName,
      accountCardLastFourDigits,
    ],
  );

  return {
    ...props,
    openTermsOfUseModalOnKeyDown,
    faceValueText,
    paymentInfoText,
  };
};
