import { useCallback, type KeyboardEvent } from 'react';
import type { PaymentInfoPresenterProps, PaymentInfoProps } from './PaymentInfo.types';

export const usePresenter = (props: PaymentInfoProps): PaymentInfoPresenterProps => {
  const onActionButtonKeyDown = useCallback((event: KeyboardEvent<HTMLElement>) => event.stopPropagation(), []);

  return {
    ...props,
    onActionButtonKeyDown,
  };
};
