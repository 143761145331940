import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Modals.module.scss';
import type { ModalState } from './Modals.types';

export const useModalState = (params?: {
  /** Optional flag to force modal to open */
  isModalOpenOverride?: boolean;
  /** Optional function to call when modal closes */
  onModalClosed?: () => void;
  /** Optional flag to enable slide up/down animation on opening/closing of the modal */
  isAnimationEnabled?: boolean;
}): ModalState => {
  const { isModalOpenOverride = false, onModalClosed, isAnimationEnabled = false } = params ?? {};

  // Indicates whether modal should be opened
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // Class name to be applied to the modal to enable slide up/down animation on opening/closing of the modal
  const [animatedModalClassName, setAnimatedModalClassName] = useState('');

  const openModal = useCallback(() => {
    if (isAnimationEnabled) {
      // Apply slide up animation on opening of the modal
      setAnimatedModalClassName(styles.modalSlideUp);
    }

    setIsModalOpen(true);
  }, [isAnimationEnabled]);

  useEffect(() => {
    if (isModalOpenOverride) {
      openModal();
    }
  }, [isModalOpenOverride, openModal]);

  const closeModal = useCallback(() => {
    if (isAnimationEnabled) {
      // Apply slide down animation on closing of the modal
      setAnimatedModalClassName(styles.modalSlideDown);
      // Delay closing of the modal to give time to complete closing animation
      setTimeout(() => {
        setIsModalOpen(false);
        onModalClosed?.();
      }, 250);
    } else {
      setIsModalOpen(false);
      onModalClosed?.();
    }
  }, [isAnimationEnabled, onModalClosed]);

  return useMemo(
    () => ({ isModalOpen, animatedModalClassName, openModal, closeModal }),
    [isModalOpen, animatedModalClassName, openModal, closeModal],
  );
};
