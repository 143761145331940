import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';

type SubmenuTabItemInternalProps = {
  text?: TextProps;
  selectedItemDivider?: DividerProps;
};

type SubmenuTabItemPropsValues = {
  'Default': SubmenuTabItemInternalProps;
  'Selected': SubmenuTabItemInternalProps;
};

const propValues: SubmenuTabItemPropsValues = {
  'Default': {
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
    selectedItemDivider: {
      type: 'Vertical',
      style: 'Thick',
      colour: 'CoreBlue60',
    },
  },
  'Selected': {
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
    selectedItemDivider: {
      type: 'Vertical',
      style: 'Thick',
      colour: 'CoreBlue60',
    },
  },
};

const getProps = (type: string): SubmenuTabItemInternalProps => {
  const values: SubmenuTabItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
