import cx from 'classnames';
import React, { useRef, type ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Backdrop } from '../../atoms/Backdrop';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import AccountMenuBlock from '../../blocks/AccountMenuBlock';
import NavMenuItem from '../NavMenuItem';
import styles from './AccountMenu.module.scss';
import usePresenter from './AccountMenu.presenter';
import type { AccountMenuCombinedProps } from './types';

// This component will be rendered on desktop whereas AccountMenuBlock is rendered on mobile devices
const AccountMenu: React.FC<AccountMenuCombinedProps> = (props) => {
  const {
    state = 'Collapsed',
    className,
    greetingFirstName,
    expandButton,
    collapseButton,
    closeButton,
    renderType,
    accountMenuBlock,
    handleToggle,
    onKeyDown,
    myAccount,
    myTickets,
    logOut,
    isDropdownOpen,
    dropdownToggleAriaLabel,
  } = usePresenter(props);

  const toggleRef = useRef<HTMLDivElement | null>(null);

  const currentStyle: string = cx(styles.accountMenu, styles[`accountMenu${state}`]);

  const greetingFirstNameView: ReactNode = (
    <div className={styles.greetingFirstNameContainer}>
      <Text
        size='Medium'
        style='SemiBold'
        colour='BaseLight'
        className={styles.greetingFirstName}
        {...greetingFirstName}
      />
    </div>
  );

  const toggleButtonView: ReactNode = (
    <Button
      type='Icon'
      style='Text'
      size='Small'
      focusable={false}
      className={styles.toggleButton}
      {...(state === 'Collapsed' ? expandButton : collapseButton)}
    />
  );

  const closeButtonView: ReactNode = state === 'Expanded' && (
    <Button
      type='Icon'
      style='Text'
      size='Small'
      className={styles.closeButton}
      {...closeButton}
    />
  );

  const accountInfo: ReactNode = (
    <div className={cx(currentStyle, className)}>
      {greetingFirstNameView}
      {toggleButtonView}
      {closeButtonView}
    </div>
  );

  if (renderType) {
    return (
      <div className={cx(currentStyle, className)}>
        {accountInfo}
      </div>
    );
  }

  return (
    <>
      <Dropdown
        show={isDropdownOpen}
        onToggle={handleToggle}
        className={styles.dropdown}
      >
        <Dropdown.Toggle
          ref={toggleRef}
          as='div'
          tabIndex={0}
          role='button'
          aria-expanded={isDropdownOpen}
          aria-label={dropdownToggleAriaLabel}
          onKeyDown={onKeyDown}
          className={styles.dropdownToggle}
        >
          <div className={cx(currentStyle, className)}>
            {accountInfo}
          </div>
        </Dropdown.Toggle>
        {!!isDropdownOpen && (<>
          <Dropdown.Menu className={styles.dropdownMenu} align='right'>
            <NavMenuItem className={styles.myAccount} {...myAccount} />
            <NavMenuItem className={styles.myTickets} {...myTickets} />
            <NavMenuItem className={styles.logOut} {...logOut} />
          </Dropdown.Menu>
          <Backdrop onClose={() => handleToggle?.(false)} />
        </>)}
      </Dropdown>
      <AccountMenuBlock {...accountMenuBlock} />
    </>
  );
};

export default AccountMenu;
