import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import styles from './TicketAlertModal.module.scss';
import { usePresenter } from './TicketAlertModal.presenter';
import type { TicketAlertModalProps } from './TicketAlertModal.types';

export const TicketAlertModal: React.FC<TicketAlertModalProps> = (props) => {
  const {
    title,
    message,
    primaryButtonLabel,
    onPrimaryButtonClick,
    onCloseButtonClick,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  const closeButton: ReactNode = (
    <Button
      type='Icon'
      style='Text'
      size='Small'
      icon={{
        asset: 'Close',
        style: 'BaseDark',
        className: styles.closeButtonIcon,
      }}
      ariaLabel={t('common.close')}
      onClick={onCloseButtonClick}
      className={styles.closeButton}
    />
  );

  const header: ReactNode = (
    <div className={styles.header}>
      <Icon
        asset='Warning'
        style='BaseDark'
        className={styles.headerIcon}
      />
      <Text
        type='Heading'
        size='Small'
        style='Light'
        colour='BaseDark'
        value={title}
      />
    </div>
  );

  const messageView: ReactNode = (
    <Text
      size='Large'
      style='Regular'
      colour='SubduedDark'
      value={message}
    />
  );

  const primaryButton: ReactNode = (
    <Button
      type='Text'
      style='Contained'
      size='Large'
      text={{ value: primaryButtonLabel }}
      onClick={onPrimaryButtonClick}
      className={styles.primaryButton}
    />
  );

  return (
    <Modal
      show={true}
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      onHide={onCloseButtonClick}
    >
      <div className={cx(styles.ticketAlertModal, className)}>
        {closeButton}
        <div className={styles.content}>
          {header}
          {messageView}
          {primaryButton}
        </div>
      </div>
    </Modal>
  );
};
