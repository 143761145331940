import React from 'react';
import cx from 'classnames';

import Text from '../Text';

import styles from './Tab.module.scss';
import usePresenter from './Tab.presenter';
import getProps from './Tab.props';
import { TabCombinedProps } from './types';


const Tab: React.FC<TabCombinedProps> = (props) => {
  const {
    style,
    colour,
    type,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,    
    onClick,
    text,
    id, // MANUAL OVERRIDE
    role, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${style}${colour}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`tab${variantName}`];


  // MANUAL OVERRIDE STARTS
  let contentView;

  switch (variantName) {
    case 'RoundedDarkActive':
    case 'RoundedDarkDefault':
    case 'SquareLightActive':
    case 'SquareLightDefault': {
      contentView = (
        <>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <div className={cx(styles.indicator, classes?.indicator)}/>
        </>
      );
      break;
    }
    default:
      contentView = <div>�</div>;
      break;
  }

  if (onClick) {
    return (
      <button
        id={id} // MANUAL OVERRIDE
        role={role} // MANUAL OVERRIDE
        disabled={disabled}
        type={buttonType}
        form={buttonForm}
        onClick={onClick}
        className={cx(currentStyle, className)}>
        {contentView}
      </button>
    );
  }

  return (
    <div
      id={id}
      className={cx(currentStyle, className)}>
      {contentView}
    </div>
  );
  // MANUAL OVERRIDE ENDS
};

export default Tab;

