import type { Event } from '../../../modules/partnership';
import type { EventCardProps } from '../../molecules/EventCard';

/**
 * Gets the array of event card props for rendering events
 * @returns {EventCardProps[]} Array of event card props for rendering events
 */
export const getEventCardProps = (params: {
  events: Event[];
  shouldShowEventImages: boolean | undefined;
  /** Page name to use for analytics */
  pageName: string;
}): EventCardProps[] => {
  const { events, shouldShowEventImages, pageName } = params;

  return events.map((event: Event, eventCardIndex: number) => ({
    key: event.id,
    event,
    variant: 'horizontal',
    shouldShowImage: shouldShowEventImages,
    pageName,
    sectionTitleKey: undefined,
    sectionIndex: undefined,
    eventCardIndex,
  }));
};
