import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { URLs } from '../../../lib/constants';
import { AuthContext } from '../../../modules/auth';
import { UserIdleContext } from '../../../modules/userIdle';
import type { AlertModalCombinedProps } from './types';

const usePresenter = (): AlertModalCombinedProps => {
  const { showWarning, closeWarning } = useContext(UserIdleContext);
  const { refetchAccount } = useContext(AuthContext);
  const { t } = useTranslation();

  const inactive = () => {
    window.location.assign(URLs.Logout);
  };

  const renewSession = () => {
    closeWarning();
    refetchAccount();
  };

  return {
    open: showWarning,
    title: {
      value: t('inactiveModal.title'),
    },
    message: {
      value: t('inactiveModal.message'),
    },
    closeButton: {
      onClick: closeWarning,
    },
    secondaryButton: {
      text: {
        value: t('inactiveModal.signOut'),
      },
      onClick: inactive,
    },
    primaryButton: {
      text: {
        value: t('inactiveModal.stillHere'),
      },
      onClick: renewSession,
    },
  };
};

export default usePresenter;
