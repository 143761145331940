import type { TextColourEnum } from '../../atoms/Text';
import type { SubTotalTheme } from './SubTotal.types';

export const PrimaryTextColoursByTheme = {
  light: 'BaseLight',
  dark: 'BaseDark',
} as const satisfies Record<SubTotalTheme, TextColourEnum>;

export const SecondaryTextColoursByTheme = {
  light: 'Charcoal10',
  dark: 'SubduedLight',
} as const satisfies Record<SubTotalTheme, TextColourEnum>;
