import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import { ToolTip } from '../../atoms/ToolTip';
import styles from './LabelledContent.module.scss';
import { usePresenter } from './LabelledContent.presenter';
import type { LabelledContentPresenterProps, LabelledContentProps } from './LabelledContent.types';

export const LabelledContent: React.FC<LabelledContentProps> = (props) => {
  const presenterProps: LabelledContentPresenterProps = usePresenter(props);

  const {
    labelKey,
    labelTextType = 'Body',
    labelTextColour = 'BaseDark',
    labelInfoTooltipKey,
    itemTextType = 'Body',
    itemTextSize = 'Medium',
    className,
  } = presenterProps;

  const { t } = useTranslation();

  const label: ReactNode = (
    <Text
      type={labelTextType}
      size='Medium'
      style='SemiBold'
      colour={labelTextColour}
      value={t(labelKey)}
    />
  );

  const labelInfoTooltip: ReactNode = labelInfoTooltipKey && (
    <ToolTip
      textKey={labelInfoTooltipKey}
      ariaLabelKey={labelInfoTooltipKey}
      placement='auto-end'
    >
      <Icon
        asset='Info'
        style='DigitalGrey80'
        className={styles.labelInfoTooltipIcon}
      />
    </ToolTip>
  );

  // Use NonNullable<ReactNode> to ensure we return a value
  const getContent = (): NonNullable<ReactNode> => {
    // Render a single item using base styles
    if ('item' in presenterProps) {
      return (
        <Text
          type={itemTextType}
          size={itemTextSize}
          style='Regular'
          colour='SubduedLight'
          value={presenterProps.item}
        />
      );
    }

    // Render multiple items in a column using base styles
    if ('items' in presenterProps) {
      return (<>
        {presenterProps.items.map((item: string, itemIndex: number) => (
          <Text
            key={itemIndex}
            type={itemTextType}
            size={itemTextSize}
            style='Regular'
            colour='SubduedLight'
            value={item}
          />
        ))}
      </>);
    }

    // Render a custom item as-is
    return presenterProps.children;
  };

  return (
    <div className={cx(styles.labelledContent, className)}>
      <div className={styles.labelRow}>
        {label}
        {labelInfoTooltip}
      </div>
      {getContent()}
    </div>
  );
};
