import type { CSSProperties } from 'react';
import { trackSelectContentEvent, type EventKeys } from '../../../modules/analytics';
import type { AppendQueryParamsToUrlFunc } from '../../../modules/navigation/Navigation.types';
import type { MultiToggleOption } from './MultiToggle.types';

export const getMultiToggleStateFromUrl = <TId extends string | number>(params: {
  /** Array of allowed multi toggle option Ids */
  multiToggleOptionIds: readonly TId[];
  /** Value of multi toggle parameter from the current URL */
  multiToggleParam: string | undefined;
  /** Function to clear multi toggle query parameter in the URL */
  clearMultiToggleParamInUrl: () => void;
}): TId | undefined => {
  const { multiToggleOptionIds, multiToggleParam, clearMultiToggleParamInUrl } = params;

  if (!multiToggleParam) {
    return undefined;
  }

  let isMultiToggleParamValid: boolean = false;

  try {
    // Accept only valid values for multi toggle parameter.
    // Try to find an existing multi toggle option by Id.
    const multiToggleStateFromUrl: TId | undefined = multiToggleOptionIds.find((multiToggleOptionId: TId) => multiToggleOptionId === multiToggleParam);
    if (multiToggleStateFromUrl) {
      isMultiToggleParamValid = true;
      return multiToggleStateFromUrl;
    }
  } finally {
    // Remove invalid multi toggle parameter from the URL
    if (!isMultiToggleParamValid) {
      clearMultiToggleParamInUrl();
    }
  }

  return undefined;
};

export const selectMultiToggleOption = <TId extends string | number>(params: {
  /** Query parameter in the URL that will be used for managing the state of the multi toggle component */
  queryParamName: string;
  /** Selected multi toggle option */
  newMultiToggleOption: MultiToggleOption<TId>;
  /** Function that appends query parameters to the current URL */
  appendQueryParamsToUrl: AppendQueryParamsToUrlFunc;
  /** Function to track analytics events */
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown> | undefined) => void;
}): void => {
  const { queryParamName, newMultiToggleOption, appendQueryParamsToUrl, trackEvent } = params;

  appendQueryParamsToUrl({ [queryParamName]: newMultiToggleOption.id.toString() });

  trackSelectContentEvent(
    trackEvent,
    'ToggleOption',
    'Filter',
    newMultiToggleOption.id.toString(),
  );
};

/**
 * Gets styles that set X position and width of the selection chip.
 * @returns {CSSProperties | undefined} Styles that set X position and width of the selection chip.
 */
export const getSelectionChipStyles = <TId extends string | number>(params: {
  /** Array of allowed multi toggle options */
  multiToggleOptions: MultiToggleOption<TId>[];
  /** Current multi toggle state */
  multiToggleState: TId | undefined;
  /** Array of HTML elements for multi toggle items */
  itemHtmlElements: Array<HTMLButtonElement | null>;
}): CSSProperties | undefined => {
  const { multiToggleOptions, multiToggleState, itemHtmlElements } = params;

  const selectedIndex: number = multiToggleState ? multiToggleOptions.findIndex(({ id }) => id === multiToggleState) : -1;

  const selectedItem: HTMLButtonElement | null | undefined = itemHtmlElements[selectedIndex];

  if (selectedItem) {
    return {
      left: `${selectedItem.offsetLeft}px`,
      width: `${selectedItem.clientWidth}px`,
    };
  }

  return undefined;
};
