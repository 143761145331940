import cx from 'classnames';
import styles from './ExpandableContent.module.scss';

/**
 * Returns class name to be applied to the content if the content needs to be truncated.
 * @returns {string | undefined} styles.contentWithMaxLines + styles.contentWithXMaxLines if maxLines is a positive number. Otherwise returns undefined.
 */
export const getContentClassName = (params: {
  /** Maximum number of lines to show before content gets truncated */
  maxLines: number | undefined;
}): string | undefined => {
  const { maxLines } = params;
  return maxLines && maxLines > 0
    ? cx(styles.contentWithMaxLines, styles[`contentWith${maxLines}MaxLines`])
    : undefined;
};

/**
 * Checks if See More button should be shown.
 * @returns {boolean} True if the content is truncated, otherwise false.
 */
export const checkIsSeeMoreButtonShown = (params: {
  /** HTML div element of the content text */
  contentHtmlElement: HTMLDivElement | null;
}): boolean => {
  const { contentHtmlElement } = params;
  return !!contentHtmlElement && contentHtmlElement.offsetHeight < contentHtmlElement.scrollHeight;
};
