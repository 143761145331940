import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import HomeImageAsset from '../../../resources/images/HomeImage.png';
import Text from '../../atoms/Text';
import { Banner, HomePageCardholderExclusivesBannerProps, HomePageMLBBannerProps } from '../../molecules/Banner';
import type { EventCardProps } from '../../molecules/EventCard';
import { HeroBanner } from '../../molecules/HeroBanner';
import type { PerformerCardProps } from '../../molecules/PerformerCard';
import { CarouselSection } from '../../organisms/CarouselSection/CarouselSection';
import { RegionFilter } from '../../organisms/RegionFilter';
import styles from './HomePage.module.scss';
import { usePresenter } from './HomePage.presenter';
import type { HomePageProps } from './HomePage.types';

export const HomePage: React.FC<HomePageProps> = (props) => {
  const {
    cardholderExclusiveEventsCarouselSectionProps,
    upcomingEventsCarouselSectionProps,
    musicPerformersCarouselSectionProps,
    sportsPerformersCarouselSectionProps,
    comedyPerformersCarouselSectionProps,
    theaterPerformersCarouselSectionProps,
    regions,
    regionFilterState,
    onMlbBannerButtonClick,
    onCardholderExclusivesBannerButtonClick,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  const heroBanner: ReactNode = (
    <HeroBanner
      titleKey='homePage.heroTitle'
      descriptionKey='homePage.heroDescription'
      backgroundImageUrl={HomeImageAsset}
    />
  );

  const mlbBanner: ReactNode = (
    <Banner
      {...HomePageMLBBannerProps}
      onButtonClick={onMlbBannerButtonClick}
      className={styles.bannerContainer}
      classes={{
        banner: styles.banner,
        content: styles.content,
        title: styles.title,
        description: styles.description,
        buttonContainer: styles.buttonContainer,
        buttonText: styles.buttonText,
      }}
    />
  );

  const cardholderExclusivesBanner: ReactNode = (
    <Banner
      {...HomePageCardholderExclusivesBannerProps}
      onButtonClick={onCardholderExclusivesBannerButtonClick}
      className={cx(styles.bannerContainer, styles.cardholderExclusivesBannerContainer)}
      classes={{
        banner: cx(styles.banner, styles.cardholderExclusivesBanner),
        content: styles.content,
        title: styles.title,
        description: styles.description,
        buttonContainer: styles.buttonContainer,
        buttonText: styles.buttonText,
      }}
    />
  );

  const filters: ReactNode = (<>
    <Text
      size='Large'
      style='Regular'
      colour='SubduedLight'
      value={t('homePage.browseEventsIn')}
      className={styles.filterHint}
    />
    <RegionFilter
      regions={regions}
      regionFilterState={regionFilterState}
      canSearchRegion={true}
      canUseCurrentRegion={true}
      isClearable={false}
      className={styles.regionFilter}
      classes={{
        filterToggleWrapper: styles.filterToggleWrapper,
        filterToggle: styles.filterToggle,
        displayValue: styles.displayValue,
        filterIcon: styles.filterIcon,
      }}
    />
  </>);

  const cardholderExclusiveEventsCarouselSection: ReactNode = <CarouselSection<EventCardProps> {...cardholderExclusiveEventsCarouselSectionProps} />;
  const upcomingEventsCarouselSection: ReactNode = <CarouselSection<EventCardProps> {...upcomingEventsCarouselSectionProps} />;
  const musicPerformersCarouselSection: ReactNode = <CarouselSection<PerformerCardProps> {...musicPerformersCarouselSectionProps} />;
  const sportsPerformersCarouselSection: ReactNode = <CarouselSection<PerformerCardProps> {...sportsPerformersCarouselSectionProps} />;
  const comedyPerformersCarouselSection: ReactNode = <CarouselSection<PerformerCardProps> {...comedyPerformersCarouselSectionProps} />;
  const theaterPerformersCarouselSection: ReactNode = <CarouselSection<PerformerCardProps> {...theaterPerformersCarouselSectionProps} />;

  return (
    <div className={cx(styles.homePage, className)}>
      <div className={cx(styles.container, styles.darkContainer)}>
        {heroBanner}
        <div className={styles.carouselContainer}>
          {cardholderExclusiveEventsCarouselSection}
        </div>
        <div className={styles.subContainer}>
          {mlbBanner}
        </div>
      </div>
      <div className={styles.container}>
        <div className={cx(styles.subContainer, styles.filtersContainer, styles.withVerticalBorders)}>
          {filters}
        </div>
        <div className={cx(styles.carouselContainer, styles.withVerticalBorders)}>
          {upcomingEventsCarouselSection}
          {musicPerformersCarouselSection}
          {sportsPerformersCarouselSection}
          {comedyPerformersCarouselSection}
          {theaterPerformersCarouselSection}
        </div>
      </div>
      <div className={styles.container}>
        <div className={cx(styles.subContainer, styles.withVerticalBorders)}>
          {cardholderExclusivesBanner}
        </div>
      </div>
    </div>
  );
};
