import { useCallback, useRef, useState, type FocusEvent } from 'react';
import type { FocusState, FocusStateHook } from './types';

/**
 * Hook to handle focus state of text inputs.
 * This is needed when we need to have different CSS styles when text input is focused via tabbing vs mouse clicking.
 * The problem is that for text inputs :focus-visible (which is normally used for detecting tabbing) is also set for mouse clicks.
 */
export const useFocusState = (): FocusStateHook => {
  const [focusState, setFocusState] = useState<FocusState>('no-focus');

  const timerRef = useRef<NodeJS.Timeout>();

  const clearTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const onClick = useCallback(() => {
    clearTimer();
    setFocusState('mouse-focus');
  }, [clearTimer]);

  const onFocus = useCallback((event: FocusEvent) => {
    if (event.relatedTarget) {
      // If related target is defined then focus may be set via tabbing.
      // Need to delay by 150ms in case onClick follows shortly.
      clearTimer();
      timerRef.current = setTimeout(() => setFocusState('tab-focus'), 150);
    } else {
      onClick();
    }
  }, [clearTimer, onClick]);

  const onBlur = useCallback(() => {
    clearTimer();
    setFocusState('no-focus');
  }, [clearTimer]);

  return {
    focusState,
    onClick,
    onFocus,
    onBlur,
  };
};
