import cx from 'classnames';
import React from 'react';

import NavMenuItem from '../../molecules/NavMenuItem';
import styles from './NavMenuList.module.scss';
import useNavMenuListPresenter from './NavMenuList.presenter';
import getProps from './NavMenuList.props';
import { NavMenuListCombinedProps } from './types';

const NavMenuList: React.FC<NavMenuListCombinedProps> = (props) => {
  const {
    className,
    classes,
    navMenuItems,
  } = useNavMenuListPresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.navMenuList;

  const navMenuItemViews = navMenuItems?.map((navMenuItem, index) => (
    <NavMenuItem
      key={index}
      className={cx(styles.navMenuItem, classes?.navMenuItem)}
      {...internalProps.navMenuItem}
      {...navMenuItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {navMenuItemViews}
    </div>
  );
};

export default NavMenuList;
