import { useCallback, useMemo } from 'react';
import { useQueryParamFromUrl } from '../../../lib/util';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import type { PresetFilterOption } from './PresetFilter.types';
import { getPresetFilterStateFromUrl } from './PresetFilter.utils';

/**
 * Hook to extract preset filter state from query parameters in the current URL.
 * Examples:
 * - /all-events?tab=exclusives&category=music
 * - /all-events?tab=music&category=14
 * - /all-events?tab=sports&category=3&performer=52
 * - /all-events?tab=theater&category=23
 */
export const usePresetFilterStateFromUrl = <TId extends (string | number) = (string | number)>(params: {
  /** Query parameter in the URL that will be used for managing the state of the preset filter component */
  queryParamName: string;
  /** Map of allowed preset filter options where key is preset filter option Id and value is preset filter option */
  presetFilterOptionsMap: Record<string, PresetFilterOption<TId>> | undefined;
}): PresetFilterOption<TId> | undefined => {
  const { queryParamName, presetFilterOptionsMap } = params;

  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  const clearPresetParamInUrl = useCallback(() => {
    appendQueryParamsToUrl({ [queryParamName]: '' });
  }, [queryParamName, appendQueryParamsToUrl]);

  const presetParam: string | undefined = useQueryParamFromUrl(queryParamName);

  const presetFilterStateFromUrl: PresetFilterOption<TId> | undefined = useMemo(
    () => getPresetFilterStateFromUrl({ presetFilterOptionsMap, presetParam, clearPresetParamInUrl }),
    [presetFilterOptionsMap, presetParam, clearPresetParamInUrl],
  );

  return presetFilterStateFromUrl;
};
