import React from 'react';
import styles from './Backdrop.module.scss';
import { usePresenter } from './Backdrop.presenter';
import type { BackdropProps } from './Backdrop.types';

export const Backdrop: React.FC<BackdropProps> = (props) => {
  const { onClose } = usePresenter(props);

  return (
    <div
      className={styles.backdrop}
      onClick={onClose}
    />
  );
};
