import { useCallback, useMemo, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { URLs } from '../../../lib/constants';
import { getTranslation } from '../../../lib/reactIUtils';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import type { MlbCardPresenterProps, MlbCardProps } from './MlbCard.types';

export const usePresenter = (props: MlbCardProps): MlbCardPresenterProps => {
  const { pageName } = props;

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  const mlbCardTitle: ReactNode = useMemo(() => getTranslation('mlbCard.title'), []);

  const mlbCardDescription: string = useMemo(() => t('mlbCard.description', { currentYear: new Date().getFullYear() }), [t]);

  const mlbCardButtonLabel: string = useMemo(() => t('mlbCard.buttonLabel'), [t]);

  const onMlbCardClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      pageName,
      'MLBCard',
      mlbCardButtonLabel,
    );
  }, [pageName, mlbCardButtonLabel, trackEvent]);

  return {
    ...props,
    mlbCardTitle,
    mlbCardDescription,
    mlbCardButtonLabel,
    mlbCardHref: URLs.MlbPage,
    onMlbCardClick,
  };
};
