import cx from 'classnames';
import React from 'react';

import Text from '../../atoms/Text';
import EventDateStatus from '../EventDateStatus';
import Tag from '../Tag';

import styles from './TicketStatus.module.scss';
import usePresenter from './TicketStatus.presenter';
import getProps from './TicketStatus.props';
import { TicketStatusCombinedProps } from './types';

const TicketStatus: React.FC<TicketStatusCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    date,
    quantity,
    amount,
    tag,
    title,
    eventDateStatus,
    location,
    date1,
    time,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`ticketStatus${variantName}`];

  let componentView: JSX.Element;

  switch (variantName) {
    case 'Negative': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.topContent, classes?.topContent)}>
              <Text
                className={cx(styles.date, classes?.date)}
                {...internalProps.date}
                {...date} />
              <div className={cx(styles.mobileQuantityTag, classes?.mobileQuantityTag)}>
                <div className={cx(styles.mobileQuantityAmount, classes?.mobileQuantityAmount)}>
                  <Text
                    className={cx(styles.quantity, classes?.quantity)}
                    {...internalProps.quantity}
                    {...quantity} />
                  <Text
                    className={cx(styles.amount, classes?.amount)}
                    {...internalProps.amount}
                    {...amount} />
                </div>
                <Tag
                  className={cx(styles.tag, classes?.tag)}
                  {...internalProps.tag}
                  {...tag} />
              </div>
            </div>
            <div className={cx(styles.bottomContent, classes?.bottomContent)}>
              <div className={cx(styles.topContent1, classes?.topContent1)}>
                <Text
                  className={cx(styles.title1, classes?.title)}
                  {...internalProps.title}
                  {...title} />
              </div>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title} />
              <div className={cx(styles.eventLogistics, classes?.eventLogistics)}>
                <EventDateStatus
                  className={cx(styles.eventDateStatus, classes?.eventDateStatus)}
                  {...internalProps.eventDateStatus}
                  {...eventDateStatus} />
                <Text
                  className={cx(styles.location, classes?.location)}
                  {...internalProps.location}
                  {...location} />
              </div>
            </div>
          </div>
          <div className={cx(styles.quantityTag, classes?.quantityTag)}>
            <div className={cx(styles.quantityAmount, classes?.quantityAmount)}>
              <Text
                className={cx(styles.quantity, classes?.quantity)}
                {...internalProps.quantity}
                {...quantity} />
              <Text
                className={cx(styles.amount, classes?.amount)}
                {...internalProps.amount}
                {...amount} />
            </div>
            <Tag
              className={cx(styles.tag, classes?.tag)}
              {...internalProps.tag}
              {...tag} />
          </div>
        </div>
      );
      break;
    }
    case 'Positive': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.topContent, classes?.topContent)}>
              <Text
                className={cx(styles.date, classes?.date)}
                {...internalProps.date}
                {...date} />
              <div className={cx(styles.mobileQuantityTag, classes?.mobileQuantityTag)}>
                <div className={cx(styles.mobileQuantityAmount, classes?.mobileQuantityAmount)}>
                  <Text
                    className={cx(styles.quantity, classes?.quantity)}
                    {...internalProps.quantity}
                    {...quantity} />
                  <Text
                    className={cx(styles.amount, classes?.amount)}
                    {...internalProps.amount}
                    {...amount} />
                </div>
                <Tag
                  className={cx(styles.tag, classes?.tag)}
                  {...internalProps.tag}
                  {...tag} />
              </div>
            </div>
            <div className={cx(styles.bottomContent, classes?.bottomContent)}>
              <div className={cx(styles.topContent1, classes?.topContent1)}>
                <Text
                  className={cx(styles.title1, classes?.title)}
                  {...internalProps.title}
                  {...title} />
              </div>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title} />
              <div className={cx(styles.eventLogistics, classes?.eventLogistics)}>
                <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
                  <Text
                    className={cx(styles.date1, classes?.date1)}
                    {...internalProps.date1}
                    {...date1} />
                  <Text
                    className={cx(styles.time, classes?.time)}
                    {...internalProps.time}
                    {...time} />
                </div>
                <EventDateStatus
                  className={cx(styles.eventDateStatus, classes?.eventDateStatus)}
                  {...internalProps.eventDateStatus}
                  {...eventDateStatus} />
                <Text
                  className={cx(styles.location, classes?.location)}
                  {...internalProps.location}
                  {...location} />
              </div>
            </div>
          </div>
          <div className={cx(styles.quantityTag, classes?.quantityTag)}>
            <div className={cx(styles.quantityAmount, classes?.quantityAmount)}>
              <Text
                className={cx(styles.quantity, classes?.quantity)}
                {...internalProps.quantity}
                {...quantity} />
              <Text
                className={cx(styles.amount, classes?.amount)}
                {...internalProps.amount}
                {...amount} />
            </div>
            <Tag
              className={cx(styles.tag, classes?.tag)}
              {...internalProps.tag}
              {...tag} />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TicketStatus;
