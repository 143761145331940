import { useMemo } from 'react';
import type { BottomLogo, ListingDetailInfoPresenterProps, ListingDetailInfoProps } from './ListingDetailInfo.types';
import { getBottomLogo } from './ListingDetailInfo.utils';

export const usePresenter = (props: ListingDetailInfoProps): ListingDetailInfoPresenterProps => {
  const {
    listingDetailMetadata: {
      eventMetadata: { isExclusiveEvent },
      listingMetadata: { hasCp1BrokerId },
    },
  } = props;

  /**
   * Logo to display at the bottom:
   * - 'capitalOne' if event is exclusive and listing has CP1 broker Id 29014.
   * - 'poweredByVividSeats' in all other cases.
   */
  const bottomLogo: BottomLogo = useMemo(
    () => getBottomLogo({ isExclusiveEvent, hasCp1BrokerId }),
    [isExclusiveEvent, hasCp1BrokerId],
  );

  return {
    ...props,
    bottomLogo,
  };
};
