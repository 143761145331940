import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackPageViewEvent } from '../../../modules/analytics/util';
import OnBoardingContent from '../../molecules/OnBoardingContent';
import styles from './OnBoardingModal.module.scss';

export type OnBoardingModalProps = {
  handleClose?: () => void;
  show?: boolean;
};

const OnBoardingModal: React.FC<OnBoardingModalProps> = ({
  handleClose = (): void => { },
  show = false,
}) => {
  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    if (show) {
      trackPageViewEvent(
        trackEvent,
        'Onboarding Screen',
      );
    }
  }, [show]);

  return (
    <Modal
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      show={show}
      autoFocus={false}
    >
      <OnBoardingContent handleClose={handleClose} />
    </Modal>
  );
};

export default OnBoardingModal;
