import { useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import type { BaseComponentProps } from '../../../lib/types';
import { runInNextCycle } from '../../../lib/util';
import { useAnalyticsManager } from '../../../modules/analytics';
import { TopNavContext } from '../../../modules/topNav';
import type { ImageCombinedProps } from '../../atoms/Image';
import type { ListingCardProps } from '../../molecules/ListingCard';
import { getListingCardProps } from '../EventPage';
import type { GuestListEventPagePresenterProps, GuestListEventPageProps } from './GuestListEventPage.types';
import { getCarouselImageProps } from './GuestListEventPage.utils';

export const usePresenter = (props: GuestListEventPageProps): GuestListEventPagePresenterProps => {
  const {
    eventMetadata: {
      carouselImageUrls,
      isPayWithRewardsOnly,
    },
    listingsMetadata: {
      listings,
    } = {},
  } = props;

  const { shouldShowFooter, setShouldShowFooter } = useContext(TopNavContext);

  // Always show footer for guest list events.
  // Please note that when event page starts loading then EventPage.presenter.ts initially hides the footer.
  useLayoutEffect(() => {
    if (!shouldShowFooter) {
      // There is a possibility of a race condition because EventPage.presenter.ts (parent component) also updates shouldShowFooter value.
      // To ensure this code always executes last, we use runInNextCycle function.
      runInNextCycle(() => setShouldShowFooter(true));
    }
  }, [shouldShowFooter, setShouldShowFooter]);

  const { trackEvent } = useAnalyticsManager();

  /** Array of image props for rendering the carousel of event images */
  const carouselImageProps: Array<BaseComponentProps & ImageCombinedProps> = useMemo(
    () => getCarouselImageProps({ carouselImageUrls }),
    [carouselImageUrls],
  );

  /** Array of listing card props for rendering the listings */
  const listingCardProps: ListingCardProps[] = useMemo(
    () => getListingCardProps({
      listings,
      isPayWithRewardsOnly,
      shouldShowAipOverride: false,
    }),
    [listings, isPayWithRewardsOnly],
  );

  // Track event listings
  useEffect(() => {
    trackEvent('view_item', {
      ecommerce: {
        content_type: 'view_production',
        items: listings,
      },
    });
  }, [listings, trackEvent]);

  return {
    ...props,
    carouselImageProps,
    listingCardProps,
  };
};
