import { useContext, useEffect, useMemo } from 'react';
import { EventListContext } from '../../../modules/eventList';
import type { Event, EventsQueryParams, PageQueryParams } from '../../../modules/partnership';
import { getEvents } from '../../../modules/partnership/api';
import type { EventCardProps } from '../../molecules/EventCard';
import { usePaginatedList, type PaginatedListHookResponse } from '../../organisms/PaginatedList';
import type { BaseEventListPagePresenterProps, BaseEventListPageProps } from './BaseEventListPage.types';
import { getEventCardProps } from './BaseEventListPage.utils';

export const usePresenter = <TApiParams extends PageQueryParams = EventsQueryParams>(props: BaseEventListPageProps<TApiParams>): BaseEventListPagePresenterProps<TApiParams> => {
  // By default fetch events with 'getEvents' function
  const { fetchEventsFunc = getEvents, trackFetchAnalytics, filterEventsFunc, shouldShowEventImages, pageName } = props;

  const { isFetchEnabled, staticApiParams, setEventsTotal } = useContext(EventListContext);

  /** State for the paginated list of events */
  const eventListState: PaginatedListHookResponse<Event> = usePaginatedList<TApiParams, Event>({
    isFetchEnabled,
    fetchItemsFunc: fetchEventsFunc,
    staticApiParams: staticApiParams as TApiParams,
    trackFetchAnalytics,
    filterItemsFunc: filterEventsFunc,
  });

  const {
    isFetchingItems: isFetchingEvents,
    items: events,
    itemsTotal: eventsTotal,
  } = eventListState;

  /** Array of event card props for rendering events */
  const eventCardProps: EventCardProps[] = useMemo(
    () => getEventCardProps({ events, shouldShowEventImages, pageName }),
    [events, shouldShowEventImages, pageName],
  );

  // Update Event List context with the total number of events
  useEffect(
    () => setEventsTotal(eventsTotal),
    [eventsTotal, setEventsTotal],
  );

  return {
    ...props,
    isFetchEnabled,
    isFetchingEvents,
    eventCardProps,
    eventListState,
  };
};
