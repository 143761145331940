import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import { DescriptionColoursByTheme, TitleColoursByTheme } from './EmptyState.constants';
import styles from './EmptyState.module.scss';
import { usePresenter } from './EmptyState.presenter';
import type { EmptyStateProps } from './EmptyState.types';

export const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const {
    titleKey,
    descriptionKey,
    actionButtonProps,
    theme = 'light',
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <div className={cx(styles.emptyState, styles[`${theme}EmptyState`], className)}>
      <Text
        type='Subheading'
        size='Small'
        style='Regular'
        colour={TitleColoursByTheme[theme]}
        align='Center'
        value={t(titleKey)}
      />
      <Text
        size='Medium'
        style='Regular'
        colour={DescriptionColoursByTheme[theme]}
        align='Center'
        value={t(descriptionKey)}
      />
      {!!actionButtonProps && (
        <Button
          type='Text'
          style='Contained'
          size='Medium'
          text={{ value: t(actionButtonProps.labelKey) }}
          href={actionButtonProps.href}
        />
      )}
    </div>
  );
};
