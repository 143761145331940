import cx from 'classnames';
import React from 'react';
import ReactSlider from 'react-slider';
import Text from '../../atoms/Text';
import styles from './Slider.module.scss';
import { usePresenter } from './Slider.presenter';
import type { SliderProps } from './Slider.types';

export const Slider: React.FC<SliderProps> = (props) => {
  const {
    value,
    min,
    max,
    onChange,
    onAfterChange,
    formattedMinValue,
    formattedMaxValue,
    ariaLabel,
    ariaValuetext,
    className,
  } = usePresenter(props);

  return (
    <div className={cx(styles.sliderContainer, className)}>
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedDark'
        align='Left'
        value={formattedMinValue}
        className={styles.formattedValue}
      />
      <div className={styles.sliderWrapper}>
        <ReactSlider
          value={value}
          min={min}
          max={max}
          onChange={onChange}
          onAfterChange={onAfterChange}
          ariaLabel={ariaLabel}
          ariaValuetext={ariaValuetext}
          className={styles.slider}
          trackClassName={styles.track}
          thumbClassName={styles.thumb}
        />
      </div>
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedDark'
        align='Right'
        value={formattedMaxValue}
        className={styles.formattedValue}
      />
    </div>
  );
};
