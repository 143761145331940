import type { CategoryMenuItemState } from '../navigation/Navigation.types';
import type { TopNavContextValue } from './TopNavContext.types';

export const InitialTopNavContextValue = {
  categoryMenuItemStates: [] as CategoryMenuItemState[],
  hasCategoryMenuItemStates: false,
  activeTopNavMenuType: undefined,
  setActiveTopNavMenuType: () => { },
  searchQuery: '',
  setSearchQuery: () => { },
  searchSuggestions: undefined,
  topFixedHeight: 0,
  addTopFixedHeight: () => { },
  shouldShowTopNav: true,
  setShouldShowTopNav: () => { },
  shouldShowFooter: true,
  setShouldShowFooter: () => { },
} as const satisfies TopNavContextValue;
