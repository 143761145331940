import cx from 'classnames';
import React from 'react';
import ErrorBlock from '../../blocks/ErrorBlock';
import styles from './ErrorPage.module.scss';
import { usePresenter } from './ErrorPage.presenter';
import type { ErrorPageProps } from './ErrorPage.types';

export const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const {
    errorBlock,
    className,
  } = usePresenter(props);

  return (
    <div className={cx(styles.errorPage, className)}>
      <div className={styles.container}>
        <ErrorBlock
          className={styles.errorBlock}
          {...errorBlock}
        />
      </div>
    </div>
  );
};
