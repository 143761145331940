import { getPerformerUrl } from '../../../lib/performerUtils';
import { trackCardClickEvent, useAnalyticsManager } from '../../../modules/analytics';
import GenericPerformerImageAsset from '../../../resources/images/GenericPerformerImage.jpg';
import type { PerformerCardPresenterProps, PerformerCardProps } from './PerformerCard.types';

export const usePresenter = (props: PerformerCardProps): PerformerCardPresenterProps => {
  const {
    performer,
    pageName,
    sectionTitleKey,
    sectionIndex,
    performerCardIndex,
  } = props;

  const { trackEvent } = useAnalyticsManager();

  // Important: This component is memoized so there is no need to use useMemo here in the presenter.

  const performerUrl: string = getPerformerUrl(performer.id);
  const performerImage: string = performer.image || GenericPerformerImageAsset;

  const onPerformerCardClick = () => trackCardClickEvent({
    trackEvent,
    pageName,
    sectionTitleKey,
    sectionIndex,
    cardIndex: performerCardIndex,
    cardName: performer.name,
  });

  return {
    ...props,
    performerUrl,
    performerImage,
    onPerformerCardClick,
  };
};
