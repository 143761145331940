import React from 'react';
import { CONTENT_ID } from '../../../lib/constants';
import Text from '../../atoms/Text';
import styles from './ImageAndTitle.module.scss';
import { usePresenter } from './ImageAndTitle.presenter';
import type { ImageAndTitleProps } from './ImageAndTitle.types';

export const ImageAndTitle: React.FC<ImageAndTitleProps> = (props) => {
  const { imageUrl, title } = usePresenter(props);

  return (
    <div
      className={styles.image}
      style={{ backgroundImage: `linear-gradient(180deg, rgba(0, 19, 43, 0) 0%, rgba(0, 19, 43, 1) 100%), url(${imageUrl})` }}
    >
      <Text
        type='NewBannerHeading'
        size='Medium'
        style='Regular'
        colour='BaseLight'
        value={title}
        id={CONTENT_ID}
        className={styles.title}
      />
    </div>
  );
};
