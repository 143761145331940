import { isNumber } from '../../../lib/util';
import { getTransform } from '../../../modules/map';
import type { SvgMapSectionName } from '../../../modules/partnership';
import { FillOverrides } from './SvgMapSectionNames.constants';
import styles from './SvgMapSectionNames.module.scss';
import type { SvgMapSectionNameProps } from './SvgMapSectionNames.types';

export const getTspanDy = (params: {
  svgMapSectionName: SvgMapSectionName;
}): number => {
  const { svgMapSectionName } = params;

  const isMapsV2: boolean = svgMapSectionName.dataGenMap === 'v2';
  if (isMapsV2) {
    return 0;
  }

  // Please note that font size may contain 'px' and decimal points, e.g. '16.001px'
  // Use parseFloat to ignore non-digit characters, e.g. 'px'
  const fontSize: number = parseFloat(svgMapSectionName['font-size'] || '10');
  const validFontSize: number = isNumber(fontSize) ? fontSize : 10;

  const multiplier = 0.34;

  return validFontSize * multiplier;
};

/**
 * Gets the array of SVG map section name props for rendering on the map
 * @returns {SvgMapSectionNameProps[]} Array of SVG map section name props for rendering on the map
 */
export const getSvgMapSectionNameProps = (params: {
  svgMapSectionNames: SvgMapSectionName[];
}): SvgMapSectionNameProps[] => {
  const { svgMapSectionNames } = params;

  return svgMapSectionNames.map((svgMapSectionName: SvgMapSectionName, svgMapSectionNameIndex: number) => {
    const {
      text,
      'font-size': fontSize,
      'text-anchor': textAnchor,
      fill,
      stroke,
      transform,
      x = '0',
      y = '0',
    } = svgMapSectionName;

    return {
      key: `${svgMapSectionNameIndex}-${text}`,
      fontSize,
      fontFamily: 'Poppins, sans-serif', // Always override font family
      fontWeight: 400, // Always override font weight
      textAnchor,
      fill: FillOverrides[fill?.toLowerCase() ?? ''] || FillOverrides.default, // For all fill colours except #ffffff or #cccccc override with black colour #000000
      stroke,
      transform: getTransform(transform),
      x: Number(x) || 0,
      y: Number(y) || 0,
      className: styles.svgMapSectionName,
      tspanDy: getTspanDy({ svgMapSectionName }),
      tspanText: text,
    };
  });
};
