import cx from 'classnames';
import React from 'react';

import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './HighlightMessage.module.scss';
import usePresenter from './HighlightMessage.presenter';
import getProps from './HighlightMessage.props';
import { HighlightMessageCombinedProps } from './types';

const HighlightMessage: React.FC<HighlightMessageCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    divider,
    icon,
    message,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`highlightMessage${variantName}`];

  let componentView: JSX.Element;

  switch (variantName) {
    case 'Error':
    case 'Info': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider} />
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon} />
            <Text
              className={cx(styles.message, classes?.message)}
              {...internalProps.message}
              {...message} />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default HighlightMessage;
