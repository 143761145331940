import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams, type NavigateOptions } from 'react-router-dom';
import { checkCheckoutEligibility } from '../../../lib/checkoutUtils';
import { DELIVERY_ID_PARAM, EVENT_ID_PARAM, EXCLUSIVE_LISTINGS_PARAM, OrderErrorProps, QUANTITY_PARAM, STEP_PARAM, URLs } from '../../../lib/constants';
import { getListingDetailMetadata } from '../../../lib/listingUtils';
import type { ListingDetailMetadata } from '../../../lib/types';
import { getNonExhaustiveCasesInSwitchStatementError, useIsScrolledVertically, useNumericQueryParamFromUrl, useQueryParamFromUrl, useWindowSize } from '../../../lib/util';
import { trackCheckoutEvents, trackPageViewEvent, trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { AuthContext } from '../../../modules/auth';
import { useAppendQueryParamsToUrl, usePreserveHistoryState } from '../../../modules/navigation/Navigation.hooks';
import type { GoBackHistoryState } from '../../../modules/navigation/Navigation.types';
import { getCurrentHistoryIndex, getCurrentHistoryState } from '../../../modules/navigation/Navigation.utils';
import type { ListingDetailsQueryParams, OrderResponse } from '../../../modules/partnership';
import { getListingDetails } from '../../../modules/partnership/api';
import { TopNavContext } from '../../../modules/topNav';
import type { TicketAlertModalProps } from '../../organisms/TicketAlertModal';
import type { OrderConfirmationPageState } from '../OrderConfirmationPage';
import { MAX_STEP, StepConfigurations, Steps } from './CheckoutPage.constants';
import type { CheckoutAddressInfoProps, CheckoutContactInfoProps, CheckoutOrderInfoProps, CheckoutPagePresenterProps, CheckoutPageProps, CheckoutPaymentInfoProps, CheckoutValidityState, Step } from './CheckoutPage.types';
import { checkCanFetchListingDetail, checkIsRightContentShown, getCheckoutValidityState, getListingDetailQueryParams } from './CheckoutPage.utils';
import { useCheckoutAddressInfo, useCheckoutContactInfo, useCheckoutOrderInfo, useCheckoutPaymentInfo } from './hooks';

export const usePresenter = (props: CheckoutPageProps): CheckoutPagePresenterProps => {
  const {
    account,
    loading: isAccountLoading,
    setAccountExternally,
    selectedAccountCardIndex,
    selectedAccountCardDetail,
    selectAccountCardByIndex,
    refetchAccount,
  } = useContext(AuthContext);

  const { setShouldShowFooter } = useContext(TopNavContext);

  // Always hide footer
  useLayoutEffect(() => {
    setShouldShowFooter(false);
    return () => setShouldShowFooter(true);
  }, [setShouldShowFooter]);

  usePreserveHistoryState();

  const navigate = useNavigate();

  const { isDesktop } = useWindowSize();

  const { trackEvent, onError } = useAnalyticsManager();

  const { t } = useTranslation();

  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  /** Function that navigates to another step by updating 'step' query parameter in the current URL */
  const navigateToStep = useCallback((newStep: Step, options?: NavigateOptions) => {
    appendQueryParamsToUrl({ [STEP_PARAM]: newStep.toString() }, { replace: false, ...options });
  }, [appendQueryParamsToUrl]);

  const goToPaymentInfo = useCallback(() => navigateToStep(Steps.PaymentInfo), [navigateToStep]);

  const { listingId } = useParams<{ listingId: string; }>();

  /** Validated event Id. Undefined if event Id is invalid, e.g. when it is non-numeric or it is less than 1. */
  const eventId: number | undefined = useNumericQueryParamFromUrl(EVENT_ID_PARAM, { minValue: 1, shouldRemoveIfInvalid: false });

  /** Validated quantity. Undefined if quantity is invalid, e.g. when it is non-numeric or it is less than 1. */
  const quantity: number | undefined = useNumericQueryParamFromUrl(QUANTITY_PARAM, { minValue: 1, shouldRemoveIfInvalid: false });

  /** Validated exclusive_listings parameter. Undefined if exclusive_listings parameter is invalid, e.g. when it is neither 'true' nor 'false'. */
  const exclusiveListings: 'true' | 'false' | undefined = useQueryParamFromUrl<'true' | 'false'>(EXCLUSIVE_LISTINGS_PARAM, { allowedValues: ['true', 'false'] });

  /** Validated delivery Id. Undefined if delivery Id is invalid, e.g. when it is non-numeric or it is less than 0. */
  const deliveryId: number | undefined = useNumericQueryParamFromUrl(DELIVERY_ID_PARAM, { minValue: 0, shouldRemoveIfInvalid: false });

  /** Validated 1-based step index. Undefined if step index is invalid, e.g. when it is non-numeric or it is not in range of 1-4. */
  const step: Step | undefined = useNumericQueryParamFromUrl(STEP_PARAM, { minValue: 1, maxValue: MAX_STEP, shouldRemoveIfInvalid: false }) as Step | undefined;

  /**
   * Query parameters to fetch listing detail with quantity, exclusive_listings and delivery_id properties.
   * Undefined if:
   * - Quantity is not a valid positive number.
   * - Or exclusive_listings is neither 'true' nor 'false'.
   * - Or delivery Is is not a number equal or greater than 0.
   */
  const listingDetailQueryParams: ListingDetailsQueryParams | undefined = useMemo(
    () => getListingDetailQueryParams({ quantity, exclusiveListings, deliveryId }),
    [quantity, exclusiveListings, deliveryId],
  );

  const canFetchListingDetail: boolean = useMemo(
    () => checkCanFetchListingDetail({ account, eventId, listingId, listingDetailQueryParams, step }),
    [account, eventId, listingId, listingDetailQueryParams, step],
  );

  // API call to fetch event listing detail
  // Enabled only if all preconditions for checkout are met
  const { data: listingDetail, isLoading: isListingDetailLoading, isError: isListingDetailError } = useQuery(
    ['getListingDetails', eventId, listingId, ...Object.values(listingDetailQueryParams ?? {})],
    () => getListingDetails(`${eventId}`, listingId!, listingDetailQueryParams!),
    { enabled: canFetchListingDetail, onError },
  );

  /** Detailed information about event listing detail */
  const listingDetailMetadata: ListingDetailMetadata | undefined = useMemo(
    () => listingDetail
      ? getListingDetailMetadata({ listingDetail, loyaltyUnitName: selectedAccountCardDetail?.rewardUnitName, shouldShowAipOverride: false })
      : undefined,
    [listingDetail, selectedAccountCardDetail],
  );

  const { eventMetadata } = listingDetailMetadata ?? {};
  const { isPayWithRewardsOnly, eventMaxPurchaseLimit } = eventMetadata ?? {};

  /**
   * Checkout validity state:
   * - 'processing' if API requests are in flight.
   * - 'failure' if checkout is invalid, e.g. required data is missing or there are API errors.
   * - 'success' if checkout is valid, i.e. all required data has been successfully loaded and validated.
   */
  const checkoutValidityState: CheckoutValidityState = useMemo(
    () => getCheckoutValidityState({ isAccountLoading, account, canFetchListingDetail, isListingDetailLoading, isListingDetailError, listingDetailMetadata }),
    [isAccountLoading, account, canFetchListingDetail, isListingDetailLoading, isListingDetailError, listingDetailMetadata],
  );

  // Track analytics for page visits
  useEffect(() => {
    if (checkoutValidityState === 'success') {
      trackEvent('begin_checkout');

      trackPageViewEvent(
        trackEvent,
        'Checkout Page',
      );
    }
  }, [checkoutValidityState, trackEvent]);

  // Track checkout events
  useEffect(() => {
    if (checkoutValidityState === 'success' && step === Steps.ContactInfo) {
      trackCheckoutEvents({ trackEvent, eventKey: 'add-to-cart', listingDetailMetadata });
      trackCheckoutEvents({ trackEvent, eventKey: 'begin_checkout', listingDetailMetadata });
    }
  }, [checkoutValidityState, step, listingDetailMetadata, trackEvent]);

  // Load ioBlackBox library
  // Required for validation of the credit card information
  useEffect(() => {
    const initialScript: HTMLScriptElement = document.createElement('script');
    initialScript.innerHTML = 'var io_bbout_element_id = \'ioBlackBox\'; var io_install_stm = false; var io_install_flash = false; var io_exclude_stm = 12;';
    document.head.appendChild(initialScript);

    const accertifyScript: HTMLScriptElement = document.createElement('script');
    accertifyScript.setAttribute('src', 'https://mpsnare.iesnare.com/snare.js');
    document.head.appendChild(accertifyScript);

    return () => {
      document.head.removeChild(initialScript);
      document.head.removeChild(accertifyScript);
    };
  }, []);

  const checkoutContactInfoProps: CheckoutContactInfoProps = useCheckoutContactInfo();
  const { checkIsContactInfoValid } = checkoutContactInfoProps;

  const checkoutPaymentInfoProps: CheckoutPaymentInfoProps = useCheckoutPaymentInfo({ listingDetailMetadata });
  const { checkIsPaymentInfoValid, remainingCashPrice, formattedRemainingCashPriceWithCents, appliedRewardUnits } = checkoutPaymentInfoProps;

  const checkoutAddressInfoProps: CheckoutAddressInfoProps = useCheckoutAddressInfo({
    listingDetailMetadata,
    currentPhoneNumber: checkoutContactInfoProps.phoneNumber,
  });
  const { checkIsAddressInfoValid } = checkoutAddressInfoProps;

  const checkoutOrderInfoProps: CheckoutOrderInfoProps = useCheckoutOrderInfo({
    listingDetailMetadata,
    checkoutContactInfoProps,
    checkoutPaymentInfoProps,
    checkoutAddressInfoProps,
    goToPaymentInfo,
  });
  const { checkIsOrderInfoValid, placeOrder, accountCardImageUrl, accountCardLastFourDigits } = checkoutOrderInfoProps;

  const [ticketAlertModalProps, setTicketAlertModalProps] = useState<TicketAlertModalProps | undefined>(undefined);

  const insufficientRewardUnitsModalProps: TicketAlertModalProps = useMemo(() => ({
    title: t('errors.insufficientRewardUnits.title'),
    message: t('errors.insufficientRewardUnits.message'),
    primaryButtonLabel: t('errors.insufficientRewardUnits.button'),
    onPrimaryButtonClick: () => setTicketAlertModalProps(undefined),
    onCloseButtonClick: () => setTicketAlertModalProps(undefined),
  }), [t]);

  const purchaseLimitErrorModalProps: TicketAlertModalProps = useMemo(() => ({
    title: t('errors.purchaseLimitError.title'),
    message: t('errors.purchaseLimitError.message', { eventMaxPurchaseLimit, count: eventMaxPurchaseLimit }),
    primaryButtonLabel: t('errors.purchaseLimitError.button'),
    onPrimaryButtonClick: () => setTicketAlertModalProps(undefined),
    onCloseButtonClick: () => setTicketAlertModalProps(undefined),
  }), [t, eventMaxPurchaseLimit]);

  /** True while the order is being validated and placed */
  const [isValidatingAndPlacingOrder, setIsValidatingAndPlacingOrder] = useState<boolean>(false);

  /** Callback on Next Step button click which is dependent on the current step */
  const onNextStepButtonClick = useCallback(
    async () => {
      if (step) {
        switch (step) {
          case Steps.ContactInfo: {
            trackSelectContentEvent(
              trackEvent,
              'CheckoutPage',
              'Customer Info',
              t(StepConfigurations[Steps.ContactInfo].nextStepButtonLabelKey),
            );

            if (checkIsContactInfoValid()) {
              // Track checkout events
              trackCheckoutEvents({ trackEvent, eventKey: 'checkout_login' });

              navigateToStep((step + 1) as Step);
            }
            return;
          }
          case Steps.PaymentInfo: {
            trackSelectContentEvent(
              trackEvent,
              'CheckoutPage',
              'Payment Info',
              t(StepConfigurations[Steps.PaymentInfo].nextStepButtonLabelKey),
            );

            if (await checkIsPaymentInfoValid({ shouldCheckBraintree: true })) {
              // Track checkout events
              trackCheckoutEvents({ trackEvent, eventKey: 'add_payment_info', listingDetailMetadata });

              navigateToStep((step + 1) as Step);
            }
            return;
          }
          case Steps.Address: {
            trackSelectContentEvent(
              trackEvent,
              'CheckoutPage',
              'Billing Info',
              t(StepConfigurations[Steps.Address].nextStepButtonLabelKey),
            );

            if (checkIsAddressInfoValid()) {
              navigateToStep((step + 1) as Step);
            }
            return;
          }
          case Steps.ConfirmOrder: {
            trackSelectContentEvent(
              trackEvent,
              'CheckoutPage',
              'Legal Block',
              t(StepConfigurations[Steps.ConfirmOrder].nextStepButtonLabelKey),
            );

            try {
              if (!isValidatingAndPlacingOrder && listingDetail && listingDetailMetadata && listingDetailQueryParams && checkIsOrderInfoValid()) {
                setIsValidatingAndPlacingOrder(true);

                const {
                  /** Re-fetched user account */
                  refetchedAccount,
                  /** Error state. True if any of the API calls has failed. */
                  isCheckoutEligibilityApiError,
                  /**
                   * Indicates whether user does not have enough reward units to pay for the tickets.
                   * If user applied reward units then True if user no longer has those reward units available.
                   * In addition, if the event only allows payment with rewards then True if user does not have enough reward units to pay for the tickets.
                   */
                  areInsufficientRewardUnits,
                  /** Total number of previously purchased tickets for the same event */
                  previouslyPurchasedTicketTotal,
                  /**
                   * Indicates whether the user would cross the limit of the maximum tickets per account per event should they proceed with the purchase.
                   * E.g. if the limit is 4 and user has previously purchased 2 tickets for the same event, then:
                   * - If they try to buy 1 or 2 more tickets for the same event then the flag will be false (2 + 1 <= 4, 2 + 2 <= 4).
                   * - If they try to buy 3 or more tickets for the same event then the flag will be true (2 + 3 > 4).
                   */
                  isPurchaseLimitCrossed,
                } = await checkCheckoutEligibility({
                  listingDetail,
                  listingDetailQueryParams,
                  selectedQuantity: listingDetail.pricing.quantity,
                  appliedRewardUnits,
                  isPayWithRewardsOnly,
                  eventMaxPurchaseLimit,
                  setAccountExternally,
                  selectedAccountCardIndex,
                  selectAccountCardByIndex,
                  trackEvent,
                });

                if (!refetchedAccount || isCheckoutEligibilityApiError) {
                  navigate(URLs.ErrorPage, { state: OrderErrorProps });
                  setIsValidatingAndPlacingOrder(false);
                  return;
                }

                if (areInsufficientRewardUnits) {
                  setTicketAlertModalProps(insufficientRewardUnitsModalProps);
                  setIsValidatingAndPlacingOrder(false);
                  return;
                } else if (isPurchaseLimitCrossed) {
                  setTicketAlertModalProps(purchaseLimitErrorModalProps);
                  setIsValidatingAndPlacingOrder(false);
                  return;
                }

                setTicketAlertModalProps(undefined);

                const orderResponse: OrderResponse = await placeOrder({ account: refetchedAccount });

                // Re-fetch user account to reflect changes, e.g. reduced number of available reward units
                refetchAccount();

                // Track checkout events
                trackCheckoutEvents({
                  trackEvent,
                  eventKey: 'purchase',
                  listingDetailMetadata,
                  orderResponse,
                  previouslyPurchasedTicketTotal,
                });

                const orderConfirmationPageState: OrderConfirmationPageState = {
                  listingDetailMetadata,
                  accountCardImageUrl,
                  accountCardLastFourDigits,
                  orderResponse,
                  remainingCashPrice,
                  formattedRemainingCashPriceWithCents,
                };

                navigate(URLs.OrderConfirmationPage, { state: orderConfirmationPageState });

                setIsValidatingAndPlacingOrder(false);
              }
            } catch {
              navigate(URLs.ErrorPage, { state: OrderErrorProps });
              setIsValidatingAndPlacingOrder(false);
            }

            return;
          }
          default: {
            // If the code does not build here then it means that there are missing cases in the switch statement
            throw getNonExhaustiveCasesInSwitchStatementError(step);
          }
        }
      }
    },
    [
      accountCardImageUrl,
      accountCardLastFourDigits,
      appliedRewardUnits,
      checkIsAddressInfoValid,
      checkIsContactInfoValid,
      checkIsOrderInfoValid,
      checkIsPaymentInfoValid,
      eventMaxPurchaseLimit,
      formattedRemainingCashPriceWithCents,
      insufficientRewardUnitsModalProps,
      isPayWithRewardsOnly,
      isValidatingAndPlacingOrder,
      listingDetail,
      listingDetailMetadata,
      listingDetailQueryParams,
      navigate,
      navigateToStep,
      placeOrder,
      purchaseLimitErrorModalProps,
      refetchAccount,
      remainingCashPrice,
      selectAccountCardByIndex,
      selectedAccountCardIndex,
      setAccountExternally,
      step,
      t,
      trackEvent,
    ],
  );

  const prevStepRef = useRef<Step | undefined>(undefined);
  const isInitialStepCheckedRef = useRef<boolean>(false);

  useEffect(() => {
    const validateData = async () => {
      if (!step || step === prevStepRef.current) {
        return;
      }

      try {
        // If page loads for the first time
        if (!isInitialStepCheckedRef.current) {
          let goBackHistoryState = getCurrentHistoryState() as GoBackHistoryState | undefined;

          // Prevent user from starting checkout on a step other than 1.
          // This may happen if user goes to checkout via a direct URL and 'step' parameter is not 1.
          // Also it may happen if user completes checkout, lands on Order Confirmation page and then clicks on browser's back button. In this case 'step' will be 4 (last checkout step).
          //
          // Also if user goes to checkout via a direct URL then go-back history state will be undefined.
          // This state is required to allow user to click on X button in checkout header and to be navigated to the previous page.
          // In this case we construct go-back history state manually.
          //
          // In both cases above we need to redirect user to the first step and ensure that go-back history state is set.
          const shouldRedirectToFirstStep: boolean = !goBackHistoryState || step !== Steps.ContactInfo;
          if (shouldRedirectToFirstStep) {
            if (!goBackHistoryState) {
              goBackHistoryState = { goBackHistoryIndex: getCurrentHistoryIndex() - 1 };
            }

            navigateToStep(Steps.ContactInfo, { state: goBackHistoryState, replace: true });
          }

          isInitialStepCheckedRef.current = true;
          return;
        }

        // If user navigates backward/forward by clicking on browser's back/forward buttons then browser navigates to the previous/next steps but our validation rules do not execute.
        // To fix it, we re-validate data from the previous step whenever step changes.
        // If validation fails then we redirect user one step back.
        if (
          (step > Steps.ContactInfo && !checkIsContactInfoValid())
          || (step > Steps.PaymentInfo && !(await checkIsPaymentInfoValid({ shouldCheckBraintree: false })))
          || (step > Steps.Address && !checkIsAddressInfoValid())
        ) {
          navigate(-1);
        }
      } finally {
        prevStepRef.current = step;
      }
    };

    void validateData();
  }, [step, checkIsContactInfoValid, checkIsPaymentInfoValid, checkIsAddressInfoValid, navigate, navigateToStep]);

  /**
   * Indicates if the right content (Total Charge and Listing Detail Info sections) is shown.
   * True on desktops for any step or on mobiles for 'Payment info' step only.
   */
  const isRightContentShown: boolean = useMemo(
    () => checkIsRightContentShown({ isDesktop, step }),
    [isDesktop, step],
  );

  /** True if page is scrolled vertically */
  const isScrolledVertically: boolean = useIsScrolledVertically();

  return {
    ...props,
    step,
    checkoutValidityState,
    listingDetailMetadata,
    contactInfoProps: checkoutContactInfoProps,
    paymentInfoProps: checkoutPaymentInfoProps,
    addressInfoProps: checkoutAddressInfoProps,
    orderInfoProps: checkoutOrderInfoProps,
    onNextStepButtonClick,
    isValidatingAndPlacingOrder,
    ticketAlertModalProps,
    isRightContentShown,
    isScrolledVertically,
  };
};
