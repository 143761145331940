import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentLoadErrorBlock from '../../../legacy/blocks/ContentLoadErrorBlock';
import { SVG_MAP_ID } from '../../../modules/map';
import { Spinner } from '../../atoms/Spinner';
import { SvgMapSectionNames } from '../SvgMapSectionNames';
import { SvgMapSections } from '../SvgMapSections';
import styles from './SvgMap.module.scss';
import { usePresenter } from './SvgMap.presenter';
import type { SvgMapProps } from './SvgMap.types';

export const SvgMap: React.FC<SvgMapProps> = (props) => {
  const {
    isSvgMapPanZoomEnabled,
    isSvgMapDataLoading,
    svgMapContainerRef,
    svgMapRef,
    svgMapData,
    svgMapDataError,
    onSvgMapClick,
    onSvgMapDoubleClick,
    onSvgMapMouseOver,
    onSvgMapMouseOut,
    svgMapSections,
    svgMapSectionNames,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  if (isSvgMapDataLoading || !svgMapData) {
    return !svgMapDataError
      ? <Spinner />
      : (
        <ContentLoadErrorBlock
          title={{ value: t('errors.unableToLoadContent.title') }}
          message={{ value: t('errors.unableToLoadContent.message') }}
        />
      );
  }

  return (
    <div
      ref={svgMapContainerRef}
      className={styles.svgMapContainer}
      data-testid='map-container'
    >
      <svg
        ref={svgMapRef}
        id={SVG_MAP_ID} // Required for automation tests and hovering/selection functionality, do not change accidentally
        data-testid={SVG_MAP_ID}
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        preserveAspectRatio='xMidYMid'
        viewBox={`0 0 ${svgMapData.width} ${svgMapData.height}`}
        onClick={onSvgMapClick}
        onDoubleClick={onSvgMapDoubleClick}
        onMouseOver={onSvgMapMouseOver}
        onMouseOut={onSvgMapMouseOut}
        className={cx(styles.svgMap, { [styles.svgMapWithPanZoom]: isSvgMapPanZoomEnabled }, className)}
      >
        <SvgMapSections svgMapSections={svgMapSections} />
        <SvgMapSectionNames svgMapSectionNames={svgMapSectionNames} />
      </svg>
    </div>
  );
};
