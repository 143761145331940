import { useContext, useEffect, useRef } from 'react';
import { CssVariableNameEnum } from '../../../lib/types';
import { useHtmlElementSize } from '../../../lib/util';
import { TopNavContext } from '../../../modules/topNav';
import type { TopDisclaimerPresenterProps, TopDisclaimerProps } from './TopDisclaimer.types';

export const usePresenter = (props: TopDisclaimerProps): TopDisclaimerPresenterProps => {
  const { topDisclaimer } = props;

  const { addTopFixedHeight } = useContext(TopNavContext);

  const topDisclaimerRef = useRef<HTMLDivElement>(null);

  const { height: topDisclaimerHeight } = useHtmlElementSize({
    htmlElement: topDisclaimerRef.current,
    // Need to update TopDisclaimer height whenever text value changes
    extraDependency: topDisclaimer,
  });

  // Store TopDisclaimer height.
  // Explanation: TopDisclaimer is rendered with a fixed position which means it floats above the rest of the content.
  // To avoid layout issues (e.g. some content may get hidden behind the TopDisclaimer) we also render a spacer div of the same height as the TopDisclaimer.
  useEffect(() => {
    addTopFixedHeight(CssVariableNameEnum.topDisclaimerHeight, topDisclaimerHeight);
    return () => addTopFixedHeight(CssVariableNameEnum.topDisclaimerHeight, 0);
  }, [topDisclaimerHeight, addTopFixedHeight]);

  return {
    ...props,
    topDisclaimerRef,
  };
};
