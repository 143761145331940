import type { DateRange } from '../../components/organisms/DateFilter';

export enum DayOfWeek {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

/**
 * Get today's date.
 * @returns {Date} Today's date.
 */
export const getTodaysDate = (): Date => {
  const date = new Date();
  return date;
};

/**
 * Get tomorrow's date.
 * @returns {Date} Tomorrow's date.
 */
export const getTomorrowsDate = (): Date => {
  const date = getTodaysDate();
  date.setDate(date.getDate() + 1);
  return date;
};

/**
 * Get the date of a certain day of the week.
 * @param {DayOfWeek} dayOfWeek - The day of the week.
 * @returns {Date} The date of the specified day of the week.
 */
export const getCertainDayDate = (dayOfWeek: DayOfWeek): Date => {
  const date = getTodaysDate();
  date.setDate(date.getDate() + (dayOfWeek + 7 - date.getDay()) % 7);
  return date;
};

/**
 * Set the start time (00:00:00) for a given date.
 * @param {Date} date - The date to set the start time for.
 * @returns {Date} The date with the start time set.
 */
export const setStartTime = (date: Date): Date => {
  date.setHours(0, 0, 0, 0);
  return date;
};

/**
 * Set the end time (23:59:59) for a given date.
 * @param {Date} date - The date to set the end time for.
 * @returns {Date} The date with the end time set.
 */
export const setEndTime = (date: Date): Date => {
  date.setHours(23, 59, 59, 59);
  return date;
};

/**
 * Format a given date as 'MMM dd' (e.g., 'Jan 1').
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date string.
 */
export const formatSelectedDate = (date: Date): string => {
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
  }).format(date);
};

/**
 * Get the date range for weekend of the current week (Friday to Sunday).
 * @returns {DateRange} The date range for weekend of the current week.
 */
export const getWeekendDateRange = (): DateRange => {
  const startDate: Date = getCertainDayDate(DayOfWeek.Friday);
  const endDate: Date = getCertainDayDate(DayOfWeek.Sunday);

  // In case this function is called on Saturday or Sunday
  // then start date will be Friday of the next week
  // and end date will be Sunday of this week.
  // In this case subtract 7 days from the start date so that it becomes Friday of this week.
  if (startDate > endDate) {
    startDate.setDate(startDate.getDate() - 7);
  }

  return { startDate, endDate };
};

/**
 * Check if a given date range is today.
 * @param {DateRange | undefined} dateRange - The date range to check.
 * @returns {boolean} True if the date range is today, false otherwise.
 */
export const isToday = (dateRange: DateRange | undefined): boolean => {
  const todaysDate = getTodaysDate();
  return dateRange?.startDate?.toDateString() === todaysDate.toDateString() && dateRange?.endDate?.toDateString() === todaysDate.toDateString();
};

/**
 * Check if a given date range is tomorrow.
 * @param {DateRange | undefined} dateRange - The date range to check.
 * @returns {boolean} True if the date range is tomorrow, false otherwise.
 */
export const isTomorrow = (dateRange: DateRange | undefined): boolean => {
  const tomorrowDate = getTomorrowsDate();
  return dateRange?.startDate?.toDateString() === tomorrowDate.toDateString() && dateRange?.endDate?.toDateString() === tomorrowDate.toDateString();
};

/**
 * Check if a given date range is this weekend.
 * @param {DateRange | undefined} dateRange - The date range to check.
 * @returns {boolean} True if the date range is this weekend, false otherwise.
 */
export const isThisWeekend = (dateRange: DateRange | undefined): boolean => {
  const weekend = getWeekendDateRange();
  return (
    dateRange?.startDate?.toDateString() === weekend.startDate?.toDateString() &&
    dateRange?.endDate?.toDateString() === weekend.endDate?.toDateString()
  );
};

/**
 * Check if a given date range is a custom range (not today, tomorrow, or this weekend).
 * @param {DateRange | undefined} dateRange - The date range to check.
 * @returns {boolean} True if the date range is a custom range, false otherwise.
 */
export const isCustomRange = (dateRange: DateRange | undefined): boolean => {
  if (dateRange && dateRange.startDate && dateRange.endDate) {
    return !!dateRange && !isToday(dateRange) && !isTomorrow(dateRange) && !isThisWeekend(dateRange);
  }
  return false;
};

export const treatAsLocalDate = (date: Date): Date => {
  // Remove UTC timezone Z from ISO date string and convert it to Date
  return new Date(date.toISOString().replace('Z', ''));
};
