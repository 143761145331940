import cx from 'classnames';
import React from 'react';


import styles from './TeamHomeAwayImage.module.scss';
import usePresenter from './TeamHomeAwayImage.presenter';
import { TeamHomeAwayImageCombinedProps } from './types';

const TeamHomeAwayImage: React.FC<TeamHomeAwayImageCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    text,
    teamInitials,
  } = usePresenter(props);

  const variantName = `${type}`;
  const currentStyle = styles[`teamHomeAwayImage${variantName}`];

  let componentView: JSX.Element;

  const imageView = (
    <div className={cx(styles.image, classes?.image)}>
      <p className={cx(styles.text, classes?.text)}>
        {text}
      </p>
      <p className={cx(styles.teamInitials, classes?.teamInitials)}>
        {teamInitials}
      </p>
    </div>
  );

  switch (variantName) {
    case 'Long':
    case 'Square': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          {imageView}
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TeamHomeAwayImage;
