import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import type { SliderRange } from '../../molecules/Slider';
import type { ListingsFiltersModalPresenterProps, ListingsFiltersModalProps } from './ListingsFiltersModal.types';

export const usePresenter = (props: ListingsFiltersModalProps): ListingsFiltersModalPresenterProps => {
  const {
    quantity: initialQuantity,
    priceRange: initialPriceRange,
    priceMinMaxRange,
    onListingsFiltersChanged: initialOnListingsFiltersChanged,
    closeListingsFiltersModal: initialCloseListingsFiltersModal,
  } = props;

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  // Track analytics for opening of the modal
  useEffect(() => {
    trackSelectContentEvent(
      trackEvent,
      'Production',
      'Filter Toggle',
    );
  }, [trackEvent]);

  /** Currently selected quantity or undefined if no quantity is selected */
  const [quantity, setQuantity] = useState<number | undefined>(initialQuantity);

  /** Currently selected price slider range */
  const [priceRange, setPriceRange] = useState<SliderRange | undefined>(initialPriceRange);

  /** Callback when quantity or price slider range change */
  const onListingsFiltersChanged = useCallback((params: { newQuantity: number | undefined; newPriceRange: SliderRange | undefined; }) => {
    setQuantity(params.newQuantity);
    setPriceRange(params.newPriceRange);
  }, []);

  /** Function to close a modal that configures filters for listings, e.g. ticket quantity, min/max prices */
  const closeListingsFiltersModal = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Production',
      'Filter Modal',
      'Close Filter',
    );

    initialCloseListingsFiltersModal();
  }, [trackEvent, initialCloseListingsFiltersModal]);

  /** Function to apply all filters and close the modal */
  const applyFilters = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Production',
      'Filter',
      t('listingsFilters.apply'),
    );

    initialOnListingsFiltersChanged({ newQuantity: quantity, newPriceRange: priceRange });

    closeListingsFiltersModal();
  }, [t, quantity, priceRange, trackEvent, initialOnListingsFiltersChanged, closeListingsFiltersModal]);

  /** Function to reset all filters to default values */
  const resetFilters = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Production',
      'Filter',
      t('listingsFilters.resetFilters'),
    );

    setQuantity(undefined);
    setPriceRange(priceMinMaxRange);
  }, [t, priceMinMaxRange, trackEvent]);

  return {
    ...props,
    quantity,
    priceRange,
    onListingsFiltersChanged,
    applyFilters,
    resetFilters,
    closeListingsFiltersModal,
  };
};
