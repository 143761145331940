import { useState } from 'react';
import { NavMenuItemCombinedProps } from './types';

const usePresenter = (props: NavMenuItemCombinedProps): NavMenuItemCombinedProps => {
  const { expansionProps, type } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(type === 'Expanded');

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const newProps: Partial<NavMenuItemCombinedProps> | undefined = expansionProps ? {
    type: isExpanded ? 'Expanded' : 'Collapsed',
    nextButton: {
      icon: {
        asset: isExpanded ? 'ChevronUp' : 'ChevronDown',
        style: 'SubduedDark',
      },
    },
    onClick: toggleExpand,
  } : undefined;

  return {
    ...props,
    ...newProps,
  };
};

export default usePresenter;
