import { handleTagFilter } from '../../../lib/util';
import type { Account } from '../../../modules/auth/types';
import type { EventsQueryParams, PerformerDetail } from '../../../modules/partnership';
import type { DateRange } from '../../organisms/DateFilter';
import { checkIsCurrentRegionSelected, type RegionFilterState } from '../../organisms/RegionFilter';
import type { HomeAwayToggleState, PerformerType } from './PerformerPage.types';

/**
 * Returns true if either performerId is not a valid positive number or API request to fetch performer details failed.
 * @returns {boolean} True if either performerId is not a valid positive number or API request to fetch performer details failed.
 */
export const checkIsPerformerInvalid = (params: {
  /** Performer Id */
  performerId: number | undefined;
  /** True if API request to fetch performer details failed */
  isPerformerDetailError: boolean;
}): boolean => {
  const { performerId, isPerformerDetailError } = params;
  return !performerId || performerId < 0 || isPerformerDetailError;
};

/**
 * Gets performer type, e.g. nonSports / sports. Undefined if performer details are undefined.
 * @param {PerformerDetail | undefined} performerDetail Performer details to get performer type from.
 * @returns {PerformerType | undefined} Performer type, e.g. nonSports / sports. Undefined if performer details are undefined.
 */
export const getPerformerType = (performerDetail: PerformerDetail | undefined): PerformerType | undefined => {
  return performerDetail
    ? performerDetail.segment_name.toLowerCase() === 'sports' ? 'sports' : 'nonSports'
    : undefined;
};

/**
 * Builds query parameters for fetching events based on the provided filters.
 * @returns {EventsQueryParams} Query parameters for fetching events.
 */
export const buildPerformerPageStaticApiParams = (params: {
  /** Performer Id */
  performerId: number | undefined;
  /** Performer type, e.g. nonSports / sports. Undefined if performer details are undefined. */
  performerType: PerformerType | undefined;
  /** Account object, used to apply additional filters for exclusives */
  account: Account | undefined;
  /** Home/Away toggle state that is applicable only to Sports performers */
  homeAwayToggleState: HomeAwayToggleState | undefined;
  /** Region filter state for filtering events */
  regionFilterState: RegionFilterState | undefined;
  /** Object containing the start and end dates for filtering events */
  dateRange: Record<keyof DateRange, string>;
}): EventsQueryParams => {
  const {
    performerId,
    performerType,
    account,
    homeAwayToggleState,
    regionFilterState,
    dateRange,
  } = params;

  if (!performerId || !performerType || !account) {
    return {};
  }

  return handleTagFilter(
    {
      performer_id: performerId,
      ...(performerType === 'sports' && homeAwayToggleState && homeAwayToggleState !== 'all' ? { home_or_away: homeAwayToggleState.toUpperCase() } : {}),
      ...(regionFilterState && !checkIsCurrentRegionSelected(regionFilterState) ? { region_id: regionFilterState.id } : {}),
      ...(dateRange.startDate ? { date_start: dateRange.startDate } : {}),
      ...(dateRange.endDate ? { date_end: dateRange.endDate } : {}),
    },
    account,
    true, // Add 'NOT C1_EXCLUSIVE' secondary tag filter
    undefined,
    undefined,
  );
};
