import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import type { PageQueryParams, PageResponse } from '../../../modules/partnership';
import { INITIAL_PAGE_STATE } from './PaginatedList.constants';
import type { PageState, PaginatedListHookRequest, PaginatedListHookResponse } from './PaginatedList.types';
import { fetchItemsFromApi, updatePageStateFromResponse, updateStaticApiParams } from './PaginatedList.utils';

export const usePaginatedList = <TApiParams extends PageQueryParams, TItem extends { id: number; }>(
  request: PaginatedListHookRequest<TApiParams, TItem>,
): PaginatedListHookResponse<TItem> => {
  const { isFetchEnabled, fetchItemsFunc, staticApiParams: newStaticApiParams, trackFetchAnalytics, filterItemsFunc } = request;

  const { mutateAsync: fetchItems, isLoading: isFetchingItems } = useMutation(fetchItemsFunc);

  const [staticApiParams, setStaticApiParams] = useState<TApiParams | undefined>();

  const [pageState, setPageState] = useState<PageState>(INITIAL_PAGE_STATE);

  // All items that have been fetched so far
  const [items, setItems] = useState<TItem[]>([]);

  // Total number of items on the backend
  const [itemsTotal, setItemsTotal] = useState<number>(0);

  // Always reset page index and clear all items whenever static API parameters change
  useEffect(() => {
    if (isFetchEnabled) {
      updateStaticApiParams({
        newStaticApiParams,
        staticApiParams,
        setStaticApiParams,
        setPageState,
        setItems,
      });
    }
  }, [isFetchEnabled, newStaticApiParams]); // eslint-disable-line react-hooks/exhaustive-deps

  // API response for the most recent fetch request
  const [lastFetchResponse, setLastFetchResponse] = useState<PageResponse<TItem> | undefined>();

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  // Fetch items from the backend and store API response in the local state
  useEffect(() => {
    if (staticApiParams && pageState.shouldFetchPage) {
      void fetchItemsFromApi({
        staticApiParams,
        pageState,
        items,
        trackFetchAnalytics,
        fetchItems,
        filterItemsFunc,
        setLastFetchResponse,
        setPageState,
        trackEvent,
      });
    }
  }, [staticApiParams, pageState, items, fetchItems, filterItemsFunc, trackFetchAnalytics, trackEvent]);

  // Update page index (if changed), items and itemsTotal whenever we receive an API response
  useEffect(() => {
    if (lastFetchResponse) {
      updatePageStateFromResponse({
        lastFetchResponse,
        setPageState,
        setItems,
        setItemsTotal,
      });
    }
  }, [lastFetchResponse]);

  // Flag to render See More button
  const isSeeMoreShown: boolean = useMemo(() => {
    return !!lastFetchResponse
      && pageState.page < lastFetchResponse.number_of_pages // There must be at least 1 remaining page to fetch from the backend
      && items.length < itemsTotal // There must be at least 1 remaining item to fetch from the backend
      && !!lastFetchResponse.items.length; // The most recent API response must have returned at least 1 item
  }, [pageState.page, lastFetchResponse, items.length, itemsTotal]);

  // Function to fetch the next page of items
  const fetchNext = useCallback(() => {
    setPageState((prevPageState: PageState) => ({
      page: prevPageState.page + 1,
      shouldFetchPage: true,
    }));

    trackSelectContentEvent(
      trackEvent,
      'Button',
      'EventList',
      t('paginatedList.seeMore'),
    );
  }, [t, trackEvent]);

  return {
    isFetchingItems,
    items,
    itemsTotal,
    isSeeMoreShown,
    fetchNext,
  };
};
