import type { AccountCardDetail } from '../../../modules/auth/types';
import type { AccountCardProps } from '../AccountCard';
import { AccountCardThemesByRewardsBalanceTheme } from './RewardsBalance.constants';
import type { RewardsBalanceTheme } from './RewardsBalance.types';

/**
 * Builds an array of account card props.
 * @returns {AccountCardProps[]} Array of account card props.
 */
export const getAccountCardProps = (params: {
  /** Account card details */
  accountCardDetails: AccountCardDetail[];
  theme: RewardsBalanceTheme;
}): AccountCardProps[] => {
  const { accountCardDetails, theme: rewardsBalanceTheme } = params;

  return accountCardDetails.map((accountCardDetail: AccountCardDetail, key: number) => ({
    ...accountCardDetail,
    key,
    theme: AccountCardThemesByRewardsBalanceTheme[rewardsBalanceTheme],
    areRewardUnitsBold: true,
  }));
};
