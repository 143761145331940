import React from 'react';
import { SearchSuggestionSection } from '../../organisms/SearchSuggestionSection';
import styles from './SearchSuggestionBlock.module.scss';
import { usePresenter } from './SearchSuggestionBlock.presenter';
import type { SearchSuggestionBlockProps } from './SearchSuggestionBlock.types';

export const SearchSuggestionBlock: React.FC<SearchSuggestionBlockProps> = (props) => {
  const {
    searchSuggestionSections,
  } = usePresenter(props);

  return (
    <div className={styles.searchSuggestionBlock}>
      <div className={styles.content}>
        {searchSuggestionSections.map((searchSuggestionSectionProps, index) => (
          <SearchSuggestionSection key={index} {...searchSuggestionSectionProps} />
        ))}
      </div>
    </div>
  );
};
