import { useContext, useEffect, useRef } from 'react';
import { buildListingHref, getListingMetadata, getListingSectionHtmlId } from '../../../lib/listingUtils';
import type { ListingMetadata } from '../../../lib/types';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { AuthContext } from '../../../modules/auth';
import type { ListingCardPresenterProps, ListingCardProps } from './ListingCard.types';

export const usePresenter = (props: ListingCardProps): ListingCardPresenterProps => {
  const {
    listing,
    shouldShowAipOverride,
    setHoveredSection,
    setSelectedSection,
  } = props;

  // Important: This component is memoized so there is no need to use useMemo here in the presenter.

  const { selectedAccountCardDetail } = useContext(AuthContext);

  const { trackEvent } = useAnalyticsManager();

  const listingMetadata: ListingMetadata = getListingMetadata({
    listing,
    loyaltyUnitName: selectedAccountCardDetail?.rewardUnitName,
    shouldShowAipOverride,
  });

  const {
    id: listingId,
    ticketQuantities,
    hasCp1BrokerId,
  } = listingMetadata;

  const listingHref: string = buildListingHref({
    search: window.location.search,
    listingId,
    ticketQuantity: ticketQuantities[0],
    hasCp1BrokerId,
  });

  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    const timer: NodeJS.Timeout | undefined = timerRef.current;

    // Clear timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const shouldSkipOnListingCardMouseEnter = useRef<boolean>(false);
  const shouldSkipOnListingCardMouseLeave = useRef<boolean>(false);

  const listingSectionHtmlId: string = getListingSectionHtmlId(listing);

  /** Callback on listing card click */
  const onListingCardClick = () => {
    trackSelectContentEvent(
      trackEvent,
      'Production',
      'Ticket Listing',
      listingMetadata.sectionName,
    );

    // Reset hovered section to hide tooltip with min price
    setHoveredSection?.(undefined);

    shouldSkipOnListingCardMouseLeave.current = true;
  };

  const showMinPriceTooltipAndSelection = () => {
    // Set hovered section to show tooltip with min price
    setHoveredSection?.({ element: listingSectionHtmlId });

    // If there is an already selected section that was selected directly from SVG map then this existing selection takes priority over selection from listing cards.
    // In this case the currently selected section will not be updated.
    // Note: Selection from a listing card uses selection mode 'without-listing-filtering-by-section'.
    setSelectedSection?.({
      element: listingSectionHtmlId,
      selectionMode: 'without-listing-filtering-by-section',
    });
  };

  const hideMinPriceTooltipAndSelection = () => {
    // Reset hovered section to hide tooltip with min price
    setHoveredSection?.(undefined);

    // Reset selected section only if it was selected by hovering over a listing card
    setSelectedSection?.('reset-temporary-selection');
  };

  /** Callback on listing card touch start */
  const onListingCardTouchStart = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Delay processing by 500ms in case user is making a click
    timerRef.current = setTimeout(() => showMinPriceTooltipAndSelection(), 500);
  };

  /** Callback on listing card touch end */
  const onListingCardTouchEnd = () => {
    if (timerRef.current) {
      // If user is making a click clear timer not to show tooltip with min price and not to select the section
      clearTimeout(timerRef.current);
    }

    hideMinPriceTooltipAndSelection();

    shouldSkipOnListingCardMouseEnter.current = true;
  };

  /** Callback on listing card mouse enter */
  const onListingCardMouseEnter = () => {
    if (!shouldSkipOnListingCardMouseEnter.current) {
      showMinPriceTooltipAndSelection();
    }

    shouldSkipOnListingCardMouseEnter.current = false;
  };

  /** Callback on listing card mouse leave */
  const onListingCardMouseLeave = () => {
    if (!shouldSkipOnListingCardMouseLeave.current) {
      hideMinPriceTooltipAndSelection();
    }

    shouldSkipOnListingCardMouseLeave.current = false;
  };

  return {
    ...props,
    listingMetadata,
    listingHref,
    onListingCardClick,
    onListingCardTouchStart,
    onListingCardTouchEnd,
    onListingCardMouseEnter,
    onListingCardMouseLeave,
  };
};
