import React, { useContext, type ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useWindowSize } from '../../../lib/util';
import { TopNavContext } from '../../../modules/topNav';
import { Backdrop } from '../../atoms/Backdrop';
import { SearchSuggestionBlock } from '../../blocks/SearchSuggestionBlock';
import { SearchBar } from '../../molecules/SearchBar';
import styles from './SearchBarWithSuggestionsDropdown.module.scss';
import { usePresenter } from './SearchBarWithSuggestionsDropdown.presenter';
import type { SearchBarWithSuggestionsDropdownProps } from './SearchBarWithSuggestionsDropdown.types';

export const SearchBarWithSuggestionsDropdown: React.FC<SearchBarWithSuggestionsDropdownProps> = (props) => {
  const {
    searchSuggestionsDropdownRef,
    onSearchSuggestionsDropdownToggled,
  } = usePresenter(props);

  const { searchSuggestions } = useContext(TopNavContext);

  const { isDesktop } = useWindowSize();

  const searchSuggestionsView: ReactNode = isDesktop && !!searchSuggestions && (
    <SearchSuggestionBlock searchSuggestions={searchSuggestions} />
  );

  return <>
    {isDesktop && (
      <Dropdown
        ref={searchSuggestionsDropdownRef}
        show={!!searchSuggestionsView}
        onToggle={onSearchSuggestionsDropdownToggled}
        className={styles.dropdown}
      >
        <Dropdown.Toggle
          as='div'
          aria-haspopup={undefined}
          aria-expanded={undefined}
          className={styles.dropdownToggle}
        >
          <SearchBar
            className={styles.searchBar}
            isTextInputFocusedOnMount={false}
            isTextInputFocusedOnClear={true}
            isTextInputBlurredOnFocus={false}
            hasSearchSuggestions={!!searchSuggestionsView}
          />
        </Dropdown.Toggle>
        {!!searchSuggestionsView && (<>
          <Dropdown.Menu className={styles.dropdownMenu}>
            {searchSuggestionsView}
          </Dropdown.Menu>
          <Backdrop onClose={onSearchSuggestionsDropdownToggled} />
        </>)}
      </Dropdown>
    )}
  </>;
};
