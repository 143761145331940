import { THEATER_SUB_CATEGORIES } from '../../../modules/categories';
import i18n from '../../../modules/locale/i18n';
import type { PresetFilterOption } from '../../organisms/PresetFilter';
import type { VenueCategory } from './VenuePage.types';

export const TRACKING_PAGE_NAME = {
  forPageVisits: 'Venue',
  /** Used for view_item_list tracking */
  forViewItemList: 'venue_landing_page_View',
  forEventCardClicks: 'Venue Landing Page',
} as const;

export const CATEGORIES_MAP = {
  sports: {
    id: 'sports',
    name: i18n.t('venuePage.categories.sports'),
    apiParams: {
      category: 'sports',
    },
    includeSecondaryRagFilter: true,
  },
  music: {
    id: 'music',
    name: i18n.t('venuePage.categories.music'),
    apiParams: {
      category: 'music',
    },
    includeSecondaryRagFilter: true,
  },
  comedy: {
    id: 'comedy',
    name: i18n.t('venuePage.categories.comedy'),
    apiParams: {
      category: 'theatre',
      sub_category_id: THEATER_SUB_CATEGORIES.comedy,
    },
    includeSecondaryRagFilter: true,
  },
  theater: {
    id: 'theater',
    name: i18n.t('venuePage.categories.theater'),
    apiParams: {
      category: 'theatre',
      excluded_sub_category_name: 'comedy',
    },
    includeSecondaryRagFilter: true,
  },
  cardholderExclusives: {
    id: 'cardholderExclusives',
    name: i18n.t('venuePage.categories.cardholderExclusives'),
    apiParams: {},
    includeSecondaryRagFilter: false,
  },
} as const;

/** Array of preset filter options for the category filter */
export const CategoryFilterOptions: PresetFilterOption<VenueCategory>[] = Object.values(CATEGORIES_MAP);
