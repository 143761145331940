import { areObjectsEqual } from '../../lib/objectUtils';
import { ListingMinPricesBySection } from '../../lib/types';
import { isNumber } from '../../lib/util';
import { SVG_MAP_ID } from './MapContext.constants';
import type { MinPriceTooltipData, SelectedSection, SelectionMode, TooltipPosition } from './MapContext.types';

export const getTransform = (
  /** E.g. 'm1,0,0,1,0,0' */
  transform: string = '',
): string => {
  const transformSplits: string[] = transform.split('m');
  return transformSplits[1] ? `matrix(${transformSplits[1]})` : transform;
};

export const getTooltipPositionFromDimensions = (params: {
  /** X coordinate of element's top left corner */
  x: number;
  /** Y coordinate of element's top left corner */
  y: number;
  /** Element's width */
  width: number;
  /** Element's height */
  height: number;
}): TooltipPosition => {
  const { x, y, width, height } = params;

  // For the majority of map elements the best placement for the tooltip is at the top of the section being hovered.
  // But tooltips for map elements that wrap around the map should be centered in the middle of the map.
  const bestY: number = height > 255 ? y + height / 2 : y;
  return { x: x + width / 2, y: bestY };
};

/** Gets CSS selector for provided sections, e.g. #svg-map path#id-123, #svg-map path#id-234 */
export const getCssSelectorForSections = (params: {
  sectionHtmlIds: Array<string | undefined>;
}): string | undefined => {
  const { sectionHtmlIds } = params;

  const nonEmptySectionHtmlIds: string[] = sectionHtmlIds.filter((sectionHtmlId: string | undefined) => !!sectionHtmlId) as string[];

  if (!nonEmptySectionHtmlIds.length) {
    return undefined;
  }

  return nonEmptySectionHtmlIds.map((sectionHtmlId: string) => `#${SVG_MAP_ID} path#${sectionHtmlId}`).join(', ');
};

export const getSectionHtmlId = (element: string | SVGSVGElement): string | undefined =>  typeof element === 'string' ? element : element.id;

export const getNewHoveredSectionHtmlId = (element: string | SVGSVGElement): string | undefined => typeof element === 'string'
  ? element
  : element.id;

export const handleSectHoveredSection = (
  isSvgMapSupported: boolean,
  params: { element: SVGSVGElement | string; } | undefined,
  availableSectionHtmlIdMap: Record<string, true>,
  listingMinPricesBySection: ListingMinPricesBySection,
  setHoveredSectionHtmlId: (value: React.SetStateAction<string | undefined>) => void,
  setMinPriceTooltipData: (value: React.SetStateAction<MinPriceTooltipData | undefined>) => void,
) => {
  // Do not do anything if SVG map is not supported
  if (!isSvgMapSupported) {
    return;
  }
  
  if (!params) {
    setHoveredSectionHtmlId(undefined);
    setMinPriceTooltipData(undefined);
    return;
  }
  
  const newHoveredSectionHtmlId: string | undefined = getNewHoveredSectionHtmlId(params.element);
  
  // Update hovered section HTML Id
  // Ensure we update hovered section HTML Id only if it is one of available sections that have at least one listing with a price
  if (!newHoveredSectionHtmlId || !availableSectionHtmlIdMap[newHoveredSectionHtmlId]) {
    setHoveredSectionHtmlId(undefined);
  } else {
    setHoveredSectionHtmlId(newHoveredSectionHtmlId);
  }
  
  // Update tooltip data with min price and position
  // Ensure we update tooltip data only if we have a min price for the hovered section
  if (!newHoveredSectionHtmlId || !isNumber(listingMinPricesBySection[newHoveredSectionHtmlId])) {
    setMinPriceTooltipData(undefined);
  } else {
    const svgElement: SVGSVGElement | null = typeof params.element === 'string'
      ? document.querySelector(`#${SVG_MAP_ID} path#${newHoveredSectionHtmlId}`)
      : params.element;
  
    if (svgElement) {
      const { x, y, width, height }: DOMRect = svgElement.getBoundingClientRect();
      const tooltipPosition: TooltipPosition = getTooltipPositionFromDimensions({ x, y, width, height });
  
      setMinPriceTooltipData({
        minPrice: listingMinPricesBySection[newHoveredSectionHtmlId],
        tooltipPosition,
      });
    }
  }
};

export const handleSetSelectedSection = (
  isSvgMapSupported: boolean,
  params: {
    element: SVGSVGElement | string,
    selectionMode: SelectionMode,
  } | 'reset-temporary-selection' | undefined,
  internalSetSelectedSection: (value: React.SetStateAction<SelectedSection | undefined>) => void,
  availableSectionHtmlIdMap: Record<string, true>,
) => {
  // Do not do anything if SVG map is not supported
  if (!isSvgMapSupported) {
    return;
  }

  if (!params) {
    internalSetSelectedSection(undefined);
    return;
  }

  // If 'reset-temporary-selection' is provided then selected section will be reset only if it was selected by hovering over a listing card
  // In this case selection mode will be 'without-listing-filtering-by-section'
  if (params === 'reset-temporary-selection') {
    internalSetSelectedSection((prevSelectedSection: SelectedSection | undefined) => {
      return !prevSelectedSection || prevSelectedSection.selectionMode === 'without-listing-filtering-by-section'
        ? undefined
        : prevSelectedSection;
    });
    return;
  }

  const sectionHtmlId: string | undefined = getSectionHtmlId(params.element);

  // Update selected section HTML Id
  // Ensure we update selected section HTML Id only if it is one of available sections that have at least one listing with a price
  if (sectionHtmlId && availableSectionHtmlIdMap[sectionHtmlId]) {
    const { selectionMode } = params;

    internalSetSelectedSection((prevSelectedSection: SelectedSection | undefined) => {
      const newSelectedSection: SelectedSection = { sectionHtmlId, selectionMode };

      if (!prevSelectedSection) {
        return newSelectedSection;
      }

      // If there is an already selected section that was selected directly from SVG map then this existing selection takes priority over selection from listing cards.
      // In this case the currently selected section will not be updated.
      // Note: Selection from a listing card uses selection mode 'without-listing-filtering-by-section'.
      if (prevSelectedSection.selectionMode === 'with-listing-filtering-by-section' && selectionMode !== 'with-listing-filtering-by-section') {
        return prevSelectedSection;
      }

      const hasSelectedSectionChanged: boolean = !areObjectsEqual(prevSelectedSection, newSelectedSection);

      if (hasSelectedSectionChanged) {
        return newSelectedSection;
      } else {
        // If this section is already selected directly from SVG map and the same section is selected again then selection will be reset
        return selectionMode === 'with-listing-filtering-by-section'
          ? undefined
          : prevSelectedSection;
      }
    });
  }
};