import React from 'react';
import { AccountCard, type AccountCardProps } from '../AccountCard';
import styles from './AccountCards.module.scss';
import { usePresenter } from './AccountCards.presenter';
import type { AccountCardsProps } from './AccountCards.types';

export const AccountCards: React.FC<AccountCardsProps> = (props) => {
  const { accountCardProps } = usePresenter(props);

  return (
    <div className={styles.accountCards}>
      {accountCardProps.map((currentAccountCardProps: AccountCardProps) => (
        <AccountCard
          {...currentAccountCardProps}
          key={currentAccountCardProps.key}
        />
      ))}
    </div>
  );
};
