import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ContentLoadErrorBlock from '../../../legacy/blocks/ContentLoadErrorBlock';
import { StaticImageMap } from '../../molecules/StaticImageMap';
import { SvgMap } from '../../molecules/SvgMap';
import styles from './MapWrapper.module.scss';
import { usePresenter } from './MapWrapper.presenter';
import type { MapWrapperProps } from './MapWrapper.types';

export const MapWrapper: React.FC<MapWrapperProps> = (props) => {
  const {
    isSvgMapSupported,
    isSvgMapPanZoomEnabled,
    isStaticImageMapSupported,
    staticImageMapUrl,
  } = usePresenter(props);

  const { t } = useTranslation();

  // Use NonNullable<ReactNode> to ensure we return a value
  const getMapView = (): NonNullable<ReactNode> => {
    if (isSvgMapSupported) {
      return <SvgMap isSvgMapPanZoomEnabled={isSvgMapPanZoomEnabled} />;
    }

    if (isStaticImageMapSupported) {
      return <StaticImageMap staticImageMapUrl={staticImageMapUrl} />;
    }

    return (
      <ContentLoadErrorBlock
        title={{ value: t('errors.unableToLoadContent.title') }}
        message={{ value: t('errors.unableToLoadContent.message') }}
      />
    );
  };

  return (
    <div className={styles.mapWrapper}>
      {getMapView()}
    </div>
  );
};
