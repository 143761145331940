import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getQueryParams } from '../../../lib/util';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackErrorEvent } from '../../../modules/analytics/util';
import { ApiError } from '../../../modules/error/types';
import type { PerformerDetail, PerformerPageResponse, PerformersQueryParams } from '../../../modules/partnership';
import type { DropdownOption } from '../../molecules/Filter/types';
import { getDefaultFilterOption, getDefaultLocationFilterOptions, getFilteredLocationFilterOptions, getSelectedLocationFilterOption } from '../../molecules/Filter/utils';
import type { ThumbnailCardValueProps } from '../../molecules/ThumbnailCard';
import useInteractor from './SportTeamsNearLocationBlock.interactor';
import type { SportTeamsNearLocationBlockCombinedProps } from './types';

const usePresenter = (props: SportTeamsNearLocationBlockCombinedProps): SportTeamsNearLocationBlockCombinedProps => {
  const {
    fetchTeams,
    getPerformer,
    currentRegion,
    sportsLeague,
    locationList,
  } = useInteractor(props);

  const { trackEvent } = useAnalyticsManager();
  const { search } = useLocation();
  const { t } = useTranslation();
  const query = new URLSearchParams(search);
  const regionId: string | null = query.get('region_id');
  const [teamCards, setTeamCards] = useState<PerformerDetail[]>();
  const [locationFilterOptions, setLocationFilterOptions] = useState<DropdownOption[]>([]);
  const [apiParams, setApiParams] = useState<PerformersQueryParams>({
    region_id: currentRegion?.id,
    ...getQueryParams(search),
    subgenre_id: sportsLeague,
  });

  /** Default location filter options that include options for search field and current region */
  const defaultLocationFilterOptions: DropdownOption[] = useMemo(
    () => getDefaultLocationFilterOptions(currentRegion),
    [currentRegion],
  );

  // Update location filter options every time current region changes
  useEffect(() => {
    setLocationFilterOptions(defaultLocationFilterOptions);
  }, [defaultLocationFilterOptions]);

  /** First non-search-field location filter option */
  const defaultLocationFilterOption: DropdownOption | undefined = useMemo(
    () => getDefaultFilterOption(locationFilterOptions),
    [locationFilterOptions],
  );

  /** Selected location filter option for a region which Id matches 'region_id' URL query parameter */
  const selectedLocationFilterOption: DropdownOption | undefined = useMemo(
    () => getSelectedLocationFilterOption(locationList, regionId),
    [locationList, regionId],
  );

  const handleRegionFilter = (newFilterText: string) => {
    if (newFilterText.length >= 3) {
      if (locationList) {
        const newLocationFilterOptions: DropdownOption[] = [
          ...defaultLocationFilterOptions,
          ...getFilteredLocationFilterOptions(locationList, newFilterText),
        ];
        setLocationFilterOptions(newLocationFilterOptions);
      }
    } else {
      setLocationFilterOptions(defaultLocationFilterOptions);
    }
  };

  useEffect(() => {
    setTeamCards([]);
    const queryParams = { ...getQueryParams(search) };
    const currentRegionParams = {
      region_id: currentRegion?.id,
    };

    setApiParams({
      ...currentRegionParams,
      ...queryParams,
      subgenre_id: sportsLeague,
    });
  }, [search, currentRegion, sportsLeague]);

  useEffect(() => {
    const doFetchTeams = async (queryParams: PerformersQueryParams) => {
      try {
        let teamDetails;
        if (fetchTeams && getPerformer) {
          teamDetails = await fetchTeams(queryParams).then(
            (page: PerformerPageResponse) => {
              return Promise.all(
                page.items.map(async (item) => {
                  const team = await getPerformer(String(item.id));
                  return team;
                }),
              );
            },
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setTeamCards(teamDetails);
      } catch (error) {
        if (error && ApiError.isApiError(error)) {
          trackErrorEvent(
            trackEvent,
            error.code,
            error.message,
          );
        }
        // TODO
      }
    };

    const queryParams = { ...apiParams };
    if (currentRegion?.id || regionId) {
      void doFetchTeams(queryParams);
    }
  }, [apiParams, fetchTeams, getPerformer]);

  const cards = teamCards?.map((item): ThumbnailCardValueProps => {
    return {
      title: {
        value: item.name,
      },
      imageSrc: item.image,
      linkPath: `/performers/${item.id}`,
    };
  });

  return {
    ...props,
    state: cards?.length ? 'Filled' : 'Empty',
    thumbnailCardList: {
      thumbnailCards: cards,
    },
    filter: {
      type: 'TextIconFilter',
      filterType: 'SearchLocationFilter',
      filterTitle: t('sportsLeague.teamsNear'),
      filterQueryParamName: 'region_id',
      options: locationFilterOptions,
      defaultFilterOption: defaultLocationFilterOption,
      selectedFilterOption: selectedLocationFilterOption,
      onFilterTextChanged: handleRegionFilter,
    },
  };
};

export default usePresenter;
