import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../atoms/Text';
import styles from './StepItem.module.scss';
import { usePresenter } from './StepItem.presenter';
import type { StepItemProps } from './StepItem.types';

export const StepItem: React.FC<StepItemProps> = (props) => {
  const {
    stepItemLabelKey,
    stepItemState,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <div className={cx(styles.stepItem, styles[`${stepItemState}StepItem`], className)}>
      <Text
        size='Small'
        style='Regular'
        colour='SubduedLight'
        align='Center'
        value={t(stepItemLabelKey)}
        classes={{ value: styles.stepItemLabel }}
      />
    </div>
  );
};
