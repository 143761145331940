import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { checkIsEventVenueInStates } from '../../../../lib/eventUtils';
import type { ListingDetailMetadata } from '../../../../lib/types';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../../modules/analytics';
import type { Account } from '../../../../modules/auth/types';
import type { Order, OrderResponse } from '../../../../modules/partnership';
import { createOrder as createOrderApi } from '../../../../modules/partnership/api';
import type { IconAssetEnum, IconStyleEnum } from '../../../atoms/Icon';
import { TERMS_OF_USE_MODAL, useTermsOfUseModal } from '../../../organisms/TermsOfUseModal';
import type { CheckoutAddressInfoProps, CheckoutContactInfoProps, CheckoutOrderInfoProps, CheckoutPaymentInfoProps } from '../CheckoutPage.types';
import { buildOrderRequest } from '../CheckoutPage.utils';

export const useCheckoutOrderInfo = (params: {
  listingDetailMetadata: ListingDetailMetadata | undefined;
  checkoutContactInfoProps: CheckoutContactInfoProps;
  checkoutPaymentInfoProps: CheckoutPaymentInfoProps;
  checkoutAddressInfoProps: CheckoutAddressInfoProps;
  goToPaymentInfo: () => void;
}): CheckoutOrderInfoProps => {
  const {
    listingDetailMetadata,
    checkoutContactInfoProps,
    checkoutPaymentInfoProps,
    checkoutAddressInfoProps,
    goToPaymentInfo,
  } = params;

  const { trackEvent, onError } = useAnalyticsManager();

  const { t } = useTranslation();

  const { openTermsOfUseModal: initialOpenTermsOfUseModal } = useTermsOfUseModal();

  const openTermsOfUseModal = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'CheckoutPage',
      'Modal',
      TERMS_OF_USE_MODAL,
    );

    initialOpenTermsOfUseModal();
  }, [trackEvent, initialOpenTermsOfUseModal]);

  /**
   * Indicates whether terms-of-use checkbox should be shown.
   * True only if event venue is in the state of New York.
   */
  const shouldShowTermsOfUseCheckbox: boolean = useMemo(
    () => checkIsEventVenueInStates(listingDetailMetadata?.eventMetadata, ['NY']),
    [listingDetailMetadata?.eventMetadata],
  );

  const [areTermsOfUseAccepted, setAreTermsOfUseAccepted] = useState<boolean>(false);

  const toggleTermOfUseCheckbox = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'CheckoutPage',
      'CheckBox',
      t('orderInfo.termsOfUse.description'),
    );

    setAreTermsOfUseAccepted((prevAreTermsOfUseAccepted: boolean) => !prevAreTermsOfUseAccepted);
  }, [t, trackEvent]);

  /** Icon asset for terms-of-use checkbox */
  const termsOfUseCheckboxIconAsset: IconAssetEnum = useMemo(
    () => areTermsOfUseAccepted ? 'CheckboxFilled' : 'CheckboxEmpty',
    [areTermsOfUseAccepted],
  );

  const [isTermsOfUseError, setIsTermsOfUseError] = useState<boolean>(false);

  /** Icon style for terms-of-use checkbox */
  const termsOfUseCheckboxStyle: IconStyleEnum = useMemo(
    () => isTermsOfUseError ? 'Error' : 'ActionBase',
    [isTermsOfUseError],
  );

  // Reset isTermsOfUseError when areTermsOfUseAccepted is true
  useEffect(() => {
    if (areTermsOfUseAccepted && isTermsOfUseError) {
      setIsTermsOfUseError(false);
    }
  }, [areTermsOfUseAccepted, isTermsOfUseError]);

  /** Function to check if the order information is valid */
  const checkIsOrderInfoValid = (): boolean => {
    if (shouldShowTermsOfUseCheckbox && !areTermsOfUseAccepted) {
      setIsTermsOfUseError(true);
      return false;
    }

    return true;
  };

  const { mutateAsync: createOrder } = useMutation(createOrderApi, { onError });

  /** Function to place an order */
  const placeOrder = useCallback(
    async (placeOrderParams: { account: Account; }): Promise<OrderResponse> => {
      const orderRequest: Order = buildOrderRequest({
        account: placeOrderParams.account,
        listingDetailMetadata,
        editedPhoneNumber: checkoutContactInfoProps.phoneNumber,
        creditCardData: checkoutPaymentInfoProps.creditCardData,
        appliedRewardUnits: checkoutPaymentInfoProps.appliedRewardUnits,
        shippingAddress: checkoutAddressInfoProps.isShippingAddressRequired ? checkoutAddressInfoProps.shippingAddress : undefined,
      });

      const orderResponse: OrderResponse = await createOrder(orderRequest);
      return orderResponse;
    },
    [
      listingDetailMetadata,
      checkoutContactInfoProps,
      checkoutPaymentInfoProps,
      checkoutAddressInfoProps,
      createOrder,
    ]);

  return {
    ...checkoutContactInfoProps,
    ...checkoutPaymentInfoProps,
    ...checkoutAddressInfoProps,
    goToPaymentInfo,
    openTermsOfUseModal,
    isFaceValueShown: !!listingDetailMetadata?.faceValue,
    shouldShowTermsOfUseCheckbox,
    areTermsOfUseAccepted,
    toggleTermOfUseCheckbox,
    termsOfUseCheckboxIconAsset,
    termsOfUseCheckboxStyle,
    isTermsOfUseError,
    checkIsOrderInfoValid,
    placeOrder,
  };
};
