import deepmerge from 'deepmerge';

/** Typed wrapper for deepmerge function to ensure arguments are of the same type */
export const typedDeepMerge = <T extends Record<string, unknown>>(source: T, target: Partial<T>, options?: deepmerge.Options): T => {
  return deepmerge<T>(source, target, options);
};

export const areObjectsEqual = (
  obj1: unknown,
  obj2: unknown,
  options?: {
    /** Properties to skip during comparison */
    properties: Record<string, 'ignore'>;
  },
): boolean => {
  // If objects are strictly equal then return true
  if (obj1 === obj2) {
    return true;
  }

  // Check if any object is one of the following:
  // - bigint
  // - boolean
  // - function
  // - number
  // - string
  // - symbol
  // - null (in Javascript (typeof null === 'object') so check for 'null' additionally)
  // - undefined
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    // At this points objects are not strictly equal so return false
    return false;
  }

  // At this point objects can only be either plain objects or arrays

  // If one of the objects is an array and the other is not then return false
  if ((Array.isArray(obj1) && !Array.isArray(obj2)) || (!Array.isArray(obj1) && Array.isArray(obj2))) {
    return false;
  }

  // If both objects are arrays then compare each element
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }

    for (let i = 0; i < obj1.length; i += 1) {
      if (!areObjectsEqual(obj1[i], obj2[i], options)) {
        return false;
      }
    }
  }

  // At this point objects can only be both plain objects

  let keys1: string[] = Object.keys(obj1);
  let keys2: string[] = Object.keys(obj2);

  // Skip ignored properties if provided
  if (options?.properties) {
    keys1 = keys1.filter((key: string) => options.properties[key] !== 'ignore');
    keys2 = keys2.filter((key: string) => options.properties[key] !== 'ignore');
  }

  // If number of keys is not the same then return false
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Compare each property value in plain objects
  for (const key of keys1) {
    const value1 = obj1[key];
    const value2 = obj2[key];

    if (!areObjectsEqual(value1, value2, options)) {
      return false;
    }
  }

  return true;
};

/**
 * Function to convert array of items to a map where key is item.id and value is item.
 * @param {TItem[]} items Array of items to be converted to a map.
 * @returns {Record<TId, TItem>} Map where key is item.id and value is item.
 */
export const convertArrayToMap = <TId extends string | number, TItem extends { id: TId; }>(items: TItem[]): Record<TId & string, TItem> => {
  return items.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {} as Record<TId, TItem>);
};

/**
 * Creates an array of a specified length, filled with a specified value.
 * @template TItem The type of the items in the array.
 * @returns {TItem[]} An array of the specified length filled with the specified value.
 */
export const getArrayOfLength = <TItem>(params: {
  /** The length of the array to be created */
  arrayLength: number;
  /** The value to fill the array with */
  itemValue: TItem;
}): TItem[] => {
  const { arrayLength, itemValue } = params;
  return new Array<TItem>(arrayLength).fill(itemValue);
};

/**
 * If array length is less than minLength then returns an empty array.
 * If array length is greater than maxLength then returns the first maxLength items in the array.
 * Otherwise returns the provided array as-is.
 * @returns {TItem[]} The provided array that satisfies minLength/maxLength requirements. Otherwise, an empty array.
 */
export const checkArrayMinMaxLength = <TItem>(params: {
  /** Array to check for min length */
  array: TItem[] | undefined;
  /** Min length of the array */
  minLength: number;
  /** Optional max length of the array */
  maxLength?: number;
}): TItem[] => {
  const { array, minLength, maxLength } = params;

  if (array && array.length >= minLength) {
    return maxLength !== undefined && array.length > maxLength ? array.slice(0, maxLength) : array;
  } else {
    return [];
  }
};
