import { useCallback, useContext, useMemo } from 'react';
import { TopNavContext } from '../../../modules/topNav';
import type { IconAssetEnum } from '../../atoms/Icon';
import type { ExploreButtonPresenterProps, ExploreButtonProps } from './ExploreButton.types';
import { getActiveTopNavMenuType, getExploreButtonIconAsset, getIsExploreMenuExpanded } from './ExploreButton.utils';

export const usePresenter = (props: ExploreButtonProps): ExploreButtonPresenterProps => {
  const { activeTopNavMenuType, setActiveTopNavMenuType } = useContext(TopNavContext);

  const isExploreMenuExpanded: boolean = useMemo(() => getIsExploreMenuExpanded({ activeTopNavMenuType }), [activeTopNavMenuType]);

  const exploreButtonIconAsset: IconAssetEnum = useMemo(() => getExploreButtonIconAsset({ isExploreMenuExpanded }), [isExploreMenuExpanded]);

  const onExploreMenuToggled = useCallback((isOpen: boolean = false) => {
    setActiveTopNavMenuType(prevActiveTopNavMenuType => getActiveTopNavMenuType({ prevActiveTopNavMenuType, isOpen }));
  }, [setActiveTopNavMenuType]);

  return {
    ...props,
    exploreButtonIconAsset,
    onExploreMenuToggled,
    isExploreMenuExpanded,
  };
};
