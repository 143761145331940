import type { Category } from '../partnership';
import type { CategoriesContextValue } from './CategoriesContext.types';

export const initialCategoriesContextValue = {
  categories: [] as Category[],
  areCategoriesLoading: false,
  error: undefined,
} as const satisfies CategoriesContextValue;

export const CATEGORIES = {
  music: 2,
  sports: 3,
  theater: 4,
} as const;

export const MUSIC_SUB_CATEGORIES = {
  adultContemporary: 21,
  alternative: 74,
  bluesJazz: 19,
  city: 59,
  classical: 16,
  countryFolk: 14,
  danceElectronica: 60,
  hardRock: 73,
  kPop: 79,
  latinMusic: 52,
  musicFestivals: 63,
  newAge: 61,
  otherConcerts: 68,
  parking: 75,
  pop: 17,
  rAndB: 20,
  rapHipHop: 15,
  reggae: 54,
  rock: 18,
  worldMusic: 51,
} as const;

export const SPORTS_SUB_CATEGORIES = {
  aaf: 81,
  amateurWrestling: 86,
  arenaFootball: 55,
  baseball: 38,
  basketball: 40,
  boxingFighting: 32,
  college: 41,
  collegeBaseball: 78,
  extremeSports: 50,
  f1Racing: 88,
  football: 37,
  gymnastics: 29,
  hockey: 39,
  horseRacing: 33,
  lacrosse: 57,
  mlbBaseball: 2,
  mls: 87,
  minorLeagueBaseball: 77,
  motorsports: 70,
  nascarRacing: 7,
  nbaBasketball: 3,
  ncaaBasketball: 6,
  ncaaFootball: 5,
  ncaaWomensBasketball: 89,
  nflFootball: 1,
  nhlHockey: 4,
  otherSports: 67,
  pgaGolf: 8,
  racing: 42,
  rodeo: 36,
  rugby: 30,
  skating: 31,
  soccer: 34,
  softball: 76,
  tennis: 35,
  volleyball: 71,
  wnba: 83,
  wwe: 84,
  wrestling: 13,
  xfl: 82,
  eSports: 80,
} as const;

export const SPORTS_LEAGUE_SUB_CATEGORY_IDS: number[] = [
  SPORTS_SUB_CATEGORIES.nbaBasketball,
  SPORTS_SUB_CATEGORIES.nhlHockey,
  SPORTS_SUB_CATEGORIES.nflFootball,
  SPORTS_SUB_CATEGORIES.mls,
  SPORTS_SUB_CATEGORIES.mlbBaseball,
  SPORTS_SUB_CATEGORIES.ncaaFootball,
  SPORTS_SUB_CATEGORIES.ncaaBasketball,
  SPORTS_SUB_CATEGORIES.collegeBaseball,
];

export const THEATER_SUB_CATEGORIES = {
  artsTheater: 62,
  balletDance: 22,
  broadway: 64,
  circus: 25,
  cirqueDuSoleil: 24,
  comedy: 11,
  family: 10,
  magic: 27,
  musical: 23,
  offBroadway: 28,
  opera: 12,
  otherTheater: 69,
  podcast: 85,
  publicSpeaking: 53,
} as const;
