import type { SvgMapSectionProps, SvgMapSectionsPresenterProps, SvgMapSectionsProps } from './SvgMapSections.types';
import { getSvgMapSectionProps } from './SvgMapSections.utils';

export const usePresenter = (props: SvgMapSectionsProps): SvgMapSectionsPresenterProps => {
  const { svgMapSections } = props;

  // Important: This component is memoized so there is no need to use useMemo here in the presenter.

  /** Array of SVG map section props for rendering on the map */
  const svgMapSectionProps: SvgMapSectionProps[] = getSvgMapSectionProps({ svgMapSections });

  return {
    ...props,
    svgMapSectionProps,
  };
};
