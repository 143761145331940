import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import { ListingsFilters } from '../ListingsFilters/ListingsFilters';
import styles from './ListingsFiltersModal.module.scss';
import { usePresenter } from './ListingsFiltersModal.presenter';
import type { ListingsFiltersModalProps } from './ListingsFiltersModal.types';

export const ListingsFiltersModal: React.FC<ListingsFiltersModalProps> = (props) => {
  const {
    className,
    applyFilters,
    resetFilters,
    closeListingsFiltersModal,
    ...listingsFiltersProps
  } = usePresenter(props);

  const { t } = useTranslation();

  const title: ReactNode = (
    <Text
      type='Subheading'
      size='ExtraSmall'
      style='Regular'
      colour='SubduedLight'
      value={t('listingsFilters.filters')}
    />
  );

  const closeButton: ReactNode = (
    <Button
      type='Icon'
      style='Text'
      size='Medium'
      icon={{
        asset: 'Close',
        style: 'SubduedLight',
      }}
      ariaLabel={t('listingsFilters.closeListingsFiltersModal')}
      onClick={closeListingsFiltersModal}
      className={styles.closeButton}
    />
  );

  const applyFiltersButton: ReactNode = (
    <Button
      type='Text'
      style='Contained'
      size='Medium'
      text={{
        type: 'ButtonText',
        size: 'Medium',
        style: 'Regular',
        colour: 'BaseLight',
        align: 'Center',
        value: t('listingsFilters.apply'),
      }}
      onClick={applyFilters}
      className={styles.applyFiltersButton}
    />
  );

  const resetFiltersButton: ReactNode = (
    <Button
      type='Text'
      style='Text'
      size='Small'
      text={{
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        colour: 'CoreBlue40',
        align: 'Center',
        value: t('listingsFilters.resetFilters'),
      }}
      onClick={resetFilters}
      className={styles.resetFiltersButton}
    />
  );

  return (
    <Modal
      show={true}
      onEscapeKeyDown={closeListingsFiltersModal}
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
    >
      <div className={cx(styles.listingsFiltersModal, className)}>
        <div className={styles.topContent}>
          <div className={styles.header}>
            {title}
            {closeButton}
          </div>
          <ListingsFilters {...listingsFiltersProps} />
        </div>
        <div className={styles.bottomContent}>
          {applyFiltersButton}
          {resetFiltersButton}
        </div>
      </div>
    </Modal>
  );
};
