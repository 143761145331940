import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import Text from '../../atoms/Text';
import { getClickAccessibilityProps } from '../../organisms/TopNav';
import styles from './SubmenuItem.module.scss';
import usePresenter from './SubmenuItem.presenter';
import getProps from './SubmenuItem.props';
import type { SubmenuItemCombinedProps } from './types';

const SubmenuItem: React.FC<SubmenuItemCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    // MANUAL OVERRIDE - removed divider
    // MANUAL OVERRIDE STARTS
    elementRef,
    focusable = true,
    handleTab,
    linkPath,
    onItemClicked,
    externalLink,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = '';
  const internalProps = getProps(variantName);
  const currentStyle = styles[`submenuItem${variantName}`];

  const accessibilityProps = getClickAccessibilityProps({
    actions: {
      onClick: onItemClicked,
      onTab: (forward) => handleTab && handleTab(forward),
    },
  });

  let componentView: JSX.Element;

  switch (variantName) {
    case '': {
      componentView = (
        <Link
          to={linkPath ?? ''}
          rel='noreferrer'
          target={externalLink ? '_blank' : undefined}
          ref={elementRef}
          tabIndex={focusable ? 0 : -1}
          className={cx(currentStyle, className)}
          onClick={onItemClicked}
          {...accessibilityProps}
          role='menuitem'
        >
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text} />
          </div>
        </Link>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SubmenuItem;
