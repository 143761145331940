import { useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { HOME_OR_AWAY_PARAM, REGION_PARAM } from '../../../lib/constants';
import { parseNumber } from '../../../lib/util';
import { trackPageViewEvent, useAnalyticsManager } from '../../../modules/analytics';
import { AuthContext } from '../../../modules/auth';
import { EventListContext } from '../../../modules/eventList';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import { getPerformer } from '../../../modules/partnership/api';
import { RegionContext } from '../../../modules/region';
import { useMultiToggleStateFromUrl } from '../../molecules/MultiToggle';
import { convertDateFilterStateToDateRange, useDateFilterStateFromUrl, type DateFilterState, type DateRange } from '../../organisms/DateFilter';
import { useRegionFilterStateFromUrl, type RegionFilterState } from '../../organisms/RegionFilter';
import { HomeAwayToggleStates, TRACKING_PAGE_NAME } from './PerformerPage.constants';
import type { HomeAwayToggleState, PerformerPagePresenterProps, PerformerPageProps, PerformerType } from './PerformerPage.types';
import { buildPerformerPageStaticApiParams, checkIsPerformerInvalid, getPerformerType } from './PerformerPage.utils';

export const usePresenter = (props: PerformerPageProps): PerformerPagePresenterProps => {
  const { setTopDisclaimer } = props;

  const { account } = useContext(AuthContext);

  const { setIsFetchEnabled, setStaticApiParams } = useContext(EventListContext);

  const {
    areRegionsByPerformerIdLoading,
    regionsByPerformerId,
    regionsByPerformerIdMap,
    setPerformerId,
  } = useContext(RegionContext);

  const { trackEvent, onError } = useAnalyticsManager();

  const { performerId: performerIdStr } = useParams<{ performerId: string; }>();

  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  /** Validated performer Id. Undefined if performer Id is invalid, e.g. when it is non-numeric or it is less than 1. */
  const performerId: number | undefined = useMemo(() => parseNumber(performerIdStr, { minValue: 1 }), [performerIdStr]);

  const { data: performerDetail, isError: isPerformerDetailError } = useQuery(
    ['getPerformer', performerId],
    () => getPerformer(`${performerId}`),
    { enabled: !!performerId, onError, retry: false },
  );

  const isPerformerInvalid: boolean = useMemo(
    () => checkIsPerformerInvalid({ performerId, isPerformerDetailError }),
    [performerId, isPerformerDetailError],
  );

  /** Performer type, e.g. nonSports / sports. Undefined if performer details are undefined. */
  const performerType: PerformerType | undefined = useMemo(
    () => getPerformerType(performerDetail),
    [performerDetail],
  );

  // Track analytics for page visits
  useEffect(() => {
    if (performerDetail) {
      trackPageViewEvent(
        trackEvent,
        TRACKING_PAGE_NAME.forPageVisits,
        {
          performer_id: performerDetail.id.toString(),
          performer: performerDetail.name,
        },
      );
    }
  }, [performerDetail, trackEvent]);

  // Track analytics for page visits
  useEffect(() => {
    if (performerType) {
      trackEvent('view_item_list', {
        ecommerce: {
          item_list_name: TRACKING_PAGE_NAME[performerType].forViewItemList,
        },
      });
    }
  }, [performerType, trackEvent]);

  // Set top disclaimer message based on the performer details
  useEffect(() => {
    setTopDisclaimer(performerDetail?.disclaimer);

    // Cleanup on component unmount
    return () => {
      setTopDisclaimer(undefined);
    };
  }, [performerDetail?.disclaimer, setTopDisclaimer]);

  /** Home/Away toggle state that is extracted from query parameters in the current URL */
  const homeAwayToggleState: HomeAwayToggleState | undefined = useMultiToggleStateFromUrl({ queryParamName: HOME_OR_AWAY_PARAM, multiToggleOptionIds: HomeAwayToggleStates });

  // Set performer Id to fetch the list of regions where performer has events
  useEffect(() => {
    if (performerId) {
      setPerformerId(performerId);
    }

    // Cleanup on component unmount
    return () => setPerformerId(undefined);
  }, [performerId, setPerformerId]);

  /** Region filter state that is extracted from query parameters in the current URL */
  const regionFilterState: RegionFilterState | undefined = useRegionFilterStateFromUrl({ regionsMap: regionsByPerformerIdMap });

  /**
   * Flag indicating if the region filter is enabled  based on the Home/Away toggle state.
   * Returns true when the Home/Away toggle state is either "all" (meaning neither "Home" nor "Away" is selected) or undefined (i.e. not set).
   */
  const isRegionFilterEnabled: boolean = useMemo(() => !homeAwayToggleState || homeAwayToggleState === 'all', [homeAwayToggleState]);

  // If region filter is disabled then remove the region filter parameter from the current URL by setting its value to an empty string.
  // This will reset region filter.
  useEffect(() => {
    if (!isRegionFilterEnabled) {
      appendQueryParamsToUrl({ [REGION_PARAM]: '' });
    }
  }, [isRegionFilterEnabled, appendQueryParamsToUrl]);

  /** Date filter state that is extracted from query parameters in the current URL */
  const dateFilterState: DateFilterState | undefined = useDateFilterStateFromUrl();
  const dateRange: Record<keyof DateRange, string> = useMemo(() => convertDateFilterStateToDateRange(dateFilterState), [dateFilterState]);

  // Update Event List context with isFetchEnabled flag
  useEffect(
    () => setIsFetchEnabled(!!account && !areRegionsByPerformerIdLoading && !!performerDetail),
    [account, areRegionsByPerformerIdLoading, performerDetail, setIsFetchEnabled],
  );

  // Update Event List context with static API parameters that will be merged with dynamic page index
  useEffect(() => {
    setStaticApiParams(buildPerformerPageStaticApiParams({
      performerId,
      performerType,
      account,
      homeAwayToggleState,
      regionFilterState,
      dateRange,
    }));
  }, [performerId, performerType, account, homeAwayToggleState, regionFilterState, dateRange, setStaticApiParams]);

  return {
    ...props,
    isPerformerInvalid,
    performerDetail,
    performerType,
    homeAwayToggleState,
    regionsByPerformerId,
    regionFilterState,
    isRegionFilterEnabled,
    dateFilterState,
  };
};
