import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AriaRoles } from '../../../lib/types';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import { AccountCard } from '../../molecules/AccountCard';
import { LabelledContent } from '../../molecules/LabelledContent';
import commonStyles from '../../pages/CheckoutPage/CheckoutPage.module.scss';
import { VividSeatsOrderNotificationsEmail } from './OrderInfo.constants';
import styles from './OrderInfo.module.scss';
import { usePresenter } from './OrderInfo.presenter';
import type { OrderInfoProps } from './OrderInfo.types';

export const OrderInfo: React.FC<OrderInfoProps> = (props) => {
  const {
    email,
    phoneNumber,
    accountCardImageUrl,
    accountCardLastFourDigits,
    remainingCashPrice,
    formattedRemainingCashPriceWithCents,
    appliedRewardUnits,
    formattedAppliedRewardUnitsWithUnitName,
    billingAddress: {
      firstName,
      lastName,
      streetAddress,
      city,
      state,
      postalCode,
      country,
    },
    goToPaymentInfo,
    openTermsOfUseModal,
    openTermsOfUseModalOnKeyDown,
    faceValueText,
    paymentInfoText,
    shouldShowTermsOfUseCheckbox,
    areTermsOfUseAccepted,
    toggleTermOfUseCheckbox,
    termsOfUseCheckboxIconAsset,
    termsOfUseCheckboxStyle,
    isTermsOfUseError,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  const contactInfoContent: ReactNode = (
    <div className={cx(commonStyles.column, styles.content, styles.withBottomBorder)}>
      <Text
        type='Subheading'
        size='Medium'
        style='SemiBold'
        colour='SubduedDark'
        value={t('orderInfo.contactInfo.title')}
      />
      <LabelledContent
        labelKey='orderInfo.contactInfo.email.label'
        item={email}
      />
    </div>
  );

  const paymentInfoContent: ReactNode = (
    <div className={cx(commonStyles.column, styles.content, styles.withBottomBorder)}>
      <div className={styles.paymentInfoTitleContainer}>
        <Text
          type='Subheading'
          size='Medium'
          style='SemiBold'
          colour='SubduedDark'
          value={t('orderInfo.paymentInfo.title')}
        />
        <Button
          type='Text'
          style='Text'
          size='Medium'
          focusable={true}
          text={{ value: t('orderInfo.paymentInfo.edit') }}
          onClick={goToPaymentInfo}
          className={commonStyles.actionButton}
          classes={{ text: commonStyles.actionButtonText }}
        />
      </div>
      <LabelledContent
        labelKey='orderInfo.paymentInfo.billingAddress.label'
        items={[`${firstName} ${lastName}`, streetAddress, `${city}, ${state}`, postalCode, country]}
      />
      <LabelledContent
        labelKey='orderInfo.paymentInfo.phoneNumber.label'
        item={phoneNumber}
      />
      <LabelledContent labelKey='orderInfo.paymentInfo.paymentMethod.label'>
        <AccountCard
          key={accountCardImageUrl}
          accountCardImageUrl={accountCardImageUrl}
          accountCardLastFourDigits={accountCardLastFourDigits}
          formattedRewardUnitsTotal='' // Do not show available reward units
          theme='subduedLight'
          areRewardUnitsBold={false}
        />
      </LabelledContent>
      {remainingCashPrice > 0 && (
        <LabelledContent
          labelKey='orderInfo.paymentInfo.totalCardPayment.label'
          item={formattedRemainingCashPriceWithCents}
        />
      )}
      {appliedRewardUnits > 0 && (
        <LabelledContent
          labelKey='orderInfo.paymentInfo.rewardsToBeRedeemed.label'
          item={formattedAppliedRewardUnitsWithUnitName}
        />
      )}
    </div>
  );

  const termsOfUseCheckbox: ReactNode = shouldShowTermsOfUseCheckbox && (
    <div className={cx(commonStyles.column, commonStyles.withGap, styles.termsOfUseCheckbox)}>
      <button
        type='button'
        onClick={toggleTermOfUseCheckbox}
        aria-label={t('orderInfo.termsOfUse.description')}
        aria-pressed={areTermsOfUseAccepted}
        className={cx(commonStyles.checkbox, { [commonStyles.boxError]: isTermsOfUseError })}
      >
        <Icon
          asset={termsOfUseCheckboxIconAsset}
          style={termsOfUseCheckboxStyle}
          className={commonStyles.checkboxIcon}
        />
        <Text
          size='Medium'
          style='Regular'
          colour='SubduedDark'
          value={t('orderInfo.termsOfUse.description')}
        />
      </button>
      {isTermsOfUseError && (
        <Text
          size='Medium'
          style='Regular'
          colour='Negative'
          value={t('orderInfo.termsOfUse.error')}
          ariaRole={AriaRoles.ALERT}
          ariaAtomic={true}
        />
      )}
    </div>
  );

  const confirmAndPayOrderContent: ReactNode = (
    <div className={cx(commonStyles.column, styles.content)}>
      <Text
        type='Subheading'
        size='Medium'
        style='SemiBold'
        colour='SubduedDark'
        value={t('orderInfo.confirmAndPlaceOrder.title')}
      />
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedLight'
        value={(
          <Trans
            i18nKey='orderInfo.confirmAndPlaceOrder.description'
            values={{ faceValueText, paymentInfoText }}
            components={{
              termsOfUseLink: (
                <a
                  tabIndex={0} // Add tab index to allow focus via tabbing (Note: href is not provided intentionally)
                  aria-label={t('orderInfo.termsOfUse.ariaLabel')}
                  onKeyDown={openTermsOfUseModalOnKeyDown}
                  onClick={openTermsOfUseModal}
                  className={styles.hyperLink}
                />
              ),
              vividSeatsOrderNotificationsEmail: (
                <a href={`mailto:${VividSeatsOrderNotificationsEmail}`} className={styles.hyperLink}>{VividSeatsOrderNotificationsEmail}</a>
              ),
            }}
          />
        )}
      />
      {termsOfUseCheckbox}
    </div>
  );

  return (
    <div className={cx(commonStyles.column, className)}>
      {contactInfoContent}
      {paymentInfoContent}
      {confirmAndPayOrderContent}
    </div>
  );
};
