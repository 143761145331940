import type { AccountCardDetail } from '../../../modules/auth/types';
import type { AccountCardProps } from '../AccountCard';
import styles from './AccountCards.module.scss';

/**
 * Builds an array of account card props.
 * @returns {AccountCardProps[]} Array of account card props.
 */
export const getAccountCardProps = (params: {
  /** Account card details */
  accountCardDetails: AccountCardDetail[];
}): AccountCardProps[] => {
  const { accountCardDetails } = params;

  return accountCardDetails.map((accountCardDetail: AccountCardDetail, key: number) => ({
    ...accountCardDetail,
    key,
    theme: 'subduedDark',
    areRewardUnitsBold: false,
    className: styles.accountCard,
  }));
};
