import { EXCLUSIVE_CATEGORY_MAP } from '../../../lib/constants';
import { convertArrayToMap } from '../../../lib/objectUtils';
import { THEATER_SUB_CATEGORIES } from '../../../modules/categories';
import i18n from '../../../modules/locale/i18n';
import type { EventsQueryParams } from '../../../modules/partnership';
import type { PresetFilterOption } from '../../organisms/PresetFilter';
import type { AllEventsTabKey } from './AllEventsPage.types';

export const TRACKING_PAGE_NAME = {
  forPageVisits: 'All Events Page',
  forEventCardClicks: 'All Events Page',
} as const;

export const ALL_EVENTS_PAGE_TAB_KEYS = ['allEvents', 'exclusives', 'music', 'sports', 'comedy', 'theater'] as const;

export const TABS_WITH_CATEGORY_FILTER: AllEventsTabKey[] = ['exclusives', 'music', 'sports', 'theater'];

export const TABS_WITH_PERFORMER_FILTER: AllEventsTabKey[] = ['sports'];

/** Map for tab specific API parameters */
export const ApiParamsByTabKey: Record<AllEventsTabKey, EventsQueryParams> = {
  allEvents: {},
  exclusives: {},
  music: { category: 'music' },
  sports: { category: 'sports' },
  comedy: {
    category: 'theatre',
    sub_category_id: THEATER_SUB_CATEGORIES.comedy,
  },
  theater: { category: 'theatre' },
};

export const ExclusivesTabCategoryFilterOptions: PresetFilterOption<keyof typeof EXCLUSIVE_CATEGORY_MAP>[] = Object.keys(EXCLUSIVE_CATEGORY_MAP).map(category => ({
  id: category as keyof typeof EXCLUSIVE_CATEGORY_MAP,
  name: i18n.t(`exclusiveCategories.${category}`),
}));

export const ExclusivesTabCategoryFilterOptionsMap: Record<keyof typeof EXCLUSIVE_CATEGORY_MAP, PresetFilterOption<keyof typeof EXCLUSIVE_CATEGORY_MAP>> = convertArrayToMap(ExclusivesTabCategoryFilterOptions);

export const THEATER_EXCLUDED_SUB_CATEGORY_IDS: number[] = [THEATER_SUB_CATEGORIES.comedy];
