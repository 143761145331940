import { useCallback, useContext, useMemo } from 'react';
import { useWindowSize } from '../../../lib/util';
import { MapContext } from '../../../modules/map';
import GenericEventImageAsset from '../../../resources/images/GenericEventImage.jpg';
import type { NonGuestListPreCheckoutModalPresenterProps, NonGuestListPreCheckoutModalProps } from './NonGuestListPreCheckoutModal.types';

export const usePresenter = (props: NonGuestListPreCheckoutModalProps): NonGuestListPreCheckoutModalPresenterProps => {
  const {
    eventMetadata: {
      eventHeroMobileImageUrl,
      eventHeroDesktopImageUrl,
    },
    closePreCheckout,
  } = props;

  const { setSelectedSection } = useContext(MapContext);

  const { isMobile } = useWindowSize();

  const eventImageUrl: string = useMemo(
    () => (isMobile ? eventHeroMobileImageUrl : eventHeroDesktopImageUrl) || GenericEventImageAsset,
    [isMobile, eventHeroMobileImageUrl, eventHeroDesktopImageUrl],
  );

  const closeNonGuestListPreCheckoutModal = useCallback(() => {
    closePreCheckout();

    // Reset selected section only if it was selected by hovering over a listing card
    setSelectedSection('reset-temporary-selection');
  }, [closePreCheckout, setSelectedSection]);

  return {
    ...props,
    eventImageUrl,
    closeNonGuestListPreCheckoutModal,
  };
};
