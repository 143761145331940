import type { BottomLogo } from './ListingDetailInfo.types';

/**
 * Gets logo to display at the bottom:
 * - 'capitalOne' if event is exclusive and listing has CP1 broker Id 29014.
 * - 'poweredByVividSeats' in all other cases.
 */
export const getBottomLogo = (params: {
  /** Indicates if the event is exclusive, i.e. it contains C1_EXCLUSIVE tag */
  isExclusiveEvent: boolean;
  /** Indicates if the listing has CP1 broker Id 29014 */
  hasCp1BrokerId: boolean;
}): BottomLogo => {
  const { isExclusiveEvent, hasCp1BrokerId } = params;
  return isExclusiveEvent && hasCp1BrokerId ? 'capitalOne' : 'poweredByVividSeats';
};
