import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { areObjectsEqual, checkArrayMinMaxLength } from '../../../lib/objectUtils';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackPageViewEvent, trackSelectContentEvent } from '../../../modules/analytics/util';
import { AuthContext } from '../../../modules/auth';
import { CATEGORIES, THEATER_SUB_CATEGORIES } from '../../../modules/categories';
import type { Event, EventsQueryParams, Performer, PerformersQueryParams } from '../../../modules/partnership';
import { getEvents, getTopPerformers } from '../../../modules/partnership/api';
import { RegionContext } from '../../../modules/region';
import { EventCard, type EventCardProps } from '../../molecules/EventCard';
import { PerformerCard, type PerformerCardProps } from '../../molecules/PerformerCard';
import type { CarouselSectionProps } from '../../organisms/CarouselSection';
import { checkIsCurrentRegionSelected, useRegionFilterStateFromUrl, type RegionFilterState } from '../../organisms/RegionFilter';
import { buildAllEventsPageUrlWithParams } from '../AllEventsPage';
import { CARDHOLDER_EXCLUSIVE_EVENTS_PAGE_SIZE, DEFAULT_PAGE_SIZE, MIN_CARDHOLDER_EXCLUSIVE_EVENTS_TO_SHOW, TRACKING_PAGE_NAME } from './HomePage.constants';
import type { HomePagePresenterProps, HomePageProps } from './HomePage.types';
import { buildCardholderExclusiveEventsQueryParams, buildPerformersQueryParams, buildUpcomingEventsQueryParams, filterMusicPerformers, getAllEventsLinkHref, getCardholderExclusiveEventsLinkHref, getCarouselSectionProps, getEventCardProps, getPerformerCardProps } from './HomePage.utils';

export const usePresenter = (props: HomePageProps): HomePagePresenterProps => {
  const { setTopDisclaimer } = props;

  const { account } = useContext(AuthContext);

  const { t } = useTranslation();

  const { trackEvent, onError } = useAnalyticsManager();

  const {
    areRegionsLoading,
    allRegions: regions,
    allRegionsMap: regionsMap,
    currentRegion,
    homePageRegionFilterState,
    setHomePageRegionFilterState,
  } = useContext(RegionContext);

  /** True while account and regions are loading */
  const isRequiredDataLoading: boolean = useMemo(() => !account || areRegionsLoading, [account, areRegionsLoading]);

  useEffect(() => {
    setTopDisclaimer(undefined);
  }, [setTopDisclaimer]);

  // Track analytics for page visits
  useEffect(() => {
    trackPageViewEvent(trackEvent, TRACKING_PAGE_NAME.forPageVisits);
  }, [trackEvent]);

  /** Region filter state that is extracted from query parameters in the current URL */
  const regionFilterStateFromUrl: RegionFilterState | undefined = useRegionFilterStateFromUrl({ regionsMap });

  // Update Home page region filter state in region context
  useEffect(() => {
    if (regionFilterStateFromUrl && !areObjectsEqual(regionFilterStateFromUrl, homePageRegionFilterState)) {
      setHomePageRegionFilterState(regionFilterStateFromUrl);
    }
  }, [regionFilterStateFromUrl, homePageRegionFilterState, setHomePageRegionFilterState]);

  /** Region filter state from either the current URL or from the region context */
  const regionFilterState: RegionFilterState = useMemo(
    () => regionFilterStateFromUrl || homePageRegionFilterState,
    [regionFilterStateFromUrl, homePageRegionFilterState],
  );

  const regionId = useMemo<number | undefined>(() => {
    return !areRegionsLoading
      ? (checkIsCurrentRegionSelected(regionFilterState) ? currentRegion : regionFilterState)?.id
      : undefined;
  }, [areRegionsLoading, regionFilterState, currentRegion]);

  // Cardholder Exclusive Events

  const cardholderExclusiveEventsQueryParams: EventsQueryParams | undefined = useMemo(
    () => buildCardholderExclusiveEventsQueryParams({ account }),
    [account],
  );

  const {
    data: cardholderExclusiveEventsResponse,
    isLoading: areCardholderExclusiveEventsLoading,
    error: cardholderExclusiveEventsError,
  } = useQuery(
    ['getCardholderExclusiveEvents', ...Object.values(cardholderExclusiveEventsQueryParams ?? {})],
    () => getEvents(cardholderExclusiveEventsQueryParams),
    { enabled: !!cardholderExclusiveEventsQueryParams, onError },
  );

  /**
   * If there are less than 3 cardholder exclusive events then return an empty array to hide Cardholder Exclusives carousel section.
   * If there are more than 4 cardholder exclusive events then return the first 4 cardholder exclusive events.
   */
  const cardholderExclusiveEvents: Event[] = useMemo(
    () => checkArrayMinMaxLength({
      array: cardholderExclusiveEventsResponse?.items,
      minLength: MIN_CARDHOLDER_EXCLUSIVE_EVENTS_TO_SHOW,
    }),
    [cardholderExclusiveEventsResponse?.items],
  );

  /** Link to Exclusive Events page if there are more than 4 cardholder exclusive events */
  const cardholderExclusiveEventsLinkHref: string | undefined = useMemo(
    () => getCardholderExclusiveEventsLinkHref({
      cardholderExclusiveEventsTotal: cardholderExclusiveEventsResponse?.total,
      minCardholderExclusiveEvents: CARDHOLDER_EXCLUSIVE_EVENTS_PAGE_SIZE,
    }),
    [cardholderExclusiveEventsResponse?.total],
  );

  /** Click handler for Cardholder Exclusives - View All button link */
  const onCardholderExclusiveEventsLinkClick = useCallback(() => trackEvent('select_content_view_all_c1x'), [trackEvent]);

  const cardholderExclusiveEventsCarouselSectionProps: CarouselSectionProps<EventCardProps> = useMemo(
    () => getCarouselSectionProps({
      linkHref: cardholderExclusiveEventsLinkHref,
      onLinkClick: onCardholderExclusiveEventsLinkClick,
      isLoading: !cardholderExclusiveEventsQueryParams || areCardholderExclusiveEventsLoading,
      isError: !!cardholderExclusiveEventsError,
      component: EventCard,
      componentProps: getEventCardProps({ events: cardholderExclusiveEvents, eventCardVariant: 'squareOnDark', carouselSectionPropsKey: 'cardholderExclusiveEvents' }),
      carouselSectionPropsKey: 'cardholderExclusiveEvents',
    }),
    [cardholderExclusiveEventsLinkHref, onCardholderExclusiveEventsLinkClick, cardholderExclusiveEventsQueryParams, areCardholderExclusiveEventsLoading, cardholderExclusiveEventsError, cardholderExclusiveEvents],
  );

  // Upcoming Events

  const upcomingEventsQueryParams: EventsQueryParams | undefined = useMemo(
    () => buildUpcomingEventsQueryParams({ isRequiredDataLoading, regionId }),
    [isRequiredDataLoading, regionId],
  );

  const {
    data: upcomingEventsResponse,
    isLoading: areUpcomingEventsLoading,
    error: upcomingEventsError,
  } = useQuery(
    ['getUpcomingEvents', ...Object.values(upcomingEventsQueryParams ?? {})],
    () => getEvents(upcomingEventsQueryParams),
    { enabled: !!upcomingEventsQueryParams, onError },
  );

  /** Link to All Events page if there are more than 8 upcoming events */
  const upcomingEventsLinkHref: string | undefined = useMemo(
    () => getAllEventsLinkHref({
      itemsTotal: upcomingEventsResponse?.total,
      minItems: DEFAULT_PAGE_SIZE,
      allEventsTabKey: undefined,
      regionFilterState,
    }),
    [upcomingEventsResponse?.total, regionFilterState],
  );

  const upcomingEventsCarouselSectionProps: CarouselSectionProps<EventCardProps> = useMemo(
    () => getCarouselSectionProps({
      linkHref: upcomingEventsLinkHref,
      isLoading: !upcomingEventsQueryParams || areUpcomingEventsLoading,
      isError: !!upcomingEventsError,
      component: EventCard,
      componentProps: getEventCardProps({ events: upcomingEventsResponse?.items, eventCardVariant: 'squareOnLight', carouselSectionPropsKey: 'upcomingEvents' }),
      carouselSectionPropsKey: 'upcomingEvents',
    }),
    [upcomingEventsLinkHref, upcomingEventsQueryParams, areUpcomingEventsLoading, upcomingEventsError, upcomingEventsResponse?.items],
  );

  // Music Performers

  const musicPerformersQueryParams: PerformersQueryParams | undefined = useMemo(
    () => buildPerformersQueryParams({
      isRequiredDataLoading,
      regionId,
      additionalQueryParams: {
        // Request 2 more music performers to account for Taylor Swift who needs to be filtered out and to check if we need to render View Events link
        pageSize: DEFAULT_PAGE_SIZE + 2,
        categoryId: CATEGORIES.music,
      },
    }),
    [isRequiredDataLoading, regionId],
  );

  const {
    data: unfilteredMusicPerformers,
    isLoading: areMusicPerformersLoading,
    error: musicPerformersError,
  } = useQuery(
    ['getMusicPerformers', ...Object.values(musicPerformersQueryParams ?? {})],
    () => getTopPerformers(musicPerformersQueryParams),
    { enabled: !!musicPerformersQueryParams, onError },
  );

  /** Up to 8 music performers with Taylor Swift filtered out */
  const musicPerformers: Performer[] = useMemo(
    () => filterMusicPerformers(unfilteredMusicPerformers),
    [unfilteredMusicPerformers],
  );

  /** Link to All Events page (Music tab) */
  const musicPerformersLinkHref: string | undefined = useMemo(
    () => buildAllEventsPageUrlWithParams({ tabKey: 'music', regionFilterState }),
    [regionFilterState],
  );

  const musicPerformersCarouselSectionProps: CarouselSectionProps<PerformerCardProps> = useMemo(
    () => getCarouselSectionProps({
      linkHref: musicPerformersLinkHref,
      isLoading: !musicPerformersQueryParams || areMusicPerformersLoading,
      isError: !!musicPerformersError,
      component: PerformerCard,
      componentProps: getPerformerCardProps({ performers: musicPerformers, carouselSectionPropsKey: 'musicPerformers' }),
      carouselSectionPropsKey: 'musicPerformers',
    }),
    [musicPerformersLinkHref, musicPerformersQueryParams, areMusicPerformersLoading, musicPerformersError, musicPerformers],
  );

  // Sports Performers

  const sportsPerformersQueryParams: PerformersQueryParams | undefined = useMemo(
    () => buildPerformersQueryParams({
      isRequiredDataLoading,
      regionId,
      additionalQueryParams: { categoryId: CATEGORIES.sports },
    }),
    [isRequiredDataLoading, regionId],
  );

  const {
    data: sportsPerformers,
    isLoading: areSportsPerformersLoading,
    error: sportsPerformersError,
  } = useQuery(
    ['getSportsPerformers', ...Object.values(sportsPerformersQueryParams ?? {})],
    () => getTopPerformers(sportsPerformersQueryParams),
    { enabled: !!sportsPerformersQueryParams, onError },
  );

  /** Link to All Events page (Sports tab) */
  const sportsPerformersLinkHref: string | undefined = useMemo(
    () => buildAllEventsPageUrlWithParams({ tabKey: 'sports', regionFilterState }),
    [regionFilterState],
  );

  const sportsPerformersCarouselSectionProps: CarouselSectionProps<PerformerCardProps> = useMemo(
    () => getCarouselSectionProps({
      linkHref: sportsPerformersLinkHref,
      isLoading: !sportsPerformersQueryParams || areSportsPerformersLoading,
      isError: !!sportsPerformersError,
      component: PerformerCard,
      componentProps: getPerformerCardProps({ performers: sportsPerformers?.slice(0, DEFAULT_PAGE_SIZE), carouselSectionPropsKey: 'sportsPerformers' }),
      carouselSectionPropsKey: 'sportsPerformers',
    }),
    [sportsPerformersLinkHref, sportsPerformersQueryParams, areSportsPerformersLoading, sportsPerformersError, sportsPerformers],
  );

  // Comedy Performers

  const comedyPerformersQueryParams: PerformersQueryParams | undefined = useMemo(
    () => buildPerformersQueryParams({
      isRequiredDataLoading,
      regionId,
      additionalQueryParams: {
        categoryId: CATEGORIES.theater,
        subCategoryId: THEATER_SUB_CATEGORIES.comedy,
      },
    }),
    [isRequiredDataLoading, regionId],
  );

  const {
    data: comedyPerformers,
    isLoading: areComedyPerformersLoading,
    error: comedyPerformersError,
  } = useQuery(
    ['getComedyPerformers', ...Object.values(comedyPerformersQueryParams ?? {})],
    () => getTopPerformers(comedyPerformersQueryParams),
    { enabled: !!comedyPerformersQueryParams, onError },
  );

  /** Link to All Events page (Comedy tab) */
  const comedyPerformersLinkHref: string | undefined = useMemo(
    () => buildAllEventsPageUrlWithParams({ tabKey: 'comedy', regionFilterState }),
    [regionFilterState],
  );

  const comedyPerformersCarouselSectionProps: CarouselSectionProps<PerformerCardProps> = useMemo(
    () => getCarouselSectionProps({
      linkHref: comedyPerformersLinkHref,
      isLoading: !comedyPerformersQueryParams || areComedyPerformersLoading,
      isError: !!comedyPerformersError,
      component: PerformerCard,
      componentProps: getPerformerCardProps({ performers: comedyPerformers?.slice(0, DEFAULT_PAGE_SIZE), carouselSectionPropsKey: 'comedyPerformers' }),
      carouselSectionPropsKey: 'comedyPerformers',
    }),
    [comedyPerformersLinkHref, comedyPerformersQueryParams, areComedyPerformersLoading, comedyPerformersError, comedyPerformers],
  );

  // Theater Performers

  const theaterPerformersQueryParams: PerformersQueryParams | undefined = useMemo(
    () => buildPerformersQueryParams({
      isRequiredDataLoading,
      regionId,
      additionalQueryParams: {
        categoryId: CATEGORIES.theater,
        excludedSubCategoryId: THEATER_SUB_CATEGORIES.comedy,
      },
    }),
    [isRequiredDataLoading, regionId],
  );

  const {
    data: theaterPerformers,
    isLoading: areTheaterPerformersLoading,
    error: theaterPerformersError,
  } = useQuery(
    ['getTheaterPerformers', ...Object.values(theaterPerformersQueryParams ?? {})],
    () => getTopPerformers(theaterPerformersQueryParams),
    { enabled: !!theaterPerformersQueryParams, onError },
  );

  /** Link to All Events page (Theater tab) */
  const theaterPerformersLinkHref: string | undefined = useMemo(
    () => buildAllEventsPageUrlWithParams({ tabKey: 'theater', regionFilterState }),
    [regionFilterState],
  );

  const theaterPerformersCarouselSectionProps: CarouselSectionProps<PerformerCardProps> = useMemo(
    () => getCarouselSectionProps({
      linkHref: theaterPerformersLinkHref,
      isLoading: !theaterPerformersQueryParams || areTheaterPerformersLoading,
      isError: !!theaterPerformersError,
      component: PerformerCard,
      componentProps: getPerformerCardProps({ performers: theaterPerformers?.slice(0, DEFAULT_PAGE_SIZE), carouselSectionPropsKey: 'theaterPerformers' }),
      carouselSectionPropsKey: 'theaterPerformers',
    }),
    [theaterPerformersLinkHref, theaterPerformersQueryParams, areTheaterPerformersLoading, theaterPerformersError, theaterPerformers],
  );

  const onMlbBannerButtonClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      TRACKING_PAGE_NAME.forBannerClicks,
      'Homepage MLB Banner',
      t('homePage.mlbBanner.buttonLabel'),
    );
  }, [t, trackEvent]);

  const onCardholderExclusivesBannerButtonClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      TRACKING_PAGE_NAME.forBannerClicks,
      'Bottom Banner',
      t('homePage.cardholderExclusivesBanner.buttonLabel'),
    );
    trackEvent('select_content_c1x_banner');
  }, [t, trackEvent]);

  return {
    ...props,
    cardholderExclusiveEventsCarouselSectionProps,
    upcomingEventsCarouselSectionProps,
    musicPerformersCarouselSectionProps,
    sportsPerformersCarouselSectionProps,
    comedyPerformersCarouselSectionProps,
    theaterPerformersCarouselSectionProps,
    regions,
    regionFilterState,
    onMlbBannerButtonClick,
    onCardholderExclusivesBannerButtonClick,
  };
};
