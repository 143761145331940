import cx from 'classnames';
import React, { useContext, useEffect, useMemo, useRef, type ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { useWindowSize } from '../../../lib/util';
import { TopNavContext } from '../../../modules/topNav';
import { SearchSuggestionBlock } from '../../blocks/SearchSuggestionBlock';
import { MobileSearchHeader } from '../MobileSearchHeader';
import styles from './SearchSuggestionsModal.module.scss';
import type { SearchSuggestionsModalProps } from './SearchSuggestionsModal.types';

export const SearchSuggestionsModal: React.FC<SearchSuggestionsModalProps> = () => {
  const { activeTopNavMenuType, searchSuggestions } = useContext(TopNavContext);

  const { isMobile } = useWindowSize();

  const searchSuggestionsView: ReactNode = useMemo(() => {
    return isMobile && !!searchSuggestions && (
      <SearchSuggestionBlock searchSuggestions={searchSuggestions} />
    );
  }, [isMobile, searchSuggestions]);

  const pageTopRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // When search suggestions change we need to scroll to the top of the page so that user can see the first search suggestion
    if (pageTopRef?.current) {
      // Using 'auto' behaviour to scroll instantly without scroll transition
      pageTopRef.current.scrollIntoView({ block: 'start', inline: 'start', behavior: 'auto' });
    }
  }, [searchSuggestionsView]);

  return <>
    {isMobile && activeTopNavMenuType === 'SearchMenu' && (
      <Modal
        className={styles.searchSuggestionsModal}
        dialogClassName={styles.modalDialog}
        contentClassName={styles.modalContent}
        show={true}
      >
        <Modal.Header className={styles.modalHeader}>
          <div className={cx(styles.topContent, styles.stickyTopContent)}>
            <MobileSearchHeader isInsideModal={true} />
          </div>
          <div ref={pageTopRef} className={cx(styles.topContent, 'topContentSpacer')} />
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          {searchSuggestionsView}
        </Modal.Body>
      </Modal>
    )}
  </>;
};
