import { useCallback, useMemo } from 'react';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import type { FilterOption } from '../../atoms/BaseFilter';
import type { PresetFilterOption, PresetFilterPresenterProps, PresetFilterProps } from './PresetFilter.types';
import { getPresetFilterOptions, selectFilterOption } from './PresetFilter.utils';

export const usePresenter = (props: PresetFilterProps): PresetFilterPresenterProps => {
  const {
    queryParamName,
    presetFilterState,
    presetFilterOptions,
  } = props;

  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  const filterOptions: FilterOption<PresetFilterOption>[] = useMemo(() => {
    return getPresetFilterOptions({ presetFilterState, presetFilterOptions });
  }, [presetFilterState, presetFilterOptions]);

  const onFilterOptionSelected = useCallback((presetFilterOptionValue: PresetFilterOption | undefined) => {
    selectFilterOption({ queryParamName, presetFilterOptionValue, appendQueryParamsToUrl });
  }, [queryParamName, appendQueryParamsToUrl]);

  return {
    ...props,
    filterOptions,
    onFilterOptionSelected,
  };
};
