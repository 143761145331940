import braintree, { type Client, type HostedFields } from 'braintree-web';
import type { HostedFieldsFieldDataFields } from 'braintree-web/hosted-fields';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import type { BraintreeElementsProps } from './Braintree.types';
import { BraintreeContext } from './BraintreeContext';

export const BraintreeElements: React.FC<BraintreeElementsProps> = (props) => {
  const { styles, isDisabled, children } = props;

  const { isLoading, error, client, instance, setInstance } = useContext(BraintreeContext);

  const cardNumberRef = useRef<HTMLDivElement>(null);
  const expiryRef = useRef<HTMLDivElement>(null);
  const cvvRef = useRef<HTMLDivElement>(null);

  const createHostedFields = useCallback(
    async (
      _client: Client,
      _styles: Record<string, unknown>,
      cardNumberContainer: HTMLDivElement,
      expiryContainer: HTMLDivElement,
      cvvContainer: HTMLDivElement,
    ) => {
      try {
        const currentFields: HostedFieldsFieldDataFields | undefined = instance?.getState().fields;

        const isSameInstance: boolean = !!currentFields
          && currentFields.number.container === cardNumberContainer
          && currentFields.expirationDate.container === expiryContainer
          && currentFields.cvv.container === cvvContainer;

        if (!isSameInstance) {
          const newInstance: HostedFields = await braintree.hostedFields.create({
            client: _client,
            styles: _styles,
            fields: {
              number: {
                container: cardNumberContainer,
                placeholder: '',
              },
              expirationDate: {
                container: expiryContainer,
                placeholder: 'MM/YY',
              },
              cvv: {
                container: cvvContainer,
                placeholder: 'CVV',
              },
            },
          });

          setInstance(newInstance);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [instance, setInstance],
  );

  useEffect(() => {
    if (isDisabled) {
      cardNumberRef.current?.children?.[0]?.setAttribute('tabIndex', '-1');
      expiryRef.current?.children?.[0]?.setAttribute('tabIndex', '-1');
      cvvRef.current?.children?.[0]?.setAttribute('tabIndex', '-1');
    } else {
      cardNumberRef.current?.children?.[0]?.setAttribute('tabIndex', '0');
      cvvRef.current?.children?.[0]?.setAttribute('tabIndex', '0');
      expiryRef.current?.children?.[0]?.setAttribute('tabIndex', '0');
    }
  }, [isDisabled]);

  useEffect(() => {
    if (client && cardNumberRef.current && expiryRef.current && cvvRef.current) {
      void createHostedFields(
        client,
        styles,
        cardNumberRef.current,
        expiryRef.current,
        cvvRef.current,
      );
    }
  }, [client, styles, cardNumberRef, expiryRef, cvvRef, createHostedFields]);

  return children(cardNumberRef, expiryRef, cvvRef, isLoading, error);
};
