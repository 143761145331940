import { useCallback, useContext } from 'react';
import { TopNavContext } from '../../../modules/topNav';
import type { SearchSuggestionItemPresenterProps, SearchSuggestionItemProps } from './SearchSuggestionItem.types';

export const usePresenter = (props: SearchSuggestionItemProps): SearchSuggestionItemPresenterProps => {
  const { onClick: initialOnClick } = props;

  const { setActiveTopNavMenuType } = useContext(TopNavContext);

  const onClick = useCallback(() => {
    setActiveTopNavMenuType(undefined);
    initialOnClick();
  }, [setActiveTopNavMenuType, initialOnClick]);

  const onEsc = useCallback(() => {
    setActiveTopNavMenuType(undefined);
  }, [setActiveTopNavMenuType]);

  return {
    ...props,
    onClick,
    onEsc,
  };
};
