import cx from 'classnames';
import React, { Fragment, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import { AccountCard } from '../../molecules/AccountCard';
import { LabelledContent } from '../../molecules/LabelledContent';
import { MarketingOptinMlb } from '../../molecules/MarketingOptinMlb';
import { getFormattedPhoneNumber } from '../CheckoutPage';
import PDFDoc from './checkoutOrderPrint/PdfDocument';
import PdfDocument from './checkoutOrderPrint/PdfDocumentGenerator';
import styles from './OrderConfirmationPage.module.scss';
import { usePresenter } from './OrderConfirmationPage.presenter';
import type { DeliveryNote, OrderConfirmationPageProps } from './OrderConfirmationPage.types';

export const OrderConfirmationPage: React.FC<OrderConfirmationPageProps> = (props) => {
  const orderConfirmationPagePresenterProps = usePresenter(props);

  const {
    orderId,
    billingAddress,
    accountCardImageUrl,
    accountCardLastFourDigits,
    listingDetailMetadata,
    paidCashPrice,
    formattedPaidCashPriceWithCents,
    redeemedRewardUnits,
    formattedRedeemedRewardUnitsWithUnitName,
    deliveryNotes,
    estimatedDeliveryDate,
    marketingOptinMlbProps,
    setPdfUrl,
    onPrintButtonClicked,
    className,
  } = orderConfirmationPagePresenterProps;

  const { eventMetadata, listingMetadata, pricing, seats } = listingDetailMetadata ?? {};
  const { eventName, eventDateTimeAndVenueDetails } = eventMetadata ?? {};
  const { sectionName, row, sellerNotes } = listingMetadata ?? {};

  const { t } = useTranslation();

  const messageContent: ReactNode = (
    <div className={styles.messageContent}>
      <Text
        type='Subheading'
        size='Medium'
        style='SemiBold'
        colour='SubduedDark'
        value={t('orderConfirmationInfo.messageContent.title')}
      />
      <Text
        type='Subheading'
        size='Small'
        style='Regular'
        colour='SubduedDark'
        value={t('orderConfirmationInfo.messageContent.description')}
      />
    </div>
  );

  const orderDetailsContent: ReactNode = (
    <div className={styles.orderDetailsContent}>
      <PdfDocument
        title=''
        setUrl={setPdfUrl}
        document={<PDFDoc {...orderConfirmationPagePresenterProps} />}
      />
      <div>
        <Text
          type='Subheading'
          size='Medium'
          style='SemiBold'
          colour='SubduedDark'
          value={t('orderConfirmationInfo.orderDetails.title')}
        />
        <Text
          size='Medium'
          style='Regular'
          colour='SubduedLight'
          value={t('orderConfirmationInfo.orderDetails.description', { orderId })}
        />
      </div>
      <Button
        type='IconText'
        style='Text'
        size='Small'
        icon={{ asset: 'Printer' }}
        text={{ value: t('orderConfirmation.print') }}
        onClick={onPrintButtonClicked}
        className={styles.printButton}
      />
    </div>
  );

  const topContent: ReactNode = (<>
    {messageContent}
    {orderDetailsContent}
  </>);

  const eventDetail: ReactNode = !!eventName && !!eventDateTimeAndVenueDetails && (
    <LabelledContent
      labelKey={eventName}
      items={[
        t('event.shortWeekDayShortDateTime', eventDateTimeAndVenueDetails),
        t('event.venueNameCityStateCode', eventDateTimeAndVenueDetails),
      ]}
    />
  );

  const seatingInfo: ReactNode = (
    <div className={styles.seatingInfo}>
      {!!sectionName && (
        <LabelledContent
          labelKey='listingDetailInfo.section'
          item={sectionName}
          itemTextSize='Large'
          className={styles.availableWidthItem}
        />
      )}
      {!!row && (
        <LabelledContent
          labelKey='listingDetailInfo.row'
          item={row}
          itemTextSize='Large'
          className={styles.fixedWidthItem}
        />
      )}
      {!!pricing?.quantity && (
        <LabelledContent
          labelKey='listingDetailInfo.quantity'
          item={pricing.quantity.toString()}
          itemTextSize='Large'
          className={styles.fixedWidthItem}
        />
      )}
      {!!seats && (
        <LabelledContent
          labelKey='listingDetailInfo.seats'
          item={seats}
          itemTextSize='Large'
          className={styles.fixedWidthItem}
        />
      )}
    </div>
  );

  const sellerNotesView: ReactNode = !!sellerNotes && (
    <LabelledContent
      labelKey='orderConfirmationInfo.ticketSummary.sellerNotes.title'
      item={sellerNotes}
    />
  );

  const ticketSummaryContent: ReactNode = (
    <div className={cx(styles.column, styles.sectionContent)}>
      <Text
        type='Subheading'
        size='Medium'
        style='SemiBold'
        colour='SubduedDark'
        value={t('orderConfirmationInfo.ticketSummary.title')}
      />
      {eventDetail}
      {seatingInfo}
      {sellerNotesView}
    </div>
  );

  const paymentInfoContent: ReactNode = (
    <div className={cx(styles.column, styles.sectionContent)}>
      <Text
        type='Subheading'
        size='Medium'
        style='SemiBold'
        colour='SubduedDark'
        value={t('orderConfirmationInfo.paymentInfo.title')}
      />
      <LabelledContent
        labelKey='orderConfirmationInfo.paymentInfo.billingAddress.label'
        items={[
          `${billingAddress?.first_name} ${billingAddress?.last_name}`,
          `${billingAddress?.address_line_1}`,
          ...(billingAddress?.address_line_2 ? [billingAddress?.address_line_2] : []),
          `${billingAddress?.city}, ${billingAddress?.region}`,
          `${billingAddress?.postal_code}`,
          `${billingAddress?.country}`,
        ]}
      />
      {!!billingAddress?.phone_number && (
        <LabelledContent
          labelKey='orderConfirmationInfo.paymentInfo.phoneNumber.label'
          item={getFormattedPhoneNumber(billingAddress.phone_number)}
        />
      )}
      {!!accountCardImageUrl && !!accountCardLastFourDigits && (
        <LabelledContent labelKey='orderConfirmationInfo.paymentInfo.paymentMethod.label'>
          <AccountCard
            key={accountCardImageUrl}
            accountCardImageUrl={accountCardImageUrl}
            accountCardLastFourDigits={accountCardLastFourDigits}
            formattedRewardUnitsTotal='' // Do not show available reward units
            theme='subduedLight'
            areRewardUnitsBold={false}
          />
        </LabelledContent>
      )}
      {!!paidCashPrice && paidCashPrice > 0 && !!formattedPaidCashPriceWithCents && (
        <LabelledContent
          labelKey='orderConfirmationInfo.paymentInfo.totalCardPayment.label'
          item={formattedPaidCashPriceWithCents}
        />
      )}
      {!!redeemedRewardUnits && redeemedRewardUnits > 0 && !!formattedRedeemedRewardUnitsWithUnitName && (
        <LabelledContent
          labelKey='orderConfirmationInfo.paymentInfo.rewardsToBeRedeemed.label'
          item={formattedRedeemedRewardUnitsWithUnitName}
        />
      )}
    </div>
  );

  const deliveryInfoContent: ReactNode = (
    <div className={cx(styles.column, styles.sectionContent)}>
      <Text
        type='Subheading'
        size='Medium'
        style='SemiBold'
        colour='SubduedDark'
        value={t('orderConfirmationInfo.deliveryNotes.title')}
      />
      <ul className={styles.deliveryNotes}>
        {deliveryNotes.map(({ description, isBulletPoint }: DeliveryNote, index: number) => {
          const descriptionNoteView: ReactNode = (
            <Text
              size='Medium'
              style='Regular'
              colour='SubduedLight'
              value={description}
            />
          );

          return (
            <Fragment key={index}>
              {!!isBulletPoint ? <li>{descriptionNoteView}</li> : descriptionNoteView}
            </Fragment>
          );
        })}
      </ul>
      {!!estimatedDeliveryDate && (
        <LabelledContent
          labelKey='orderConfirmationInfo.deliveryNotes.expectedDelivery.label'
          item={estimatedDeliveryDate}
        />
      )}
    </div>
  );

  const marketingOptinMlb: ReactNode = !!marketingOptinMlbProps && (
    <MarketingOptinMlb {...marketingOptinMlbProps} />
  );

  return (
    <div className={cx(styles.orderConfirmationPage, className)}>
      <div className={cx(styles.column, styles.contentContainer)}>
        {topContent}
        <div className={styles.content}>
          <div className={cx(styles.column, styles.leftContent)}>
            {ticketSummaryContent}
            {paymentInfoContent}
            {marketingOptinMlb}
          </div>
          <div className={cx(styles.column, styles.rightContent)}>
            {deliveryInfoContent}
          </div>
        </div>
      </div>
    </div>
  );
};
