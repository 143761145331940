import React, { createContext, useMemo, useState } from 'react';
import type { EventsQueryParams } from '../partnership';
import { EventListContextInitialValue } from './EventListContext.constants';
import type { EventListContextValue } from './EventListContext.types';

export const EventListContext = createContext<EventListContextValue>(EventListContextInitialValue);

export const EventListProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  // Flag to indicate that API fetch is enabled
  const [isFetchEnabled, setIsFetchEnabled] = useState<boolean>(false);

  // Static API parameters that will be merged with dynamic page index
  const [staticApiParams, setStaticApiParams] = useState<EventsQueryParams>({});

  //  Total number of events
  const [eventsTotal, setEventsTotal] = useState<number>(0);

  const value: EventListContextValue = useMemo(() => ({
    isFetchEnabled,
    setIsFetchEnabled,
    staticApiParams,
    setStaticApiParams,
    eventsTotal,
    setEventsTotal,
  }), [isFetchEnabled, staticApiParams, eventsTotal]);

  return (
    <EventListContext.Provider value={value}>
      {children}
    </EventListContext.Provider>
  );
};
