import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import styles from './Banner.module.scss';
import { usePresenter } from './Banner.presenter';
import type { BannerProps } from './Banner.types';

export const Banner: React.FC<BannerProps> = (props) => {
  const {
    title,
    description,
    backgroundImageUrl,
    href,
    buttonLabel,
    buttonType,
    buttonStyle,
    buttonSize,
    onButtonClick,
    iconAsset,
    iconStyle,
    isBannerClickable = false,
    className,
    classes,
  } = usePresenter(props);

  const bannerContent: ReactNode = (
    <div className={cx(styles.content, classes?.content)}>
      <Text
        size='Large'
        style='SemiBold'
        colour='BaseLight'
        align='Center'
        value={title}
        className={classes?.title}
      />
      {!!description && (
        <Text
          type='Body'
          size='Large'
          style='Regular'
          colour='BaseLight'
          align='Center'
          value={description}
          className={classes?.description}
        />
      )}
      <Button
        type={buttonType}
        style={buttonStyle}
        size={buttonSize}
        icon={{
          asset: iconAsset,
          style: iconStyle,
        }}
        text={{ value: buttonLabel }}
        href={isBannerClickable ? undefined : href} // If banner is clickable then the button should not have a href
        onClick={isBannerClickable ? undefined : onButtonClick} // If banner is clickable then the button should not have an onClick handler
        className={cx(styles.buttonContainer, classes?.buttonContainer)}
        classes={{
          content: cx(styles.buttonContent, classes?.buttonContent),
          text: cx(styles.buttonText, classes?.buttonText),
          icon: cx(styles.buttonIcon, classes?.buttonIcon),
        }}
      />
    </div>
  );

  const clickableBanner: ReactNode = isBannerClickable && (
    <Link
      to={href}
      className={cx(styles.banner, styles.clickableBanner, classes?.banner)}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      onClick={onButtonClick}
      tabIndex={0}
    >
      {bannerContent}
    </Link>
  );

  const nonClickableBanner: ReactNode = !isBannerClickable && (
    <div
      className={cx(styles.banner, classes?.banner)}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      {bannerContent}
    </div>
  );

  return (
    <div className={cx(styles.bannerContainer, className)}>
      {isBannerClickable ? clickableBanner : nonClickableBanner}
    </div>
  );
};
