import type { StepItemProps, StepItemState } from '../StepItem';

/** Gets an array of step item props for rendering the checkout steps */
export const getStepItemProps = (params: {
  /** 1-based current step index */
  step: number;
  /** Array of translation keys for step item labels */
  stepItemLabelKeys: readonly string[];
}): StepItemProps[] => {
  const { step, stepItemLabelKeys } = params;

  // Return an empty array is step index is out of bound
  if (step < 1 || step > stepItemLabelKeys.length) {
    return [];
  }

  return stepItemLabelKeys.map((stepItemLabelKey: string, index: number) => {
    const currentStep: number = index + 1;

    /**
     * State of the current step:
     * - 'completed' if it is before the current step
     * - 'active' if it is the current step
     * - 'inCompleted' if it is after the current step
     */
    const stepItemState: StepItemState =
      currentStep === step
        ? 'active'
        : currentStep < step ? 'completed' : 'inCompleted';

    return {
      key: currentStep,
      stepItemLabelKey,
      stepItemState,
    };
  });
};
