import { Dispatch, SetStateAction } from 'react';
import { CategoryMenuItemState } from '../../../modules/navigation/Navigation.types';
import { SubmenuTabItemCombinedProps } from '../../molecules/SubmenuTabItem/types';

export const buildCategoryTabs = (
  topNavTabsData: CategoryMenuItemState[],
  onItemClicked: Dispatch<SetStateAction<string | undefined>>,
  onMouseOver: (id: string | undefined) => void,
  openDropdownId?: string,
): SubmenuTabItemCombinedProps[] => {
  return topNavTabsData.map((category): SubmenuTabItemCombinedProps => ({
    state: openDropdownId === category.id ? 'Selected' : 'Default',
    id: category.id,
    text: {
      value: category.name,
    },
    dividerPosition: category.hasTopBorder ? 'Top' : 'None',
    onItemClicked: () => onItemClicked(category.id),
    onMouseOver: () => onMouseOver(category.id),
  }));
};
