import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { StepItem, type StepItemProps } from '../StepItem';
import styles from './CheckoutStepper.module.scss';
import { usePresenter } from './CheckoutStepper.presenter';
import type { CheckoutStepperProps } from './CheckoutStepper.types';

export const CheckoutStepper: React.FC<CheckoutStepperProps> = (props) => {
  const {
    stepItemProps,
    className,
  } = usePresenter(props);

  const stepItems: ReactNode = stepItemProps.map((currentStepItemProps: StepItemProps) => (
    <StepItem
      {...currentStepItemProps}
      key={currentStepItemProps.key}
    />
  ));

  return (
    <div className={cx(styles.checkoutStepper, className)}>
      {stepItems}
    </div>
  );
};
