import { useContext, useState } from 'react';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { EXCLUSIVE_ID } from '../../../modules/navigation/Navigation.constants';
import { TopNavContext } from '../../../modules/topNav';
import type { SubmenuTabItemCombinedProps } from '../../molecules/SubmenuTabItem/types';
import { buildCategoryTabs } from './SubmenuWithDoubleTabs.utils';
import type { SubmenuWithDoubleTabsCombinedProps } from './types';

const useSubmenuWithDoubleTabsPresenter = (props: SubmenuWithDoubleTabsCombinedProps): SubmenuWithDoubleTabsCombinedProps => {
  const { trackEvent } = useAnalyticsManager();

  // Get the top navigation data from context
  const { categoryMenuItemStates, hasCategoryMenuItemStates } = useContext(TopNavContext);

  const [openDropdownId, setOpenDropdownId] = useState<string | undefined>(categoryMenuItemStates && categoryMenuItemStates[0]?.id);

  const openCategory = categoryMenuItemStates?.find((tab) => tab?.id === openDropdownId);
  const onTabHover = (id: string | undefined) => setOpenDropdownId(id);

  // Constructing the desktop navigation tabs for categories
  const categoryTabList: SubmenuTabItemCombinedProps[] = buildCategoryTabs(categoryMenuItemStates, setOpenDropdownId, onTabHover, openDropdownId);

  // Submenu configuration with tabs for Category which has SubCategory
  const submenuWithTabs = {
    data: categoryMenuItemStates,
    isOpen: true,
    id: openDropdownId,
  };

  // Submenu configuration with tabs for Category without SubCategory.
  const submenuWithoutTabs = {
    data: categoryMenuItemStates,
    id: openDropdownId,
    isOpen: true,
    onItemClicked: (event) => {
      if (openCategory?.id === EXCLUSIVE_ID && event?.name !== 'Presales') {
        trackEvent('select_content_c1x_navigation-menu');
      }
    },
  };

  return {
    ...props,
    hasCategoryMenuItemStates,
    openDropdownId,
    sportsMenuTabList: {
      submenuTabItems: categoryTabList,
    },
    activeMenu: openCategory,
    submenuWithTabs: submenuWithTabs,
    submenuWithoutTabs: submenuWithoutTabs,
  };
};

export default useSubmenuWithDoubleTabsPresenter;
