import { useCallback, useContext, type SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { TopNavContext } from '../../../modules/topNav';
import type { SubmenuTabItemCombinedProps } from './types';

const usePresenter = (props: SubmenuTabItemCombinedProps): SubmenuTabItemCombinedProps => {
  const { linkPath, onItemClicked: initialOnItemClicked } = props;

  const { setActiveTopNavMenuType } = useContext(TopNavContext);

  const navigate = useNavigate();

  const onItemClicked = useCallback((event: SyntheticEvent) => {
    event.preventDefault();

    if (linkPath) {
      setActiveTopNavMenuType(undefined);
      navigate(linkPath);
    }

    initialOnItemClicked?.(event);
  }, [linkPath, initialOnItemClicked, setActiveTopNavMenuType, navigate]);

  return {
    ...props,
    onItemClicked,
  };
};

export default usePresenter;
